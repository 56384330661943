import React from 'react';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import InputMask from 'react-input-mask';
import {
  Input,
  Grid,
  Message,
  Button,
  Icon,
  Checkbox,
  Dropdown,
} from 'semantic-ui-react';
import {
  USERNAME,
  PASSWORD,
  CONFIRM_PASSWORD,
  FIRST_NAME,
  LAST_NAME,
  EMAIL,
  PHONE_NUMBER,
  IS_ADMINISTRATOR,
  INITIALS,
  CELL_PHONE,
  ACCOUNT_TYPE,
  DEALER_ID,
  USER_ID,
} from './FormSetup/FieldNames';
import ValidationSchema from './FormSetup/ValidationSchema';
import { accountTypeList } from '../StaticLists';
import DealerDropdownList from '../Dealers/DealerDropdownList';
import { saveUser, showUserForm } from '../../actions';

const UserForm = (props) => {
  //console.log('User Form Props', props);
  const formInitialValues = {
    [USER_ID]: parseInt(props.userForm.userId),
    [USERNAME]: props.userForm.username,
    [PASSWORD]: '',
    [CONFIRM_PASSWORD]: '',
    [FIRST_NAME]: props.userForm.firstName,
    [LAST_NAME]: props.userForm.lastName,
    [EMAIL]: props.userForm.email,
    [PHONE_NUMBER]: props.userForm.phoneNumber,
    [IS_ADMINISTRATOR]: props.userForm.isAdministrator,
    [INITIALS]: props.userForm.initials,
    [CELL_PHONE]: props.userForm.cellPhone,
    [ACCOUNT_TYPE]:
      props.userForm.accountType !== undefined
        ? props.userForm.accountType
        : 'employee',
    [DEALER_ID]: props.userForm.dealerId,
  };

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={ValidationSchema}
      enableReinitialize={true}
      onSubmit={(values) => props.saveUser(values)}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        values,
        errors,
        touched,
        initialValues,
      }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <Grid columns={2} stackable>
              <Grid.Row className='header-style'>
                <Grid.Column>User Information</Grid.Column>
              </Grid.Row>
              <Grid.Column width={16}>
                <Message>
                  Passwords must be at least 8 Characters long, have at least 1
                  Uppercase Letter, 1 Lowercase Letter, 1 Number and 1 Special
                  Character
                </Message>
              </Grid.Column>
              <Grid.Column className='brm-form-label brm-form-row'>
                Username
              </Grid.Column>
              <Grid.Column className='brm-form-row'>
                <Input
                  type='text'
                  name={USERNAME}
                  className='brm-form-input'
                  defaultValue={initialValues[USERNAME]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors[USERNAME] && touched[USERNAME] ? (
                  <Message negative size='tiny'>
                    {errors[USERNAME]}
                  </Message>
                ) : null}
              </Grid.Column>
              <Grid.Column className='brm-form-label brm-form-row'>
                Password
              </Grid.Column>
              <Grid.Column className='brm-form-row'>
                <Input
                  type='password'
                  name={PASSWORD}
                  className='brm-form-input'
                  defaultValue={initialValues[PASSWORD]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors[PASSWORD] && touched[PASSWORD] ? (
                  <Message negative size='tiny'>
                    {errors[PASSWORD]}
                  </Message>
                ) : null}
              </Grid.Column>
              <Grid.Column className='brm-form-label brm-form-row'>
                Confirm Password
              </Grid.Column>
              <Grid.Column className='brm-form-row'>
                <Input
                  type='password'
                  name={CONFIRM_PASSWORD}
                  className='brm-form-input'
                  defaultValue={initialValues[CONFIRM_PASSWORD]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors[CONFIRM_PASSWORD] && touched[CONFIRM_PASSWORD] ? (
                  <Message negative size='tiny'>
                    {errors[CONFIRM_PASSWORD]}
                  </Message>
                ) : null}
              </Grid.Column>
              <Grid.Column className='brm-form-label brm-form-row'>
                First Name
              </Grid.Column>
              <Grid.Column className='brm-form-row'>
                <Input
                  type='text'
                  name={FIRST_NAME}
                  className='brm-form-input'
                  defaultValue={initialValues[FIRST_NAME]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors[FIRST_NAME] && touched[FIRST_NAME] ? (
                  <Message negative size='tiny'>
                    {errors[FIRST_NAME]}
                  </Message>
                ) : null}
              </Grid.Column>
              <Grid.Column className='brm-form-label brm-form-row'>
                Last Name
              </Grid.Column>
              <Grid.Column className='brm-form-row'>
                <Input
                  type='text'
                  name={LAST_NAME}
                  className='brm-form-input'
                  defaultValue={initialValues[LAST_NAME]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors[LAST_NAME] && touched[LAST_NAME] ? (
                  <Message negative size='tiny'>
                    {errors[LAST_NAME]}
                  </Message>
                ) : null}
              </Grid.Column>
              <Grid.Column className='brm-form-label brm-form-row'>
                Initials
              </Grid.Column>
              <Grid.Column className='brm-form-row'>
                <Input
                  type='text'
                  name={INITIALS}
                  className='brm-form-input'
                  defaultValue={initialValues[INITIALS]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors[INITIALS] && touched[INITIALS] ? (
                  <Message negative size='tiny'>
                    {errors[INITIALS]}
                  </Message>
                ) : null}
              </Grid.Column>
              <Grid.Column className='brm-form-label brm-form-row'>
                Email
              </Grid.Column>
              <Grid.Column className='brm-form-row'>
                <Input
                  type='text'
                  name={EMAIL}
                  className='brm-form-input'
                  defaultValue={initialValues[EMAIL]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors[EMAIL] && touched[EMAIL] ? (
                  <Message negative size='tiny'>
                    {errors[EMAIL]}
                  </Message>
                ) : null}
              </Grid.Column>
              <Grid.Column className='brm-form-label brm-form-row'>
                Phone Extension
              </Grid.Column>
              <Grid.Column className='brm-form-row'>
                <Input
                  type='text'
                  name={PHONE_NUMBER}
                  className='brm-form-input'
                  defaultValue={initialValues[PHONE_NUMBER]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid.Column>
              <Grid.Column className='brm-form-label brm-form-row'>
                Cell Phone
              </Grid.Column>
              <Grid.Column className='brm-form-row'>
                <InputMask
                  mask='(999) 999-9999'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values[CELL_PHONE]}
                >
                  {(inputProps) => (
                    <Input
                      {...inputProps}
                      type='text'
                      name={CELL_PHONE}
                      className='brm-form-input'
                      defaultValue={initialValues[CELL_PHONE]}
                    />
                  )}
                </InputMask>
              </Grid.Column>
              <Grid.Column className='brm-form-label brm-form-row'>
                Account Type
              </Grid.Column>
              <Grid.Column className='brm-form-row'>
                <Dropdown
                  className='brm-form-dropdown'
                  name={ACCOUNT_TYPE}
                  selection
                  selectOnBlur={true}
                  options={accountTypeList}
                  defaultValue={initialValues[ACCOUNT_TYPE]}
                  onChange={(e, { value }) =>
                    setFieldValue(ACCOUNT_TYPE, value)
                  }
                />
              </Grid.Column>
              {'dealer' === values[ACCOUNT_TYPE] && (
                <>
                  <Grid.Column className='brm-form-label brm-form-row'>
                    Dealership
                  </Grid.Column>
                  <Grid.Column className='brm-form-row'>
                    <DealerDropdownList
                      dealerId={values[DEALER_ID]}
                      setSelectedDealerId={(value) =>
                        setFieldValue(DEALER_ID, value)
                      }
                    />
                  </Grid.Column>
                </>
              )}
              <Grid.Row>
                <Grid.Column>
                  <Button
                    type='button'
                    className='inverted-button'
                    onClick={() => props.showUserForm(false)}
                  >
                    Cancel
                  </Button>
                  <Button type='submit' className='inverted-button'>
                    <Icon name='save' />
                    SAVE &amp; CLOSE
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state) => {
  return {
    userForm: state.users.userForm,
  };
};

export default connect(mapStateToProps, { saveUser, showUserForm })(UserForm);
