export const invoiceStatus = [
  {
    key: 'receivedFromDealer',
    text: 'Received From Dealer',
    value: 'Received From Dealer',
  },
  {
    key: 'awiatingDMVReturn',
    text: 'Awaiting DMV Return',
    value: 'Awaiting DMV Return',
  },
  {
    key: 'awiatingMoreInfo',
    text: 'Awaiting More Info',
    value: 'Awaiting More Info',
  },
  {
    key: 'awaitingDealerReturn',
    text: 'Awaiting Dealer Return',
    value: 'Awaiting Dealer Return',
  },
  {
    key: 'cancelled',
    text: 'Cancelled',
    value: 'Cancelled',
  },
  {
    key: 'checkInPendingFolder',
    text: 'Check in pending folder',
    value: 'Check in pending folder',
  },
  {
    key: 'cod',
    text: 'COD',
    value: 'COD',
  },
  {
    key: 'complete',
    text: 'Complete',
    value: 'Complete',
  },
  {
    key: 'dealerWantsReturned',
    text: 'Dealer wants Returned',
    text: 'Dealer wants Returned',
  },
  {
    key: 'pendingFolder',
    text: 'Pending Folder',
    value: 'Pending Folder',
  },
  {
    key: 'pspProcessing',
    text: 'PSP Processing',
    value: 'PSP Processing',
  },
  {
    key: 'regDraw',
    text: 'Reg. Draw',
    value: 'Reg. Draw',
  },
  {
    key: 'returnToDealer',
    text: 'Return to Dealer',
    value: 'Return To Dealer',
  },
  {
    key: 'rejected',
    text: 'Rejected',
    value: 'Rejected',
  },
];

export const dateFilterList = [
  {
    key: 'custom',
    value: 'custom',
    text: 'Custom',
  },
  {
    key: 'today',
    value: 'today',
    text: 'Today',
  },
  {
    key: 'yesterday',
    value: 'yesterday',
    text: 'Yesterday',
  },
  {
    key: 'week',
    value: 'week',
    text: 'Last 7 Days',
  },
  {
    key: 'thirtyday',
    value: 'thirtyday',
    text: 'Last 30 Days',
  },
  {
    key: 'nintyday',
    value: 'nintyday',
    text: 'Last 90 Days',
  },
  {
    key: 'year',
    value: 'year',
    text: 'Past Year',
  },
];

export const balanceSelect = [
  {
    key: 'all',
    value: 'all',
    text: 'All',
  },
  {
    key: 'yes',
    value: 'yes',
    text: 'Yes',
  },
  {
    key: 'no',
    value: 'no',
    text: 'No',
  },
];

export const resultPageSizeOpts = [
  {
    key: 5,
    value: 5,
    text: 'Show 5',
  },
  {
    key: 10,
    value: 10,
    text: 'Show 10',
  },
  {
    key: 25,
    value: 25,
    text: 'Show 25',
  },
  {
    key: 50,
    value: 50,
    text: 'Show 50',
  },
  {
    key: 100,
    value: 100,
    text: 'Show 100',
  },
];

export const txnTypes = [
  {
    key: 1,
    text: 'INTRANSIT',
    value: 'INTRANSIT',
  },
  {
    key: 2,
    text: '2290-H -ISALESTAX',
    value: '2290-H -ISALESTAX',
  },
  {
    key: 3,
    text: 'ABSTRACT',
    value: 'ABSTRACT',
  },
  {
    key: 4,
    text: 'ADD LIEN',
    value: 'ADD LIEN',
  },
  {
    key: 5,
    text: 'ADD LIEN/REMOVE LIEN',
    value: 'ADD LIEN/REMOVE LIEN',
  },
  {
    key: 6,
    text: 'ADDRESS CHANGE',
    value: 'ADDRESS CHANGE',
  },
  {
    key: 7,
    text: 'ALBANY SPECIAL',
    value: 'ALBANY SPECIAL',
  },
  {
    key: 8,
    text: 'AMENDMENT',
    value: 'AMENDMENT',
  },
  {
    key: 9,
    text: 'BOAT',
    value: 'BOAT',
  },
  {
    key: 10,
    text: 'BOUNCED CHECK',
    value: 'BOUNCED CHECK',
  },
  {
    key: 11,
    text: 'CARFAX',
    value: 'CARFAX',
  },
  {
    key: 12,
    text: 'CHANGE OF ADDRESS',
    value: 'CHANGE OF ADDRESS',
  },
  {
    key: 13,
    text: 'CONVICTION STUB',
    value: 'CONVICTION STUB',
  },
  {
    key: 14,
    text: 'CORP CODE',
    value: 'CORP CODE',
  },
  {
    key: 15,
    text: 'CREDIT',
    value: 'CREDIT',
  },
  {
    key: 16,
    text: 'DDC',
    value: 'DDC',
  },
  {
    key: 17,
    text: 'DEFENSIVE DRIVING COURSE',
    value: 'DEFENSIVE DRIVING COURSE',
  },
  {
    key: 18,
    text: 'DOT Number',
    value: 'DOT Number',
  },
  {
    key: 19,
    text: 'DUP FS-6T',
    value: 'DUP FS-6T',
  },
  {
    key: 20,
    text: 'DUP REG',
    value: 'DUP REG',
  },
  {
    key: 21,
    text: 'DUP TITLE',
    value: 'DUP TITLE',
  },
  {
    key: 22,
    text: 'Duplicate Registration',
    value: 'Duplicate Registration',
  },
  {
    key: 23,
    text: 'EZ PASS',
    value: 'EZ PASS',
  },
  {
    key: 24,
    text: 'fax',
    value: 'fax',
  },
  {
    key: 25,
    text: 'FED EX USE',
    value: 'FED EX USE',
  },
  {
    key: 26,
    text: 'FS6T',
    value: 'FS6T',
  },
  {
    key: 27,
    text: 'HUT PERMIT',
    value: 'HUT PERMIT',
  },
  {
    key: 28,
    text: 'In Transit',
    value: 'In Transit',
  },
  {
    key: 29,
    text: 'Insurance',
    value: 'Insurance',
  },
  {
    key: 30,
    text: 'INSURANCE LAPSE',
    value: 'INSURANCE LAPSE',
  },
  {
    key: 31,
    text: 'IRP PLATES',
    value: 'IRP PLATES',
  },
  {
    key: 32,
    text: 'License Monitoring',
    value: 'License Monitoring',
  },
  {
    key: 33,
    text: 'LICENSE RENEWAL',
    value: 'LICENSE RENEWAL',
  },
  {
    key: 34,
    text: 'LIEN',
    value: 'LIEN',
  },
  {
    key: 35,
    text: 'Lien Correction',
    value: 'Lien Correction',
  },
  {
    key: 36,
    text: 'MD PLATES',
    value: 'MD PLATES',
  },
  {
    key: 37,
    text: 'MOVING VIOLATION',
    value: 'MOVING VIOLATION',
  },
  {
    key: 38,
    text: 'MVR',
    value: 'MVR',
  },
  {
    key: 39,
    text: 'MVT STICKER',
    value: 'MVT STICKER',
  },
  {
    key: 40,
    text: 'NJ REG',
    value: 'NJ REG',
  },
  {
    key: 41,
    text: 'PASSPORT',
    value: 'PASSPORT',
  },
  {
    key: 42,
    text: 'petti cash',
    value: 'petti cash',
  },
  {
    key: 43,
    text: 'Pick up stored plates',
    value: 'Pick up stored plates',
  },
  {
    key: 44,
    text: 'Plate Surrender',
    value: 'Plate Surrender',
  },
  {
    key: 45,
    text: 'Plates Registration',
    value: 'Plates Registration',
  },
  {
    key: 46,
    text: 'PVB',
    value: 'PVB',
  },
  {
    key: 47,
    text: 'REMOVE LIEN',
    value: 'REMOVE LIEN',
  },
  {
    key: 48,
    text: 'RENEWAL',
    value: 'RENEWAL',
  },
  {
    key: 49,
    text: 'REPLACEMENT PLATES',
    value: 'REPLACEMENT PLATES',
  },
  {
    key: 50,
    text: 'REPO PAPERS',
    value: 'REPO PAPERS',
  },
  {
    key: 51,
    text: 'SALES TAX ONLY',
    value: 'SALES TAX ONLY',
  },
  {
    key: 52,
    text: 'SALVAGE',
    value: 'SALVAGE',
  },
  {
    key: 53,
    text: 'Storage',
    value: 'Storage',
  },
  {
    key: 54,
    text: 'STORE PLATES',
    value: 'STORE PLATES',
  },
  {
    key: 55,
    text: 'Surrender Plates',
    value: 'Surrender Plates',
  },
  {
    key: 56,
    text: 'TITLE CORRECTION',
    value: 'TITLE CORRECTION',
  },
  {
    key: 57,
    text: 'TITLE ONLY',
    value: 'TITLE ONLY',
  },
  {
    key: 58,
    text: 'TRANSFER PLATES',
    value: 'TRANSFER PLATES',
  },
  {
    key: 59,
    text: 'TRANSFER PLATES/RENEWAL',
    value: 'TRANSFER PLATES/RENEWAL',
  },
  {
    key: 60,
    text: 'TVB',
    value: 'TVB',
  },
];

export const usaList = [
  {
    key: 1,
    text: 'Alabama',
    value: 'Alabama',
  },
  {
    key: 2,
    text: 'Alaska',
    value: 'Alaska',
  },
  {
    key: 3,
    text: 'Arizona',
    value: 'Arizona',
  },
  {
    key: 4,
    text: 'Arkansas',
    value: 'Arkansas',
  },
  {
    key: 5,
    text: 'California',
    value: 'California',
  },
  {
    key: 6,
    text: 'Colorado',
    value: 'Colorado',
  },
  {
    key: 7,
    text: 'Connecticut',
    value: 'Connecticut',
  },
  {
    key: 8,
    text: 'Delaware',
    value: 'Delaware',
  },
  {
    key: 9,
    text: 'District of Columbia',
    value: 'District of Columbia',
  },
  {
    key: 10,
    text: 'Florida',
    value: 'Florida',
  },
  {
    key: 11,
    text: 'Georgia',
    value: 'Georgia',
  },
  {
    key: 12,
    text: 'Hawaii',
    value: 'Hawaii',
  },
  {
    key: 13,
    text: 'Idaho',
    value: 'Idaho',
  },
  {
    key: 14,
    text: 'Illinois',
    value: 'Illinois',
  },
  {
    key: 15,
    text: 'Indiana',
    value: 'Indiana',
  },
  {
    key: 16,
    text: 'Iowa',
    value: 'Iowa',
  },
  {
    key: 17,
    text: 'Kansas',
    value: 'Kansas',
  },
  {
    key: 18,
    text: 'Kentucky',
    value: 'Kentucky',
  },
  {
    key: 19,
    text: 'Louisiana',
    value: 'Louisiana',
  },
  {
    key: 20,
    text: 'Maine',
    value: 'Maine',
  },
  {
    key: 21,
    text: 'Maryland',
    value: 'Maryland',
  },
  {
    key: 22,
    text: 'Massachusetts',
    value: 'Massachusetts',
  },
  {
    key: 23,
    text: 'Michigan',
    value: 'Michigan',
  },
  {
    key: 24,
    text: 'Minnesota',
    value: 'Minnesota',
  },
  {
    key: 25,
    text: 'Mississippi',
    value: 'Mississippi',
  },
  {
    key: 26,
    text: 'Missouri',
    value: 'Missouri',
  },
  {
    key: 27,
    text: 'Montana',
    value: 'Montana',
  },
  {
    key: 28,
    text: 'Nebraska',
    value: 'Nebraska',
  },
  {
    key: 29,
    text: 'Nevada',
    value: 'Nevada',
  },
  {
    key: 30,
    text: 'New Hampshire',
    value: 'New Hampshire',
  },
  {
    key: 31,
    text: 'New Jersey',
    value: 'New Jersey',
  },
  {
    key: 32,
    text: 'New Mexico',
    value: 'New Mexico',
  },
  {
    key: 33,
    text: 'New York',
    value: 'New York',
  },
  {
    key: 34,
    text: 'North Carolina',
    value: 'North Carolina',
  },
  {
    key: 35,
    text: 'North Dakota',
    value: 'North Dakota',
  },
  {
    key: 36,
    text: 'Ohio',
    value: 'Ohio',
  },
  {
    key: 37,
    text: 'Oklahoma',
    value: 'Oklahoma',
  },
  {
    key: 38,
    text: 'Oregon',
    value: 'Oregon',
  },
  {
    key: 39,
    text: 'Pennsylvania',
    value: 'Pennsylvania',
  },
  {
    key: 40,
    text: 'Rhode Island',
    value: 'Rhode Island',
  },
  {
    key: 41,
    text: 'South Carolina',
    value: 'South Carolina',
  },
  {
    key: 42,
    text: 'South Dakota',
    value: 'South Dakota',
  },
  {
    key: 43,
    text: 'Tennessee',
    value: 'Tennessee',
  },
  {
    key: 44,
    text: 'Texas',
    value: 'Texas',
  },
  {
    key: 45,
    text: 'Utah',
    value: 'Utah',
  },
  {
    key: 46,
    text: 'Vermont',
    value: 'Vermont',
  },
  {
    key: 47,
    text: 'Virginia',
    value: 'Virginia',
  },
  {
    key: 48,
    text: 'Washington',
    value: 'Washington',
  },
  {
    key: 49,
    text: 'West Virginia',
    value: 'West Virginia',
  },
  {
    key: 50,
    text: 'Wisconsin',
    value: 'Wisconsin',
  },
  {
    key: 51,
    text: 'Wyoming',
    value: 'Wyoming',
  },
];

export const accountTypeList = [
  {
    key: 'employee',
    text: 'Employee',
    value: 'employee',
  },
  {
    key: 'administrator',
    text: 'Administrator',
    value: 'administrator',
  },
  {
    key: 'dealer',
    text: 'Dealer',
    value: 'dealer',
  },
];
