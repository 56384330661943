import React from 'react';
import { Grid } from 'semantic-ui-react';
import _ from 'lodash';

function ReadOnlyInvoiceRegistrationInfo(props) {
  const {
    firstName,
    lastName,
    companyName,
    phoneNumber,
    regAddress,
    regCity,
    regZipCode,
    stateDeal,
    updatedByUserId,
    txnType,
    leasingCompany,
    lienHolderName,
    plateNumber,
    vinNumber,
    carMake,
    carYear,
  } = props.txnForm;

  const userRefInfo = _.find(props.userList, { userId: updatedByUserId });

  return (
    <>
      {/*Row*/}
      <Grid.Column width={2}>Name</Grid.Column>
      <Grid.Column width={6}>
        {lastName}, {firstName}
      </Grid.Column>
      <Grid.Column width={2}>Company</Grid.Column>
      <Grid.Column width={6}>{companyName}</Grid.Column>
      {/*End Row*/}
      {/*Row*/}
      <Grid.Column width={2}>Phone</Grid.Column>
      <Grid.Column width={4}>{phoneNumber}</Grid.Column>
      <Grid.Column width={2}>Company</Grid.Column>
      <Grid.Column width={4}>{companyName}</Grid.Column>
      <Grid.Column width={2}>Rep.</Grid.Column>
      <Grid.Column width={2}>{''}</Grid.Column>
      {/*End Row*/}
      {/*Row*/}
      <Grid.Column width={2}>Address</Grid.Column>
      <Grid.Column width={14}>
        {regAddress}, {regCity}, {stateDeal} {regZipCode}
      </Grid.Column>
      {/*End Row*/}
      {/*Row*/}
      <Grid.Column width={16} className='read-only-invoice-section-header'>
        TRANSACTION INFO
      </Grid.Column>
      {/*End Row*/}
      {/*Row*/}
      <Grid.Column width={2}>Type</Grid.Column>
      <Grid.Column width={6}>{txnType}</Grid.Column>
      <Grid.Column width={2}>Leasing Company</Grid.Column>
      <Grid.Column width={6}>{leasingCompany}</Grid.Column>
      {/*End Row*/}
      {/*Row*/}
      <Grid.Column width={2}>State</Grid.Column>
      <Grid.Column width={6}>{stateDeal}</Grid.Column>
      <Grid.Column width={2}>Lienholder</Grid.Column>
      <Grid.Column width={6}>{lienHolderName}</Grid.Column>
      {/*End Row*/}
      {/*Row*/}
      <Grid.Column width={2}>Plate #</Grid.Column>
      <Grid.Column width={6}>{plateNumber}</Grid.Column>
      <Grid.Column width={2}>VIN</Grid.Column>
      <Grid.Column width={6}>{vinNumber}</Grid.Column>
      {/*End Row*/}
      {/*Row*/}
      <Grid.Column width={2}>Car Year</Grid.Column>
      <Grid.Column width={6}>{carYear}</Grid.Column>
      <Grid.Column width={2}>Car Make</Grid.Column>
      <Grid.Column width={6}>{carMake}</Grid.Column>
      {/*End Row*/}
    </>
  );
}

export default ReadOnlyInvoiceRegistrationInfo;
