import _ from 'lodash';
import {
  BILLING_REMOVED,
  BILLING_SAVED,
  SUBMIT_BILLING,
  GET_BILLING,
  GET_DEALER_BILLINGS,
  GET_DEALER_OPEN_INVOICES,
  CLEAR_BILLING,
  CLEAR_SUCCESS,
  CLEAR_ERROR,
  SHOW_SUCCESS,
  THROW_ERROR,
} from './types';
import {
  getAllInvoices,
  sessionExpired,
  showLoading,
  proccessAPIErrors,
} from './globalActions';
import { processPayment } from './financialTxnActions';
import moment from 'moment';
import main from '../apis/main';

/**
 * Save a new or update an existing billing.  Calls the api endpoint to save a finiancial transaction,
 * but we're saving as a billing payment.
 *
 * @param {object} billingData - data to be submitted
 * @returns
 */
export const submitBilling = (billingData) => async (dispatch, getState) => {
  return true;
};

/**
 * Mark a billing financial transaction as removed and update all associated invoices and
 * payment transactions accordingly.
 *
 * @param {int} billingId - id of the billing financial transaction to remove
 * @returns
 */
export const removeBilling = (billingId) => async (dispatch, getState) => {
  dispatch(showLoading(true));
  // need the current session id to make the call
  const { sessionId, currentUser } = getState().users;

  // make the call to the removal endpont to delete the billing
  // and all associated payments to invoices
  let bodyFormData = new FormData();
  bodyFormData.append('billing_id', billingId);
  bodyFormData.append('updated_by', currentUser.userId);

  try {
    const response = await main.post(
      `/api/financial_txns_mark_deleted/${billingId}/${sessionId}`,
      bodyFormData
    );

    // good response?
    if (201 === response.status) {
      // full removal was successful
      dispatch({
        type: SHOW_SUCCESS,
        payload: 'Bulk Payment has been removed',
      });
      dispatch({ type: BILLING_REMOVED, payload: true });
    } else if (200 === response.status) {
      // billing payment was removed, but there was an issue removing
      // the individual payments
      dispatch({
        type: SHOW_SUCCESS,
        payload: response.data.notice.text,
      });
      dispatch({ type: BILLING_REMOVED, payload: true });
    } else {
      // dispatch an error
      dispatch(
        proccessAPIErrors({
          statusCode: response.status,
          errorMsg: response.data.error,
        })
      );
    }
  } catch (e) {
    // catch any errors that are not handled
    //console.log('Error', e);
    dispatch({
      type: THROW_ERROR,
      payload:
        'There was a problem removing the Bulk Payment. ' +
        e.response.data.error.description,
    });
  }
  dispatch(showLoading(false));
};

/**
 * Actions dispatched when closing the results when a billing
 * payment is successfully removed.
 *
 * @returns
 */
export const confirmBillingRemoved = () => async (dispatch) => {
  dispatch({ type: CLEAR_SUCCESS });
  dispatch({ type: BILLING_REMOVED, payload: false });
};

/**
 * Retrieves a specific billing financial transaction and all invoices paid with it.
 *
 * @param {int} billingId - id of the billing financial transaction to retrieve
 * @returns
 */
export const getBilling = (billingId) => async (dispatch, getState) => {
  // need the current session id to make the call
  const { sessionId } = getState().users;

  // make the call to the transactions endpoint that will retreive the details
  // of the specific billing payment
  const response = await main.get(
    `/api/financial_txns/${billingId}/${sessionId}`
  );

  // make sure a good response was returned
  if (200 === response.status) {
    const responseData = response.data[0];
    // we need a list of the invoices that are associated with this billing
    // along with the payments associated with the billing

    // data was found, set the billing form information
    const billingData = {
      billingId: billingId,
      amountReceived: responseData.amount,
      amountToCredit: responseData.billing_amount_not_applied,
      amountToApply:
        parseFloat(responseData.amount) -
        parseFloat(responseData.billing_amount_not_applied),
      dealerId: responseData.dealer_id,
      paymentInfo: {
        paymentDate: moment(responseData.created_at),
        paymentMethod: responseData.payment_type,
        referenceNumber: responseData.ref_number,
        paymentProcessed: responseData.payment_processed,
      },
      invoicesApplied: responseData.invoices,
      memo: responseData.memo,
    };

    dispatch({ type: GET_BILLING, payload: billingData });
  } else {
    // there was a problem, notify the user and return the error message
    dispatch(
      proccessAPIErrors({
        statusCode: response.status,
        errorMsg: response.data.error,
      })
    );
  }

  return true;
};

/**
 * Retrieves all payments with invoice references associated with a billing.  Called from the getBilling function.
 *
 * @param {int} billingId - id of the billing financial transaction associated with the invoices
 * @returns
 */
const getBillingInvoicePayments = (billingId) => async (dispatch, getState) => {
  return true;
};

/**
 * Retrieves all billing financial transactions associated with a dealer.
 *
 * @param {int} dealerId - id of the dealer associated with the billing financial transactions to retrieve
 * @returns
 */
export const getDealerBillings = (dealerId) => async (dispatch, getState) => {
  return true;
};

/**
 * Retrieves all invoices (plate transactions) that are open for a dealer.
 *
 * @param {int} dealerId - id of the dealer to find open invoices for
 * @param {array} invoices - array of objects that contain the invoice ids that were paid from a billing (for edits)
 * @returns
 */
export const getDealerOutstandingInvoices =
  (dealerId, invoices) => async (dispatch) => {
    // build the search criteria for the invoices
    const searchData = {
      ignoreDates: true,
      dealer: dealerId,
      hasBalance: 'yes',
      forBilling: true,
      status: 'all',
    };

    // do we need to add some invoices to the search? (for edits)
    if (invoices.length > 0) {
      // invoices parameter is an array of objects, so need to only
      // send the plate_id value
      searchData.invoiceIds = [];
      _.each(invoices, (value) => {
        searchData.invoiceIds.push(value.plate_id);
      });
    }

    //console.log('Get Outstanding Invoices Search Data', searchData);

    // call the search function
    dispatch(getAllInvoices(searchData));
  };

const updateInvoiceBalanceAndStatus = async (invoiceData) => {
  let bodyFormData = new FormData();
  bodyFormData.append('updated_by', invoiceData.userId);
  bodyFormData.append('current_balance', invoiceData.currentBalance);
  bodyFormData.append('status', invoiceData.status);
  const response = await main.post(
    `/api/plate_txns_balance_status/${invoiceData.plateTxnId}/${invoiceData.sessionId}`,
    bodyFormData
  );

  // did we get a good response?
  if (response.status !== 201) {
    // there was a problem, notify the user and return the error message
    dispatch(
      proccessAPIErrors({
        statusCode: response.status,
        errorMsg: response.data.error,
      })
    );
    return false;
  }

  return true;
};

/**
 * Adds all individual invoice payments from a billing
 *
 * @param {object} paymentData - contains all invoice payments to be added
 * @param {int} billingId - the billing record that the payments should be associated with
 * @param {string} sessionId - the current, valid user session
 * @param {int} editId - determines what endpoint needs to be called.  If this value is 0 then it's a new add.
 * @returns
 */
const addBatchPayments = async (paymentData, billingId, sessionId, editId) => {
  // create the form data object
  let bodyFormData = new FormData();
  bodyFormData.append('session_token', sessionId);
  bodyFormData.append('billing_id', billingId);
  bodyFormData.append('invoices', JSON.stringify(paymentData));

  let endpointToCall = '/api/batch_financial_txns'; // default to adding all new payments
  try {
    // which endpoint needs to be called?
    if (editId > 0) {
      // we need to call the endpoint to do a batch update
      endpointToCall = '/api/batch_update_financial_txns';
    }

    const response = await main.post(endpointToCall, bodyFormData);
    //console.log('Batch Response', response);

    // did we get a good response?
    if (response.status !== 201) {
      return false;
    }
  } catch (e) {
    // catch any errors that are not handled
    //console.log('Error', e);
    return false;
  }

  // payment creations succeeded
  return true;
};

/**
 * Distributes a billing payment across the invoices selected to be associated with the payment.  Creates new financial
 * transaction entries for the invoices that were selected.  Also saves any remaining
 * payment as a credit on the billing financial transaction entry.
 *
 * @param {object} billingData - billing object containing the total payment amount and parent financial transaction id (billing id)
 * @returns
 */
export const distributeBillingPayment =
  (billingData) => async (dispatch, getState) => {
    // dispatch the loading actions so the user knows the process is running
    dispatch(showLoading(true));
    // grab the session and user information
    const { currentUser, sessionId } = getState().users;
    const { invoicesAmountApplied } = getState().billings;

    // convert the current billing id to an integer value
    const editId = parseInt(billingData.billingId);

    /** STEP 1 - Create the Parent Transaction */
    let bodyFormData = new FormData();
    bodyFormData.append('txn_type', billingData.txnType);
    bodyFormData.append('created_by', currentUser.userId);
    bodyFormData.append('updated_by', currentUser.userId);
    bodyFormData.append('payment_type', billingData.paymentType);
    bodyFormData.append(
      'amount',
      parseFloat(billingData.amountReceived).toFixed(2)
    );
    bodyFormData.append(
      'billing_amount_not_applied',
      billingData.amountToCredit
    );
    bodyFormData.append('ref_number', billingData.referenceNumber);
    bodyFormData.append('toward', 'Bulk Payment');
    bodyFormData.append('in_reg', 1);
    bodyFormData.append('deleted', 0);
    bodyFormData.append('plate_id', 0);
    bodyFormData.append('billing_payment', 1);
    bodyFormData.append('parent_financial_txn_id', 0);
    bodyFormData.append('dealer_id', billingData.dealer);
    bodyFormData.append('memo', billingData.memo);
    bodyFormData.append('cc_last_4', '');
    bodyFormData.append('cc_expire', '');
    bodyFormData.append('cc_card_type', '');
    bodyFormData.append('ach_last_4', '');
    bodyFormData.append('payment_processed', 0);
    bodyFormData.append('existing_credit', billingData.existingCredit);
    // bodyFormData.append(
    //   'cc_expire',
    //   values.ccExpire === undefined ? '' : values.ccExpire
    // );
    // bodyFormData.append('cc_card_type', '');
    // bodyFormData.append('ach_last_4', '');
    // bodyFormData.append('payment_processed', financialTxnForm.paymentProcessed);
    bodyFormData.append('session_token', sessionId);

    // if there is a billing id present, then we need to do an update instead
    // of adding a new one
    let parentResponse = '';
    try {
      if (editId > 0) {
        // this is an update to an existing billing entry
        parentResponse = await main.post(
          `/api/financial_txns/${billingData.billingId}/${sessionId}`,
          bodyFormData
        );
      } else {
        // this is a new billing record
        parentResponse = await main.post('/api/financial_txns', bodyFormData);
      }
    } catch (e) {
      // catch any errors that are not handled
      //console.log('Error', e);
      dispatch({
        type: THROW_ERROR,
        payload:
          'There was a updating the Bulk Payment. ' +
          e.response.data.error.description,
      });
    }

    // did we get a good response?
    if (parentResponse.status !== 201) {
      // there was a problem, notify the user and return the error message
      dispatch(
        proccessAPIErrors({
          statusCode: parentResponse.status,
          errorMsg: parentResponse.data.error,
        })
      );
      return;
    }

    /** STEP 2 - Distribute the Payment */

    // response was good, so store the parent transaction id for reference
    const parentTxnId = parentResponse.data.notice.id;
    //console.log('Parent Financial Txn', parentTxnId);

    // instantiate an array of objects that will be used to add a batch of payments
    let billingPayments = [];

    // instantiate an array of object that will be used to updated the balances and status of
    // the invoices that the payments were applied to
    let invoicesPaid = [];

    // loop through the invoices (plate transactions) that will be paid
    // through this payment
    // the values in the loop are an object with the plateTxnId, currentBalance and paymentAmount as
    // the billing payment form handles all the calculations for determining if an invoice was paid off
    _.each(billingData.invoicesToPay, async (values) => {
      // destructure the values object
      const { txnId, currentBalance, payment, status } = values;

      // the object structure to be sent to the billing endpoint will be dependant on
      // if we're editing or adding a new billing
      let paymentObj = {
        txn_type: billingData.txnType,
        payment_type: billingData.paymentType,
        amount: payment,
        ref_number: billingData.referenceNumber,
        toward: billingData.toward,
        in_reg: 1,
        dealer_id: billingData.dealer,
        plate_id: txnId,
        created_by: currentUser.userId,
        updated_by: currentUser.userId,
        billing_id: parentTxnId,
      }; // default as normal add

      if (editId > 0) {
        // this is an edit, so the payment object needs to be constructed differently
        // find out if the invoice to be paid had it's amount changed
        const invoicePaymentFound = _.find(invoicesAmountApplied, {
          plate_id: txnId,
        });
        if (invoicePaymentFound !== undefined) {
          // invoice was found, need to send the old amount with the new amount
          paymentObj = {
            ...paymentObj,
            action: 'update',
            old_amount: invoicePaymentFound.amount,
            new_amount: payment,
          };
        } else {
          // this is a new invoice payment so just need to add the action value
          paymentObj = { ...paymentObj, action: 'add' };
        }
      }

      // add the required data that will be sent for adding a batch of payments
      billingPayments.push(paymentObj);

      // figure up the new current balance of the plate invoice
      // and check to see if the status needs to be updated to completed
      const newInvoiceBalance =
        parseFloat(currentBalance) - parseFloat(payment);

      // update the invoice balance and status
      const invoiceData = {
        sessionId: sessionId,
        userId: currentUser.userId,
        plateTxnId: txnId,
        currentBalance: newInvoiceBalance,
        status: newInvoiceBalance === 0 ? 'Complete' : status,
      };

      invoicesPaid.push(invoiceData);
    });

    // now we need to see if any invoices should have their payments removed if this is an edit
    if (editId > 0) {
      _.each(invoicesAmountApplied, (item) => {
        const inPaymentsObject = _.find(billingPayments, {
          plate_id: item.plate_id,
        });
        if (inPaymentsObject === undefined) {
          // a previously applied payment has now been removed
          billingPayments.push({
            action: 'remove',
            plate_id: item.plate_id,
          });
        }
      });
    }

    //console.log('Billing Payments Object', billingPayments);

    // call the function that will add all the payments for the billing payment
    const addPaymentsResult = await addBatchPayments(
      billingPayments,
      parentTxnId,
      sessionId,
      editId
    );
    //console.log('Add Payments Result', addPaymentsResult);

    // make sure all payments were successfully added before continuing
    if (!addPaymentsResult) {
      // there was a problem and we cannot continue
      dispatch(
        proccessAPIErrors({
          statusCode: 500,
          errorMsg:
            'There was a failure adding the payments to the invoices for the billing.  Please try again.  If the problem persists please contact the developers so they can check the logs.',
        })
      );
      // even though there is an error we need to trigger the saved flag to show the error modal
      dispatch({ type: BILLING_SAVED, payload: true });
      return;
    }

    /** STEP 3 - Update the Invoice Balances */
    let invoicesNotification = ' and Invoices have been updated';
    let anyFailures = false;
    // go through each payment item and update the balances of the associated invoices
    _.each(invoicesPaid, async (values) => {
      //console.log('Invoice to Update', values);
      const invoiceUpdated = await updateInvoiceBalanceAndStatus(values);

      // we must notify the user of any balances and status that did not successfully update
      if (!invoiceUpdated) {
        // append to the string that reports the invoice id balances that were not updated
        if (!anyFailures) {
          invoicesNotification =
            'The following Invoices failed to update.  Please report these to the development team: ';
          anyFailures = true;
        }
        invoicesNotification += '(ID: ' + values.plateTxnId;
        invoicesNotification += ' New Balance: ' + values.currentBalance + ') ';
      }

      //console.log('Invoice Updated', invoiceUpdated);
    });

    /** STEP 4 - PROCESS PAYMENT THROUGH CARDKNOX (IF APPLICABLE) */
    if (
      billingData.paymentProcessObj.ccNumber.length > 0 ||
      billingData.paymentProcessObj.achAccountNumber.length > 0
    ) {
      // payment processing data is present, so send the payment to cardknox for processing
      dispatch(processPayment(billingData.paymentProcessObj, parentTxnId));
    }

    // hide the loading screen
    dispatch(showLoading(false));
    dispatch({
      type: SHOW_SUCCESS,
      payload: 'Billing Payment has been saved' + invoicesNotification,
    });
    dispatch({ type: BILLING_SAVED, payload: true });
  };

/**
 * Simple function that clears out the billing form data
 * from the billings reducer.
 *
 * @returns
 */
export const clearBillingForm = () => async (dispatch) => {
  dispatch({ type: CLEAR_SUCCESS });
  dispatch({ type: CLEAR_ERROR });
  dispatch({ type: CLEAR_BILLING });
};

export const clearBillingError = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERROR });
  dispatch({ type: BILLING_SAVED, payload: false });
};
