export const USERNAME = 'username';
export const PASSWORD = 'password';
export const CONFIRM_PASSWORD = 'confirmPassword';
export const FIRST_NAME = 'firstName';
export const LAST_NAME = 'lastName';
export const EMAIL = 'email';
export const PHONE_NUMBER = 'phoneNumber';
export const IS_ADMINISTRATOR = 'isAdministrator';
export const INITIALS = 'initials';
export const CELL_PHONE = 'cellPhone';
export const ACCOUNT_TYPE = 'accountType';
export const DEALER_ID = 'dealerId';
export const USER_ID = 'userId';
