import _ from 'lodash';
import main from '../apis/main';
import moment from 'moment';
import { showLoading } from './globalActions';
import {
  GET_REPORTS,
  SELECT_REPORT,
  SHOW_REPORT,
  THROW_ERROR,
  CLEAR_ERROR,
  UPDATE_REPORT_PARAMS,
  RESET_FILTERS,
} from './types';

/**
 * Retrieve all reports from the system
 */
export const getReports = () => async (dispatch, getState) => {
  const { sessionId } = getState().users;
  const response = await main.get(`/api/reports/${sessionId}`);
  //console.log(response);
  if (200 === response.status) {
    const responseData = response.data;

    // check for session token error
    if (responseData.error !== undefined) {
      dispatch(showLoading(false));
      // what type of error?
      if (responseData.error === 'Invalid session token') {
        // the session token is invalid or expired
        dispatch(sessionExpired());
        return;
      }

      // throw an error
      dispatch({ type: THROW_ERROR, payload: responseData.error.text });
      return;
    }

    let reportList = [];
    _.each(response.data, (values) => {
      reportList.push({
        reportId: values.id,
        reportName: values.name,
        defs: values.defs,
      });
    });

    dispatch({ type: GET_REPORTS, payload: reportList });
    dispatch({ type: CLEAR_ERROR });
  } else {
    // something went wrong
    dispatch({ type: THROW_ERROR, payload: response.statusText });
  }
};

/**
 * Retrieves the information needed for a given report
 * @param {integer} reportId
 */
export const getReportInfo = (reportId) => async (dispatch, getState) => {
  const { reportList } = getState().reports;
  const reportInfo = _.find(reportList, { reportId: reportId });
  //console.log('Report Info', reportInfo);
  const fieldDefs = JSON.parse(reportInfo.defs);
  //console.log('Defs', fieldDefs.defs);

  // build the field list and parameter fields for the results request
  let fieldList = [];
  let paramsFields = [];
  _.each(fieldDefs.defs, (info) => {
    fieldList.push({
      fieldType: info.uiType,
      type: info.type,
      action: info.action,
      tableColumn: info.tableColumn,
      listSource: info.listSource,
      params: info.params,
    });

    // check if filter values are a date range?
    let filterValues = [];
    if ('between' === info.action && 'datepicker' === info.uiType) {
      filterValues.push(moment().format('YYYY-MM-DD'));
      filterValues.push(moment().format('YYYY-MM-DD'));
    } else {
      filterValues.push('');
    }

    paramsFields.push({
      tableColumn: info.tableColumn,
      action: info.action,
      type: info.type,
      values: filterValues,
    });
  });

  dispatch({
    type: SELECT_REPORT,
    payload: {
      id: reportInfo.reportId,
      fields: fieldList,
      params: paramsFields,
      tableHeaders: fieldDefs.reportTableLabels,
    },
  });
};

/**
 * Updates the parameters for a report
 */
export const updateReportParams = (value, field, valueIndex) => {
  //console.log('Update Value', value);
  //console.log('Update Field', field);
  //console.log('Update Index', valueIndex);
  return { type: UPDATE_REPORT_PARAMS, payload: { value, field, valueIndex } };
};

/**
 * Fetches the results of the current report
 */
export const runReport = () => async (dispatch, getState) => {
  const { currentReport } = getState().reports;
  const { sessionId } = getState().users;
  dispatch(showLoading(true));

  // make the call to get the results for the report
  const response = await main.post(`/api/reports/${sessionId}`, currentReport);

  // process the response
  if (200 === response.status) {
    const responseData = response.data;

    // check for session token error
    if (responseData.error !== undefined) {
      dispatch(showLoading(false));
      // what type of error?
      if (responseData.error === 'Invalid session token') {
        // the session token is invalid or expired
        dispatch(sessionExpired());
        return;
      }

      // throw an error
      dispatch({ type: THROW_ERROR, payload: responseData.error.text });
      return;
    }

    dispatch({ type: SHOW_REPORT, payload: responseData });
    dispatch({ type: CLEAR_ERROR });
  } else {
    // something went wrong
    dispatch({ type: THROW_ERROR, payload: response.statusText });
  }

  dispatch(showLoading(false));
};

/**
 * Resets the report filters to their default values
 */
export const resetFilters = () => {
  return { type: RESET_FILTERS };
};
