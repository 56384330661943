import React from 'react';
import { Link } from 'react-router-dom';
import { Message, Modal, Button } from 'semantic-ui-react';

const ResultsModal = (props) => {
    return (
        <Modal open size="large" closeOnEscape={false} closeOnDimmerClick={false}>
            <Modal.Header>{props.title}</Modal.Header>
            <Modal.Content>
                {props.success.length > 0
                    ? <Message positive>{props.success}</Message>
                    : <Message negative>{props.error}</Message>
                }
            </Modal.Content>
            <Modal.Actions>
                {props.success.length > 0
                    ? props.hasLink 
                        ?   <Link to={props.goToPage}>
                                <Button 
                                    type="button"
                                    className="confirm-button"
                                    onClick={() => props.closeFunction()}
                                >
                                    CLOSE
                                </Button>
                            </Link>
                        :   <Button 
                                type="button"
                                className="confirm-button"
                                onClick={() => props.closeFunction()}
                            >
                                CLOSE
                            </Button>
                    : <Button
                        type="button"
                        className="decline-button"
                        onClick={() => props.closeFunction()}
                    >
                        CLOSE
                    </Button>
                }
            </Modal.Actions>
        </Modal>
    );
}

export default ResultsModal;