import React, { Fragment } from 'react';
import { Header } from 'semantic-ui-react';

const AmountReceivedText = (props) => {
  return (
    <Fragment>
      <Header>Amount Received</Header>
      <span style={{ fontWeight: '900', fontSize: '2.0em' }}>
        ${parseFloat(props.amountReceived).toFixed(2)}
      </span>
    </Fragment>
  );
};

export default AmountReceivedText;
