import React, { useState } from 'react';
import { Input } from 'semantic-ui-react';

const TextFilter = (props) => {
    const [ currentValue, setCurrentValue ] = useState();

    return (
        <Input 
            type="text" 
            className="brm-form-input"
            value={currentValue}
            onChange={(e, { value }) => {
                setCurrentValue(value); 
                props.changeFunction(value, props.tableColumn, props.index);
            }} 
        />
    );
}

export default TextFilter;