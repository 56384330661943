/**
 * Functions for the State Fees
 */
import _ from 'lodash';
import { showLoading, proccessAPIErrors } from './globalActions';
import main from '../apis/main';
import moment from 'moment';
import {
  RESET_STATE_FEES_UPDATED,
  SAVE_STATE_FEES,
  SET_STATE_FEES,
  UPDATE_STATE_FEES,
  CLEAR_SUCCESS,
  CLEAR_ERROR,
  SHOW_SUCCESS,
  THROW_ERROR,
  UPDATE_STATE_FEE_RESULT,
} from './types';

/**
 * Calls the state_fees endpoint using GET to retrieve all
 * states that are in the system with their current fees.
 */
export const getAllStateFees = () => async (dispatch, getState) => {
  // trigger the loading screen
  dispatch(showLoading(true));

  // retrieve the currently active session key
  const { sessionId } = getState().users;

  // call the api endpoint
  const response = await main.get(`/api/state_fees/${sessionId}`);
  if (200 === response.status) {
    // successful retrieval of the state fees, compile the results and
    // save to the state object
    let stateFeeList = [];
    _.each(response.data, (values) => {
      stateFeeList.push({
        id: values.id,
        stateAbbreviation: values.abbreviation,
        stateName: values.full_name,
        amount: values.fee_amount,
        changedById: values.fee_changed_by,
        changedByName: values.initials,
        dateChanged: moment(new Date(values.date_changed)).format('lll'),
        updated: false,
        updateResult: 'No Change',
        updateErrorMessage: '',
      });
    });

    // set the current state fee list state
    dispatch({ type: SET_STATE_FEES, payload: stateFeeList });
  } else {
    // there was a problem, so dispatch the error handler
    dispatch(
      proccessAPIErrors({
        statusCode: response.status,
        errorMsg: response.data.error,
      })
    );
  }

  // hide the loading screen
  dispatch(showLoading(false));
};

/**
 * Updates the fee amount for a given state in the
 * State Fees state object.
 *
 * @param {int} feeId
 * @param {double} feeAmount
 * @returns
 */
export const updateStateFee = (feeId, feeAmount) => {
  return { type: UPDATE_STATE_FEES, payload: { id: feeId, amount: feeAmount } };
};

/**
 * Takes the array of state fee ids given and calls the backend
 * api to update the database.
 *
 * @returns
 */
export const saveStateFees = () => async (dispatch, getState) => {
  // trigger the loading screen
  dispatch(showLoading(true));

  // retrieve the currently active session key
  const { currentUser, sessionId } = getState().users;

  // get the state fee list object
  const { stateFeeList } = getState().stateFees;

  // find all of the fees that were changed and call the update endpoint
  // for each fee that changed
  const changedStateFees = _.filter(stateFeeList, { updated: true });
  for (const stateFee of changedStateFees) {
    let bodyFormData = new FormData();
    bodyFormData.append('user_id', currentUser.userId);
    bodyFormData.append('amount', stateFee.amount);
    try {
      const response = await main.post(
        `/api/state_fees/${stateFee.id}/${sessionId}`,
        bodyFormData
      );
      dispatch({
        type: UPDATE_STATE_FEE_RESULT,
        payload: {
          feeId: stateFee.id,
          result: 'Updated',
          error: '',
          dateUpdated: moment().format('lll'),
        },
      });
    } catch (e) {
      //console.log('Error: ', e.response.data);
      // something went wrong so indicate that the fee was not updated
      // along with the error message
      dispatch({
        type: UPDATE_STATE_FEE_RESULT,
        payload: {
          feeId: stateFee.id,
          result: 'Failed',
          error: e.response.data.error.text,
        },
      });
    }
  }
  // hide the loading screen
  dispatch(showLoading(false));
};

export const clearStateFeesSaved = () => async (dispatch) => {
  // reset the fees updated state
  dispatch({ type: RESET_STATE_FEES_UPDATED });
  dispatch({ type: CLEAR_SUCCESS });
  dispatch({ type: CLEAR_ERROR });
};
