import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import {
  TextArea,
  Grid,
  Message,
  Icon,
  Checkbox,
  Button,
} from 'semantic-ui-react';
import { saveNote, showNotesForm } from '../../../actions';

const NotesForm = (props) => {
  // validation schema
  const validationSchema = yup.object({
    note: yup.string().required('You must enter your note.'),
  });

  // initial values for the form are in the state object
  const formInitialValues = {
    note: props.notesForm.note,
    internal: props.internal,
  };

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={validationSchema}
      onSubmit={(values) =>
        props.saveNote(values, props.refId, props.parentType)
      }
    >
      {({
        handleChange,
        handleSubmit,
        setFieldValue,
        values,
        errors,
        touched,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Grid>
            <Grid.Row className='header-style'>
              <Grid.Column>NOTE</Grid.Column>
            </Grid.Row>
            <Grid.Row className='brm-form-row'>
              <Grid.Column className='brm-form-label'>
                <strong>{values.internal === 1 ? 'Internal Note' : ''}</strong>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className='brm-form-row'>
              <Grid.Column>
                <TextArea
                  placeholder='Add Note Here...'
                  name='note'
                  rows={10}
                  onChange={handleChange}
                  style={{ width: '100%' }}
                />
                {errors.note && touched.note ? (
                  <Message negative size='tiny'>
                    {errors.note}
                  </Message>
                ) : null}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Button
                  type='button'
                  className='inverted-button'
                  onClick={() => props.showNotesForm(false)}
                >
                  Cancel
                </Button>
                <Button type='submit' className='inverted-button'>
                  <Icon name='save' /> SAVE &amp; CLOSE
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state) => {
  return {
    notesForm: state.notes.notesForm,
    internal: state.notes.internal,
  };
};

export default connect(mapStateToProps, { saveNote, showNotesForm })(NotesForm);
