import React, { useState, useMemo, Fragment } from 'react';
import _ from 'lodash';
import { Table, Dropdown, Menu, Icon, Button } from 'semantic-ui-react';
import { useTable, usePagination, useSortBy } from 'react-table';
import ReactExport from 'react-data-export';
import { resultPageSizeOpts } from '../StaticLists';

// renders the excel results download button
const renderReportDownload = (headers, data) => {
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  let excelHeaderRow = [];

  // loop through the headers
  _.each(headers, (values, key) => {
    const columnName = values.split('.');
    excelHeaderRow.push(<ExcelColumn label={key} value={columnName[1]} />);
  });

  return (
    <ExcelFile
      element={<Button className='inverted-button'>Download Results</Button>}
    >
      <ExcelSheet data={data} name='Report Results'>
        {excelHeaderRow}
      </ExcelSheet>
    </ExcelFile>
  );
};

const ReportResults = (props) => {
  const columns = useMemo(() => {
    let headerArray = [];
    _.each(props.tableHeaders, (values, key) => {
      const columnName = values.split('.');
      headerArray.push({
        Header: key,
        accessor: columnName[1],
      });
    });

    return headerArray;
  }, []);

  const data = useMemo(() => props.results);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    { columns, data, initialState: { pageIndex: 0, pageSize: 10 } },
    useSortBy,
    usePagination
  );

  return (
    <Fragment>
      <br />
      {renderReportDownload(props.tableHeaders, props.results)}
      <Table celled {...getTableProps()} style={{ fontSize: '0.9em' }}>
        <Table.Header>
          {headerGroups.map((headerGroup) => (
            <Table.Row {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Table.HeaderCell
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? [<Icon name='sort down' />]
                        : [<Icon name='sort up' />]
                      : column.disableSortBy
                      ? ''
                      : [<Icon name='sort' />]}
                  </span>
                </Table.HeaderCell>
              ))}
            </Table.Row>
          ))}
        </Table.Header>
        <Table.Body {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <Table.Row {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <Table.Cell {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </Table.Cell>
                  );
                })}
              </Table.Row>
            );
          })}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan='4'>
              <Dropdown
                value={pageSize}
                options={resultPageSizeOpts}
                selection
                selectOnBlur={true}
                className='brm-form-dropdown brm-form-header-dropdown'
                onChange={(e, { value }) => setPageSize(Number(value))}
              />
            </Table.HeaderCell>
            <Table.HeaderCell colSpan='3' textAlign='center'>
              <span>
                <strong>
                  Records:{' '}
                  {pageIndex === 0
                    ? 1
                    : pageSize + pageIndex * pageSize - (pageSize - 1)}{' '}
                  -{' '}
                  {data.length < pageSize + pageIndex * pageSize
                    ? data.length
                    : pageSize + pageIndex * pageSize}{' '}
                  of {data.length}
                </strong>
              </span>
            </Table.HeaderCell>
            <Table.HeaderCell colSpan='4'>
              <Menu floated='right' pagination>
                <Menu.Item
                  as='a'
                  icon
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                >
                  <Icon name='angle double left' />
                </Menu.Item>
                <Menu.Item
                  as='a'
                  icon
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  <Icon name='angle left' />
                </Menu.Item>
                <Menu.Item as='a'>
                  Page: {pageIndex + 1} of {pageOptions.length}
                </Menu.Item>
                <Menu.Item
                  as='a'
                  icon
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  <Icon name='angle right' />
                </Menu.Item>
                <Menu.Item
                  as='a'
                  icon
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  <Icon name='angle double right' />
                </Menu.Item>
              </Menu>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </Fragment>
  );
};

export default ReportResults;
