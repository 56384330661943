import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Container, Modal, Button } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import ReadOnlyInvoiceHeader from './ReadOnlyInvoiceHeader';
import ReadOnlyInvoiceRegistrationInfo from './ReadOnlyInvoiceRegistrationInfo';
import ReadOnlyInvoiceCharges from './ReadOnlyInvoiceCharges';
import ReadOnlyInvoicePayments from './ReadOnlyInvoicePayments';
import NotesList from '../../PlateInvoicing/Notes/NotesList';
import NotesForm from '../../PlateInvoicing/Notes/NotesForm';
import PageHeader from '../../PageHeader';

/**
 * Renders the Notes form if needed
 * @param {boolean} show - whether to show the form or not
 * @param {int} refId - current invoice record id
 * @returns - JSX for the Notes Form or null if the form should be closed
 */
const renderNotesForm = (show, refId) => {
  if (show) {
    return (
      <Modal open size='large' closeOnEscape={false} closeOnDimmerClick={false}>
        <Modal.Content>
          <NotesForm refId={refId} parentType='invoice' />
        </Modal.Content>
      </Modal>
    );
  }
  return null;
};

function ReadOnlyInvoice(props) {
  const navigate = useNavigate();
  const { txnForm, txnList, notes } = useSelector((state) => state.plates);
  const { userList } = useSelector((state) => state.users);
  const { showNotesModal } = useSelector((state) => state.notes);
  return (
    <Container>
      <PageHeader />
      <Grid stackable>
        <ReadOnlyInvoiceHeader txnForm={txnForm} />
        <ReadOnlyInvoiceRegistrationInfo
          txnForm={txnForm}
          userList={userList}
        />
        <ReadOnlyInvoiceCharges txnForm={txnForm} />
        <ReadOnlyInvoicePayments txnForm={txnForm} txnList={txnList} />
      </Grid>
      <NotesList
        noteType='dealer-invoice'
        parentType='invoice'
        listHeight='210px'
        notes={notes}
        invoiceId={txnForm.txnId}
        invoiceValues={[]}
        saveInvoiceFunction={() => null}
      />
      <Grid>
        <Grid.Column width={12}>&nbsp;</Grid.Column>
        <Grid.Column width={4}>
          <Button
            type='button'
            className='normal-button'
            style={{ float: 'right' }}
            onClick={() => {
              navigate(-1);
            }}
          >
            BACK
          </Button>
        </Grid.Column>
      </Grid>
      <br />
      <br />
      {renderNotesForm(showNotesModal, txnForm.txnId)}
    </Container>
  );
}

export default ReadOnlyInvoice;
