import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import InputMask from 'react-input-mask';
import {
  Header,
  Grid,
  Input,
  Dropdown,
  Message,
  Icon,
} from 'semantic-ui-react';
import { txnTypes, usaList } from '../StaticLists';
import Confirmation from '../Confirmation';
import {
  REG_FIRST_NAME,
  REG_LAST_NAME,
  REG_EMAIL,
  REG_PHONE,
  REG_TXN_TYPE,
  REG_STATE_DEAL,
  REG_CAR_MAKE,
  REG_CAR_YEAR,
  REG_VIN_NUMBER,
  REG_LIEN_HOLDER,
  REG_PLATE_NUMBER,
  REG_LEASING_COMPANY,
  REG_ADDRESS,
  REG_CITY,
  REG_ZIP_CODE,
  REG_COMPANY_NAME,
} from './FormSetup/FieldNames';
import {
  showLienHolderForm,
  getLienHolder,
  addNewLienHolder,
  showCarMakesForm,
  getCarMake,
  addNewCarMake,
  removeLienHolder,
} from '../../actions';
import _, { values } from 'lodash';

const renderCarMakeEditIcon = (props) => {
  if (props.values[REG_CAR_MAKE].length > 0) {
    return <Icon name='pencil' onClick={() => props.showCarMakesForm(true)} />;
  }
  return null;
};

const RegistrationInfo = (props) => {
  // state to monitor if a lien holder was removed
  const [deleteLienHolder, setDeleteLienHolder] = useState(false);

  // state for the list of states in the state deal dropdown
  const [stateDealList, setStateDealList] = useState([]);

  // use the useEffect hook to monitor the changes to the deleteDealer flag
  useEffect(() => {
    // we only care if the deleteLienHolder state was changed to true
    // if deleteLienHolder is true, then we need to clear out the lien holder dropdown
    // selection
    if (deleteLienHolder) {
      // execute the remove dealer action
      props.removeLienHolder(props.values[REG_LIEN_HOLDER]);

      // clear out the field values
      props.setFieldValue(REG_LIEN_HOLDER, '-1');

      // set remove lien holder local state to false
      setDeleteLienHolder(false);
    }
  }, [deleteLienHolder]);

  // the state list is only built one time when the component loads
  useEffect(() => {
    let stateDealOptions = [];
    _.each(props.stateFeeList, (value) => {
      stateDealOptions.push({
        key: value.id,
        value: value.stateName,
        text: value.stateName,
      });
    });
    setStateDealList(stateDealOptions);
  }, []);

  /**
   * Renders the edit and delete (if administrator) icons next to the lien holders list
   * @returns
   */
  const renderLienHolderEditIcon = () => {
    if (props.values[REG_LIEN_HOLDER] > 0) {
      return (
        <Fragment>
          <Icon name='pencil' onClick={() => props.showLienHolderForm(true)} />
          {props.isAdministrator && (
            <Confirmation
              title='Confirm Deletion'
              content={`You are about to delete ${props.lienHolderForm.name}.  Are you sure you want to do this?`}
              confirmId={true}
              confirmAction={setDeleteLienHolder}
              trigger={<Icon style={{ cursor: 'pointer' }} name='trash' />}
            />
          )}
        </Fragment>
      );
    }
    return null;
  };

  //console.log('Current Lien Holder', props.values[REG_LIEN_HOLDER]);
  return (
    <Fragment>
      <Header>REGISTRATION INFORMATION</Header>
      <Grid stackable>
        <Grid.Row className='brm-form-row'>
          <Grid.Column
            width={4}
            verticalAlign='middle'
            className='brm-form-label'
          >
            First Name
          </Grid.Column>
          <Grid.Column width={12}>
            <Input
              tabIndex='7'
              type='text'
              name={REG_FIRST_NAME}
              value={
                props.values[REG_FIRST_NAME] ||
                props.initialValues[REG_FIRST_NAME]
              }
              className='brm-form-input'
              onChange={props.handleChange}
              onBlur={props.handleBlur}
            />
            {props.errors[REG_FIRST_NAME] && props.touched[REG_FIRST_NAME] ? (
              <Message negative size='tiny'>
                {props.errors[REG_FIRST_NAME]}
              </Message>
            ) : null}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className='brm-form-row'>
          <Grid.Column
            width={4}
            verticalAlign='middle'
            className='brm-form-label'
          >
            Last Name
          </Grid.Column>
          <Grid.Column width={12}>
            <Input
              tabIndex='8'
              type='text'
              name={REG_LAST_NAME}
              value={
                props.values[REG_LAST_NAME] ||
                props.initialValues[REG_LAST_NAME]
              }
              className='brm-form-input'
              onChange={props.handleChange}
              onBlur={props.handleBlur}
            />
            {props.errors[REG_LAST_NAME] && props.touched[REG_LAST_NAME] ? (
              <Message negative size='tiny'>
                {props.errors[REG_LAST_NAME]}
              </Message>
            ) : null}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className='brm-form-row'>
          <Grid.Column
            width={4}
            verticalAlign='middle'
            className='brm-form-label'
          >
            Company Name
          </Grid.Column>
          <Grid.Column width={12}>
            <Input
              tabIndex='9'
              type='text'
              name={REG_COMPANY_NAME}
              value={
                props.values[REG_COMPANY_NAME] ||
                props.initialValues[REG_COMPANY_NAME]
              }
              className='brm-form-input'
              onChange={props.handleChange}
              onBlur={props.handleBlur}
            />
            {props.errors[REG_COMPANY_NAME] &&
            props.touched[REG_COMPANY_NAME] ? (
              <Message negative size='tiny'>
                {props.errors[REG_COMPANY_NAME]}
              </Message>
            ) : null}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className='brm-form-row'>
          <Grid.Column
            width={4}
            verticalAlign='middle'
            className='brm-form-label'
          >
            Email
          </Grid.Column>
          <Grid.Column width={12}>
            <Input
              tabIndex='10'
              type='text'
              name={REG_EMAIL}
              value={props.values[REG_EMAIL] || props.initialValues[REG_EMAIL]}
              className='brm-form-input'
              onChange={props.handleChange}
              onBlur={props.handleBlur}
            />
            {props.errors[REG_EMAIL] && props.touched[REG_EMAIL] ? (
              <Message negative size='tiny'>
                {props.errors[REG_EMAIL]}
              </Message>
            ) : null}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className='brm-form-row'>
          <Grid.Column
            width={4}
            verticalAlign='middle'
            className='brm-form-label'
          >
            Phone
          </Grid.Column>
          <Grid.Column width={12}>
            <InputMask
              mask='(999) 999-9999'
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values[REG_PHONE]}
            >
              {(inputProps) => (
                <Input
                  {...inputProps}
                  tabIndex='11'
                  type='text'
                  name={REG_PHONE}
                  value={
                    props.values[REG_PHONE] || props.initialValues[REG_PHONE]
                  }
                  className='brm-form-input'
                />
              )}
            </InputMask>
            {props.errors[REG_PHONE] && props.touched[REG_PHONE] ? (
              <Message negative size='tiny'>
                {props.errors[REG_PHONE]}
              </Message>
            ) : null}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className='brm-form-row'>
          <Grid.Column
            width={4}
            verticalAlign='middle'
            className='brm-form-label'
          >
            Transaction
          </Grid.Column>
          <Grid.Column width={12}>
            <Dropdown
              tabIndex='12'
              placeholder='Select Transaction Type'
              name={REG_TXN_TYPE}
              defaultValue={props.initialValues[REG_TXN_TYPE]}
              value={
                props.values[REG_TXN_TYPE] || props.initialValues[REG_TXN_TYPE]
              }
              className='brm-form-dropdown'
              search
              selection
              selectOnBlur={true}
              options={txnTypes}
              onChange={(e, { value }) =>
                props.setFieldValue(REG_TXN_TYPE, value)
              }
            />
            {props.errors[REG_TXN_TYPE] && props.touched[REG_TXN_TYPE] ? (
              <Message negative size='tiny'>
                {props.errors[REG_TXN_TYPE]}
              </Message>
            ) : null}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className='brm-form-row'>
          <Grid.Column
            width={4}
            verticalAlign='middle'
            className='brm-form-label'
          >
            Address
          </Grid.Column>
          <Grid.Column width={12}>
            <Input
              tabIndex='13'
              type='text'
              name={REG_ADDRESS}
              value={
                props.values[REG_ADDRESS] || props.initialValues[REG_ADDRESS]
              }
              className='brm-form-input'
              onChange={props.handleChange}
              onBlur={props.handleBlur}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className='brm-form-row'>
          <Grid.Column
            width={4}
            verticalAlign='middle'
            className='brm-form-label'
          >
            City
          </Grid.Column>
          <Grid.Column width={12}>
            <Input
              tabIndex='14'
              type='text'
              name={REG_CITY}
              value={props.values[REG_CITY] || props.initialValues[REG_CITY]}
              className='brm-form-input'
              onChange={props.handleChange}
              onBlur={props.handleBlur}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className='brm-form-row'>
          <Grid.Column
            width={4}
            verticalAlign='middle'
            className='brm-form-label'
          >
            State Deal
          </Grid.Column>
          <Grid.Column width={12}>
            <Dropdown
              tabIndex='15'
              placeholder='Select State'
              name={REG_STATE_DEAL}
              value={
                props.values[REG_STATE_DEAL] ||
                props.initialValues[REG_STATE_DEAL]
              }
              className='brm-form-dropdown'
              search
              selection
              selectOnBlur={true}
              options={stateDealList}
              onChange={(e, { value }) => {
                props.setFieldValue(REG_STATE_DEAL, value);
                // add or update the state expense associated with this invoice
              }}
            />
            {props.errors[REG_STATE_DEAL] && props.touched[REG_STATE_DEAL] ? (
              <Message negative size='tiny'>
                {props.errors[REG_STATE_DEAL]}
              </Message>
            ) : null}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className='brm-form-row'>
          <Grid.Column
            width={4}
            verticalAlign='middle'
            className='brm-form-label'
          >
            Zip Code
          </Grid.Column>
          <Grid.Column width={12}>
            <Input
              tabIndex='16'
              type='text'
              name={REG_ZIP_CODE}
              value={
                props.values[REG_ZIP_CODE] || props.initialValues[REG_ZIP_CODE]
              }
              className='brm-form-input'
              onChange={props.handleChange}
              onBlur={props.handleBlur}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className='brm-form-row'>
          <Grid.Column
            width={4}
            verticalAlign='middle'
            className='brm-form-label'
          >
            Car Make
          </Grid.Column>
          <Grid.Column width={12}>
            <Dropdown
              tabIndex='17'
              placeholder='Select Make of Car'
              name={REG_CAR_MAKE}
              value={
                props.values[REG_CAR_MAKE] || props.initialValues[REG_CAR_MAKE]
              }
              className='brm-form-dropdown'
              search
              selection
              selectOnBlur={true}
              options={props.carMakeList}
              onChange={(e, { value }) => {
                if (0 === value) {
                  props.addNewCarMake();
                  props.showCarMakesForm(true);
                } else if (value !== -1) {
                  props.getCarMake(value);
                  props.setFieldValue(REG_CAR_MAKE, value);
                }
              }}
            />
            {renderCarMakeEditIcon(props)}
            {props.errors[REG_CAR_MAKE] && props.touched[REG_CAR_MAKE] ? (
              <Message negative size='tiny'>
                {props.errors[REG_CAR_MAKE]}
              </Message>
            ) : null}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className='brm-form-row'>
          <Grid.Column
            width={4}
            verticalAlign='middle'
            className='brm-form-label'
          >
            Car Year
          </Grid.Column>
          <Grid.Column width={12}>
            <Input
              tabIndex='18'
              type='text'
              name={REG_CAR_YEAR}
              value={
                props.values[REG_CAR_YEAR] || props.initialValues[REG_CAR_YEAR]
              }
              className='brm-form-input'
              onChange={props.handleChange}
              onBlur={props.handleBlur}
            />
            {props.errors[REG_CAR_YEAR] && props.touched[REG_CAR_YEAR] ? (
              <Message negative size='tiny'>
                {props.errors[REG_CAR_YEAR]}
              </Message>
            ) : null}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className='brm-form-row'>
          <Grid.Column
            width={4}
            verticalAlign='middle'
            className='brm-form-label'
          >
            VIN #
          </Grid.Column>
          <Grid.Column width={12}>
            <Input
              tabIndex='19'
              type='text'
              name={REG_VIN_NUMBER}
              value={
                props.values[REG_VIN_NUMBER] ||
                props.initialValues[REG_VIN_NUMBER]
              }
              className='brm-form-input'
              onChange={props.handleChange}
              onBlur={props.handleBlur}
            />
            {props.errors[REG_VIN_NUMBER] && props.touched[REG_VIN_NUMBER] ? (
              <Message negative size='tiny'>
                {props.errors[REG_VIN_NUMBER]}
              </Message>
            ) : null}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className='brm-form-row'>
          <Grid.Column
            width={4}
            verticalAlign='middle'
            className='brm-form-label'
          >
            Plate #
          </Grid.Column>
          <Grid.Column width={12}>
            <Input
              tabIndex='20'
              type='text'
              name={REG_PLATE_NUMBER}
              value={
                props.values[REG_PLATE_NUMBER] ||
                props.initialValues[REG_PLATE_NUMBER]
              }
              className='brm-form-input'
              onChange={props.handleChange}
              onBlur={props.handleBlur}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className='brm-form-row'>
          <Grid.Column
            width={4}
            verticalAlign='middle'
            className='brm-form-label'
          >
            Lien Holder
          </Grid.Column>
          <Grid.Column width={12}>
            <Dropdown
              tabIndex='21'
              placeholder='Select Lien Holder'
              style={{ width: '250px' }}
              name={REG_LIEN_HOLDER}
              value={
                props.values[REG_LIEN_HOLDER] ||
                props.initialValues[REG_LIEN_HOLDER]
              }
              className='brm-form-dropdown'
              search
              selection
              selectOnBlur={true}
              options={props.lienHolderList}
              onChange={async (e, { value }) => {
                if (0 === value) {
                  props.addNewLienHolder();
                  props.showLienHolderForm(true);
                } else if (value !== -1) {
                  await props.getLienHolder(value);
                  props.setFieldValue(REG_LIEN_HOLDER, value);
                }
              }}
            />{' '}
            {renderLienHolderEditIcon()}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className='brm-form-row'>
          <Grid.Column
            width={4}
            verticalAlign='middle'
            className='brm-form-label'
          >
            Leasing Company
          </Grid.Column>
          <Grid.Column width={12}>
            <Input
              tabIndex='22'
              type='text'
              name={REG_LEASING_COMPANY}
              className='brm-form-input'
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={
                props.values[REG_LEASING_COMPANY] ||
                props.initialValues[REG_LEASING_COMPANY]
              }
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    carMakeList: state.main.carMakeList,
    lienHolderList: state.lienholders.lienHolderList,
    isAdministrator: state.users.isAdministrator,
    lienHolderForm: state.lienholders.lienHolderForm,
    stateFeeList: state.stateFees.stateFeeList,
  };
};

export default connect(mapStateToProps, {
  showLienHolderForm,
  getLienHolder,
  addNewLienHolder,
  showCarMakesForm,
  getCarMake,
  addNewCarMake,
  removeLienHolder,
})(RegistrationInfo);
