export const PLATE_STATUS = 'plateStatus';
export const DEALER_NAME = 'dealerName';
export const DEALER_PHONE = 'dealerPhone';
export const COURIER = 'courier';
export const RETURN_TO = 'returnTo';
export const REG_FIRST_NAME = 'regFirstName';
export const REG_LAST_NAME = 'regLastName';
export const REG_COMPANY_NAME = 'regCompanyName';
export const REG_EMAIL = 'regEmail';
export const REG_PHONE = 'regPhone';
export const REG_TXN_TYPE = 'regTxnType';
export const REG_ADDRESS = 'regAddress';
export const REG_CITY = 'regCity';
export const REG_ZIP_CODE = 'regZipCode';
export const REG_STATE_DEAL = 'regStateDeal';
export const REG_CAR_MAKE = 'regCarMake';
export const REG_CAR_YEAR = 'regCarYear';
export const REG_VIN_NUMBER = 'regVinNumber';
export const REG_PLATE_NUMBER = 'regPlateNumber';
export const REG_LIEN_HOLDER = 'regLienHolder';
export const REG_LEASING_COMPANY = 'regLeasingCompany';
export const RECEIVED_FROM_DEALER_DATE = 'receivedFromDealerDate';
export const SENT_TO_DMV_DATE = 'sendToDMVDate';
export const RETURN_FROM_DMV_DATE = 'returnFromDMVDate';
export const BRM_CHARGE = 'brmCharge';
export const DMV_CHARGE = 'dmvCharge';
export const SALES_TAX = 'salesTax';
export const OTHER_FEES = 'otherFees';
