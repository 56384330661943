import {
    GET_COMBINED_INVOICES,
    FIND_COMBINED_INVOICE,
    SET_COMBINED_INVOICE_NOTES,
    SET_COMBINED_INVOICE_INTERNAL_NOTES,
    GET_FINANCIAL_TXN_BY_COMBINED_INVOICE,
    GET_INVOICES_FOR_COMBINED_INVOICE,
    TOGGLE_SELECTED_INVOICE,
    SAVE_COMBINED_INVOICE,
    COMBINED_INVOICE_SAVED,
    SAVE_TEMP_COMBINED_INVOICE_TXNS,
    SAVE_TEMP_COMBINED_INVOICE_INTERNAL_NOTE,
    SAVE_TEMP_COMBINED_INVOICE_NOTE,
    UPDATE_COMBINED_INVOICE_TOTAL_BALANCE,
    NEW_COMBINED_INVOICE,
    SET_COMBINED_INVOICE_DEALER,
    REMOVE_COMBINED_INVOICE_NOTE,
    REMOVE_COMBINED_INVOICE_INTERNAL_NOTE,
    REMOVE_INVOICE_INTERNAL_NOTE,
    PRINT_COMBINED_INVOICE
} from '../actions/types';

const INITIAL_STATE = {
    combinedInvoiceForm: {
        combinedInvoiceId: 0,
        combinedInvoiceDate: '',
        dealerId: 0,
        dealerInfo: {
            name: '',
            address:'',
            city: '',
            state: '',
            zipCode: ''
        },
        invoices: [],
        payments: [],
        notes: [],
        internalNotes: [],
        currentBalance: 0.00,
        totalCharges: 0.00   
    },
    combinedInvoicesSaved: false,
    combinedInvoicesList: [],
    combinedInvoicesCharges: 0.00,
    showPrintCombinedInvoice: false,
    combinedInvoiceFileName: ''
};

const combinedInvoiceState = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_COMBINED_INVOICES:
            return { 
                ...state, 
                combinedInvoicesList: action.payload.combinedList,
                combinedInvoicesCharges: action.payload.allCharges 
            };
        case NEW_COMBINED_INVOICE:
            return {
                ...state,
                combinedInvoiceForm: {
                    combinedInvoiceId: 0,
                    combinedInvoiceDate: '',
                    dealerId: 0,
                    dealerInfo: {
                        name: '',
                        address:'',
                        city: '',
                        state: '',
                        zipCode: ''
                    },
                    invoices: [],
                    payments: [],
                    notes: [],
                    internalNotes: [],
                    currentBalance: 0.00,
                    totalCharges: 0.00   
                }
            }
        case FIND_COMBINED_INVOICE:
            return {
                ...state,
                combinedInvoiceForm: {
                    ...state.combinedInvoiceForm,
                    combinedInvoiceId: action.payload.id,
                    combinedInvoiceDate: action.payload.created_at,
                    dealerId: action.payload.dealer_id,
                    totalCharges: action.payload.amount_charged
                }
            };
        case SET_COMBINED_INVOICE_DEALER:
            return {
                ...state,
                combinedInvoiceForm: {
                    ...state.combinedInvoiceForm,
                    dealerInfo: {
                        name: action.payload.company_name,
                        address: action.payload.address,
                        city: action.payload.city,
                        state: action.payload.state,
                        zipCode: action.payload.zip,
                        phone: action.payload.phone
                    }
                }
            };
        case SET_COMBINED_INVOICE_NOTES:
            return { 
                ...state,
                combinedInvoiceForm: {
                    ...state.combinedInvoiceForm,
                    notes: action.payload
                }
            };
        case SET_COMBINED_INVOICE_INTERNAL_NOTES:
            return { 
                ...state,
                combinedInvoiceForm: {
                    ...state.combinedInvoiceForm,
                    internalNotes: action.payload
                }
            };
        case GET_FINANCIAL_TXN_BY_COMBINED_INVOICE:
            return {
                ...state,
                combinedInvoiceForm: {
                    ...state.combinedInvoiceForm,
                    payments: action.payload.txnData,
                    currentBalance: action.payload.currentBalance
                }
            };
        case GET_INVOICES_FOR_COMBINED_INVOICE:
            return {
                ...state,
                combinedInvoiceForm: {
                    ...state.combinedInvoiceForm,
                    invoices: action.payload
                }
            };
        case TOGGLE_SELECTED_INVOICE:
            return {
                ...state,
                combinedInvoiceForm: {
                    ...state.combinedInvoiceForm,
                    invoices: state.combinedInvoiceForm.invoices.map((invoice) => {
                        return invoice.txnId === action.payload.txnId
							? { ...invoice, selected: action.payload.selected }
							: invoice
                    }),
                    currentBalance: parseFloat(action.payload.invoiceAmt) + parseFloat(state.combinedInvoiceForm.currentBalance),
                    totalCharges: parseFloat(action.payload.invoiceAmt) + parseFloat(state.combinedInvoiceForm.totalCharges)
                }
            };
        case SAVE_TEMP_COMBINED_INVOICE_TXNS:
            return { 
                ...state,
                combinedInvoiceForm: {
                    ...state.combinedInvoiceForm,
                    payments: [
                        ...state.combinedInvoiceForm.payments,
                        action.payload
                    ]
                }
            };
        case SAVE_TEMP_COMBINED_INVOICE_INTERNAL_NOTE:
            return {
                ...state,
                combinedInvoiceForm: {
                    ...state.combinedInvoiceForm,
                    internalNotes: [
                        ...state.combinedInvoiceForm.internalNotes,
                        action.payload
                    ]
                }
            };
        case SAVE_TEMP_COMBINED_INVOICE_NOTE:
            return {
                ...state,
                combinedInvoiceForm: {
                    ...state.combinedInvoiceForm,
                    notes: [
                        ...state.combinedInvoiceForm.notes,
                        action.payload
                    ]
                }
            };
        case SAVE_COMBINED_INVOICE:
            return { 
                ...state,
                combinedInvoiceForm: {
                    combinedInvoiceId: 0,
                    combinedInvoiceDate: '',
                    dealerId: 0,
                    dealerInfo: {
                        name: '',
                        address:'',
                        city: '',
                        state: '',
                        zipCode: ''
                    },
                    invoices: [],
                    payments: [],
                    notes: [],
                    internalNotes: [],
                    currentBalance: 0.00,
                    totalCharges: 0.00   
                },
                combinedInvoicesSaved: true
            };
        case UPDATE_COMBINED_INVOICE_TOTAL_BALANCE:
            return {
                ...state,
                combinedInvoiceForm: {
                    ...state.combinedInvoiceForm,
                    currentBalance: action.payload
                }
            };
        case COMBINED_INVOICE_SAVED:
            return { ...state, combinedInvoicesSaved: false };
        case REMOVE_COMBINED_INVOICE_NOTE:
            return { ...state,
                combinedInvoiceForm: {
                    ...state.combinedInvoiceForm,
                    notes: state.combinedInvoiceForm.notes.filter(value => value.id !== action.payload)
                }
            };
        case REMOVE_INVOICE_INTERNAL_NOTE:
            return { ...state, 
                combinedInvoiceForm: {
                    ...state.combinedInvoiceForm,
                    internalNotes: state.combinedInvoiceForm.internalNotes.filter(value => value.id !== action.payload)
                }
            };
        case PRINT_COMBINED_INVOICE:
            return { 
                ...state,
                showPrintCombinedInvoice: action.payload.show,
                combinedInvoiceFileName: action.payload.fileName
            };
        default:
            return state;
    }
};

export default combinedInvoiceState;