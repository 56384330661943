import { REHYDRATE } from 'redux-persist';
import {
  THROW_ERROR,
  CLEAR_ERROR,
  SHOW_SUCCESS,
  LOGOUT_USER,
  GET_CAR_MAKES,
  ALL_INVOICES,
  SHOW_LOADING,
  SAVE_CAR_MAKE,
  CLEAR_CAR_MAKE_FORM,
  SHOW_CAR_MAKE_FORM,
  FIND_CAR_MAKE,
  CLEAR_SUCCESS,
  SELECT_FOR_PRINT,
  PRINT_MULTI_INVOICES,
  SET_CURRENT_INVOICE_FILTERS,
  SET_CURRENT_INVOICE_LIST_PAGE_INDEX,
} from '../actions/types';

const INITIAL_STATE = {
  isLoading: false,
  currentPage: '',
  openForm: '',
  errors: '',
  success: '',
  warning: '',
  carMakeForm: {
    makeId: 0,
    makeName: '',
  },
  carMakeList: [],
  invoiceList: [],
  currentInvoiceListPageIndex: 0,
  currentInvoiceListPageSize: 10,
  currentInvoiceListFilters: {
    startDate: '',
    endDate: '',
    status: 'all',
    hasBalance: 'all',
    dealer: 'all',
    checkNumber: '',
    ignoreDates: false,
    fuzzy: '',
    invoiceNumber: '',
    dateSearchBy: 'created',
    paymentAmountLow: '',
    paymentAmountHigh: '',
  },
  listTotalOwed: 0.0,
  listTotalOverpaid: 0.0,
  listTotalCharges: 0.0,
  listTotalFees: 0.0,
  listTotalExpenses: 0.0,
  listTotalNetProfit: 0.0,
  listPercentageNetProfit: 0,
  listBRMFeesWithExpenses: 0.0,
  showCarMakeModal: false,
  showMultiInvoicePrint: false,
  pdfMultiInvoiceFileName: '',
  totalSelectedInvoices: 0,
};

const mainState = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REHYDRATE:
      // this is needed in case the app crashes when the loading screen is still visible
      return { ...state, isLoading: false };
    case THROW_ERROR:
      return { ...state, errors: action.payload, success: '' };
    case CLEAR_ERROR:
      return { ...state, errors: '' };
    case SHOW_SUCCESS:
      return { ...state, success: action.payload };
    case CLEAR_SUCCESS:
      return { ...state, success: '' };
    case GET_CAR_MAKES:
      return { ...state, carMakeList: action.payload };
    case ALL_INVOICES:
      return {
        ...state,
        invoiceList: action.payload.invoiceList,
        listTotalOwed: action.payload.totalOwed,
        listTotalOverpaid: action.payload.totalOverpaid,
        listTotalCharges: action.payload.allCharges,
        totalSelectedInvoices: 0,
        listTotalFees: action.payload.totalBRMFees,
        currentInvoiceListPageIndex: INITIAL_STATE.currentInvoiceListPageIndex,
        listTotalExpenses: action.payload.totalExpenses,
        listTotalNetProfit: action.payload.totalNetProfit,
        listPercentageNetProfit: action.payload.percentNetProfit,
        listBRMFeesWithExpenses: action.payload.brmFeesWithExpenses,
      };
    case SHOW_CAR_MAKE_FORM:
      return { ...state, showCarMakeModal: action.payload };
    case SAVE_CAR_MAKE:
    case CLEAR_CAR_MAKE_FORM:
      return { ...state, carMakeForm: { makeId: 0, makeName: '' } };
    case FIND_CAR_MAKE:
      return {
        ...state,
        carMakeForm: {
          makeId: action.payload.id,
          makeName: action.payload.car_make,
        },
      };
    case LOGOUT_USER:
      return {
        ...state,
        isLoading: false,
        currentPage: '',
        openForm: '',
        errors: '',
        success: action.payload.success,
        warning: action.payload.warning,
        carMakeList: [],
        invoiceList: [],
        listTotalOverpaid: 0.0,
        listTotalOwed: 0.0,
        listTotalCharges: 0.0,
        pdfMultiInvoiceFileName: '',
        totalSelectedInvoices: 0,
        listTotalFees: 0.0,
        listTotalExpenses: 0.0,
        listTotalNetProfit: 0.0,
        listPercentageNetProfit: 0,
      };
    case SHOW_LOADING:
      return { ...state, isLoading: action.payload };
    case SELECT_FOR_PRINT:
      return {
        ...state,
        invoiceList: state.invoiceList.map((value) => {
          return value.txnId === action.payload.txnId
            ? { ...value, selectedForPrint: action.payload.selected }
            : value;
        }),
        totalSelectedInvoices: action.payload.selectedInvoices,
      };
    case PRINT_MULTI_INVOICES:
      return {
        ...state,
        showMultiInvoicePrint: action.payload.show,
        pdfMultiInvoiceFileName: action.payload.fileName,
      };
    case SET_CURRENT_INVOICE_FILTERS:
      return {
        ...state,
        currentInvoiceListFilters: {
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
          status: action.payload.status,
          hasBalance: action.payload.hasBalance,
          dealer: action.payload.dealer,
          checkNumber: action.payload.checkNumber,
          ignoreDates: action.payload.ignoreDates,
          fuzzy: action.payload.fuzzySearchString,
          invoiceNumber: action.payload.invoiceNumber,
          dateSearchBy: action.payload.dateSearchBy,
          paymentAmountLow: action.payload.paymentAmountLow,
          paymentAmountHigh: action.payload.paymentAmountHigh,
        },
      };
    case SET_CURRENT_INVOICE_LIST_PAGE_INDEX:
      return {
        ...state,
        currentInvoiceListPageIndex: action.payload.pageIndex,
        currentInvoiceListPageSize: action.payload.listSize,
      };
    default:
      return state;
  }
};

export default mainState;
