import _ from 'lodash';
import main from '../apis/main';
import moment from 'moment';
import { getNotesList, saveNote } from './noteActions';
import { getDealer } from './dealerActions';
import { getLienHolder } from './lienHolderActions';
import { saveFinancialTxn } from './financialTxnActions';
import { showLoading, getAllInvoices, sessionExpired } from './globalActions';
import {
  getPlateTransactionExpenses,
  saveExpenses,
  addUpdateStateFeeExpense,
  calculateExpenses,
} from './expenseActions';
import {
  SUBMIT_INVOICE,
  CALCULATE_CHARGES,
  GET_INVOICE,
  GET_FINANCIAL_TXN_BY_INVOICE,
  SAVE_TEMP_FINANCIAL_TXN,
  SAVE_TEMP_INTERNAL_NOTE,
  SAVE_TEMP_INVOICE_NOTE,
  CLEAR_INVOICE,
  UPDATE_CURRENT_BALANCE,
  PRINT_INVOICE,
  THROW_ERROR,
  CLEAR_ERROR,
  CLEAR_SUCCESS,
  SHOW_SUCCESS,
  COMBINED_INVOICE_SAVED,
  RESET_FINANCIAL_TXN_SAVED,
  SET_INVOICE_ID,
  INVOICE_SAVED,
  INVOICE_REMOVED,
  CALCULATE_TOTAL_PROFIT,
} from './types';

/**
 * Saves a Plate Invoice to the database
 * @param {object} values - the values from the invoice form
 * @param {string} saveAction - Determines if we need to stay on the invoice form or close the form and go back to the dashboard.
 *                              Save and print, adding a new payment and adding a new note will keep the user on the invoice form.
 */
export const saveInvoice =
  (values, saveAction) => async (dispatch, getState) => {
    dispatch(showLoading(true));
    // build an audit object
    let auditObj = {};

    // get the current state
    const { txnForm, txnList, notes, internalNotes } = getState().plates;
    const { currentUser, sessionId } = getState().users;
    const { currentInvoiceListFilters } = getState().main;
    const { expensesList } = getState().expenses;
    const { dealerList } = getState().dealers;
    //console.log('Txn Form Data', txnForm);
    //console.log('Values Sent', values);
    let response = {};
    let auditNote = '';
    let dealerDate = values.receivedFromDealerDate;
    let toDMVDate = values.sendToDMVDate;
    let returnDMVDate = values.returnFromDMVDate;
    if (dealerDate !== undefined && dealerDate !== null) {
      dealerDate = moment(dealerDate).format('YYYY-MM-DD hh:mm:ss');
    }
    if (toDMVDate !== undefined && toDMVDate !== null) {
      toDMVDate = moment(toDMVDate).format('YYYY-MM-DD hh:mm:ss');
    }
    if (returnDMVDate !== undefined && returnDMVDate !== null) {
      returnDMVDate = moment(returnDMVDate).format('YYYY-MM-DD hh:mm:ss');
    }
    // build the submission object
    let bodyFormData = new FormData();
    bodyFormData.append('created_by', currentUser.userId);
    bodyFormData.append('updated_by', currentUser.userId);
    bodyFormData.append('dealer_id', values.dealerName);
    bodyFormData.append('dealer_phone', values.dealerPhone);
    bodyFormData.append('dealer_courier', values.courier);
    bodyFormData.append('returned_to', values.returnTo);
    bodyFormData.append('reg_first_name', values.regFirstName);
    bodyFormData.append('reg_last_name', values.regLastName);
    bodyFormData.append('reg_company_name', values.regCompanyName);
    bodyFormData.append('reg_email', values.regEmail);
    bodyFormData.append('reg_phone', values.regPhone);
    bodyFormData.append('reg_transaction_type', values.regTxnType);
    bodyFormData.append('reg_address', values.regAddress);
    bodyFormData.append('reg_city', values.regCity);
    bodyFormData.append('reg_zipcode', values.regZipCode);
    bodyFormData.append('reg_state_deal', values.regStateDeal);
    bodyFormData.append('reg_car_make', values.regCarMake);
    bodyFormData.append('reg_car_year', values.regCarYear);
    bodyFormData.append('reg_vin', values.regVinNumber);
    bodyFormData.append('reg_plate_number', values.regPlateNumber);
    bodyFormData.append('reg_lien_holder_id', values.regLienHolder);
    bodyFormData.append('leasing_company', values.regLeasingCompany);
    bodyFormData.append('from_dealer_date', dealerDate);
    bodyFormData.append('dmv_sent_date', toDMVDate);
    bodyFormData.append('dmv_return_date', returnDMVDate);
    bodyFormData.append('brm_fees', values.brmCharge);
    bodyFormData.append('dmv_fees', values.dmvCharge);
    bodyFormData.append('other_fees', values.otherFees);
    bodyFormData.append('sale_tax', values.salesTax);
    bodyFormData.append('status', values.plateStatus);
    bodyFormData.append('total_charges', txnForm.chargeTotal);
    bodyFormData.append('combined_invoice_id', txnForm.combinedInvoiceId);
    bodyFormData.append(
      'paid_from_combined_invoice',
      txnForm.paidFromCombinedInvoice
    );
    bodyFormData.append('is_financial_invoice', 1);
    bodyFormData.append('current_balance', txnForm.currentBalance);

    // do we need to send a notification to the dealer?
    if ('save-and-send' === saveAction) {
      // find the dealer selected and use the email address, if there is one
      const dealerFullInfo = _.find(dealerList, {
        dealerId: values.dealerName,
      });
      if (dealerFullInfo !== undefined) {
        // make sure there is an email address
        if (dealerFullInfo.email.length > 0) {
          bodyFormData.append('notify_dealer', true);
          bodyFormData.append('dealer_email', dealerFullInfo.email);
        }
      }
    }
    //console.log(bodyFormData);
    if (0 === txnForm.txnId) {
      // this is a new invoice entry
      bodyFormData.append('session_token', sessionId);
      response = await main.post('/api/plate_txns', bodyFormData);
    } else {
      /**
       * Add audit information for any differences between what was submitted and the
       * current state object.  Also designate a special audit note if any charges were
       * changed.
       */
      if (values.dealerName !== txnForm.dealerId) {
        auditObj = {
          ...auditObj,
          dealer: { oldValue: txnForm.dealerId, newValue: values.dealerName },
        };
      }

      if (values.dealerPhone !== txnForm.dealerPhoneNumber) {
        auditObj = {
          ...auditObj,
          dealerPhoneNumber: {
            oldValue: txnForm.dealerPhoneNumber,
            newValue: values.dealerPhone,
          },
        };
      }

      if (values.courier !== txnForm.dealerCourier) {
        auditObj = {
          ...auditObj,
          dealerCourier: {
            oldValue: txnForm.dealerCourier,
            newValue: values.courier,
          },
        };
      }

      if (values.returnTo !== txnForm.returnedTo) {
        auditObj = {
          ...auditObj,
          returnedTo: {
            oldValue: txnForm.returnedTo,
            newValue: values.returnTo,
          },
        };
      }

      if (values.regFirstName !== txnForm.firstName) {
        auditObj = {
          ...auditObj,
          firstName: {
            oldValue: txnForm.firstName,
            newValue: values.regFirstName,
          },
        };
      }

      if (values.regLastName !== txnForm.lastName) {
        auditObj = {
          ...auditObj,
          lastName: {
            oldValue: txnForm.lastName,
            newValue: values.regLastName,
          },
        };
      }

      if (values.regCompanyName !== txnForm.companyName) {
        auditObj = {
          ...auditObj,
          lastName: {
            oldValue: txnForm.companyName,
            newValue: values.regCompanyName,
          },
        };
      }

      if (values.regEmail !== txnForm.email) {
        auditObj = {
          ...auditObj,
          email: { oldValue: txnForm.email, newValue: values.regEmail },
        };
      }

      if (values.regPhone !== txnForm.phoneNumber) {
        auditObj = {
          ...auditObj,
          phoneNumber: {
            oldValue: txnForm.phoneNumber,
            newValue: values.regPhone,
          },
        };
      }

      if (values.regTxnType !== txnForm.txnType) {
        auditObj = {
          ...auditObj,
          txnType: { oldValue: txnForm.txnType, newValue: values.regTxnType },
        };
      }

      if (values.regAddress !== txnForm.regAddress) {
        auditObj = {
          ...auditObj,
          regAddress: {
            oldValue: txnForm.regAddress,
            newValue: values.regAddress,
          },
        };
      }

      if (values.regCity !== txnForm.regCity) {
        auditObj = {
          ...auditObj,
          regCity: { oldValue: txnForm.regCity, newValue: values.regCity },
        };
      }

      if (values.regZipCode !== txnForm.regZipCode) {
        auditObj = {
          ...auditObj,
          regZipCode: {
            oldValue: txnForm.regZipCode,
            newValue: values.regZipCode,
          },
        };
      }

      if (values.regStateDeal !== txnForm.stateDeal) {
        auditObj = {
          ...auditObj,
          stateDeal: {
            oldValue: txnForm.stateDeal,
            newValue: values.regStateDeal,
          },
        };
      }

      if (values.regCarMake !== txnForm.carMake) {
        auditObj = {
          ...auditObj,
          carMake: { oldValue: txnForm.carMake, newValue: values.regCarMake },
        };
      }

      if (values.regCarYear !== txnForm.carYear) {
        auditObj = {
          ...auditObj,
          carYear: { oldValue: txnForm.carYear, newValue: values.regCarYear },
        };
      }

      if (values.regVinNumber !== txnForm.vinNumber) {
        auditObj = {
          ...auditObj,
          vinNumber: {
            oldValue: txnForm.vinNumber,
            newValue: values.regVinNumber,
          },
        };
      }

      if (values.regPlateNumber !== txnForm.plateNumber) {
        auditObj = {
          ...auditObj,
          plateNumber: {
            oldValue: txnForm.plateNumber,
            newValue: values.regPlateNumber,
          },
        };
      }

      if (values.regLienHolder !== txnForm.lienHolderId) {
        auditObj = {
          ...auditObj,
          lienHolderId: {
            oldValue: txnForm.lienHolderId,
            newValue: values.regLienHolder,
          },
        };
      }

      if (values.regLeasingCompany !== txnForm.leasingCompany) {
        auditObj = {
          ...auditObj,
          leasingCompany: {
            oldValue: txnForm.leasingCompany,
            newValue: values.regLeasingCompany,
          },
        };
      }

      if (dealerDate !== txnForm.receivedFromDealer) {
        auditObj = {
          ...auditObj,
          receivedFromDealer: {
            oldValue: txnForm.receivedFromDealer,
            newValue: dealerDate,
          },
        };
      }

      if (toDMVDate !== txnForm.sentToDMV) {
        auditObj = {
          ...auditObj,
          sentToDMV: { oldValue: txnForm.sentToDMV, newValue: toDMVDate },
        };
      }

      if (returnDMVDate !== txnForm.returnFromDMV) {
        auditObj = {
          ...auditObj,
          returnFromDMV: {
            oldValue: txnForm.returnFromDMV,
            newValue: returnDMVDate,
          },
        };
      }

      if (values.brmCharge !== txnForm.brmFees) {
        auditObj = {
          ...auditObj,
          brmFees: { oldValue: txnForm.brmFees, newValue: values.brmCharge },
        };
        auditNote +=
          'BRM Charge Changed From $' +
          txnForm.brmFees +
          ' To $' +
          values.brmCharge +
          '\n';
      }

      if (values.dmvCharge !== txnForm.dmvFees) {
        auditObj = {
          ...auditObj,
          dmvFees: { oldValue: txnForm.dmvFees, newValue: values.dmvCharge },
        };
        auditNote +=
          'DMV Fee Changed From $' +
          txnForm.dmvFees +
          ' To $' +
          values.dmvCharge +
          '\n';
      }

      if (values.salesTax !== txnForm.salesTax) {
        auditObj = {
          ...auditObj,
          salesTax: { oldValue: txnForm.salesTax, newValue: values.salesTax },
        };
        auditNote +=
          'Sales Tax Changed From $' +
          txnForm.salesTax +
          ' To $' +
          values.salesTax +
          '\n';
      }

      if (values.otherFees !== txnForm.otherFees) {
        auditObj = {
          ...auditObj,
          otherFees: {
            oldValue: txnForm.otherFees,
            newValue: values.otherFees,
          },
        };
        auditNote +=
          'Other Fees Changed From $' +
          txnForm.otherFees +
          ' To $' +
          values.otherFees +
          '\n';
      }

      if (values.plateStatus !== txnForm.status) {
        auditObj = {
          ...auditObj,
          status: { oldValue: txnForm.status, newValue: values.plateStatus },
        };
      }

      // send an update
      response = await main.post(
        `/api/plate_txns/${txnForm.txnId}/${sessionId}`,
        bodyFormData
      );
    }

    if (200 === response.status || 201 === response.status) {
      const responseData = response.data;

      // check for session token error
      if (responseData.error !== undefined) {
        dispatch(showLoading(false));
        // what type of error?
        if (responseData.error === 'Invalid session token') {
          // the session token is invalid or expired
          dispatch(sessionExpired());
          return;
        }

        // throw an error
        dispatch({ type: THROW_ERROR, payload: responseData.error.text });
        return;
      }

      if (responseData.notice !== undefined) {
        /**
         * Successful insertion, now see if any financial transactions
         * were added to a new invoice only
         */
        if (0 === txnForm.txnId) {
          // loop through the transactions entered and save them
          //console.log('Temp Payments', txnList);
          _.each(txnList, (values) => {
            let submitObj = {
              txnType: values.txn_type,
              created_by: currentUser.userId,
              updated_by: currentUser.userId,
              paymentType: values.payment_type,
              amount: values.amount,
              refNumber: values.ref_number,
              toward: values.toward,
              inReg: values.in_reg,
              deleted: 0,
            };
            dispatch(saveFinancialTxn(submitObj, responseData.notice.id));
          });

          // loop through the notes and save them
          _.each(notes, (values) => {
            let submitObj = {
              note: values.note,
              internal: 0,
            };
            dispatch(saveNote(submitObj, responseData.notice.id));
          });

          _.each(internalNotes, (values) => {
            let submitObj = {
              note: values.note,
              internal: 1,
            };
            dispatch(saveNote(submitObj, responseData.notice.id));
          });

          // if there are expenses, update them with the invoice id
          if (expensesList.length > 0) {
            dispatch(saveExpenses(responseData.notice.id));
          }
        }

        // send the audit object, if needed
        //console.log('Audit', auditObj);
        if (!_.isEmpty(auditObj)) {
          let auditFormData = new FormData();
          auditFormData.append('session_token', sessionId);
          auditFormData.append('txn_id', txnForm.txnId);
          auditFormData.append('txn_type', 'plate');
          auditFormData.append('created_by', currentUser.userId);
          auditFormData.append('txn_changes', JSON.stringify(auditObj));
          const auditResponse = await main.post(
            'api/txn_edit_log',
            auditFormData
          );
        }

        // save any charge changes as an audit note
        if (auditNote.length > 0) {
          let submitObj = {
            note: auditNote,
            internal: 1,
          };
          dispatch(saveNote(submitObj, responseData.notice.id));
        }

        // add or update a state expense if a state deal has been selected
        //console.log('State Deal', values.regStateDeal);
        if (values.regStateDeal.length > 0) {
          //console.log('Add/Update State Expense');
          const txnIdSaved =
            txnForm.txnId > 0 ? txnForm.txnId : responseData.notice.id;
          dispatch(addUpdateStateFeeExpense(values.regStateDeal, txnIdSaved));
        }

        // was save and close pressed?
        if (
          saveAction !== 'close' &&
          saveAction !== 'save-and-new' &&
          saveAction !== 'save-and-send'
        ) {
          if (0 === txnForm.txnId) {
            // the new invoice id must be set to prevent duplication of an invoice
            // must also preserve the form values
            // using the GET_INVOICE action will solve this
            dispatch({
              type: GET_INVOICE,
              payload: {
                id: responseData.notice.id,
                dealer_id: values.dealerName,
                updated_by: currentUser.userId,
                dealer_phone: values.dealerPhone,
                dealer_courier: values.courier,
                returned_to: values.returnTo,
                reg_first_name: values.regFirstName,
                reg_last_name: values.regLastName,
                reg_company_name: values.regCompanyName,
                reg_email: values.regEmail,
                reg_phone: values.regPhone,
                reg_transaction_type: values.regTxnType,
                reg_address: values.regAddress,
                reg_city: values.regCity,
                reg_zipcode: values.regZipCode,
                reg_state_deal: values.regStateDeal,
                reg_car_make: values.regCarMake,
                reg_car_year: values.regCarYear,
                reg_vin: values.regVinNumber,
                reg_plate_number: values.regPlateNumber,
                reg_lien_holder_id: values.regLienHolder,
                leasing_company: values.regLeasingCompany,
                from_dealer_date: dealerDate,
                dmv_sent_date: toDMVDate,
                dmv_return_date: returnDMVDate,
                brm_fees: values.brmCharge,
                dmv_fees: values.dmvCharge,
                other_fees: values.otherFees,
                sale_tax: values.salesTax,
                status: values.plateStatus,
                combined_invoice_id: 0,
                paid_from_combined_invoice: 0,
              },
            });
          }
          // does the print invoice modal need to be shown for the Save and Print action?
          if ('print' === saveAction) {
            dispatch(showPrintInvoice(true));
          }
        } else {
          if ('save-and-new' === saveAction) {
            // save the invoice and send the dealer information to be preserved
            dispatch({
              type: SUBMIT_INVOICE,
              payload: {
                currentUserId: currentUser.userId,
                dealerId: values.dealerName,
                dealerPhone: values.dealerPhone,
                dealerCourier: values.courier,
              },
            });
          } else {
            dispatch({
              type: SUBMIT_INVOICE,
              payload: {
                currentUserId: currentUser.userId,
                dealerId: -1,
                dealerPhone: '',
                dealerCourier: '',
              },
            });
          }
          dispatch({ type: RESET_FINANCIAL_TXN_SAVED });
          dispatch({
            type: SHOW_SUCCESS,
            payload: 'The invoice was successfully saved.',
          });
          // update the invoice list
          dispatch(
            getAllInvoices({
              startDate: currentInvoiceListFilters.startDate,
              endDate: currentInvoiceListFilters.endDate,
              status: currentInvoiceListFilters.status,
              hasBalance: currentInvoiceListFilters.hasBalance,
              dealer: currentInvoiceListFilters.dealer,
              checkNumber: currentInvoiceListFilters.checkNumber,
              ignoreDates: currentInvoiceListFilters.ignoreDates,
              fuzzySearchString: currentInvoiceListFilters.fuzzy,
            })
          );
        }
      } else {
        // bad credentials
        dispatch({
          type: THROW_ERROR,
          payload: 'There was a problem saving the Invoice.  Please try again.',
        });
      }
    } else {
      // something went wrong
      dispatch({ type: THROW_ERROR, payload: response.statusText });
    }
    dispatch(showLoading(false));
  };

/**
 * Calculates the total charges for an invoice
 * @param {object} charges
 */
export const calculateCharges =
  (charges, singleChargeField = '', singleChargeValue = 0.0) =>
  async (dispatch) => {
    //console.log('Sent Charges', charges);
    // calculate the current charges
    let BRM = charges.brmCharge;
    let DMV = charges.dmvCharge;
    let ST = charges.salesTax;
    let OTH = charges.otherFees;

    // check for a change on a singular charge field
    switch (singleChargeField) {
      case 'brmCharge':
        BRM = singleChargeValue;
        break;
      case 'dmvCharge':
        DMV = singleChargeValue;
        break;
      case 'salesTax':
        ST = singleChargeValue;
        break;
      case 'otherFees':
        OTH = charges.otherFees;
        break;
    }

    // store all the current fees
    const chargesCosts = {
      brmFees: parseFloat(BRM),
      dmvFees: parseFloat(DMV),
      otherFees: parseFloat(OTH),
      salesTax: parseFloat(ST),
    };

    const totalCharges =
      parseFloat(BRM) + parseFloat(DMV) + parseFloat(ST) + parseFloat(OTH);
    dispatch({
      type: CALCULATE_CHARGES,
      payload: {
        totalCharges: totalCharges.toFixed(2),
        currentFees: chargesCosts,
      },
    });
    dispatch(updateBalance());

    dispatch(calculateTotalProfit(chargesCosts));
  };

/**
 * Updates the current balance on an invoice
 */
export const updateBalance = () => async (dispatch, getState) => {
  const { txnForm, txnList } = getState().plates;
  let currentBalance = 0.0;
  const totalCharges = parseFloat(txnForm.chargeTotal);
  let totalPaymentsRefunds = 0.0;

  // loop through each transaction to get the total amount of payments
  _.each(txnList, (values) => {
    if ('Payment' === values.txn_type) {
      totalPaymentsRefunds += parseFloat(values.amount);
    } else {
      // refunds are subtracted from the total payments
      totalPaymentsRefunds -= parseFloat(values.amount);
    }
  });

  // calculate the current balance and fire off an update
  currentBalance = totalCharges - totalPaymentsRefunds;
  dispatch({ type: UPDATE_CURRENT_BALANCE, payload: currentBalance });
};

export const getInvoice = (txnId) => async (dispatch, getState) => {
  //console.log('Invoice: ', txnId);
  const { sessionId, currentUser } = getState().users;

  const response = await main.get(`/api/plate_txns/${txnId}/${sessionId}`);

  if (200 === response.status) {
    // check for session token error
    if (response.data.error !== undefined) {
      // what type of error?
      if (response.data.error === 'Invalid session token') {
        // the session token is invalid or expired
        dispatch(sessionExpired());
        return;
      }

      // throw an error
      dispatch({ type: THROW_ERROR, payload: responseData.error.text });
      return;
    }

    // save the data to the form
    const responseData = response.data[0];
    //console.log('Response', responseData);
    dispatch({ type: GET_INVOICE, payload: responseData });

    // get the currently selected dealer
    dispatch(getDealer(responseData.dealer_id));

    // get the currently selected lien holder
    dispatch(getLienHolder(responseData.reg_lien_holder_id));

    // get the associated payments and refunds on the invoice
    await dispatch(getFinancialTxnByInvoice(responseData.id));

    if (currentUser.accountType !== 'dealer') {
      // get the associated expenses for the invoice
      await dispatch(getPlateTransactionExpenses(responseData.id));
    }

    // get the public and internal notes on the invoice
    dispatch(getNotesList(responseData.id, 'invoice', 0)); // public
    if (currentUser.accountType !== 'dealer') {
      dispatch(getNotesList(responseData.id, 'invoice', 1)); // internal
    }

    dispatch(updateBalance());

    if (currentUser.accountType !== 'dealer') {
      dispatch(calculateExpenses());
      dispatch(calculateTotalProfit());
    }
  }
};

export const getFinancialTxnByInvoice =
  (invoiceId) => async (dispatch, getState) => {
    const { sessionId, userList } = getState().users;

    const response = await main.get(
      `/api/financial_txns_by_plate/${invoiceId}/${sessionId}`
    );
    if (200 === response.status) {
      const responseData = response.data;

      // check for session token error
      if (responseData.error !== undefined) {
        // what type of error?
        if (responseData.error === 'Invalid session token') {
          // the session token is invalid or expired
          dispatch(sessionExpired());
          return;
        }

        // throw an error
        dispatch({ type: THROW_ERROR, payload: responseData.error.text });
        return;
      }

      // build the transaction list with the user information
      let txnData = [];
      _.each(responseData, (values) => {
        const updatedBy = _.find(userList, { userId: values.updated_by });
        txnData.push({
          id: values.id,
          txn_type: values.txn_type,
          created_by: values.created_by,
          created_at: values.created_at,
          updated_by: values.updated_by,
          updated_at: values.updated_at,
          payment_type: values.payment_type,
          amount: values.amount,
          ref_number: values.ref_number,
          toward: values.toward,
          in_reg: values.in_reg,
          deleted: values.deleted,
          plate_id: values.plate_id,
          parent_id: values.parent_financial_txn_id,
          payment_processed: values.payment_processed,
          updated_by_name:
            updatedBy !== undefined
              ? updatedBy.firstName + ' ' + updatedBy.lastName
              : '',
        });
      });

      dispatch({ type: GET_FINANCIAL_TXN_BY_INVOICE, payload: txnData });
    }
  };

export const clearInvoice = () => async (dispatch, getState) => {
  const { currentUser } = getState().users;
  dispatch({ type: CLEAR_INVOICE, payload: currentUser.userId });
};

export const removeInvoice =
  (txnId, setRemoved = false) =>
  async (dispatch, getState) => {
    await dispatch(getInvoice(txnId));
    const { sessionId } = getState().users;
    const { currentInvoiceListFilters } = getState().main;

    const response = await main.delete(`/api/plate_txns/${txnId}/${sessionId}`);

    if (200 === response.status) {
      // check for session token error
      if (response.data.error !== undefined) {
        // what type of error?
        if (response.data.error === 'Invalid session token') {
          // the session token is invalid or expired
          dispatch(sessionExpired());
          return;
        }

        // throw an error
        dispatch({ type: THROW_ERROR, payload: responseData.error.text });
        return;
      }

      // check that the deletion was successful
      if (response.data.Success) {
        // record deleted, refresh the list
        dispatch(
          getAllInvoices({
            startDate: currentInvoiceListFilters.startDate,
            endDate: currentInvoiceListFilters.endDate,
            status: currentInvoiceListFilters.status,
            hasBalance: currentInvoiceListFilters.hasBalance,
            dealer: currentInvoiceListFilters.dealer,
            checkNumber: currentInvoiceListFilters.checkNumber,
            ignoreDates: currentInvoiceListFilters.ignoreDates,
            fuzzySearchString: currentInvoiceListFilters.fuzzy,
          })
        );
        if (setRemoved) {
          dispatch({ type: INVOICE_REMOVED, payload: true });
        }
      } else {
        dispatch({
          type: THROW_ERROR,
          payload: 'There was a problem removing the invoice.',
        });
      }
    } else {
      // there was an error from the endpoint
      dispatch({ type: THROW_ERROR, payload: response.statusText });
    }
  };

export const clearInvoiceRemoved = () => {
  return { type: INVOICE_REMOVED, payload: false };
};

/**
 * Saves a payment or refund on new invoices that have not yet been saved
 * @param {object} data
 */
export const saveTempFinancialTxn = (data) => {
  return { type: SAVE_TEMP_FINANCIAL_TXN, payload: data };
};

/**
 * Saves a note on new invoices that have not yet been saved
 * @param {object} data
 * @param {boolean} internal
 */
export const saveTempNote = (data, internal) => {
  if (1 === internal) {
    return { type: SAVE_TEMP_INTERNAL_NOTE, payload: data };
  }
  return { type: SAVE_TEMP_INVOICE_NOTE, payload: data };
};

/**
 * Show or hide the PDF invoice viewer to print an invoice
 */
export const showPrintInvoice = (show) => async (dispatch) => {
  if (show) {
    dispatch(getSingleInvoicePDF());
  } else {
    dispatch({ type: PRINT_INVOICE, payload: { show: false, fileName: '' } });
  }
};

/**
 * Closes the invoice result
 */
export const closeSavedInvoiceResult = () => async (dispatch) => {
  dispatch({ type: CLEAR_SUCCESS });
  dispatch({ type: CLEAR_ERROR });
  dispatch({ type: INVOICE_SAVED });
  dispatch({ type: COMBINED_INVOICE_SAVED });
};

/**
 * Calls the endpoint to retrieve the PDF invoice for printing
 */
export const getSingleInvoicePDF = () => async (dispatch, getState) => {
  dispatch(showLoading(true));
  const { sessionId, userList } = getState().users;
  const { txnForm, txnList, notes } = getState().plates;
  const { lienHolderList } = getState().lienholders;

  // get the dealer information, lien holder name and user ref
  const lienHolderInfo = _.find(lienHolderList, { key: txnForm.lienHolderId });
  const userRefInfo = _.find(userList, { userId: txnForm.updatedByUserId });

  // build the request object
  const requestObj = {
    pdfInfo: {
      txnId: txnForm.txnId,
      dealerId: txnForm.dealerId,
      rep: userRefInfo.initials,
      firstName: txnForm.firstName,
      lastName: txnForm.lastName,
      companyName: txnForm.companyName,
      phoneNumber: txnForm.phoneNumber,
      txnType: txnForm.txnType,
      regAddress: txnForm.regAddress,
      regCity: txnForm.regCity,
      regZipCode: txnForm.regZipCode,
      stateDeal: txnForm.stateDeal,
      vinNumber: txnForm.vinNumber,
      plateNumber: txnForm.plateNumber,
      lienHolderName: lienHolderInfo !== undefined ? lienHolderInfo.text : '',
      leasingCompany: txnForm.leasingCompany,
      receivedFromDealer: txnForm.receivedFromDealer,
      brmFees: txnForm.brmFees,
      dmvFees: txnForm.dmvFees,
      otherFees: txnForm.otherFees,
      salesTax: txnForm.salesTax,
      chargeTotal: txnForm.chargeTotal,
      currentBalance: txnForm.currentBalance,
    },
    txnList,
    notes,
  };

  const response = await main.post(
    `/api/pdf_plate_txn_single/${sessionId}`,
    requestObj
  );

  if (200 === response.status) {
    // check for session token error
    if (response.data.error !== undefined) {
      // what type of error?
      if (response.data.error === 'Invalid session token') {
        // the session token is invalid or expired
        dispatch(sessionExpired());
        dispatch(showLoading(false));
        return;
      }

      // throw an error
      dispatch(showLoading(false));
      dispatch({ type: THROW_ERROR, payload: response.data.error.text });
      return;
    }

    // check that the pdf was generated
    if (response.data.success) {
      dispatch({
        type: PRINT_INVOICE,
        payload: { show: true, fileName: response.data.invoiceFile },
      });
    } else {
      dispatch({
        type: THROW_ERROR,
        payload: 'There was a problem printing the invoice.',
      });
    }
  } else {
    // there was an error from the endpoint
    dispatch({ type: THROW_ERROR, payload: response.statusText });
  }
  dispatch(showLoading(false));
};

/**
 * Performs the calculations to return the net profit and net profit
 * percentage for an invoice
 *
 * @param {object} currentChargesCosts contains the updated fees necessary to accurately change the profitability.  Can be left empty if using the values from the invoice form.
 * @returns
 */
export const calculateTotalProfit =
  (currentChargesCosts = {}) =>
  async (dispatch, getState) => {
    // retrieve all values needed to calculate the total profit
    let chargesCosts = currentChargesCosts;

    if (_.isEmpty(currentChargesCosts)) {
      // all charges and costs will be pulled from the current values on the invoice
      const { currentChargeCostValues } = getState().plates;
      chargesCosts = {
        /*dmvFees: parseFloat(currentChargeCostValues.dmvFees),
        otherFees: parseFloat(currentChargeCostValues.otherFees),
        salesTax: parseFloat(currentChargeCostValues.salesTax),*/
        brmFees: parseFloat(currentChargeCostValues.brmFees),
      };
    }
    //console.log('Current Charges', chargesCosts);
    const { totalExpenses } = getState().expenses;

    // do the math and update the state
    /*const totalFeesExpenses =
      chargesCosts.dmvFees +
      chargesCosts.otherFees +
      chargesCosts.salesTax +
      parseFloat(totalExpenses);
    */

    //const netProfit = chargesCosts.brmFees - totalFeesExpenses;
    const netProfit = chargesCosts.brmFees - parseFloat(totalExpenses);
    //console.log('Calculated Net Profit', netProfit);
    const percentageProfit = Math.round(
      (parseFloat(netProfit) / chargesCosts.brmFees) * 100
    );

    dispatch({
      type: CALCULATE_TOTAL_PROFIT,
      payload: { netProfit, percentageProfit },
    });
  };
