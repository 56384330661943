import _ from 'lodash';
import main from '../apis/main';
import moment from 'moment';
import { saveTempNote } from './plateActions';
import { saveTempCINote } from './combinedInvoicesActions';
import { sessionExpired } from './globalActions';
import { v4 as uuidv4 } from 'uuid';
import {
  START_NOTE,
  SAVE_NOTE,
  SHOW_NOTES_FORM,
  GET_NOTES_LIST,
  SET_INTERNAL_NOTES,
  SET_INVOICE_NOTES,
  THROW_ERROR,
  SET_COMBINED_INVOICE_INTERNAL_NOTES,
  SET_COMBINED_INVOICE_NOTES,
  NOTE_REMOVED,
  REMOVE_COMBINED_INVOICE_INTERNAL_NOTE,
  REMOVE_COMBINED_INVOICE_NOTE,
  REMOVE_INVOICE_INTERNAL_NOTE,
  REMOVE_INVOICE_NOTE,
} from './types';

export const startNote = (internal) => async (dispatch, getState) => {
  /** API CALL TO GRAB NOTE ON EDIT, IF NECESSARY */
  const { txnId } = getState().plates;
  const { notesForm } = getState().notes;
  dispatch({ type: START_NOTE, payload: { isInternal: internal } });
  dispatch(showNotesForm(true));
};

/**
 * Saves a new note to a plate transaction or combined invoice
 * @param {object} values
 * @param {int} newPlateId
 * @param {string} parentType
 */
export const saveNote =
  (values, newInvoiceId = 0, parentType = 'invoice') =>
  async (dispatch, getState) => {
    //console.log('Note Values', values);
    //console.log('Invoice Id', newInvoiceId);
    //console.log('Parent Type', parentType);
    const { txnForm } = getState().plates;
    const { combinedInvoiceForm } = getState().combinedinvoices;
    const { currentUser, sessionId } = getState().users;
    const { notesForm, internal } = getState().notes;
    const { defaultBRMEmailAddress } = getState().main;
    const { dealerList } = getState().dealers;

    // the id to be used is dependant on the parent type
    let invoiceId = 0;
    switch (parentType) {
      case 'invoice':
        invoiceId = newInvoiceId > 0 ? newInvoiceId : txnForm.txnId;
        break;
      case 'combined-invoice':
        invoiceId =
          newInvoiceId > 0
            ? newInvoiceId
            : combinedInvoiceForm.combinedInvoiceId;
        break;
    }

    // check to see if this is for an unsaved invoice
    if (0 === invoiceId) {
      // this is new, must temporarily save the information
      const saveObj = {
        parent_type: parentType,
        parent_id: invoiceId,
        created_by: currentUser.userId,
        firstname: currentUser.firstName,
        lastname: currentUser.lastName,
        created_at: moment(),
        for_invoice: 'yes',
        note: values.note,
        internal: internal,
        id: uuidv4(),
      };

      // temp not saved is dependant on the invoice type
      switch (parentType) {
        case 'invoice':
          dispatch(saveTempNote(saveObj, internal));
          break;
        case 'combined-invoice':
          dispatch(saveTempCINote(saveObj, internal));
          break;
      }
      dispatch({ type: SAVE_NOTE });

      // do not execute anything else
      return;
    }

    // where is the intrnal note flag coming from?
    const internalNoteFlag =
      values.internal === undefined ? internal : values.internal;

    // add or edit the note
    let noteFormData = new FormData();
    noteFormData.append('parent_type', parentType);
    noteFormData.append('parent_id', invoiceId);
    noteFormData.append('created_by', currentUser.userId);
    noteFormData.append('for_invoice', 'yes');
    noteFormData.append('note', values.note);
    noteFormData.append('internal', internalNoteFlag);
    console.log('Internal Note? ', internalNoteFlag);

    if (internalNoteFlag !== 1) {
      // we will send an email notification that there is a new note on the invoice
      let noteSenderName = currentUser.firstName + ' ' + currentUser.lastName;
      let emailTo = '';
      let toBRM = 0;
      if ('dealer' === currentUser.accountType) {
        toBRM = 1;
      } else {
        // find the dealer email address to send the notification to
        // find the dealer selected and use the email address, if there is one
        const dealerFullInfo = _.find(dealerList, {
          dealerId: txnForm.dealerId,
        });
        if (dealerFullInfo !== undefined) {
          // make sure there is an email address
          if (dealerFullInfo.email.length > 0) {
            emailTo = dealerFullInfo.email;
          }
        }
      }
      //console.log('Send Notification To: ', emailTo);
      noteFormData.append('email_to', emailTo);
      noteFormData.append('invoice_txn_type', txnForm.txnType);
      noteFormData.append(
        'registration_name',
        txnForm.firstName + ' ' + txnForm.lastName
      );
      noteFormData.append('name_of_note_sender', noteSenderName);
      noteFormData.append('to_brm', toBRM);
    }

    let response = {};
    if (0 === notesForm.noteId) {
      // new note
      noteFormData.append('session_token', sessionId);
      response = await main.post('/api/notes', noteFormData);
    } else {
      // update note
      response = await main.post(
        `/api/notes/${notesForm.noteId}/${sessionId}`,
        noteFormData
      );
    }

    if (200 === response.status || 201 === response.status) {
      const responseData = response.data;

      // check for session token error
      if (responseData.error !== undefined) {
        // what type of error?
        if (responseData.error === 'Invalid session token') {
          // the session token is invalid or expired
          dispatch(sessionExpired());
          return;
        }

        // throw an error
        dispatch({ type: THROW_ERROR, payload: responseData.error.text });
        return;
      }

      if (responseData.notice.text !== undefined) {
        dispatch({ type: SAVE_NOTE });

        // refresh the notes list of the parent
        dispatch(getNotesList(invoiceId, parentType, internalNoteFlag));
      }
    } else {
      // something went wrong
      dispatch({ type: THROW_ERROR, payload: response.statusText });
    }
  };

export const showNotesForm = (show) => {
  return { type: SHOW_NOTES_FORM, payload: show };
};

export const getNotesList =
  (parentId, parentType, internal) => async (dispatch, getState) => {
    const { sessionId } = getState().users;

    // build the form for the search
    let noteFormData = new FormData();
    noteFormData.append('session_token', sessionId);
    noteFormData.append('parent_id', parentId);
    noteFormData.append('parent', parentType);
    noteFormData.append('internal', internal);

    const response = await main.post('/api/notes/list', noteFormData);

    if (200 === response.status) {
      // return the notes list based on the parent type
      const responseData = response.data;

      // check for session token error
      if (responseData.error !== undefined) {
        // what type of error?
        if (responseData.error === 'Invalid session token') {
          // the session token is invalid or expired
          dispatch(sessionExpired());
          return;
        }

        // throw an error
        dispatch({ type: THROW_ERROR, payload: responseData.error.text });
        return;
      }

      switch (parentType) {
        case 'invoice':
          if (1 === internal) {
            // fill the internal notes list
            dispatch({ type: SET_INTERNAL_NOTES, payload: responseData });
          } else {
            dispatch({ type: SET_INVOICE_NOTES, payload: responseData });
          }
          break;
        case 'combined-invoice':
          if (1 === internal) {
            // fill the internal notes list
            dispatch({
              type: SET_COMBINED_INVOICE_INTERNAL_NOTES,
              payload: responseData,
            });
          } else {
            dispatch({
              type: SET_COMBINED_INVOICE_NOTES,
              payload: responseData,
            });
          }
      }
    } else {
      // something went wrong
      dispatch({ type: THROW_ERROR, payload: response.statusText });
    }
  };

/**
 * Deletes a note from the system
 * @param {int} noteId
 * @param {string} parentType - invoice or combined-invoice
 * @param {int} internal - 1 for internal note
 */
export const removeNote =
  (noteId, parentType, internal) => async (dispatch, getState) => {
    const { sessionId } = getState().users;
    const { txnForm } = getState().plates;
    const { combinedInvoiceForm } = getState().combinedinvoices;
    //console.log('Note', noteId);
    //console.log('Parent', parentType);
    //console.log('Internal', internal);

    // check for a temporary entry to see if can go ahead and dispatch the removals and return from the function
    switch (parentType) {
      case 'invoice':
        if (0 === txnForm.txnId) {
          if (1 === internal) {
            dispatch({ type: REMOVE_INVOICE_INTERNAL_NOTE, payload: noteId });
          } else {
            dispatch({ type: REMOVE_INVOICE_NOTE, payload: noteId });
          }
          return;
        }
        break;
      case 'combined-invoice':
        if (0 === combinedInvoiceForm.combinedInvoiceId) {
          if (1 === internal) {
            dispatch({
              type: REMOVE_COMBINED_INVOICE_INTERNAL_NOTE,
              payload: noteId,
            });
          } else {
            dispatch({ type: REMOVE_COMBINED_INVOICE_NOTE, payload: noteId });
          }
          return;
        }
        break;
    }

    // if we get this far, it is an existing invoice or combined invoice
    const response = await main.delete(`/api/notes/${noteId}/${sessionId}`);

    if (200 === response.status) {
      const responseData = response.data;

      // check for session token error
      if (responseData.error !== undefined) {
        // what type of error?
        if (responseData.error === 'Invalid session token') {
          // the session token is invalid or expired
          dispatch(sessionExpired());
          return;
        }

        // throw an error
        dispatch({ type: THROW_ERROR, payload: responseData.error.text });
        return;
      }

      // was the removal a success?
      if (responseData.Success) {
        // remove from the appropriate list
        switch (parentType) {
          case 'invoice':
            if (1 === internal) {
              dispatch({ type: REMOVE_INVOICE_INTERNAL_NOTE, payload: noteId });
            } else {
              dispatch({ type: REMOVE_INVOICE_NOTE, payload: noteId });
            }
            break;
          case 'combined-invoice':
            if (1 === internal) {
              dispatch({
                type: REMOVE_COMBINED_INVOICE_INTERNAL_NOTE,
                payload: noteId,
              });
            } else {
              dispatch({ type: REMOVE_COMBINED_INVOICE_NOTE, payload: noteId });
            }
            break;
        }
      }
    } else {
      // something went wrong
      dispatch({ type: THROW_ERROR, payload: response.statusText });
    }
  };
