import React, { Fragment } from 'react';
import _, { dropRight } from 'lodash';
import moment from 'moment';
import { Segment, Button, Grid, Icon, Header } from 'semantic-ui-react';
import { connect } from 'react-redux';
import Confirmation from '../../Confirmation';
import {
  showFinancialTxnForm,
  getFinancialTxn,
  addNewFinancialTxn,
  removeFinancialTxn,
} from '../../../actions';

const renderEditIcon = (values, props) => {
  let actionButtons = [];
  if (
    values.id !== 0 &&
    (values.parent_id === '0' || values.parent_id === undefined) &&
    '1' !== values.payment_processed
  ) {
    actionButtons.push(
      <Icon onClick={() => props.getFinancialTxn(values.id)} name='pencil' />
    );

    // only show delete function to administrators
    if (props.isAdministrator) {
      // get the financial transaction information
      actionButtons.push(
        <Confirmation
          title='Confirm Deletion'
          content={`You are about to delete a Financial Transaction.  Are you sure you want to do this?`}
          confirmId={values.id}
          confirmAction={props.removeFinancialTxn}
          trigger={<Icon style={{ cursor: 'pointer' }} name='trash' />}
        />
      );
    }
    return actionButtons;
  } else if ('1' === values.payment_processed) {
    // show that the line item has been paid
    actionButtons.push(<Icon name='check circle' />);
    //actionButtons.push(<strong>PROCESSED</strong>);
    return actionButtons;
  }

  return <Icon style={{ color: '#000' }} name='lock' />;
};

export const PaymentList = (props) => {
  // function to start adding a new payment
  const addNewPayment = () => {
    // check to see if there is an invoice id
    //console.log('Props', props);
    //console.log('Invoice Id', props.invoiceId);
    if (0 === props.invoiceId) {
      // save the invoice before allowing a payment to be made
      props.saveInvoiceFunction(props.invoiceValues, 'new-payment');
    }
    props.addNewFinancialTxn();
    props.showFinancialTxnForm(true);
  };

  // build the transactions list
  let paymentTxnList = [];
  _.each(props.txnList, (values) => {
    paymentTxnList.push(
      <Segment padded>
        <Grid columns={2}>
          <Grid.Row className='invoice-boxed-list-item brm-small-font'>
            <Grid.Column>{values.updated_by_name}</Grid.Column>
          </Grid.Row>
          <Grid.Row className='invoice-boxed-list-item brm-small-font'>
            <Grid.Column width={4}>
              {moment(values.updated_at).format('MM/DD/YYYY')}
            </Grid.Column>
            <Grid.Column width={6}>{values.toward}</Grid.Column>
            <Grid.Column width={6}>Txn Id: {values.id}</Grid.Column>
          </Grid.Row>
          <Grid.Row className='invoice-boxed-list-item brm-larger-font'>
            <Grid.Column width={10}>
              <strong>
                {values.payment_type} -{''}
                {values.ref_number}
              </strong>
            </Grid.Column>
            <Grid.Column width={6} textAlign='right'>
              <strong
                className={
                  'Payment' === values.txn_type
                    ? 'payment-positive'
                    : 'payment-negative'
                }
              >
                ${values.amount}&nbsp;&nbsp;&nbsp;
                {renderEditIcon(values, props)}
              </strong>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className='invoice-boxed-list-item brm-small-font'>
            <Grid.Column>
              <span
                className={
                  'Payment' === values.txn_type
                    ? 'payment-positive'
                    : 'payment-negative'
                }
              >
                {values.txn_type}
                {'0' !== values.parent_id &&
                  values.parent_id !== undefined &&
                  ' For Combined Invoice.  Cannot be Edited or Deleted.'}
              </span>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  });
  return (
    <Fragment>
      <Grid stackable>
        <Grid.Column width={12}>
          <Header>PAYMENT/REFUND HISTORY</Header>
        </Grid.Column>
        <Grid.Column width={4} style={{ textAlign: 'right' }}>
          <Button
            tabIndex='27'
            type='button'
            size='mini'
            circular
            positive
            icon='plus'
            className='brm-add-button-icon'
            onClick={() => addNewPayment()}
          />{' '}
          <span
            style={{ cursor: 'pointer' }}
            className='brm-form-label'
            onClick={() => addNewPayment()}
          >
            ADD
          </span>
        </Grid.Column>
      </Grid>
      {/*}
      <span style={{ float: 'right' }}>
        <Icon className='payment-positive' name='check circle' /> - Payment
        Processed
      </span>
      {*/}
      <Segment.Group
        verticalAlign='middle'
        style={{
          height: props.listHeight,
          overflowY: 'scroll',
          marginBottom: 0,
        }}
      >
        {paymentTxnList}
      </Segment.Group>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    isAdministrator: state.users.isAdministrator,
  };
};

export default connect(mapStateToProps, {
  showFinancialTxnForm,
  getFinancialTxn,
  addNewFinancialTxn,
  removeFinancialTxn,
})(PaymentList);
