import _ from 'lodash';
import {
  SET_EXPENSE,
  SAVE_EXPENSE,
  SHOW_EXPENSE_FORM,
  SET_INVOICE_EXPENSES,
  REMOVE_EXPENSE,
  EXPENSE_REMOVED,
  UPDATE_EXPENSE,
  CLEAR_EXPENSE_FORM,
  CLEAR_INVOICE,
  CALCULATE_EXPENSES,
} from '../actions/types';

const INITIAL_STATE = {
  expensesForm: {
    expenseId: 0,
    description: '',
    amount: 0.0,
    hidden: 0,
  },
  expensesList: [],
  showExpenseForm: false,
  totalExpenses: 0.0,
};

const expensesState = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_EXPENSE:
      return {
        ...state,
        expensesForm: {
          expenseId: parseInt(action.payload.id),
          description: action.payload.description,
          amount: action.payload.amount,
          hidden: action.payload.hidden,
        },
      };
    case SAVE_EXPENSE:
      return {
        ...state,
        expensesList: [
          ...state.expensesList,
          {
            id: action.payload.expenseId,
            dateEntered: action.payload.dateEntered,
            dateModified: action.payload.dateModified,
            addedBy: action.payload.addedBy,
            modifiedBy: action.payload.modifiedBy,
            description: action.payload.description,
            amount: action.payload.amount,
            deleted: 0,
            plateTxnId: action.payload.plateTxnId,
            hidden: action.payload.hidden,
          },
        ],
      };
    case SHOW_EXPENSE_FORM:
      return { ...state, showExpenseForm: action.payload };
    case SET_INVOICE_EXPENSES:
      return { ...state, expensesList: action.payload };
    case REMOVE_EXPENSE:
      return {
        ...state,
        expensesList: _.filter(state.expensesList, (value) => {
          return value.id !== action.payload;
        }),
      };
    case UPDATE_EXPENSE:
      //console.log('Update Expense', action.payload);
      return {
        ...state,
        expensesList: _.map(state.expensesList, (value) =>
          value.id === action.payload.expenseId
            ? {
                ...value,
                dateModified: action.payload.dateModified,
                modifiedBy: action.payload.modifiedBy,
                description: action.payload.description,
                amount: action.payload.amount,
                plateTxnId: action.payload.plateTxnId,
              }
            : value
        ),
      };
    case CLEAR_EXPENSE_FORM:
      return {
        ...state,
        expensesForm: { expenseId: 0, description: '', amount: 0.0, hidden: 0 },
      };
    case CLEAR_INVOICE:
      return { ...state, expensesList: [], totalExpenses: 0.0 };
    case CALCULATE_EXPENSES:
      return { ...state, totalExpenses: action.payload };
    default:
      return state;
  }
};

export default expensesState;
