import {
  AUTH_USER,
  LOGOUT_USER,
  GET_USERS,
  FIND_USER,
  SAVE_USER,
  CLEAR_USER_FORM,
  SHOW_USER_FORM,
  FORGOT_PASS_EMAIL_SENT,
  NEW_PASSWORD,
  START_PASSWORD_RESET,
  REFRESH_CURRENT_USER,
  CLOSE_USER_SAVE_RESULT,
  THROW_ERROR,
  AUTH_2FA,
} from '../actions/types';

const INITIAL_STATE = {
  currentUser: {
    userId: 0,
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    currentPassword: '',
    initials: '',
    cellPhone: '',
    accountType: '',
    dealerId: 0,
  },
  userForm: {
    userId: 0,
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    phoneNumber: '',
    confirmPassword: '',
    isAdministrator: false,
    initials: '',
    cellPhone: '',
    accountType: '',
    dealerId: 0,
  },
  userList: [],
  showUserModal: false,
  sessionId: '',
  userSaved: false,
  forgotEmailMsg: '',
  passwordChanged: false,
  isAdministrator: false,
  currentTwoFactorAuthStep: 0,
};

const userState = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTH_USER:
      return {
        ...state,
        sessionId: action.payload.sessionId,
        forgotEmailMsg: '',
        userSaved: false,
        currentUser: {
          userId: action.payload.userId,
          username: action.payload.username,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          email: action.payload.email,
          currentPassword: action.payload.password,
          initials: action.payload.initials,
          cellPhone: action.payload.cellPhone,
          accountType: action.payload.accountType,
          dealerId: action.payload.dealerId,
        },
        isAdministrator: action.payload.administrator,
      };
    case AUTH_2FA:
      return {
        ...state,
        forgotEmailMsg: '',
        currentUser: {
          userId: action.payload.userId,
          username: action.payload.username,
          currentPassword: action.payload.password,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          initials: action.payload.initials,
          cellPhone: action.payload.cellPhone,
          accountType: action.payload.accountType,
          dealerId: action.payload.dealerId,
        },
        isAdministrator: action.payload.administrator,
        currentTwoFactorAuthStep: action.payload.currentTwoFactorStep,
      };
    case GET_USERS:
      return { ...state, userList: action.payload };
    case FIND_USER:
      return {
        ...state,
        userForm: {
          userId: action.payload.id,
          username: action.payload.username,
          firstName: action.payload.firstname,
          lastName: action.payload.lastname,
          email: action.payload.email,
          password: '',
          phoneNumber: action.payload.phone_number,
          confirmPassword: '',
          isAdministrator: action.payload.is_admin === '1' ? true : false,
          initials: action.payload.initials,
          cellPhone: action.payload.cell_phone,
          accountType: action.payload.account_type,
          dealerId: action.payload.dealer_id,
        },
      };
    case SAVE_USER:
      return {
        ...state,
        userSaved: true,
      };
    case CLEAR_USER_FORM:
      return {
        ...state,
        userForm: {
          userId: 0,
          username: '',
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          confirmPassword: '',
          phoneNumber: '',
          isAdministrator: false,
          initials: '',
          cellPhone: '',
          accountType: '',
          dealerId: 0,
        },
      };
    case SHOW_USER_FORM:
      return { ...state, showUserModal: action.payload };
    case FORGOT_PASS_EMAIL_SENT:
      return { ...state, forgotEmailMsg: action.payload };
    case START_PASSWORD_RESET:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          userId: action.payload,
        },
        passwordChanged: false,
      };
    case NEW_PASSWORD:
      return { ...state, passwordChanged: true, sessionId: '' };
    case REFRESH_CURRENT_USER:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          username: action.payload.userName,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          email: action.payload.email,
          phoneNumber: action.payload.phoneNumber,
          currentPassword: action.payload.password,
          initials: action.payload.initials,
          cellPhone: action.payload.cellPhone,
          accountType: action.payload.accountType,
          dealerId: action.payload.dealerId,
        },
      };
    case CLOSE_USER_SAVE_RESULT:
      return { ...state, userSaved: false };
    case THROW_ERROR:
      return { ...state, userSaved: true };
    case LOGOUT_USER:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default userState;
