import React, { useMemo, useState } from 'react';
import {
  Container,
  Table,
  Menu,
  Icon,
  Dropdown,
  Button,
  Modal,
} from 'semantic-ui-react';
import {
  useTable,
  useFilters,
  usePagination,
  useSortBy,
  useGlobalFilter,
} from 'react-table';
import { Link, useNavigate } from 'react-router-dom';
import DefaultColumnFilter from '../../TableFilters/DefaultColumnFilter';
import GlobalFilter from '../../TableFilters/GlobalFilter';
import { resultPageSizeOpts } from '../../StaticLists';

const RemoveConfirmComponent = (props) => {
  const [open, setOpen] = useState(false);
  return (
    <Modal
      open={open}
      trigger={props.trigger}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
    >
      <Modal.Header>{props.title}</Modal.Header>
      <Modal.Content>{props.content}</Modal.Content>
      <Modal.Actions>
        <Button
          type='button'
          className='decline-button'
          content='No'
          onClick={() => setOpen(false)}
        />
        <Button
          type='button'
          className='confirm-button'
          content='Yes'
          onClick={() => {
            props.confirmAction(props.confirmId, true);
            setOpen(false);
          }}
        />
      </Modal.Actions>
    </Modal>
  );
};

const renderEdit = (row, props) => {
  const txnId = row.value;
  const navigate = useNavigate();
  let actionButtons = [
    <Icon
      style={{ cursor: 'pointer' }}
      name='pencil'
      onClick={() => {
        props.getInvoice(txnId);
        navigate('/invoice');
      }}
    />,
    <span>&nbsp;&nbsp;&nbsp;</span>,
  ];

  // only show delete function to administrators
  if (props.isAdmin) {
    actionButtons.push(
      <RemoveConfirmComponent
        title='Confirm Deletion'
        content={`You are about to delete Invoice #: ${txnId}.  Are you sure you want to do this?`}
        confirmId={txnId}
        confirmAction={props.deleteInvoice}
        trigger={<Icon style={{ cursor: 'pointer' }} name='trash' />}
      />
    );
  }

  return actionButtons;
};

const renderEditLink = (row, props) => {
  const txnId = row.value;
  if ('dealer' === props.accountType) {
    return (
      <Link
        to='/dealer-invoice'
        onClick={async () => {
          await props.getInvoice(txnId);
        }}
      >
        {txnId}
      </Link>
    );
  }

  return (
    <Link
      to='/invoice'
      onClick={() => {
        props.getInvoice(txnId);
      }}
    >
      {txnId}
    </Link>
  );
};

// function for doing fuzzy searches using the match sorter module
function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

/**
 * Sets the color of a dollar amount given
 * @param {object} row
 */
const renderAmountColor = (row) => {
  const dollarAmount = parseFloat(row.value).toFixed(2);
  if (dollarAmount <= 0.0) {
    return <span style={{ color: '#22B573' }}>${row.value}</span>;
  }
  return <span style={{ color: '#F14724' }}>${row.value}</span>;
};

function DealerInvoices(props) {
  // defines the filter type for the filters we will use
  const filterTypes = useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  // define the default column behavior
  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const columns = useMemo(() => {
    let tableCols = [
      {
        Header: 'Invoice #',
        accessor: 'txnId',
        Cell: (row) => renderEditLink(row, props),
      },
      { Header: 'Created By', accessor: 'createdByInitials' },
      { Header: 'From Dealer Date', accessor: 'fromDealerDate' },
      { Header: 'Dealer', accessor: 'dealer' },
      { Header: 'Reg. Name', accessor: 'regFullName' },
      { Header: 'Reg. Company', accessor: 'regCompanyName' },
      { Header: 'Status', accessor: 'status' },
      { Header: 'Plate', accessor: 'regPlateNumber' },
      { Header: 'VIN #', accessor: 'regVin' },
      { Header: 'Lien Holder', accessor: 'lienHolderName' },
      { Header: 'Last Updated', accessor: 'updated' },
      { Header: 'Total Charges', accessor: 'totalCharges' },
      {
        Header: 'Current Balance',
        accessor: 'currentBalance',
        Cell: (row) => renderAmountColor(row),
      },
    ];
    if (props.accountType !== 'dealer') {
      tableCols.push({
        Header: 'Actions',
        accessor: 'editId',
        disableSortBy: true,
        Cell: (row) => renderEdit(row, props),
      });
    }
    return tableCols;
  }, []);

  const data = props.invoices;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    visibleColumns,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  return (
    <Container>
      <Table celled {...getTableProps()}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              colSpan={visibleColumns.length}
              style={{
                textAlign: 'left',
              }}
            >
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </Table.HeaderCell>
          </Table.Row>
          {headerGroups.map((headerGroup) => (
            <Table.Row {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Table.HeaderCell
                  {...column.getHeaderProps(column.getSortByToggleProps(), {
                    style: { maxWidth: column.maxWidth },
                  })}
                >
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? [<Icon name='sort down' />]
                        : [<Icon name='sort up' />]
                      : column.disableSortBy
                      ? ''
                      : [<Icon name='sort' />]}
                  </span>
                </Table.HeaderCell>
              ))}
            </Table.Row>
          ))}
        </Table.Header>
        <Table.Body {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <Table.Row {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <Table.Cell
                      {...cell.getCellProps({
                        style: {
                          maxWidth: cell.column.maxWidth,
                          minWidth: cell.column.minWidth,
                          overflowWrap: 'break-word',
                        },
                      })}
                    >
                      {cell.render('Cell')}
                    </Table.Cell>
                  );
                })}
              </Table.Row>
            );
          })}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan='14'>
              <Dropdown
                value={pageSize}
                options={resultPageSizeOpts}
                selection
                selectOnBlur={true}
                className='brm-form-dropdown brm-form-header-dropdown'
                onChange={(e, { value }) => setPageSize(Number(value))}
              />
              <Menu floated='right' pagination>
                <Menu.Item
                  as='a'
                  icon
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                >
                  <Icon name='angle double left' />
                </Menu.Item>
                <Menu.Item
                  as='a'
                  icon
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  <Icon name='angle left' />
                </Menu.Item>
                <Menu.Item as='a'>
                  Page: {pageIndex + 1} of {pageOptions.length}
                </Menu.Item>
                <Menu.Item
                  as='a'
                  icon
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  <Icon name='angle right' />
                </Menu.Item>
                <Menu.Item
                  as='a'
                  icon
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  <Icon name='angle double right' />
                </Menu.Item>
              </Menu>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </Container>
  );
}

export default DealerInvoices;
