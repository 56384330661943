import * as yup from 'yup';
import {
    PLATE_STATUS,
    DEALER_NAME,
    BRM_CHARGE,
    DMV_CHARGE,
    SALES_TAX,
    OTHER_FEES
} from './FieldNames';

const INVALID_DOLLAR_AMOUNT = 'Must be a valid dollar amount';

export default yup.object({
    [PLATE_STATUS]: yup.string().required('Please select a Status.'),
    [DEALER_NAME]: yup.number().required('You must select a Dealer.'),
    [BRM_CHARGE]: yup.number().test('is-decimal', INVALID_DOLLAR_AMOUNT, value => (value + "").match(/^(\d*\.)?\d+$/)),
    [DMV_CHARGE]: yup.number().test('is-decimal', INVALID_DOLLAR_AMOUNT, value => (value + "").match(/^(\d*\.)?\d+$/)),
    [SALES_TAX]: yup.number().test('is-decimal', INVALID_DOLLAR_AMOUNT, value => (value + "").match(/^(\d*\.)?\d+$/)),
    [OTHER_FEES]: yup.number().test('is-decimal', INVALID_DOLLAR_AMOUNT, value => (value + "").match(/^(\d*\.)?\d+$/))
});
