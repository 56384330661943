import React, { useState } from 'react';
import DatePicker from 'react-date-picker';
import moment from 'moment';
import { Icon } from 'semantic-ui-react';

const DateFilter = (props) => {
    const [selectedDate, changeSelectedDate ] = useState(new Date(moment()));

    return (
        <DatePicker 
            value={selectedDate}
            onChange={ date => {
                changeSelectedDate(new Date(moment(date)));
                props.changeFunction(moment(date).format('YYYY-MM-DD'), props.tableColumn, props.index);
            }}
            calendarIcon={<Icon name="calendar alternate outline" />}
            clearIcon={null}
        />
    );
}

export default DateFilter;