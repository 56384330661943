import * as yup from 'yup';

import {
  USERNAME,
  PASSWORD,
  CONFIRM_PASSWORD,
  FIRST_NAME,
  LAST_NAME,
  EMAIL,
  INITIALS,
} from './FieldNames';

const schema = yup.object({
  [USERNAME]: yup.string().required('Username is required'),
  [PASSWORD]: yup
    .string()
    .test(
      'password',
      'Password is required when a value is provided',
      function (value) {
        const passwordFieldValue = this.parent.PASSWORD || '';
        console.log('Password Value', passwordFieldValue);
        if (passwordFieldValue.length > 0) {
          return value && value.length > 0;
        }

        return true;
      }
    )
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'Password Must Contain at Least 8 Characters, One Uppercase, One Lowercase, One Number, and One Special Character'
    ),
  [CONFIRM_PASSWORD]: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
  [FIRST_NAME]: yup.string().required('First Name is required'),
  [LAST_NAME]: yup.string().required('Last Name is required'),
  [EMAIL]: yup.string().email('Email address is invalid'),
  [INITIALS]: yup.string().required('You must enter the initials of the user.'),
});

export default schema;
