import {
    SAVE_FINANCIAL_TXN, 
    SHOW_FINANCIAL_TXN_FORM,
    FIND_FINANCIAL_TXN,
    ADD_NEW_FINANCIAL_TXN,
    LOGOUT_USER,
    UPDATE_PAYMENT_FORM,
    RESET_FINANCIAL_TXN_SAVED,
    UPDATE_PAYMENT_PROCESSED
} from '../actions/types';

const INITIAL_STATE = {
	financialTxnForm: {
		financialTxnId: 0,
		txnId: 0,
		txnType: '',
		paymentType: '',
		amount: 0,
		referenceNumber: '',
		toward: '',
		inReg: "1",
        paymentProcessed: 0,
        ccName: '',
        ccNumber: '',
        ccExpire: '',
        ccCvc: '',
        achRouting: '',
        achAccountNumber: '',
        achNameOnAccount: ''
	},
	financialTxnFormCopy: {
		financialTxnId: 0,
		txnId: 0,
		txnType: '',
		paymentType:'', 
		amount: 0,
		referenceNumber: '',
		toward: '',
		inReg: 1,
        paymentProcessed: 0
    },
    showFTModal: false,
    financialTxnSaved: false
};

const financialTxnState = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SAVE_FINANCIAL_TXN:
            return { ...state, 
                showFTModal: action.payload.keepFormOpen,
                financialTxnSaved: action.payload.paymentSaved, 
                financialTxnForm: {
                    ...state.financialTxnForm,
                    financialTxnId: action.payload.financialTxnId,
                    txnId: action.payload.txnId,
                    txnType: action.payload.txnType,
                    paymentType: action.payload.paymentType,
                    amount: action.payload.amount,
                    toward: action.payload.toward,
                    inReg: '1'
            }}
        case ADD_NEW_FINANCIAL_TXN:
            return { ...state, 
                showFTModal: false,
                financialTxnSaved: false, 
                financialTxnForm: {
                financialTxnId: 0,
                txnId: 0,
                txnType: '',
                paymentType: '',
                amount: 0,
                referenceNumber: '',
                toward: '',
                inReg: "1",
                paymentProcessed: 0,
                ccName: '',
                ccNumber: '',
                ccExpire: '',
                ccCvc: '',
                achRouting: '',
                achAccountNumber: '',
                achNameOnAccount: ''
            }}
        case SHOW_FINANCIAL_TXN_FORM:
            return { ...state, showFTModal: action.payload };
        case FIND_FINANCIAL_TXN:
            return { ...state,
                financialTxnForm: {
                    txnId: action.payload.plate_id,
                    txnType: action.payload.txn_type,
                    paymentType: action.payload.payment_type,
                    amount: parseFloat(action.payload.amount).toFixed(2),
                    referenceNumber: action.payload.ref_number,
                    toward: action.payload.toward,
                    inReg: action.payload.in_reg,
                    financialTxnId: action.payload.id,
                    paymentProcessed: parseInt(action.payload.payment_processed),
                    ccName: '',
                    ccNumber: '',
                    ccExpire: '',
                    ccCvc: '',
                    achRouting: '',
                    achAccountNumber: '',
                    achNameOnAccount: ''
                }
            };
        case UPDATE_PAYMENT_FORM:
            return { ...state, 
                financialTxnForm: {
                    ...state.financialTxnForm,
                    financialTxnId: action.payload
                }
            };
        case RESET_FINANCIAL_TXN_SAVED:
            return { ...state, financialTxnSaved: false };
        case UPDATE_PAYMENT_PROCESSED:
            return { ...state, 
                financialTxnForm: {
                    ...state.financialTxnForm,
                    paymentProcessed: action.payload.processed,
                    referenceNumber: action.payload.paymentRef,
                    ccName: action.payload.ccName,
                    ccNumber: action.payload.ccNumber,
                    ccExpire: action.payload.ccExpire,
                    ccCvc: action.payload.ccCvc,
                    achRouting: action.payload.achRouting,
                    achAccountNumber: action.payload.achAccountNumber,
                    achNameOnAccount: action.payload.achNameOnAccount
                }
            };
        case LOGOUT_USER:
            return INITIAL_STATE;
        default:
            return state;
    }
};

export default financialTxnState;