import React, { useMemo, useState } from 'react';
import { Container, Table, Menu, Icon, Dropdown } from 'semantic-ui-react';
import _ from 'lodash';
import { useTable, useFilters, usePagination, useSortBy } from 'react-table';
import Confirmation from '../Confirmation';
import { resultPageSizeOpts } from '../StaticLists';

const renderEdit = (row, props) => {
  const userId = row.value;
  let actionButtons = [
    <Icon
      style={{ cursor: 'pointer' }}
      name='pencil'
      onClick={() => {
        props.getUser(userId, true);
        //props.showForm(true);
      }}
    />,
    <span>&nbsp;&nbsp;&nbsp;</span>,
  ];

  // only add a delete icon if not the current user
  //console.log('This User',userId);
  //console.log('Current User', props.currentUser.userId);
  if (userId !== props.currentUser.userId) {
    actionButtons.push(
      <Confirmation
        title='Confirm Deletion'
        content={`You are about to perminantly delete this user.  Are you sure you want to do this?`}
        confirmId={userId}
        confirmAction={() => props.removeUser(userId)}
        trigger={<Icon style={{ cursor: 'pointer' }} name='trash' />}
      />
    );
  }

  return actionButtons;
};

const UserList = (props) => {
  const columns = useMemo(
    () => [
      { Header: 'First Name', accessor: 'firstName' },
      { Header: 'Last Name', accessor: 'lastName' },
      { Header: 'Username', accessor: 'username' },
      { Header: 'Email', accessor: 'email' },
      { Header: 'Account Type', accessor: 'accountType' },
      { Header: 'Dealership (if applicable)', accessor: 'companyName' },
      {
        Header: 'Actions',
        accessor: 'userId',
        disableSortBy: true,
        Cell: (row) => renderEdit(row, props),
      },
    ],
    []
  );

  const data = props.users;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    { columns, data, initialState: { pageIndex: 0, pageSize: 10 } },
    useFilters,
    useSortBy,
    usePagination
  );

  return (
    <Container>
      <Table celled {...getTableProps()}>
        <Table.Header>
          {headerGroups.map((headerGroup) => (
            <Table.Row {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Table.HeaderCell
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? [<Icon name='sort down' />]
                        : [<Icon name='sort up' />]
                      : column.disableSortBy
                      ? ''
                      : [<Icon name='sort' />]}
                  </span>
                </Table.HeaderCell>
              ))}
            </Table.Row>
          ))}
        </Table.Header>
        <Table.Body {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <Table.Row {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <Table.Cell {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </Table.Cell>
                  );
                })}
              </Table.Row>
            );
          })}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan='10'>
              <Dropdown
                value={pageSize}
                options={resultPageSizeOpts}
                selection
                selectOnBlur={true}
                className='brm-form-dropdown brm-form-header-dropdown'
                onChange={(e, { value }) => setPageSize(Number(value))}
              />
              <Menu floated='right' pagination>
                <Menu.Item
                  as='a'
                  icon
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                >
                  <Icon name='angle double left' />
                </Menu.Item>
                <Menu.Item
                  as='a'
                  icon
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  <Icon name='angle left' />
                </Menu.Item>
                <Menu.Item as='a'>
                  Page: {pageIndex + 1} of {pageOptions.length}
                </Menu.Item>
                <Menu.Item
                  as='a'
                  icon
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  <Icon name='angle right' />
                </Menu.Item>
                <Menu.Item
                  as='a'
                  icon
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  <Icon name='angle double right' />
                </Menu.Item>
              </Menu>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </Container>
  );
};

export default UserList;
