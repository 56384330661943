export const TXN_TYPE = 'txnType';
export const PAYMENT_TYPE = 'paymentType';
export const AMOUNT = 'amount';
export const REF_NUMBER = 'refNumber';
export const TOWARD = 'toward';
export const CC_NAME = 'ccName';
export const CC_NUMBER = 'ccNumber';
export const CC_EXPIRE = 'ccExpire';
export const CC_CVC = 'ccCVC';
export const ACH_ROUTING = 'achRouting';
export const ACH_ACCOUNT_NUMBER = 'achAccountNumber';
export const ACH_NAME_ON_ACCOUNT = 'achNameOnAccount';