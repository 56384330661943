import React, { useMemo } from 'react';
import {
  Container,
  Table,
  Menu,
  Icon,
  Dropdown,
  Button,
  Modal,
} from 'semantic-ui-react';
import {
  useTable,
  useFilters,
  usePagination,
  useSortBy,
  useGlobalFilter,
} from 'react-table';
import { Link, useNavigate } from 'react-router-dom';
import DefaultColumnFilter from '../../TableFilters/DefaultColumnFilter';
import GlobalFilter from '../../TableFilters/GlobalFilter';
import { resultPageSizeOpts } from '../../StaticLists';
import Confirmation from '../../Confirmation';

// function for doing fuzzy searches using the match sorter module
function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

const renderEdit = (row, props) => {
  const billingId = row.value;
  const navigate = useNavigate();
  let actionButtons = [
    <Icon
      style={{ cursor: 'pointer' }}
      name='pencil'
      onClick={async () => {
        await props.getBilling(billingId);
        navigate('/billing-form');
      }}
    />,
    <span>&nbsp;&nbsp;&nbsp;</span>,
  ];

  // only show delete function to administrators
  if (props.isAdmin) {
    actionButtons.push(
      <Confirmation
        title='Confirm Deletion'
        content={`You are about to delete Billing #: ${billingId}.  Are you sure you want to do this?`}
        confirmId={billingId}
        confirmAction={props.removeBilling}
        trigger={<Icon style={{ cursor: 'pointer' }} name='trash' />}
      />
    );
  }

  return actionButtons;
};

const renderEditLink = (row, props) => {
  const billingId = row.value;

  return (
    <Link
      to='/billing-form'
      onClick={() => {
        props.getBilling(billingId);
      }}
    >
      {billingId}
    </Link>
  );
};

function DealerBillings(props) {
  // defines the filter type for the filters we will use
  const filterTypes = useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  // define the default column behavior
  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Billing #',
        accessor: 'billingId',
        Cell: (row) => renderEditLink(row, props),
      },
      { Header: 'Updated', accessor: 'updated' },
      { Header: 'Payment Type', accessor: 'paymentType' },
      { Header: 'Ref. Number', accessor: 'referenceNumber' },
      { Header: 'Total Payment', accessor: 'amount' },
      { Header: 'Not Applied', accessor: 'notApplied' },
      {
        Header: 'Processed Through Gateway',
        accessor: 'processedThroughGateway',
      },
      {
        Header: 'Actions',
        accessor: 'editId',
        disableSortBy: true,
        Cell: (row) => renderEdit(row, props),
      },
    ],
    []
  );

  const data = props.billings;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    visibleColumns,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  return (
    <Container>
      <Table celled {...getTableProps()}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              colSpan={visibleColumns.length}
              style={{
                textAlign: 'left',
              }}
            >
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </Table.HeaderCell>
          </Table.Row>
          {headerGroups.map((headerGroup) => (
            <Table.Row {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Table.HeaderCell
                  {...column.getHeaderProps(column.getSortByToggleProps(), {
                    style: { maxWidth: column.maxWidth },
                  })}
                >
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? [<Icon name='sort down' />]
                        : [<Icon name='sort up' />]
                      : column.disableSortBy
                      ? ''
                      : [<Icon name='sort' />]}
                  </span>
                </Table.HeaderCell>
              ))}
            </Table.Row>
          ))}
        </Table.Header>
        <Table.Body {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <Table.Row {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <Table.Cell
                      {...cell.getCellProps({
                        style: {
                          maxWidth: cell.column.maxWidth,
                          minWidth: cell.column.minWidth,
                          overflowWrap: 'break-word',
                        },
                      })}
                    >
                      {cell.render('Cell')}
                    </Table.Cell>
                  );
                })}
              </Table.Row>
            );
          })}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan='8'>
              <Dropdown
                value={pageSize}
                options={resultPageSizeOpts}
                selection
                selectOnBlur={true}
                className='brm-form-dropdown brm-form-header-dropdown'
                onChange={(e, { value }) => setPageSize(Number(value))}
              />
              <Menu floated='right' pagination>
                <Menu.Item
                  as='a'
                  icon
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                >
                  <Icon name='angle double left' />
                </Menu.Item>
                <Menu.Item
                  as='a'
                  icon
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  <Icon name='angle left' />
                </Menu.Item>
                <Menu.Item as='a'>
                  Page: {pageIndex + 1} of {pageOptions.length}
                </Menu.Item>
                <Menu.Item
                  as='a'
                  icon
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  <Icon name='angle right' />
                </Menu.Item>
                <Menu.Item
                  as='a'
                  icon
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  <Icon name='angle double right' />
                </Menu.Item>
              </Menu>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </Container>
  );
}

export default DealerBillings;
