import React, { Fragment } from 'react';
import moment from 'moment';
import { Button, Segment, Grid, Icon, Header } from 'semantic-ui-react';
import { connect } from 'react-redux';
import Confirmation from '../../Confirmation';
import { startNote, removeNote } from '../../../actions';

export const NotesList = (props) => {
  // what notes to use?
  let headerText = '';
  let internalNote = 0;

  switch (props.noteType) {
    case 'internal':
      headerText = 'Internal Notes (Employee View Only)';
      internalNote = 1;
      break;
    case 'dealer-invoice':
      headerText = 'Messages';
      break;
    default:
      headerText = 'Invoice Notes (Printed on Invoice)';
  }

  // function for adding a new note
  const addNewNote = () => {
    if (0 === props.invoiceId) {
      // save the invoice first before starting a new note
      props.saveInvoiceFunction(props.invoiceValues, 'new-note');
    }
    props.startNote(internalNote);
  };
  // build the notes list
  let thisNotesList = [];
  _.each(props.notes, (values) => {
    const createdAt = moment(values.created_at);
    thisNotesList.push(
      <Segment padded>
        <Grid>
          <Grid.Row className='invoice-boxed-list-item brm-small-font'>
            <Grid.Column>
              {createdAt.format('MM/DD/YYYY')} | {createdAt.format('hh:mm a')} |{' '}
              {values.firstname} {values.lastname}
              &nbsp;&nbsp;&nbsp;&nbsp;
              {props.isAdministrator && (
                <Confirmation
                  title='Confirm Deletion'
                  content={`You are about to delete this note.  Are you sure you want to do this?`}
                  confirmId={values.id}
                  confirmAction={(noteId) =>
                    props.removeNote(noteId, props.parentType, internalNote)
                  }
                  trigger={<Icon style={{ cursor: 'pointer' }} name='trash' />}
                />
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className='invoice-boxed-list-item brm-larger-font'>
            <Grid.Column>{values.note}</Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  });
  return (
    <Fragment>
      <Grid stackable style={{ paddingTop: '10px' }}>
        <Grid.Column width={12}>
          <Header>{headerText}</Header>
        </Grid.Column>
        <Grid.Column width={4} style={{ textAlign: 'right' }}>
          <Button
            tabIndex='29'
            type='button'
            size='mini'
            circular
            positive
            icon='plus'
            className='brm-add-button-icon'
            onClick={() => addNewNote()}
          />{' '}
          <span
            style={{ cursor: 'pointer' }}
            className='brm-form-label'
            onClick={() => addNewNote()}
          >
            {'dealer' === props.currentUser.accountType
              ? 'CREATE MESSAGE'
              : 'ADD'}
          </span>
        </Grid.Column>
      </Grid>
      <Segment.Group
        verticalAlign='middle'
        style={{ height: props.listHeight, overflowY: 'scroll' }}
      >
        {thisNotesList}
      </Segment.Group>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    noteForm: state.notes.noteForm,
    isAdministrator: state.users.isAdministrator,
    currentUser: state.users.currentUser,
  };
};

export default connect(mapStateToProps, { startNote, removeNote })(NotesList);
