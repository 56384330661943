import _ from 'lodash';
import main from '../apis/main';
import { sessionExpired, proccessAPIErrors } from './globalActions';
import {
  GET_LIEN_HOLDERS,
  SAVE_LIEN_HOLDER,
  THROW_ERROR,
  SHOW_LIEN_HOLDER_FORM,
  ADD_NEW_LIEN_HOLDER,
  FIND_LIEN_HOLDER,
  LIEN_HOLDER_DELETED,
} from './types';

export const getLienHolders = () => async (dispatch, getState) => {
  /**
   * API Call to get all lien holders
   */
  const { sessionId } = getState().users;
  const response = await main.get(`/api/lien_holders/${sessionId}`);
  if (200 === response.status) {
    const responseData = response.data;

    // check for session token error
    if (responseData.error !== undefined) {
      // what type of error?
      if (responseData.error === 'Invalid session token') {
        // the session token is invalid or expired
        dispatch(sessionExpired());
        return;
      }

      // throw an error
      dispatch({ type: THROW_ERROR, payload: responseData.error.text });
      return;
    }

    let lienHolderList = [
      {
        key: -1,
        value: -1,
        text: 'Select Lien Holder',
      },
      {
        key: 0,
        value: 0,
        text: 'Add New',
        icon: 'plus circle',
      },
    ];
    _.each(response.data, (values) => {
      lienHolderList.push({
        key: values.id,
        value: values.id,
        text: values.name,
      });
    });

    dispatch({ type: GET_LIEN_HOLDERS, payload: lienHolderList });
  } else {
    // something went wrong
    dispatch({ type: THROW_ERROR, payload: response.statusText });
  }
};

export const showLienHolderForm = (show) => {
  return { type: SHOW_LIEN_HOLDER_FORM, payload: show };
};

export const saveLienHolder = (values) => async (dispatch, getState) => {
  const { sessionId, userId } = getState().users;
  const { lienHolderForm } = getState().lienholders;
  let response = {};

  // build the submission object
  let bodyFormData = new FormData();
  bodyFormData.append(
    'name',
    values.name === undefined ? values.NAME : values.name
  );
  bodyFormData.append(
    'address',
    values.address === undefined ? values.ADDRESS : values.address
  );
  bodyFormData.append(
    'city',
    values.city === undefined ? values.CITY : values.city
  );
  bodyFormData.append(
    'state',
    values.stateAbbr === undefined ? values.STATE_ABBR : values.stateAbbr
  );
  bodyFormData.append(
    'zip',
    values.zipCode === undefined ? values.ZIP_CODE : values.zipCode
  );
  bodyFormData.append(
    'hide_from_list',
    values.hideFromList === undefined
      ? values.HIDE_FROM_LIST
      : values.hideFromList
  );
  bodyFormData.append(
    'code',
    values.code === undefined ? values.CODE : values.code
  );
  bodyFormData.append('active', 1);
  // new dealer or an update?
  if (0 === lienHolderForm.lienHolderId || -1 === lienHolderForm.lienHolderId) {
    // New Lien Holder
    bodyFormData.append('session_token', sessionId);
    response = await main.post('/api/lien_holders', bodyFormData);
  } else {
    // update existing
    response = await main.post(
      `/api/lien_holders/${lienHolderForm.lienHolderId}/${sessionId}`,
      bodyFormData
    );
  }
  if (201 === response.status || 200 === response.status) {
    const responseData = response.data;

    // check for session token error
    if (responseData.error !== undefined) {
      // what type of error?
      if (responseData.error === 'Invalid session token') {
        // the session token is invalid or expired
        dispatch(sessionExpired());
        return;
      }

      // throw an error
      dispatch({ type: THROW_ERROR, payload: responseData.error.text });
      return;
    }

    if (responseData.notice.text !== undefined) {
      // successful insertion
      dispatch(getLienHolder(responseData.id));

      // update the dealer list
      dispatch(getLienHolders());
      dispatch(showLienHolderForm(false));
    } else {
      // bad credentials
      dispatch({
        type: THROW_ERROR,
        payload:
          'There was a problem saving the Lien Holder.  Please try again.',
      });
    }
  } else {
    // something went wrong
    dispatch({ type: THROW_ERROR, payload: response.statusText });
  }
};

export const addNewLienHolder = () => {
  return { type: ADD_NEW_LIEN_HOLDER };
};

export const getLienHolder = (lienHolderId) => async (dispatch, getState) => {
  if (lienHolderId > 0) {
    const { sessionId } = getState().users;
    const response = await main.get(
      `/api/lien_holders/${lienHolderId}/${sessionId}`
    );
    if (200 === response.status) {
      // check for session token error
      if (response.data.error !== undefined) {
        // what type of error?
        if (response.data.error === 'Invalid session token') {
          // the session token is invalid or expired
          dispatch(sessionExpired());
          return;
        }

        // throw an error
        dispatch({ type: THROW_ERROR, payload: responseData.error.text });
        return;
      }

      const responseData = response.data[0];
      dispatch({ type: FIND_LIEN_HOLDER, payload: responseData });
    } else {
      // something went wrong
      dispatch({ type: THROW_ERROR, payload: response.statusText });
    }
  }
};

export const removeLienHolder =
  (lienHolderId) => async (dispatch, getState) => {
    //console.log('Removing Lien Holder');
    // grab the current session id to authenticate with the API
    const { sessionId } = getState().users;

    // call the endpoint to mark the lien holder as inactive (this will not remove the lien holder from the lien holders table)
    const response = await main.post(
      `/api/lien_holders/deactivate/${lienHolderId}/${sessionId}`
    );

    //console.log('Removal Response', response);

    if (200 === response.status) {
      // check for session token error
      if (response.data.error !== undefined) {
        // what type of error?
        if (response.data.error === 'Invalid session token') {
          // the session token is invalid or expired
          dispatch(sessionExpired());
          return;
        }

        // throw an error
        dispatch({ type: THROW_ERROR, payload: response.data.error });
        return;
      }

      // got this far, check that the deletion succeeded
      if (response.data.success) {
        // refresh the dealer list and clear out the dealer form
        dispatch(getLienHolders());
        dispatch({ type: LIEN_HOLDER_DELETED });
      } else {
        dispatch({
          type: THROW_ERROR,
          payload: 'There was a problem removing the lien holder.',
        });
      }
    } else {
      // there was an error from the endpoint
      dispatch(
        proccessAPIErrors({
          statusCode: response.status,
          errorMsg: response.data.error,
        })
      );
    }
  };
