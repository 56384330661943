import * as yup from 'yup';

import {
    PROSPECT,
    COMPANY_NAME,
    ADDRESS,
    CITY,
    STATE_ABBR,
    ZIP_CODE,
    PHONE_NUMBER,
    COURIER,
    EMAIL_ADDRESS,
    CONTACT_FIRST_NAME,
    CONTACT_LAST_NAME,
    IN_BILLING
} from './FieldNames';

const REQUIRED_MESSAGE = 'This field is required';
const EMAIL_FORMAT = 'Email is in an invalid format';

export default yup.object({
    [COMPANY_NAME]: yup.string().required(REQUIRED_MESSAGE),
    [EMAIL_ADDRESS]: yup.string().email(EMAIL_FORMAT)
});