import React, { useMemo, Fragment, forwardRef, useRef, useEffect } from 'react';
import {
  Container,
  Header,
  Button,
  Table,
  Menu,
  Icon,
  Dropdown,
  Grid,
  Segment,
} from 'semantic-ui-react';
import _ from 'lodash';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import { Link, useNavigate } from 'react-router-dom';
import {
  useTable,
  useFilters,
  usePagination,
  useSortBy,
  useRowSelect,
} from 'react-table';
import Confirmation from '../Confirmation';
import { resultPageSizeOpts } from '../StaticLists';
import PlateTxnFilters from './PlateTxnFilters';
import IndeterminateCheckbox from '../IndeterminateCheckbox';

const renderEdit = (row, props) => {
  const txnId = row.value;
  const navigate = useNavigate();
  let actionButtons = [
    <Icon
      key={txnId}
      style={{ cursor: 'pointer' }}
      name='pencil'
      onClick={() => {
        props.getInvoice(txnId);
        navigate('/invoice');
      }}
    />,
    <span>&nbsp;&nbsp;&nbsp;</span>,
  ];

  // only show delete function to administrators
  if (props.isAdmin) {
    actionButtons.push(
      <Confirmation
        title='Confirm Deletion'
        content={`You are about to delete Invoice #: ${txnId}.  Are you sure you want to do this?`}
        confirmId={txnId}
        confirmAction={props.deleteInvoice}
        trigger={<Icon style={{ cursor: 'pointer' }} name='trash' />}
      />
    );
  }

  return actionButtons;
};

const renderEditLink = (row, props) => {
  const txnId = row.value;
  const navigate = useNavigate();

  return (
    <Link
      to='/invoice'
      onClick={() => {
        props.getInvoice(txnId);
      }}
    >
      {txnId}
    </Link>
  );
};

/**
 * Sets the color of a dollar amount given
 * @param {object} row
 */
const renderAmountColor = (row) => {
  const dollarAmount = parseFloat(row.value).toFixed(2);
  if (dollarAmount <= 0.0) {
    return <span style={{ color: '#22B573' }}>${row.value}</span>;
  }
  return <span style={{ color: '#F14724' }}>${row.value}</span>;
};

/**
 * Renders a checkbox for each row of the table
 */
/*
const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <Fragment>
      <Checkbox style={{ marginTop: '5px' }} ref={resolvedRef} {...rest} />
    </Fragment>
  );
});
*/

const PlateTxnList = (props) => {
  //console.log('Selected Dates', updatedDates)

  /**
   * Responsive triggers
   */
  const isTabletOrMobile = useMediaQuery({ maxWidth: 767 });

  const columns = useMemo(
    () => [
      // {
      //   Header: 'Select',
      //   accessor: 'printId',
      //   filterable: false,
      //   disableSortBy: true,
      //   Cell: (row) => renderTableSelect(row, props.printSelector),
      // },
      {
        Header: 'Invoice #',
        accessor: 'txnId',
        Cell: (row) => renderEditLink(row, props),
      },
      {
        Header: 'Created',
        accessor: (row) => moment(row.created).format('x'),
        Cell: (row) =>
          moment(row.row.original.created).format('MM/DD/YYYY hh:mm a'),
      },
      { Header: 'Created By', accessor: 'createdByInitials' },
      { Header: 'From Dealer Date', accessor: 'fromDealerDate' },
      { Header: 'Dealer', accessor: 'dealer' },
      { Header: 'Reg. Name', accessor: 'regFullName' },
      { Header: 'Reg. Company', accessor: 'regCompanyName' },
      { Header: 'Status', accessor: 'status' },
      { Header: 'Plate', accessor: 'regPlateNumber' },
      { Header: 'VIN #', accessor: 'regVin' },
      { Header: 'Lien Holder', accessor: 'lienHolderName' },
      {
        Header: 'Last Updated',
        accessor: (row) => moment(row.updated).format('x'),
        Cell: (row) =>
          moment(row.row.original.updated).format('MM/DD/YYYY hh:mm a'),
      },
      { Header: 'Total Charges', accessor: 'totalCharges' },
      {
        Header: 'Current Balance',
        accessor: 'currentBalance',
        Cell: (row) => renderAmountColor(row),
      },
      {
        Header: 'Actions',
        accessor: 'editId',
        disableSortBy: true,
        Cell: (row) => renderEdit(row, props),
      },
    ],
    []
  );

  const data = props.invoices;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: props.currentListPageIndex,
        pageSize: props.currentListPageSize,
        sortBy: [
          {
            id: 'updated',
            desc: true,
          },
        ],
      },
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // make a column for selection
        {
          id: 'selection',
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method to
          // render a checkbox
          Cell: ({ row }) => (
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
          ),
        },
        ...columns,
      ]);
    }
  );

  return (
    <Container>
      <Header>Invoices</Header>
      <Grid>
        <Grid.Column width={8}>
          <Link to='/invoice'>
            <Button className='normal-button'>+ Add New Invoice</Button>
          </Link>
        </Grid.Column>
        <Grid.Column width={8} style={{ textAlign: 'right' }}>
          <Link to='/billing-form'>
            <Button className='normal-button'>
              <Icon name='money' size='large' />
              &nbsp;&nbsp; Receive Payment
            </Button>
          </Link>
        </Grid.Column>
      </Grid>

      <PlateTxnFilters
        dealers={props.dealers}
        filterList={props.filterList}
        totalSelected={Object.keys(selectedRowIds).length}
        printInvoices={props.printInvoices}
        selectedInvoices={selectedRowIds}
        isTabletOrMobile={isTabletOrMobile}
        currentFilters={props.currentFilters}
      />
      <Table celled {...getTableProps()} style={{ fontSize: '0.9em' }}>
        <Table.Header>
          {headerGroups.map((headerGroup) => (
            <Table.Row {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Table.HeaderCell
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? [<Icon name='sort down' />]
                        : [<Icon name='sort up' />]
                      : column.disableSortBy
                      ? ''
                      : [<Icon name='sort' />]}
                  </span>
                </Table.HeaderCell>
              ))}
            </Table.Row>
          ))}
        </Table.Header>
        <Table.Body {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <Table.Row {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <Table.Cell {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </Table.Cell>
                  );
                })}
              </Table.Row>
            );
          })}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan='4'>
              <Dropdown
                value={pageSize}
                options={resultPageSizeOpts}
                selection
                selectOnBlur={true}
                className='brm-form-dropdown brm-form-header-dropdown'
                onChange={(e, { value }) => {
                  setPageSize(Number(value));
                  // have to calculate what the new page index will be
                  const currentFirstRecord =
                    pageSize + pageIndex * pageSize - (pageSize - 1);
                  const newPageIndex = Math.floor(
                    currentFirstRecord / Number(value)
                  );
                  props.setListPageIndexAndSize(newPageIndex, Number(value));
                }}
              />
            </Table.HeaderCell>
            <Table.HeaderCell colSpan='7' textAlign='center'>
              <span>
                <strong>
                  Records:{' '}
                  {pageIndex === 0
                    ? 1
                    : pageSize + pageIndex * pageSize - (pageSize - 1)}{' '}
                  -{' '}
                  {data.length < pageSize + pageIndex * pageSize
                    ? data.length
                    : pageSize + pageIndex * pageSize}{' '}
                  of {data.length}
                </strong>
              </span>
            </Table.HeaderCell>
            <Table.HeaderCell colSpan='4'>
              <Menu floated='right' pagination>
                <Menu.Item
                  as='a'
                  icon
                  onClick={() => {
                    gotoPage(0);
                    props.setListPageIndexAndSize(0, pageSize);
                  }}
                  disabled={!canPreviousPage}
                >
                  <Icon name='angle double left' />
                </Menu.Item>
                <Menu.Item
                  as='a'
                  icon
                  onClick={() => {
                    previousPage();
                    props.setListPageIndexAndSize(pageIndex - 1, pageSize);
                  }}
                  disabled={!canPreviousPage}
                >
                  <Icon name='angle left' />
                </Menu.Item>
                <Menu.Item as='a'>
                  Page: {pageIndex + 1} of {pageOptions.length}
                </Menu.Item>
                <Menu.Item
                  as='a'
                  icon
                  onClick={() => {
                    nextPage();
                    props.setListPageIndexAndSize(pageIndex + 1, pageSize);
                  }}
                  disabled={!canNextPage}
                >
                  <Icon name='angle right' />
                </Menu.Item>
                <Menu.Item
                  as='a'
                  icon
                  onClick={() => {
                    gotoPage(pageCount - 1);
                    props.setListPageIndexAndSize(pageCount - 1, pageSize);
                  }}
                  disabled={!canNextPage}
                >
                  <Icon name='angle double right' />
                </Menu.Item>
              </Menu>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
      <Segment>
        <Grid columns={4} style={{ textAlign: 'center' }}>
          <Grid.Column>
            <strong>
              Total Charges: $
              {parseFloat(props.charges)
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </strong>
          </Grid.Column>
          <Grid.Column>
            <strong>
              Total BRM Fees: $
              {parseFloat(props.fees)
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </strong>
          </Grid.Column>
          <Grid.Column>
            <strong>
              Total Owed:{' '}
              <span style={{ color: '#F14724' }}>
                $
                {parseFloat(props.owed)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </span>
            </strong>
          </Grid.Column>
          <Grid.Column>
            <strong>
              Total Overpaid:{' '}
              <span style={{ color: '#22B573' }}>
                $
                {parseFloat(props.overpaid)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </span>
            </strong>
          </Grid.Column>
        </Grid>
      </Segment>
      {props.isAdministrator && (
        <Segment>
          <Grid style={{ textAlign: 'center' }}>
            <Grid.Column width={16}>
              <strong>
                Estimated Net Profit (For Invoices Updated On and After
                9/15/2022)
              </strong>
            </Grid.Column>
            <Grid.Column width={5}>
              <strong>
                BRM Fees for Net Profit: $
                {parseFloat(props.totalBRMFeesWithExpenses)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </strong>
            </Grid.Column>
            <Grid.Column width={5}>
              <strong>
                Total Extra Expenses: $
                {parseFloat(props.totalExtraExpenses)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </strong>
            </Grid.Column>
            <Grid.Column width={6}>
              <strong>
                Total Net Profit:
                <span>
                  $
                  {parseFloat(props.totalNetProfit)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  &nbsp;&nbsp;({props.totalNetProfitPercent}%)
                </span>
              </strong>
            </Grid.Column>
          </Grid>
        </Segment>
      )}
    </Container>
  );
};

export default PlateTxnList;
