import React from 'react';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import { Input, Grid, Message, Icon, Radio, Button, Header } from 'semantic-ui-react';
import {
    CODE,
    NAME,
    ADDRESS,
    CITY,
    STATE_ABBR,
    ZIP_CODE,
    HIDE_FROM_LIST
} from './FormSetup/FieldNames';
import ValidationSchema from './FormSetup/ValidationSchema';
import { saveLienHolder, showLienHolderForm } from '../../../actions';

const LienHolderForm = (props) => {
    // initial values for the form are in the state object
    const formInitialValues = {
        [CODE]: props.lienHolderForm.code,
        [NAME]: props.lienHolderForm.name,
        [ADDRESS]: props.lienHolderForm.address,
        [CITY]: props.lienHolderForm.city,
        [STATE_ABBR]: props.lienHolderForm.state,
        [ZIP_CODE]: props.lienHolderForm.zipCode,
        [HIDE_FROM_LIST]: props.lienHolderForm.hideFromList
    };

    return(
        <Formik
                initialValues={formInitialValues}
                enableReinitialize={true}
                validationSchema={ValidationSchema}
                onSubmit={(values) => props.saveLienHolder(values)}
            >
                {
                    ({handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched, initialValues}) => {
                        const hideChecked = values[HIDE_FROM_LIST].length > 0 ? values[HIDE_FROM_LIST] : initialValues[HIDE_FROM_LIST];
                        return (
                        <Form onSubmit={handleSubmit}>
                            <Grid columns={2} stackable>
                                <Grid.Row className="header-style">
                                    <Grid.Column>
                                        LIEN HOLDER
                                    </Grid.Column>
                                </Grid.Row>
                                    <Grid.Column className="brm-form-label brm-form-row">
                                        Code
                                    </Grid.Column>
                                    <Grid.Column className="brm-form-row">
                                        <Input 
                                            type="text" 
                                            name={CODE}
                                            className="brm-form-input" 
                                            onChange={handleChange} 
                                            onBlur={handleBlur}
                                            defaultValue={initialValues[CODE]} 
                                        />
                                        {errors[CODE] && touched[CODE] ? (
                                            <Message negative size="tiny">{errors[CODE]}</Message>
                                        ) : null}
                                    </Grid.Column>
                                    <Grid.Column className="brm-form-label brm-form-row">
                                        Name
                                    </Grid.Column>
                                    <Grid.Column className="brm-form-row">
                                        <Input 
                                            type="text" 
                                            name={NAME}
                                            className="brm-form-input" 
                                            onChange={handleChange} 
                                            onBlur={handleBlur}
                                            defaultValue={initialValues[NAME]} 
                                        />
                                        {errors[NAME] && touched[NAME] ? (
                                            <Message negative size="tiny">{errors[NAME]}</Message>
                                        ) : null}
                                    </Grid.Column>
                                    <Grid.Column className="brm-form-label brm-form-row">
                                        Address
                                    </Grid.Column>
                                    <Grid.Column className="brm-form-row">
                                        <Input 
                                            type="text" 
                                            name={ADDRESS}
                                            className="brm-form-input" 
                                            onChange={handleChange} 
                                            onBlur={handleBlur}
                                            defaultValue={initialValues[ADDRESS]} 
                                        />
                                    </Grid.Column>
                                    <Grid.Column className="brm-form-label brm-form-row">
                                        City
                                    </Grid.Column>
                                    <Grid.Column className="brm-form-row">
                                        <Input 
                                            type="text" 
                                            name={CITY}
                                            className="brm-form-input" 
                                            onChange={handleChange} 
                                            onBlur={handleBlur}
                                            defaultValue={initialValues[CITY]} 
                                        />
                                    </Grid.Column>
                                    <Grid.Column className="brm-form-label brm-form-row">
                                        State
                                    </Grid.Column>
                                    <Grid.Column className="brm-form-row">
                                        <Input 
                                            type="text" 
                                            name={STATE_ABBR}
                                            className="brm-form-input" 
                                            onChange={handleChange} 
                                            onBlur={handleBlur}
                                            defaultValue={initialValues[STATE_ABBR]} 
                                        />
                                    </Grid.Column>
                                    <Grid.Column className="brm-form-label brm-form-row">
                                        Zip Code
                                    </Grid.Column>
                                    <Grid.Column className="brm-form-row">
                                        <Input 
                                            type="text" 
                                            name={ZIP_CODE}
                                            className="brm-form-input" 
                                            onChange={handleChange} 
                                            onBlur={handleBlur}
                                            defaultValue={initialValues[ZIP_CODE]} 
                                        />
                                    </Grid.Column>
                                    <Grid.Column verticalAlign="middle" className="brm-form-label brm-form-row">
                                        Hide from List?
                                    </Grid.Column>
                                    <Grid.Column className="brm-form-row">
                                        <Radio 
                                            name={HIDE_FROM_LIST}
                                            className="brm-radio" 
                                            label="Yes" 
                                            value="1" 
                                            checked={hideChecked === "1"} 
                                            onChange={(e, { value }) => setFieldValue(HIDE_FROM_LIST, value)} 
                                        /> 
                                        {'   '}
                                        <Radio 
                                            name={HIDE_FROM_LIST}
                                            className="brm-radio" 
                                            label="No" 
                                            value="0"
                                            checked={hideChecked === "0"} 
                                            onChange={(e, { value }) => setFieldValue(HIDE_FROM_LIST, value)} 
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={16} textAlign="right">
                                        <Button type="button" className="inverted-button" onClick={() => props.showLienHolderForm(false)}>Cancel</Button>
                                        <Button type="submit" className="inverted-button">
                                            <Icon name="save" />{' '}SAVE &amp; CLOSE
                                        </Button>
                                    </Grid.Column>
                            </Grid>
                        </Form>
                    )}
                }
        </Formik>
    );
};

const mapStateToProps = (state) => {
    return {
        lienHolderForm: state.lienholders.lienHolderForm
    };
}

export default connect(mapStateToProps, { saveLienHolder, showLienHolderForm })(LienHolderForm);