export const PROSPECT = 'prospect';
export const COMPANY_NAME = 'companyName';
export const ADDRESS = 'address';
export const CITY = 'city';
export const STATE_ABBR = 'stateAbbr';
export const ZIP_CODE = 'zipCode';
export const PHONE_NUMBER = 'phoneNumber';
export const PHONE_EXT = 'phoneExt';
export const FAX_NUMBER = 'faxNumber';
export const EMAIL_ADDRESS = 'emailAddress';
export const COURIER = 'courier';
export const ID = 'id';
export const CONTACT_FIRST_NAME = 'contactFirstName';
export const CONTACT_LAST_NAME = 'contactLastName';
export const ROUTE = 'route';
export const NOTES = 'notes';
export const IN_BILLING = 'inBilling';
