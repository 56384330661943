import React from 'react';
import { Grid } from 'semantic-ui-react';
import _ from 'lodash';
import moment from 'moment';

function ReadOnlyInvoicePayments(props) {
  const { txnList } = props;
  const { brmFees, dmvFees, salesTax, otherFees } = props.txnForm;
  let totalPayments = 0.0;
  let txnListJSX = [];
  _.each(props.txnList, (values) => {
    totalPayments = parseFloat(totalPayments) + parseFloat(values.amount);
    txnListJSX.push(
      <>
        <Grid.Column width={4}>&nbsp;</Grid.Column>
        <Grid.Column width={4}>
          {moment(values.updated_at).format('MM/DD/YYYY')}
        </Grid.Column>
        <Grid.Column width={4}>
          {values.payment_type} -{''}
          {values.ref_number}
        </Grid.Column>
        <Grid.Column width={4}>
          ${parseFloat(values.amount).toFixed(2)}
        </Grid.Column>
      </>
    );
  });

  const currentBalance =
    parseFloat(brmFees) +
    parseFloat(dmvFees) +
    parseFloat(salesTax) +
    parseFloat(otherFees) -
    parseFloat(totalPayments);

  return (
    <>
      {/*Row*/}
      <Grid.Column width={4}>PAYMENTS</Grid.Column>
      <Grid.Column width={4}>Date</Grid.Column>
      <Grid.Column width={4}>Payment Type</Grid.Column>
      <Grid.Column width={4}>Amount</Grid.Column>
      {/*End Row*/}
      {txnListJSX}
      {/*Row*/}
      <Grid.Column width={12}>&nbsp;</Grid.Column>
      <Grid.Column width={4} className='read-only-invoice-section-header'>
        TOTAL PAID: ${parseFloat(totalPayments).toFixed(2)}
      </Grid.Column>
      {/*End Row*/}
      {/*Row*/}
      <Grid.Column width={12}>&nbsp;</Grid.Column>
      <Grid.Column width={4}>
        CURRENT BALANCE: ${parseFloat(currentBalance).toFixed(2)}
      </Grid.Column>
      {/*End Row*/}
    </>
  );
}

export default ReadOnlyInvoicePayments;
