import React, { Fragment } from 'react';
import { Dropdown, Header } from 'semantic-ui-react';

/**
 * Dropdown of dealers to select for the billing.
 * Cannot add, remove or edit dealers in this list.
 *
 * @param {object} props - passed from the BillingForm Component.
 * Object values:
 * dealerId - is greater than 0 when selecting a dealer from the dealers page,
 * dealerList - list of dealers in the dealersInBilling value from the dealers reducer
 */
const DealerDropdown = (props) => {
  //console.log('Current Dealer in Dropdown', props.dealerId);
  // create the list of dealers without the add new button
  let dealerOptions = [];
  _.each(props.dealerList, (values) => {
    dealerOptions.push({
      key: values.dealerId,
      value: values.dealerId,
      text: values.companyName,
    });
  });

  return (
    <Fragment>
      <Header>Select the Dealer</Header>
      <Dropdown
        placeholder='Select Dealer'
        className='brm-form-dropdown'
        defaultValue={props.dealerId}
        value={props.dealerId}
        name='dealer'
        disabled={props.billingId > 0}
        search
        selection
        selectOnBlur={true}
        options={dealerOptions}
        onChange={(e, { value }) => {
          //console.log('Selected Dealer', value);
          // trigger the search for the selected
          // dealer's open invoices if a valid
          // dealer selection has been made
          if (value > 0) {
            //console.log('Retrieving Invoices');
            props.getDealerInvoices(value, []);
            props.setDealer(value);
          }
        }}
      />
    </Fragment>
  );
};

export default DealerDropdown;
