import React, { Fragment } from 'react';
import { Form, Button, Input } from 'semantic-ui-react';
import { Formik } from 'formik';

const TwoFAStep2 = (props) => {
  return (
    <Fragment>
      <Formik
        initialValues={{
          username: props.username,
          sendCodeBy: 'NONE',
          twoFactorCode: '',
        }}
        onSubmit={(values) => props.authUser(values)}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          values,
          errors,
          touched,
        }) => {
          if (!props.passwordSet) {
            return (
              <Form onSubmit={handleSubmit}>
                <Form.Field>
                  <label>Enter the code sent to you to complete log in.</label>
                  <Input
                    type='text'
                    name='twoFactorCode'
                    fluid
                    icon='lock'
                    iconPosition='left'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.twoFactorCode}
                  />
                </Form.Field>
                <Button type='submit' className='normal-button'>
                  Submit
                </Button>
              </Form>
            );
          }
          return null;
        }}
      </Formik>
    </Fragment>
  );
};

export default TwoFAStep2;
