import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Grid, Button, Header } from 'semantic-ui-react';
import PageHeader from '../PageHeader';
import DealerList from './DealerList';
import LoadingComp from '../LoadingComp';
import ResultsModal from '../ResultsModal';
import DealerFilters from './DealerFilters';
import {
  showDealerForm,
  addNewDealer,
  getDealer,
  removeDealer,
  confirmDealerSaved,
  getDealerOutstandingInvoices,
  getFilteredDealerList,
} from '../../actions';

/*
const renderUserForm = (show) => {
  if (show) {
    return (
      <Modal open size='large' closeOnEscape={false} closeOnDimmerClick={false}>
        <Modal.Content>
          <UserForm />
        </Modal.Content>
      </Modal>
    );
  }
  return null;
};
*/

const renderLoader = (props) => {
  if (props.showLoading) {
    // message dependant on what action the page is executing
    let message = 'Retrieving Dealer Information...';
    if (props.showDealerModal) {
      message = 'Saving Dealer...';
    }
    return <LoadingComp msg={message} />;
  }
  return null;
};

const renderResults = (show, success, error, closeFunction) => {
  if (show) {
    return (
      <ResultsModal
        success={success}
        error={error}
        closeFunction={closeFunction}
        title='Save Dealer Result'
        hasLink={false}
        goToPage=''
      />
    );
  }
  return null;
};

const ManageDealers = (props) => {
  // get a fresh copy of the dealer list when the component loads
  useEffect(() => {
    props.getFilteredDealerList({
      hasBalance: props.savedFilters.hasBalance,
      invoiceCreatedStartDate: props.savedFilters.invoiceCreatedStart,
      invoiceCreatedEndDate: props.savedFilters.invoiceCreatedEnd,
      useDateRange: props.savedFilters.useDateRange,
    });
  }, []);

  return (
    <Container className='brm-container'>
      <PageHeader />
      <Header>Dealer Management</Header>
      <Button
        type='button'
        className='normal-button'
        onClick={() => {
          props.addNewDealer(0);
          props.showDealerForm(true);
        }}
      >
        + Add New Dealer
      </Button>
      <Grid columns='equal'>
        <Grid.Row>
          <Grid.Column>
            <DealerFilters
              filterList={props.getFilteredDealerList}
              hasBalance={props.savedFilters.hasBalance}
              createdStartDate={props.savedFilters.invoiceCreatedStart}
              createdEndDate={props.savedFilters.invoiceCreatedEnd}
              useCreatedDateRange={props.savedFilters.useDateRange}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <DealerList
              dealers={props.dealers}
              removeDealer={props.removeDealer}
              getDealer={props.getDealer}
              showDealerModal={props.showDealerModal}
              showDealerForm={props.showDealerForm}
              getDealerOutstandingInvoices={props.getDealerOutstandingInvoices}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {renderLoader(props)}
      {renderResults(
        props.dealerSaved,
        props.success,
        props.error,
        props.confirmDealerSaved
      )}
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    dealers: state.dealers.filteredDealerList,
    showLoading: state.main.isLoading,
    error: state.main.errors,
    success: state.main.success,
    dealerSaved: state.dealers.dealerSaved,
    showDealerModal: state.dealers.showModal,
    savedFilters: state.dealers.dealerListFilters,
  };
};

export default connect(mapStateToProps, {
  showDealerForm,
  getDealer,
  addNewDealer,
  removeDealer,
  confirmDealerSaved,
  getDealerOutstandingInvoices,
  getFilteredDealerList,
})(ManageDealers);
