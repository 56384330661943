import React from 'react';
import { Grid, Header } from 'semantic-ui-react';
import moment from 'moment';

function ReadOnlyInvoiceHeader(props) {
  const { txnId, receivedFromDealer, status } = props.txnForm;
  return (
    <>
      <Grid.Column width={4}>
        <Header as='h3'>Invoice #: {txnId}</Header>
      </Grid.Column>
      <Grid.Column
        width={8}
        style={{ backgroundColor: '#21ba45', textAlign: 'center' }}
      >
        <Header as='h3'>Status: {status}</Header>
      </Grid.Column>
      <Grid.Column width={4}>
        <Header as='h3'>
          Invoice Date: {moment(receivedFromDealer).format('MM/DD/YYYY')}
        </Header>
      </Grid.Column>
    </>
  );
}

export default ReadOnlyInvoiceHeader;
