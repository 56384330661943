import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

const LoadingComp = (props) => {
    return (
        <Dimmer active inverted page>
            <Loader size="large">{props.msg}</Loader>
        </Dimmer>
    );
}

export default LoadingComp;