import _ from 'lodash';
import main from '../apis/main';
import moment from 'moment';
import {
  FIND_COMBINED_INVOICE,
  GET_COMBINED_INVOICES,
  GET_FINANCIAL_TXN_BY_COMBINED_INVOICE,
  TOGGLE_SELECTED_INVOICE,
  SAVE_COMBINED_INVOICE,
  COMBINED_INVOICE_SAVED,
  SAVE_TEMP_COMBINED_INVOICE_TXNS,
  SAVE_TEMP_COMBINED_INVOICE_INTERNAL_NOTE,
  SAVE_TEMP_COMBINED_INVOICE_NOTE,
  UPDATE_COMBINED_INVOICE_TOTAL_BALANCE,
  NEW_COMBINED_INVOICE,
  SHOW_SUCCESS,
  CLEAR_SUCCESS,
  CLEAR_ERROR,
  PRINT_COMBINED_INVOICE,
  SET_COMBINED_INVOICE_DEALER,
  RESET_FINANCIAL_TXN_SAVED,
  THROW_ERROR,
} from './types';
import { showLoading, sessionExpired, getAllInvoices } from './globalActions';
import { saveFinancialTxn } from './financialTxnActions';
import { saveNote, getNotesList } from './noteActions';
import { getDealer } from './dealerActions';

/**
 * Retrieves all of the combined invoices
 */
export const getCombinedInvoices = () => async (dispatch, getState) => {
  dispatch(showLoading(true));
  const { sessionId } = getState().users;
  const { dealerList } = getState().dealers;
  const response = await main.get(`/api/combined_invoices/${sessionId}`);
  //console.log(response);
  if (200 === response.status) {
    const responseData = response.data;

    // check for session token error
    if (responseData.error !== undefined) {
      dispatch(showLoading(false));
      // what type of error?
      if (responseData.error === 'Invalid session token') {
        // the session token is invalid or expired
        dispatch(sessionExpired());
        return;
      }

      // throw an error
      dispatch({ type: THROW_ERROR, payload: responseData.error.text });
      return;
    }

    let combinedList = [];
    let totalOwed = 0.0;
    let totalOverpaid = 0.0;
    let allCharges = 0.0;
    _.each(responseData, (values) => {
      //console.log('combined', values);
      // do not include deleted status
      if ('0' === values.deleted) {
        // see if the total charges are what's owed or overpaid
        let totalCharges = parseFloat(values.amount_charged);
        // let currentBalance = parseFloat(values.current_balance);
        // if (currentBalance > 0.00) {
        //     // add to the total owed
        //     totalOwed += currentBalance;
        // } else {
        //     // add to the total overpaid
        //     totalOverpaid -= currentBalance;
        // }

        // save the total charges
        allCharges += totalCharges;

        // get the dealer name
        let dealerName = _.find(dealerList, { key: values.dealer_id });
        //console.log('dealer', dealerName);

        combinedList.push({
          combinedInvoiceId: values.id,
          dealer: dealerName.text,
          updated: moment(values.updated_at).format('MM/DD/YYYY hh:mm a'),
          created: moment(values.created_at).format('MM/DD/YYYY hh:mm a'),
          totalCharges: '$' + values.amount_charged,
          editId: values.id,
        });
      }
    });

    dispatch({
      type: GET_COMBINED_INVOICES,
      payload: { combinedList, allCharges },
    });
  } else {
    // something went wrong
    dispatch({ type: THROW_ERROR, payload: response.statusText });
  }
  dispatch(showLoading(false));
};

/**
 * Retrieves a specific invoice with attached payments and notes
 */
export const getCombinedInvoice =
  (combinedId) => async (dispatch, getState) => {
    const { sessionId } = getState().users;

    const response = await main.get(
      `/api/combined_invoices/${combinedId}/${sessionId}`
    );

    if (200 === response.status) {
      // check for session token error
      if (response.data.error !== undefined) {
        // what type of error?
        if (response.data.error === 'Invalid session token') {
          // the session token is invalid or expired
          dispatch(sessionExpired());
          return;
        }

        // throw an error
        dispatch({ type: THROW_ERROR, payload: responseData.error.text });
        return;
      }

      // save the data to the form
      const responseData = response.data[0];
      //console.log('Response', responseData);
      dispatch({ type: FIND_COMBINED_INVOICE, payload: responseData });

      // retrieve the dealer information
      dispatch(getDealer(responseData.dealer_id, true));

      // retrieve all of the associated invoices
      const invoiceParams = {
        startDate: '2000-01-01',
        endDate: moment().format('YYYY-MM-DD'),
        combinedId: responseData.id,
        status: 'all',
        hasBalance: 'all',
        dealer: 'all',
      };
      await dispatch(getAllInvoices(invoiceParams));

      // get the associated payments and refunds on the combined invoice
      await dispatch(
        getFinancialTxnsByCombinedInvoice(
          responseData.id,
          responseData.amount_charged
        )
      );

      // get the public and internal notes on the invoice
      dispatch(getNotesList(responseData.id, 'combined-invoice', 0)); // public
      dispatch(getNotesList(responseData.id, 'combined-invoice', 1)); // internal

      //dispatch(updateCIBalance());
    }
  };

/**
 * Retrieve all financial transactions associated with a combined invoice
 * @param {int} combinedId
 */
export const getFinancialTxnsByCombinedInvoice =
  (combinedId, totalCharges) => async (dispatch, getState) => {
    const { sessionId, userList } = getState().users;
    let bodyFormData = new FormData();
    bodyFormData.append('combined_invoice_id', combinedId);

    const response = await main.post(
      `/api/financial_txns/${sessionId}`,
      bodyFormData
    );
    if (200 === response.status) {
      const responseData = response.data;

      // check for session token error
      if (responseData.error !== undefined) {
        // what type of error?
        if (responseData.error === 'Invalid session token') {
          // the session token is invalid or expired
          dispatch(sessionExpired());
          return;
        }

        // throw an error
        dispatch({ type: THROW_ERROR, payload: responseData.error.text });
        return;
      }

      // build the transaction list with the user information and update
      // the current balance of the combined invoice
      let txnData = [];
      let currentBalance = totalCharges;
      _.each(responseData, (values) => {
        const updatedBy = _.find(userList, { userId: values.updated_by });
        txnData.push({
          id: values.id,
          txn_type: values.txn_type,
          created_by: values.created_by,
          created_at: values.created_at,
          updated_by: values.updated_by,
          updated_at: values.updated_at,
          payment_type: values.payment_type,
          amount: values.amount,
          ref_number: values.ref_number,
          toward: values.toward,
          in_reg: values.in_reg,
          deleted: values.deleted,
          plate_id: values.plate_id,
          combined_invoice_id: values.combined_invoice_id,
          updated_by_name:
            updatedBy !== undefined
              ? updatedBy.firstName + ' ' + updatedBy.lastName
              : '',
        });

        // update the current balance
        if ('Payment' === values.txn_type) {
          // subtract from the current balance
          currentBalance -= parseFloat(values.amount);
        } else {
          // this is a refund
          currentBalance += parseFloat(values.amount);
        }
      });

      dispatch({
        type: GET_FINANCIAL_TXN_BY_COMBINED_INVOICE,
        payload: { txnData, currentBalance },
      });
    }
  };

/**
 * Flags whether an invoice has been selected for a combined invoice
 * @param {int} txnId
 * @param {boolean} selected
 */
export const toggleInvoiceSelected = (txnId, selected, invoiceAmt) => {
  // add or subtract the invoice amount
  let charges = invoiceAmt.replace('$', '');
  if (!selected) {
    // remove the amount
    charges = 0 - parseFloat(charges);
  }
  return {
    type: TOGGLE_SELECTED_INVOICE,
    payload: { txnId, selected, invoiceAmt: charges },
  };
};

/**
 * Clears out the form for a new combined invoice to be created
 */
export const createNewCombinedInvoice = () => {
  return { type: NEW_COMBINED_INVOICE };
};

export const saveCombinedInvoice =
  (values, saveAndPrint = false) =>
  async (dispatch, getState) => {
    const { sessionId, currentUser } = getState().users;
    const { combinedInvoiceForm } = getState().combinedinvoices;

    dispatch(showLoading(true));

    // response object
    let response = {};

    // build the submission object
    let bodyFormData = new FormData();
    bodyFormData.append('created_by', currentUser.userId);
    bodyFormData.append('updated_by', currentUser.userId);
    bodyFormData.append('dealer_id', values.dealerId);
    bodyFormData.append('issued_date', moment().format('YYYY-MM-DD'));
    bodyFormData.append('amount_charged', combinedInvoiceForm.totalCharges);
    bodyFormData.append('deleted', 0);

    // call the correct endpoint to save the combined invoice
    if (0 === combinedInvoiceForm.combinedInvoiceId) {
      bodyFormData.append('session_token', sessionId);
      response = await main.post('/api/combined_invoices', bodyFormData);
    } else {
      // send an update
      response = await main.post(
        `/api/combined_invoices/${combinedInvoiceForm.combinedInvoiceId}/${sessionId}`,
        bodyFormData
      );
    }

    if (200 === response.status || 201 === response.status) {
      const responseData = response.data;

      // check for session token error
      if (responseData.error !== undefined) {
        dispatch(showLoading(false));
        // what type of error?
        if (responseData.error === 'Invalid session token') {
          // the session token is invalid or expired
          dispatch(sessionExpired());
          return;
        }

        // throw an error
        dispatch({ type: THROW_ERROR, payload: responseData.error.text });
        return;
      }

      if (responseData.notice.text !== undefined) {
        /**
         * Successful insertion, now see if any financial transactions
         * were added to a new combined invoice only
         */
        if (0 === combinedInvoiceForm.combinedInvoiceId) {
          // loop through the transactions entered and save them
          //console.log('Temp Payments', txnList);
          _.each(combinedInvoiceForm.payments, (values) => {
            let submitObj = {
              txnType: values.txn_type,
              created_by: currentUser.userId,
              updated_by: currentUser.userId,
              paymentType: values.payment_type,
              amount: values.amount,
              refNumber: values.ref_number,
              toward: values.toward,
              inReg: values.in_reg,
              deleted: 0,
              combined_invoice_id: responseData.notice.id,
              ccNumber: '',
              ccExpire: '',
              achAccountNumber: '',
            };
            dispatch(
              saveFinancialTxn(
                submitObj,
                responseData.notice.id,
                'combined-invoice'
              )
            );
          });

          // loop through the notes and save them
          _.each(combinedInvoiceForm.notes, (values) => {
            let submitObj = {
              note: values.note,
              internal: 0,
            };
            dispatch(
              saveNote(submitObj, responseData.notice.id, 'combined-invoice')
            );
          });

          _.each(combinedInvoiceForm.internalNotes, (values) => {
            let submitObj = {
              note: values.note,
              internal: 1,
            };
            dispatch(
              saveNote(submitObj, responseData.notice.id, 'combined-invoice')
            );
          });
        }

        // update the invoices associated with the combined invoice
        let useCIId =
          0 === combinedInvoiceForm.combinedInvoiceId
            ? responseData.notice.id
            : combinedInvoiceForm.combinedInvoiceId;
        dispatch(updateInvoicesCombinedId(useCIId));

        // send the audit object
        /*
            let auditFormData = new FormData();
            auditFormData.append('session_token', sessionId);
            auditFormData.append('txn_id', txnForm.txnId);
            auditFormData.append('txn_type', 'plate');
            auditFormData.append('created_by', currentUser.userId);
            auditFormData.append('txn_changes', JSON.stringify(auditObj));
            const auditResponse = await main.post('api/txn_edit_log', auditFormData);
            */

        if (saveAndPrint) {
          dispatch(showPrintCombinedInvoice(true));
        } else {
          dispatch({
            type: SHOW_SUCCESS,
            payload: 'The combined invoice was successfully saved.',
          });
          dispatch({
            type: SAVE_COMBINED_INVOICE,
            payload: currentUser.userId,
          });
          dispatch({ type: RESET_FINANCIAL_TXN_SAVED });
        }

        // update the combined invoice list
        dispatch(getCombinedInvoices());
        //dispatch({ type: COMBINED_INVOICE_SAVED });
      } else {
        // bad credentials
        dispatch({
          type: THROW_ERROR,
          payload: 'There was a problem saving the Invoice.  Please try again.',
        });
      }
    } else {
      // something went wrong
      dispatch({ type: THROW_ERROR, payload: response.statusText });
    }

    dispatch(showLoading(false));
  };

/**
 * Saves a payment or refund on new combined invoices that have not yet been saved
 * @param {object} data
 */
export const saveTempCIFinancialTxn = (data) => {
  return { type: SAVE_TEMP_COMBINED_INVOICE_TXNS, payload: data };
};

/**
 * Saves a note on new combined invoices that have not yet been saved
 * @param {object} data
 * @param {boolean} internal
 */
export const saveTempCINote = (data, internal) => {
  if (1 === internal) {
    return { type: SAVE_TEMP_COMBINED_INVOICE_INTERNAL_NOTE, payload: data };
  }
  return { type: SAVE_TEMP_COMBINED_INVOICE_NOTE, payload: data };
};

/**
 * Updates the current balance on a combined invoice
 */
export const updateCIBalance = () => async (dispatch, getState) => {
  const { combinedInvoiceForm } = getState().combinedinvoices;
  let currentBalance = 0.0;
  const totalCharges = parseFloat(combinedInvoiceForm.currentBalance);
  //console.log('Balance Before Update', totalCharges);
  let totalPaymentsRefunds = 0.0;

  // loop through each transaction to get the total amount of payments
  _.each(combinedInvoiceForm.payments, (values) => {
    //console.log('Payment Values', values);
    if ('Payment' === values.txn_type) {
      totalPaymentsRefunds += parseFloat(values.amount);
    } else {
      // refunds are subtracted from the total payments
      totalPaymentsRefunds -= parseFloat(values.amount);
    }
  });

  // calculate the current balance and fire off an update
  currentBalance = totalCharges - totalPaymentsRefunds;
  //console.log('Current Balance', currentBalance);
  dispatch({
    type: UPDATE_COMBINED_INVOICE_TOTAL_BALANCE,
    payload: currentBalance,
  });
};

/**
 * Associate selected invoices with a combined invoice
 * @param {int} combinedId
 */
export const updateInvoicesCombinedId =
  (combinedId) => async (dispatch, getState) => {
    const { sessionId, currentUser } = getState().users;
    const { combinedInvoiceForm } = getState().combinedinvoices;

    // go through each selected invoice and add the id to each one
    const selectedInvoices = _.filter(combinedInvoiceForm.invoices, {
      selected: true,
    });
    _.each(selectedInvoices, async (values) => {
      // build the submission object
      let bodyFormData = new FormData();
      bodyFormData.append('created_by', currentUser.userId);
      bodyFormData.append('updated_by', currentUser.userId);
      bodyFormData.append('dealer_id', values.dealerId);
      bodyFormData.append('dealer_phone', values.dealerPhone);
      bodyFormData.append('dealer_courier', values.dealerCourier);
      bodyFormData.append('returned_to', values.returnedTo);
      bodyFormData.append('reg_first_name', values.regFirstName);
      bodyFormData.append('reg_last_name', values.regLastName);
      bodyFormData.append('reg_email', values.regEmail);
      bodyFormData.append('reg_phone', values.regPhone);
      bodyFormData.append('reg_transaction_type', values.regTxnType);
      bodyFormData.append('reg_address', values.regAddress);
      bodyFormData.append('reg_city', values.regCity);
      bodyFormData.append('reg_zipcode', values.regZipCode);
      bodyFormData.append('reg_state_deal', values.regStateDeal);
      bodyFormData.append('reg_car_make', values.regCarMake);
      bodyFormData.append('reg_car_year', values.regCarYear);
      bodyFormData.append('reg_vin', values.regVin);
      bodyFormData.append('reg_plate_number', values.regPlateNumber);
      bodyFormData.append('reg_lien_holder_id', values.regLienHolder);
      bodyFormData.append('leasing_company', values.leasingCompany);
      bodyFormData.append(
        'from_dealer_date',
        moment(values.fromDealerDate).format('YYYY-MM-DD')
      );
      bodyFormData.append(
        'dmv_sent_date',
        moment(values.dmvSentDate).format('YYYY-MM-DD')
      );
      bodyFormData.append(
        'dmv_return_date',
        moment(values.dmvReturnDate).format('YYYY-MM-DD')
      );
      bodyFormData.append('brm_fees', values.brmFees);
      bodyFormData.append('dmv_fees', values.dmvFees);
      bodyFormData.append('sale_tax', values.salesTax);
      bodyFormData.append('status', values.status);
      bodyFormData.append(
        'total_charges',
        values.totalCharges.replace('$', '')
      );
      bodyFormData.append('current_balance', values.currentBalance);
      bodyFormData.append('combined_invoice_id', combinedId);
      bodyFormData.append('paid_from_combined_invoice', 0);
      bodyFormData.append('is_financial_invoice', 0);

      // send an update
      const response = await main.post(
        `/api/plate_txns/${values.txnId}/${sessionId}`,
        bodyFormData
      );
    });
  };

/**
 * Distributes a payment entered among all invoices that currently have a balance
 * @param {int} paymentId
 * @param {float} paymentAmount
 * @param {string} paymentType - Payment or Refund
 */
export const distributePaymentToInvoices =
  (paymentInfo, parentPaymentId) => async (dispatch, getState) => {
    const { combinedInvoiceForm } = getState().combinedinvoices;
    //console.log('Distribute Payments', combinedInvoiceForm);
    //console.log('Payment Info', paymentInfo);

    // initialize the starting payment amount
    let paymentLeft = parseFloat(paymentInfo.amount);

    // loop through the associated invoices
    _.each(combinedInvoiceForm.invoices, (values) => {
      //console.log('Combined Invoice Form Invoices', values);
      // check for a non-zero balance
      const invoiceBalance = parseFloat(values.currentBalance);
      //console.log('Invoice Balance', invoiceBalance);
      //console.log('Total Payment Left', paymentLeft);
      if (invoiceBalance !== 0.0) {
        // non-zero balance, need to apply part of the payment to the invoice

        // start building the financial transaction object
        let txnObj = {
          txnType: paymentInfo.txnType,
          paymentType: paymentInfo.paymentType,
          amount: 0.0,
          refNumber: paymentInfo.refNumber,
          toward: paymentInfo.toward,
          inReg: paymentInfo.inReg,
        };

        switch (paymentInfo.txnType) {
          case 'Payment':
            if (paymentLeft > invoiceBalance) {
              // invoice paid off
              txnObj.amount = invoiceBalance;
            } else {
              // apply remaining payment to the invoice balance
              txnObj.amount = paymentLeft;
            }
            // add the financial transaction for the invoice
            //console.log('Dispatch Add Financial Txn');
            dispatch(
              saveFinancialTxn(txnObj, values.txnId, 'invoice', parentPaymentId)
            );
            break;
          case 'Refund':
            // refunds can only be applied if the invoice balance is less than zero
            if (invoiceBalance < 0) {
              let totalRefund = 0 - paymentLeft;
              if (totalRefund < invoiceBalance) {
                // invoice paid off
                txnObj.amount = invoiceBalance;
              } else {
                // apply the remaining refund to the invoice balance
                txnObj.amount = totalRefund;
              }
              // add the financial transaction for the invoice
              dispatch(
                saveFinancialTxn(
                  txnObj,
                  values.txnId,
                  'invoice',
                  parentPaymentId
                )
              );
            }
            break;
        }

        // update the current balance of the plate transaction
        dispatch(
          updatePlateInvoiceBalance(
            values,
            invoiceBalance - txnObj.amount,
            combinedInvoiceForm.combinedInvoiceId
          )
        );

        // subtract from the payment to get the remaining
        paymentLeft -= invoiceBalance;
      }

      // return from the loop if the payment left is 0
      if (paymentLeft <= 0.0) {
        return false;
      }
    });
  };

export const updatePlateInvoiceBalance =
  (plateTxn, currentBalance, combinedId) => async (dispatch, getState) => {
    const { sessionId, currentUser } = getState().users;
    //console.log('Plate Txn Info', plateTxn);
    // build the submission object
    let bodyFormData = new FormData();
    bodyFormData.append('created_by', currentUser.userId);
    bodyFormData.append('updated_by', currentUser.userId);
    bodyFormData.append('dealer_id', plateTxn.dealerId);
    bodyFormData.append('dealer_phone', plateTxn.dealerPhone);
    bodyFormData.append('dealer_courier', plateTxn.dealerCourier);
    bodyFormData.append('returned_to', plateTxn.returnedTo);
    bodyFormData.append('reg_first_name', plateTxn.regFirstName);
    bodyFormData.append('reg_last_name', plateTxn.regLastName);
    bodyFormData.append('reg_email', plateTxn.regEmail);
    bodyFormData.append('reg_phone', plateTxn.regPhone);
    bodyFormData.append('reg_transaction_type', plateTxn.regTxnType);
    bodyFormData.append('reg_address', plateTxn.regAddress);
    bodyFormData.append('reg_city', plateTxn.regCity);
    bodyFormData.append('reg_zipcode', plateTxn.regZipCode);
    bodyFormData.append('reg_state_deal', plateTxn.regStateDeal);
    bodyFormData.append('reg_car_make', plateTxn.regCarMake);
    bodyFormData.append('reg_car_year', plateTxn.regCarYear);
    bodyFormData.append('reg_vin', plateTxn.regVin);
    bodyFormData.append('reg_plate_number', plateTxn.regPlateNumber);
    bodyFormData.append('reg_lien_holder_id', plateTxn.regLienHolder);
    bodyFormData.append('leasing_company', plateTxn.leasingCompany);
    bodyFormData.append(
      'from_dealer_date',
      moment(plateTxn.fromDealerDate).format('YYYY-MM-DD')
    );
    bodyFormData.append(
      'dmv_sent_date',
      moment(plateTxn.dmvSentDate).format('YYYY-MM-DD')
    );
    bodyFormData.append(
      'dmv_return_date',
      moment(plateTxn.dmvReturnDate).format('YYYY-MM-DD')
    );
    bodyFormData.append('brm_fees', plateTxn.brmFees);
    bodyFormData.append('dmv_fees', plateTxn.dmvFees);
    bodyFormData.append('sale_tax', plateTxn.salesTax);
    bodyFormData.append('status', plateTxn.status);
    bodyFormData.append(
      'total_charges',
      plateTxn.totalCharges.replace('$', '')
    );
    bodyFormData.append('current_balance', currentBalance);
    bodyFormData.append('combined_invoice_id', combinedId);
    bodyFormData.append('paid_from_combined_invoice', 0);
    bodyFormData.append('is_financial_invoice', 0);

    // send an update
    const response = await main.post(
      `/api/plate_txns/${plateTxn.txnId}/${sessionId}`,
      bodyFormData
    );
  };

/**
 * Closes the invoice result
 */
export const closeSavedCombinedInvoiceResult = () => async (dispatch) => {
  dispatch({ type: CLEAR_SUCCESS });
  dispatch({ type: CLEAR_ERROR });
  dispatch({ type: COMBINED_INVOICE_SAVED });
};

export const removeCombinedInvoice =
  (combinedId) => async (dispatch, getState) => {
    // retrieve the combined invoice info since a removal is an update
    await dispatch(getCombinedInvoice(combinedId));
    const { sessionId, currentUser } = getState().users;
    const { combinedInvoiceForm } = getState().combinedinvoices;

    // response object
    let response = {};

    // build the submission object
    let bodyFormData = new FormData();
    bodyFormData.append('created_by', currentUser.userId);
    bodyFormData.append('updated_by', currentUser.userId);
    bodyFormData.append('dealer_id', combinedInvoiceForm.dealerId);
    bodyFormData.append('issued_date', combinedInvoiceForm.combinedInvoiceDate);
    bodyFormData.append('amount_charged', combinedInvoiceForm.totalCharges);
    bodyFormData.append('deleted', 1);

    // send the update
    response = await main.post(
      `/api/combined_invoices/${combinedId}/${sessionId}`,
      bodyFormData
    );

    if (200 === response.status || 201 === response.status) {
      const responseData = response.data;

      // check for session token error
      if (responseData.error !== undefined) {
        dispatch(showLoading(false));
        // what type of error?
        if (responseData.error === 'Invalid session token') {
          // the session token is invalid or expired
          dispatch(sessionExpired());
          return;
        }

        // throw an error
        dispatch({ type: THROW_ERROR, payload: responseData.error.text });
        return;
      }

      if (responseData.notice.text !== undefined) {
        /**
         * Successful update, remove the combined invoice id from the
         * associated plate transactions
         */
        dispatch(updateInvoicesCombinedId(0));

        dispatch({ type: SAVE_COMBINED_INVOICE, payload: currentUser.userId });

        // update the combined invoice list
        dispatch(getCombinedInvoices());
        dispatch({ type: COMBINED_INVOICE_SAVED });
      } else {
        // bad credentials
        dispatch({
          type: THROW_ERROR,
          payload:
            'There was a problem removing the Invoice.  Please try again.',
        });
      }
    } else {
      // something went wrong
      dispatch({ type: THROW_ERROR, payload: response.statusText });
    }

    dispatch(showLoading(false));
  };

/**
 * Show or hide the PDF combined invoice viewer to print an invoice
 */
export const showPrintCombinedInvoice = (show) => async (dispatch) => {
  if (show) {
    dispatch(getCombinedInvoicePDF());
  } else {
    dispatch({
      type: PRINT_COMBINED_INVOICE,
      payload: { show: false, fileName: '' },
    });
  }
};

/**
 * Calls the endpoint to retrieve the PDF combined invoice for printing
 */
export const getCombinedInvoicePDF = () => async (dispatch, getState) => {
  dispatch(showLoading(true));
  const { sessionId } = getState().users;
  const { combinedInvoiceForm } = getState().combinedinvoices;

  const response = await main.post(
    `/api/pdf_combined_invoice/${sessionId}`,
    combinedInvoiceForm
  );

  if (200 === response.status) {
    // check for session token error
    if (response.data.error !== undefined) {
      // what type of error?
      if (response.data.error === 'Invalid session token') {
        // the session token is invalid or expired
        dispatch(sessionExpired());
        dispatch(showLoading(false));
        return;
      }

      // throw an error
      dispatch(showLoading(false));
      dispatch({ type: THROW_ERROR, payload: response.data.error.text });
      return;
    }

    // check that the pdf was generated
    if (response.data.success) {
      dispatch({
        type: PRINT_COMBINED_INVOICE,
        payload: { show: true, fileName: response.data.invoiceFile },
      });
    } else {
      dispatch({
        type: THROW_ERROR,
        payload: 'There was a problem printing the invoice.',
      });
    }
  } else {
    // there was an error from the endpoint
    dispatch({ type: THROW_ERROR, payload: response.statusText });
  }
  dispatch(showLoading(false));
};
