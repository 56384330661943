import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import {
  Form,
  Input,
  Button,
  Segment,
  Header,
  Message,
  Grid,
} from 'semantic-ui-react';
import { Formik } from 'formik';
import { startResetPassword, resetPassword } from '../../actions';
import ErrorMsg from '../ErrorMsg';
import SuccessMsg from '../SuccessMsg';
import * as yup from 'yup';

/**
 * Decides which message to show
 */
const renderMessage = (props) => {
  if (props.passwordSet) {
    return (
      <Fragment>
        <SuccessMsg />
        <Link to='/'>
          <Button type='button' className='normal-button'>
            Go To Login
          </Button>
        </Link>
      </Fragment>
    );
  } else if (props.error.length > 0) {
    return <ErrorMsg />;
  }

  return null;
};

const NewPassword = (props) => {
  // check the validity of the session token upon loading of the component
  const [checkedSessionId, setCheckedSessionId] = useState('');
  let { linkHash } = useParams();
  useEffect(() => {
    if (checkedSessionId.length === 0) {
      //console.log(linkHash);
      props.startResetPassword(linkHash);
      setCheckedSessionId(linkHash);
    }
  });

  // form validation
  const validationSchema = yup.object({
    password: yup.string().required('New Password is required'),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
  });

  return (
    <Grid
      textAlign='center'
      style={{ height: '100vh', backgroundColor: '#F1F5F8' }}
      verticalAlign='middle'
    >
      <Grid.Column style={{ maxWidth: 450 }}>
        <Segment stacked>
          <Header>Reset Password</Header>
          {renderMessage(props)}
          <Formik
            initialValues={{ password: '', confirmPassword: '' }}
            validationSchema={validationSchema}
            onSubmit={(values) => props.resetPassword(values, checkedSessionId)}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              errors,
              touched,
            }) => {
              if (!props.passwordSet) {
                return (
                  <Form onSubmit={handleSubmit}>
                    <Form.Field>
                      <label>New Password</label>
                      <Input
                        type='password'
                        name='password'
                        fluid
                        icon='lock'
                        iconPosition='left'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                      />
                      {errors.password && touched.password ? (
                        <Message negative size='tiny'>
                          {errors.password}
                        </Message>
                      ) : null}
                    </Form.Field>
                    <Form.Field>
                      <label>Confirm New Password</label>
                      <Input
                        type='password'
                        name='confirmPassword'
                        fluid
                        icon='lock'
                        iconPosition='left'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.confirmPassword}
                      />
                      {errors.confirmPassword && touched.confirmPassword ? (
                        <Message negative size='tiny'>
                          {errors.confirmPassword}
                        </Message>
                      ) : null}
                    </Form.Field>
                    <Button type='submit' className='normal-button'>
                      Reset Password
                    </Button>
                  </Form>
                );
              }
              return null;
            }}
          </Formik>
        </Segment>
      </Grid.Column>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    passwordSet: state.users.passwordChanged,
    sessionId: state.users.sessionId,
    error: state.main.errors,
  };
};

export default connect(mapStateToProps, { startResetPassword, resetPassword })(
  NewPassword
);
