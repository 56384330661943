import * as yup from 'yup';

import {
    TXN_TYPE,
    PAYMENT_TYPE,
    AMOUNT,
    TOWARD,
    IN_REG
} from './FieldNames';

const REQUIRED_MESSAGE = 'This field is required';
const INVALID_DOLLAR_AMOUNT = 'Must be a valid dollar amount';

export default yup.object({
    [TXN_TYPE]: yup.string().required(REQUIRED_MESSAGE),
    [PAYMENT_TYPE]: yup.string().required(REQUIRED_MESSAGE),
    [AMOUNT]: yup.number().test('is-decimal', INVALID_DOLLAR_AMOUNT, value => (value + "").match(/^(\d*\.)?\d+$/)),
    [TOWARD]: yup.string().required(REQUIRED_MESSAGE)
});