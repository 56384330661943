import React from 'react';
import { Message } from 'semantic-ui-react';
import { connect } from 'react-redux';

const renderSuccess = (msg) => {
    if (msg.length > 0) {
        return (
            <Message positive>
                {msg}
            </Message>
        );
    }

    // default return is nothing
    return null;
}

const SuccessMsg = (props) => {
    return(
        renderSuccess(props.success)
    );
};

const mapStateToProps = (state) => {
    return {
        success: state.main.success
    };
};

export default connect(mapStateToProps)(SuccessMsg);