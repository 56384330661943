import React, { useEffect, useState } from 'react';
import {
  Container,
  Segment,
  Grid,
  Header,
  Button,
  Modal,
  Icon,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import PageHeader from '../PageHeader';
import DealerInvoices from './Tables/DealerInvoices';
import {
  getInvoicesByDealer,
  getBillingsByDealer,
  confirmDealerSaved,
  showDealerForm,
  clearDealerBillings,
  clearDealerInvoices,
  getInvoice,
  removeInvoice,
  getDealerOutstandingInvoices,
  getBilling,
  removeBilling,
  clearError,
  confirmBillingRemoved,
  clearInvoiceRemoved,
  getDealerList,
} from '../../actions';
import DealerBillings from './Tables/DealerBillings';
import LoadingComp from '../LoadingComp';
import ResultsModal from '../ResultsModal';
import DealerForm from '../PlateInvoicing/Dealers/DealerForm';
import ErrorModal from '../ErrorModal';

/**
 * Shows the dealer form when editing a dealer's information
 *
 * @param {boolean} show
 * @returns
 */
const renderDealerForm = (show) => {
  if (show) {
    return (
      <Modal open size='large' closeOnEscape={false} closeOnDimmerClick={false}>
        <Modal.Content>
          <DealerForm />
        </Modal.Content>
      </Modal>
    );
  }
  return null;
};

const renderLoader = (props) => {
  if (props.showLoading) {
    // message dependant on what action the page is executing
    let message = 'Retrieving Dealer Information...';
    if (props.showDealerModal) {
      message = 'Saving Dealer...';
    }
    return <LoadingComp msg={message} />;
  }
  return null;
};

const renderResults = (show, success, error, closeFunction, resultsTitle) => {
  if (show) {
    return (
      <ResultsModal
        success={success}
        error={error}
        closeFunction={closeFunction}
        title={resultsTitle}
        hasLink={false}
        goToPage=''
      />
    );
  }
  return null;
};

/**
 * Shows the Error Modal when an error occurs.  In this
 * particular component it's mainly used for search errors.
 *
 * @param {string} message
 * @param {function} errorCloseFunction
 */
const renderError = (message, errorCloseFunction) => {
  // an error was thrown on this page
  //console.log('Show Error', message);
  return (
    <ErrorModal
      title='An Error Has Occured'
      error={message}
      closeFunction={errorCloseFunction}
    />
  );
};

function DealerDetails(props) {
  // destructure the dealer info props for easier reading
  const {
    dealerId,
    dealerName,
    street,
    city,
    state,
    zipCode,
    phoneNumber,
    phoneExtension,
    faxNumber,
    email,
    courier,
    id,
    contactFirstName,
    contactLastName,
    notes,
    prospect,
  } = props.dealerData;

  // local state to carry the current balance and credits for the dealer
  const [thisDealerBalance, setThisDealerBalance] = useState(0.0);
  const [thisDealerCredits, setThisDealerCredits] = useState(0.0);

  // retrieve the dealer's invoices and billings when the component loads
  useEffect(() => {
    props.getInvoicesByDealer(dealerId);
    props.getBillingsByDealer(dealerId);
    props.getDealerList();
  }, []);

  // monitor changes to the invoice and billings lists
  useEffect(() => {
    if (props.billingSaved) {
      // billing was removed so refresh both lists and the main dealer list
      props.getBillingsByDealer(dealerId);
      props.getInvoicesByDealer(dealerId);
      props.getDealerList();
    }
  }, [props.billingSaved]);

  // monitor deletion of an invoice
  useEffect(() => {
    if (props.invoiceRemoved) {
      // the user removed an invoice so refresh the list and dealer list info and unset the invoice removal flag
      props.getInvoicesByDealer(dealerId);
      props.clearInvoiceRemoved();
      props.getDealerList();
    }
  }, [props.invoiceRemoved]);

  // track when the dealer list is refreshed to get the latest
  // dealer balance and credits
  useEffect(() => {
    const selectedDealerInfo = _.find(props.dealerList, {
      dealerId: dealerId,
    });

    if (selectedDealerInfo !== undefined) {
      // set any credit amount they have
      setThisDealerBalance(selectedDealerInfo.currentBalance);
      setThisDealerCredits(selectedDealerInfo.totalCredits);
    }
  }, [props.dealerList]);

  // reference the router history
  const navigate = useNavigate();

  return (
    <Container>
      <PageHeader />
      <br />
      {props.currentUser.accountType !== 'dealer' && (
        <Button
          className='normal-button'
          onClick={() => {
            props.clearDealerBillings();
            props.clearDealerInvoices();
            navigate(-1);
          }}
        >
          Back to List
        </Button>
      )}
      <Grid stackable style={{ marginTop: '10px' }}>
        <Grid.Column width={4} style={{ paddingTop: '1.8rem' }}>
          <Header>
            {dealerName}&nbsp;&nbsp;
            {props.currentUser.accountType !== 'dealer' && (
              <Icon
                style={{ cursor: 'pointer', fontSize: '1em' }}
                name='pencil'
                onClick={() => {
                  props.showDealerForm(true);
                }}
              />
            )}
          </Header>
        </Grid.Column>
        <Grid.Column width={4} style={{ paddingTop: '1.8rem' }}>
          <Header>
            Current Balance: ${parseFloat(thisDealerBalance).toFixed(2)}
          </Header>
        </Grid.Column>
        <Grid.Column width={4} style={{ paddingTop: '1.8rem' }}>
          {props.currentUser.accountType !== 'dealer' && (
            <Header>
              Total Credits: ${parseFloat(thisDealerCredits).toFixed(2)}
            </Header>
          )}
        </Grid.Column>
        <Grid.Column width={4} textAlign='right'>
          {props.currentUser.accountType !== 'dealer' && (
            <Button
              className='normal-button'
              onClick={() => {
                props.getDealerOutstandingInvoices(dealerId, []);
                navigate('/billing-form');
              }}
            >
              <Icon name='money' size='large' />
              &nbsp;&nbsp; Receive Payment
            </Button>
          )}
        </Grid.Column>
      </Grid>
      <Segment>
        <Grid stackable>
          <Grid.Column
            width={'dealer' === props.currentUser.accountType ? 16 : 4}
          >
            <strong>Address</strong>
            <br />
            {street}
            <br />
            {city}, {state} {zipCode}
          </Grid.Column>
          {props.currentUser.accountType !== 'dealer' && (
            <>
              <Grid.Column width={4}>
                <strong>Courier</strong>
                <br />
                {courier}
              </Grid.Column>
              <Grid.Column width={4}>
                <strong>ID</strong>
                <br />
                {id}
              </Grid.Column>
              <Grid.Column width={4}>
                <strong>Prospect</strong>
                <br />
                {'0' === prospect ? 'No' : 'Yes'}
              </Grid.Column>
            </>
          )}
          <Grid.Column width={4}>
            <strong>Contact Name</strong>
            <br />
            {contactFirstName} {contactLastName}
          </Grid.Column>
          <Grid.Column width={4} style={{ wordWrap: 'break-word' }}>
            <strong>Email</strong>
            <br />
            {email}
          </Grid.Column>
          <Grid.Column width={4}>
            <strong>Phone</strong>
            <br />
            {phoneNumber} {phoneExtension.length > 0 && 'Ext.' + phoneExtension}
          </Grid.Column>
          <Grid.Column width={4}>
            <strong>Fax</strong>
            <br />
            {faxNumber}
          </Grid.Column>
          {props.currentUser.accountType !== 'dealer' && (
            <Grid.Column width={16}>
              <strong>Notes</strong>
              <br />
              {notes}
            </Grid.Column>
          )}
        </Grid>
      </Segment>
      <Header>Invoices</Header>
      <DealerInvoices
        invoices={props.dealerInvoices}
        isAdmin={props.isAdmin}
        getInvoice={props.getInvoice}
        deleteInvoice={props.removeInvoice}
        accountType={props.currentUser.accountType}
      />
      {props.currentUser.accountType !== 'dealer' && (
        <>
          <Header>Bulk Invoice Payments</Header>
          <DealerBillings
            billings={props.dealerBillings}
            getBilling={props.getBilling}
            removeBilling={props.removeBilling}
            isAdmin={props.isAdmin}
          />
        </>
      )}
      <br />
      {renderResults(
        props.dealerSaved,
        props.success,
        props.error,
        props.confirmDealerSaved,
        'Dealer Saved'
      )}
      {renderLoader(props.showLoading)}
      {renderDealerForm(props.showDealerModal)}
      {props.error.length > 0 && renderError(props.error, props.clearError)}
      {props.billingSaved &&
        renderResults(
          props.billingSaved,
          props.success,
          props.error,
          props.confirmBillingRemoved,
          'Billing Removed'
        )}
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    dealerData: state.dealers.dealerForm,
    dealerList: state.dealers.dealerList,
    dealerInvoices: state.dealers.dealerInvoices,
    dealerBillings: state.dealers.dealerBillings,
    showLoading: state.main.isLoading,
    error: state.main.errors,
    success: state.main.success,
    dealerSaved: state.dealers.dealerSaved,
    showDealerModal: state.dealers.showModal,
    isAdmin: state.users.isAdministrator,
    billingSaved: state.billings.billingSaved,
    invoiceRemoved: state.plates.invoiceRemoved,
    currentUser: state.users.currentUser,
  };
};

export default connect(mapStateToProps, {
  getInvoicesByDealer,
  getBillingsByDealer,
  confirmDealerSaved,
  showDealerForm,
  clearDealerBillings,
  clearDealerInvoices,
  getInvoice,
  removeInvoice,
  getDealerOutstandingInvoices,
  getBilling,
  removeBilling,
  clearError,
  confirmBillingRemoved,
  clearInvoiceRemoved,
  getDealerList,
})(DealerDetails);
