import React from 'react';
import { Grid } from 'semantic-ui-react';

const BillingTotals = (props) => {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={10}>&nbsp;</Grid.Column>
        <Grid.Column width={6}>
          <Grid>
            <Grid.Row textAlign='right'>
              <Grid.Column width={12}>
                <strong>Amount to Apply:</strong>
              </Grid.Column>
              <Grid.Column width={4}>
                ${parseFloat(props.amountToApply).toFixed(2)}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row textAlign='right'>
              <Grid.Column width={12}>
                <strong>Amount to Credit:</strong>
              </Grid.Column>
              <Grid.Column width={4}>
                ${parseFloat(props.amountToCredit).toFixed(2)}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default BillingTotals;
