import React from 'react';
import { Message } from 'semantic-ui-react';
import { connect } from 'react-redux';

const renderError = (msg) => {
    if (msg.length > 0) {
        return (
            <Message negative>
                {msg}
            </Message>
        );
    }

    // default return is nothing
    return null;
}

const ErrorMsg = (props) => {
    return(
        renderError(props.errors)
    );
};

const mapStateToProps = (state) => {
    return {
        errors: state.main.errors
    };
};

export default connect(mapStateToProps)(ErrorMsg);