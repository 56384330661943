import _ from 'lodash';
import { AES, enc } from 'crypto-js';
import main from '../apis/main';
import { accountTypeList } from '../components/StaticLists';
import {
  AUTH_USER,
  THROW_ERROR,
  CLEAR_ERROR,
  LOGOUT_USER,
  GET_USERS,
  FIND_USER,
  SAVE_USER,
  SHOW_USER_FORM,
  FORGOT_PASS_EMAIL_SENT,
  NEW_PASSWORD,
  START_PASSWORD_RESET,
  SHOW_SUCCESS,
  REFRESH_CURRENT_USER,
  CLEAR_SUCCESS,
  CLOSE_USER_SAVE_RESULT,
  CLEAR_USER_FORM,
  AUTH_2FA,
  ENCRYPT_KEY,
} from './types';
import { showLoading, sessionExpired } from './globalActions';

export const authUser = (values) => async (dispatch, getState) => {
  /**
   * Call Authentication Endpoint
   */
  dispatch(showLoading(true));
  // was a password sent?
  if (values.password === undefined) {
    // pull the password from state
    const { currentUser } = getState().users;
    const decryptPass = AES.decrypt(currentUser.currentPassword, ENCRYPT_KEY);
    console.log('Decrypted Password', decryptPass.toString(enc.Utf8));
    values.password = JSON.parse(decryptPass.toString(enc.Utf8));
  }
  let bodyFormData = new FormData();
  bodyFormData.append('username', values.username);
  bodyFormData.append('password', values.password);
  bodyFormData.append('send_code_by', values.sendCodeBy);
  bodyFormData.append('user_2fa_code', values.twoFactorCode);
  const response = await main.post('/api/auth', bodyFormData);
  if (200 === response.status) {
    const responseData = response.data;
    if (responseData.user_id !== undefined && responseData.user_id !== 0) {
      // successful login
      dispatch({ type: CLEAR_ERROR });

      /**
       * If there is not session id in the return, then the user
       * must enter a code sent to their phone to complete the login process
       */
      if (responseData.require_2fa) {
        // was this an incorrect code response?
        if ('Incorrect Code Sent' === responseData.message) {
          // throw an error
          dispatch({
            type: THROW_ERROR,
            payload: 'You entered the wrong code.  Please try again.',
          });
        } else {
          // the user must complete 2FA
          const encPass = AES.encrypt(
            JSON.stringify(values.password),
            ENCRYPT_KEY
          ).toString();
          dispatch({
            type: AUTH_2FA,
            payload: {
              userId: responseData.user_id,
              username: values.username,
              password: encPass,
              firstName: responseData.first_name,
              lastName: responseData.last_name,
              initials: responseData.initials,
              administrator: responseData.is_admin === '1' ? true : false,
              currentTwoFactorStep: 'NONE' === values.sendCodeBy ? 1 : 2,
              cellPhone: responseData.cell_phone,
              accountType: responseData.account_type,
              dealerId: responseData.dealer_id,
            },
          });
        }
      } else {
        // user is completely authenticated
        dispatch({
          type: AUTH_USER,
          payload: {
            userId: responseData.user_id,
            firstName: responseData.firstname,
            lastName: responseData.lastname,
            email: responseData.email,
            password: responseData.password,
            sessionId: responseData.session_token,
            initials: responseData.initials,
            administrator: responseData.is_admin === '1' ? true : false,
            accountType: responseData.account_type,
            dealerId: responseData.dealer_id,
          },
        });
        dispatch({ type: CLEAR_ERROR });
      }
    } else {
      // bad credentials
      dispatch({
        type: THROW_ERROR,
        payload: 'Your username or password is incorrect.  Please try again.',
      });
    }
  } else {
    // something went wrong
    dispatch({ type: THROW_ERROR, payload: response.statusText });
  }
  dispatch(showLoading(false));
};

export const logoutUser = () => {
  return {
    type: LOGOUT_USER,
    payload: { success: 'You have successfully logged out.', warning: '' },
  };
};

/**
 * Retrieves all users in the system
 */
export const getAllUsers = () => async (dispatch, getState) => {
  const { sessionId } = getState().users;
  const { isLoading } = getState().main;

  // only handle the loading action if nothing else has called it
  if (!isLoading) {
    dispatch(showLoading(true));
  }

  const response = await main.get(`/api/users/${sessionId}`);
  //console.log(response);
  if (200 === response.status) {
    const responseData = response.data;

    // check for session token error
    if (responseData.error !== undefined) {
      // the loading state does not change when
      // we bring it in, so isLoading will be false
      // if no other function set the loading state
      if (!isLoading) {
        dispatch(showLoading(false));
      }

      // what type of error?
      if (responseData.error === 'Invalid session token') {
        // the session token is invalid or expired
        dispatch(sessionExpired());
        return;
      }

      // throw an error
      dispatch({ type: THROW_ERROR, payload: responseData.error.text });
      return;
    }

    let userList = [];
    _.each(response.data, (values) => {
      const accountTypeName = _.find(accountTypeList, {
        value: values.account_type,
      });
      userList.push({
        userId: values.id,
        username: values.username,
        email: values.email,
        firstName: values.firstname,
        lastName: values.lastname,
        phone: values.phone_number,
        initials: values.initials,
        isAdministrator: values.is_admin === '1' ? true : false,
        cellPhone: values.cell_phone,
        accountType: accountTypeName !== undefined ? accountTypeName.text : '',
        dealerId: values.dealer_id,
        companyName: values.company_name,
      });
    });

    dispatch({ type: GET_USERS, payload: userList });
    dispatch({ type: CLEAR_ERROR });
  } else {
    // something went wrong
    dispatch({ type: THROW_ERROR, payload: response.statusText });
  }
  if (!isLoading) {
    dispatch(showLoading(false));
  }
};

/**
 * Retrieve a single user
 */
export const getUser =
  (id, showForm = false) =>
  async (dispatch, getState) => {
    dispatch(showLoading(true));
    const { sessionId } = getState().users;
    const response = await main.get(`/api/users/${id}/${sessionId}`);
    if (200 === response.status) {
      const responseData = response.data[0];

      // check for session token error
      if (responseData.error !== undefined) {
        dispatch(showLoading(false));
        // what type of error?
        if (responseData.error === 'Invalid session token') {
          // the session token is invalid or expired
          dispatch(sessionExpired());
          return;
        }

        // throw an error
        dispatch({ type: THROW_ERROR, payload: responseData.error.text });
        return;
      }

      dispatch({ type: FIND_USER, payload: responseData });
      dispatch({ type: SHOW_USER_FORM, payload: showForm });
      dispatch({ type: CLEAR_ERROR });
    } else {
      // something went wrong
      dispatch({ type: THROW_ERROR, payload: response.statusText });
    }
    dispatch(showLoading(false));
  };

/**
 * Save a user's info
 */
export const saveUser =
  (values, singleUser = false) =>
  async (dispatch, getState) => {
    dispatch(showLoading(true));
    const { sessionId } = getState().users;
    const { userForm } = getState().users;
    let response = {};

    // build the submission object
    let bodyFormData = new FormData();
    bodyFormData.append('username', values.username);
    bodyFormData.append('password', values.password);
    bodyFormData.append('email', values.email);
    bodyFormData.append('firstname', values.firstName);
    bodyFormData.append('lastname', values.lastName);
    bodyFormData.append('phone_number', values.phoneNumber);
    bodyFormData.append(
      'is_admin',
      'administrator' === values.accountType ? 1 : 0
    );
    bodyFormData.append('initials', values.initials);
    bodyFormData.append('cell_phone', values.cellPhone);
    bodyFormData.append('account_type', values.accountType);
    bodyFormData.append(
      'dealer_id',
      undefined === values.dealerId ? 0 : values.dealerId
    );
    // new user or an update?
    if (0 === userForm.userId) {
      // New User
      bodyFormData.append('session_token', sessionId);
      response = await main.post('/api/user/', bodyFormData);
    } else {
      // update existing
      response = await main.post(
        `/api/users/${userForm.userId}/${sessionId}`,
        bodyFormData
      );
    }
    if (200 === response.status || 201 === response.status) {
      const responseData = response.data;

      // check for session token error
      if (responseData.error !== undefined) {
        dispatch(showLoading(false));
        // what type of error?
        if (responseData.error === 'Invalid session token') {
          // the session token is invalid or expired
          dispatch(sessionExpired());
          return;
        }

        // throw an error
        dispatch({ type: THROW_ERROR, payload: responseData.error.text });
        return;
      }

      if (responseData.notice.text !== undefined) {
        // successful insertion or update
        /**
         * Update requires checking if the password changed
         */
        if (userForm.password !== values.password) {
          // password changed
          bodyFormData.append('user_id', userForm.userId);
          const passChangeResp = await main.post(
            `/api/reset_password/${sessionId}`,
            bodyFormData
          );
        }
        // update the user list only if from user management
        dispatch({
          type: SHOW_SUCCESS,
          payload: 'User information successfully saved.',
        });
        if (!singleUser) {
          dispatch({ type: SAVE_USER });
          dispatch({ type: CLEAR_USER_FORM });
          dispatch(getAllUsers());
        } else {
          // need to refresh the current user information
          dispatch({
            type: REFRESH_CURRENT_USER,
            payload: {
              userName: values.username,
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              phoneNumber: values.phoneNumber,
              initials: values.initials,
              password: values.password,
              cellPhone: values.cellPhone,
              accountType: values.accountType,
              dealerId: values.dealer_id,
            },
          });
        }
        dispatch(showUserForm(false));
        dispatch({ type: CLEAR_ERROR });
      } else {
        // problem saving
        dispatch({
          type: THROW_ERROR,
          payload: 'There was a problem saving the User.  Please try again.',
        });
      }
    } else {
      // something went wrong
      dispatch({ type: THROW_ERROR, payload: response.statusText });
    }
    dispatch(showLoading(false));
  };

/**
 * Determines whether to show the user form modal or not
 */
export const showUserForm =
  (show, newUser = false) =>
  async (dispatch) => {
    if (newUser) {
      dispatch({ type: CLEAR_USER_FORM });
    }
    dispatch({ type: SHOW_USER_FORM, payload: show });
  };

/**
 * Triggers the closing of the results modal
 */
export const closeSavedUserResult = () => async (dispatch) => {
  dispatch({ type: CLEAR_SUCCESS });
  dispatch({ type: CLEAR_ERROR });
  dispatch({ type: CLOSE_USER_SAVE_RESULT });
};

/**
 * Calls the endpoint to send the forgot password email
 */
export const forgotPassSendMsg = (email) => async (dispatch) => {
  dispatch(showLoading(true));
  let msg =
    'An Email has been sent to your email address with a link to reset your password.';

  let bodyFormData = new FormData();
  bodyFormData.append('email', email);
  const response = await main.post(`/api/user/reset_password`, bodyFormData);

  if (200 === response.status) {
    const responseData = response.data;
    if (responseData === 'User not found.') {
      msg = 'No user exists with the email address you entered.';
    }
    dispatch({ type: FORGOT_PASS_EMAIL_SENT, payload: msg });
  } else {
    // something went wrong
    dispatch({ type: THROW_ERROR, payload: response.statusText });
  }
  dispatch(showLoading(false));
};

/**
 * Remove a user from the system
 */
export const removeUser = (userId) => async (dispatch, getState) => {
  const { sessionId } = getState().users;

  const response = await main.delete(`/api/users/${userId}/${sessionId}`);

  if (200 === response.status) {
    // check for session token error
    if (response.data.error !== undefined) {
      // what type of error?
      if (response.data.error === 'Invalid session token') {
        // the session token is invalid or expired
        dispatch(sessionExpired());
        return;
      }

      // throw an error
      dispatch({ type: THROW_ERROR, payload: responseData.error.text });
      return;
    }

    // check that the deletion was successful
    if (response.data.Success) {
      // record deleted, refresh the list
      dispatch(getAllUsers());
      dispatch({ type: CLEAR_ERROR });
    }
  }
};

/**
 * Saves the userid and security token for a password reset
 */
export const startResetPassword = (securityToken) => async (dispatch) => {
  // check the validity of the security token
  const response = await main.get(`/api/check_reset_token/${securityToken}`);

  if (200 === response.status) {
    const responseData = response.data;

    dispatch({ type: START_PASSWORD_RESET, payload: responseData.user_id });
    dispatch({ type: CLEAR_ERROR });
  } else {
    // something went wrong
    dispatch({ type: THROW_ERROR, payload: response.statusText });
  }
};

/**
 * Reset a user's password
 */
export const resetPassword =
  (values, sessionId) => async (dispatch, getState) => {
    // get the user id and security token
    const { currentUser } = getState().users;

    let response = {};

    // build the submission object
    let bodyFormData = new FormData();
    bodyFormData.append('user_id', currentUser.userId);
    bodyFormData.append('password', values.password);
    response = await main.post(
      `/api/reset_password/${sessionId}`,
      bodyFormData
    );
    if (200 === response.status || 201 === response.status) {
      const responseData = response.data;

      if (responseData.notice.text !== undefined) {
        // successful update
        dispatch({ type: NEW_PASSWORD });
        dispatch({
          type: SHOW_SUCCESS,
          payload: 'Your password has been reset.',
        });
        dispatch({ type: CLEAR_ERROR });
      } else {
        // problem saving
        dispatch({
          type: THROW_ERROR,
          payload:
            'There was a problem saving your new password.  Please try again.',
        });
      }
    } else {
      // something went wrong
      dispatch({ type: THROW_ERROR, payload: response.statusText });
    }
  };
