import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Header, Grid, Input, Message } from 'semantic-ui-react';
import { useMediaQuery } from 'react-responsive';
import {
  BRM_CHARGE,
  DMV_CHARGE,
  OTHER_FEES,
  SALES_TAX,
} from './FormSetup/FieldNames';
import TotalsLine from './TotalsLine';
import { calculateCharges } from '../../actions';

const ChargesCosts = (props) => {
  /**
   * Responsive triggers
   */
  const isTabletOrMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Fragment>
      <Header>CHARGES &amp; COSTS</Header>
      <Grid stackable>
        <Grid.Row className='brm-form-row'>
          <Grid.Column
            width={4}
            verticalAlign='middle'
            className='brm-form-label'
          >
            BRM
          </Grid.Column>
          <Grid.Column width={12}>
            <Input
              tabIndex='23'
              type='text'
              name={BRM_CHARGE}
              value={
                props.values[BRM_CHARGE] ||
                parseFloat(props.initialValues[BRM_CHARGE]).toFixed(2)
              }
              className='brm-form-input brm-form-dollar-input'
              onChange={(e, { value }) => {
                props.handleChange;
                props.setFieldValue(BRM_CHARGE, value);
                props.calculateCharges(props.values, 'brmCharge', value);
              }}
              onBlur={props.handleBlur}
            />
            {props.errors[BRM_CHARGE] && props.touched[BRM_CHARGE] ? (
              <Message negative size='tiny'>
                {props.errors[BRM_CHARGE]}
              </Message>
            ) : null}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className='brm-form-row'>
          <Grid.Column
            width={4}
            verticalAlign='middle'
            className='brm-form-label'
          >
            DMV
          </Grid.Column>
          <Grid.Column width={12}>
            <Input
              tabIndex='24'
              type='text'
              name={DMV_CHARGE}
              value={
                props.values[DMV_CHARGE] ||
                parseFloat(props.initialValues[DMV_CHARGE]).toFixed(2)
              }
              className='brm-form-input brm-form-dollar-input'
              onChange={(e, { value }) => {
                props.handleChange;
                props.setFieldValue(DMV_CHARGE, value);
                props.calculateCharges(props.values, 'dmvCharge', value);
              }}
              onBlur={props.handleBlur}
            />
            {props.errors[DMV_CHARGE] && props.touched[DMV_CHARGE] ? (
              <Message negative size='tiny'>
                {props.errors[DMV_CHARGE]}
              </Message>
            ) : null}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className='brm-form-row'>
          <Grid.Column
            width={4}
            verticalAlign='middle'
            className='brm-form-label'
          >
            Other
          </Grid.Column>
          <Grid.Column width={12}>
            <Input
              tabIndex='25'
              type='text'
              name={OTHER_FEES}
              value={
                props.values[OTHER_FEES] ||
                parseFloat(props.initialValues[OTHER_FEES]).toFixed(2)
              }
              className='brm-form-input brm-form-dollar-input'
              onChange={(e, { value }) => {
                props.handleChange;
                props.setFieldValue(OTHER_FEES, value);
                props.calculateCharges(props.values, 'otherFees', value);
              }}
              onBlur={props.handleBlur}
            />
            {props.errors[OTHER_FEES] && props.touched[OTHER_FEES] ? (
              <Message negative size='tiny'>
                {props.errors[OTHER_FEES]}
              </Message>
            ) : null}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className='brm-form-row'>
          <Grid.Column
            width={4}
            verticalAlign='middle'
            className='brm-form-label'
          >
            Sales Tax
          </Grid.Column>
          <Grid.Column width={12}>
            <Input
              tabIndex='26'
              type='text'
              name={SALES_TAX}
              value={
                props.values[SALES_TAX] ||
                parseFloat(props.initialValues[SALES_TAX]).toFixed(2)
              }
              className='brm-form-input brm-form-dollar-input'
              onChange={(e, { value }) => {
                props.handleChange;
                props.setFieldValue(SALES_TAX, value);
                props.calculateCharges(props.values, 'salesTax', value);
              }}
              onBlur={props.handleBlur}
            />
            {props.errors[SALES_TAX] && props.touched[SALES_TAX] ? (
              <Message negative size='tiny'>
                {props.errors[SALES_TAX]}
              </Message>
            ) : null}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className='charges-line'>
          <Grid.Column>
            <hr />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className='charges-line'>
          <TotalsLine
            isTabletOrMobile={isTabletOrMobile}
            isAdministrator={props.isAdministrator}
            chargeTotal={props.chargeTotal}
            netProfit={props.netProfit}
            netProfitPercent={props.percentageProfit}
          />
        </Grid.Row>
      </Grid>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    chargeTotal: state.plates.txnForm.chargeTotal,
    isAdministrator: state.users.isAdministrator,
    netProfit: state.plates.totalNetProfit,
    percentageProfit: state.plates.percentageProfit,
  };
};

export default connect(mapStateToProps, { calculateCharges })(ChargesCosts);
