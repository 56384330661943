import * as yup from 'yup';

import { EXPENSE_AMOUNT, EXPENSE_DESCRIPTION } from './FieldNames';

const REQUIRED_MESSAGE = 'This field is required';
const INVALID_DOLLAR_AMOUNT = 'Must be a valid dollar amount';

export default yup.object({
  [EXPENSE_AMOUNT]: yup
    .number()
    .test('is-decimal', INVALID_DOLLAR_AMOUNT, (value) =>
      (value + '').match(/^(\d*\.)?\d+$/)
    ),
  [EXPENSE_DESCRIPTION]: yup.string().required(REQUIRED_MESSAGE),
});
