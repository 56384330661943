import React from 'react';
import { Message, Modal, Button } from 'semantic-ui-react';

const ErrorModal = (props) => {
  return (
    <Modal open size='large' closeOnEscape={false} closeOnDimmerClick={false}>
      <Modal.Header>{props.title}</Modal.Header>
      <Modal.Content>
        <Message negative>{props.error}</Message>
      </Modal.Content>
      <Modal.Actions>
        <Button
          type='button'
          className='decline-button'
          onClick={() => props.closeFunction()}
        >
          CLOSE
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ErrorModal;
