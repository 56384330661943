import * as yup from 'yup';

import {
    CODE,
    NAME,
    HIDE_FROM_LIST
} from './FieldNames';

const REQUIRED_MESSAGE = 'This field is required';

export default yup.object({
    [NAME]: yup.string().required(REQUIRED_MESSAGE)
});