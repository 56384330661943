import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { AES, enc } from 'crypto-js';
import { useNavigate } from 'react-router-dom';
import { Segment, Header, Grid } from 'semantic-ui-react';
import { authUser, getDealer } from '../../actions';
import ErrorMsg from '../ErrorMsg';
import TwoFAStep1 from './TwoFAStep1';
import TwoFAStep2 from './TwoFAStep2';
import LoadingComp from '../LoadingComp';
import { ENCRYPT_KEY } from '../../actions/types';

/**
 * Decides which message to show
 */
const renderMessage = (props) => {
  if (props.error.length > 0) {
    return <ErrorMsg />;
  }

  return null;
};

const renderLoader = (show, message) => {
  if (show) {
    return <LoadingComp msg={message} />;
  }
  return null;
};

const TwoFactorAuth = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    async function redirectToPage() {
      console.log('Login Props', props);
      if (props.sessionId.length > 0) {
        if ('dealer' === props.userInfo.accountType) {
          await props.getDealer(props.userInfo.dealerId);
          navigate('/dealer-details');
        } else {
          navigate('/dashboard');
        }
      }
    }
    redirectToPage();
  }, [props.sessionId]);

  return (
    <Grid
      textAlign='center'
      style={{ height: '100vh', backgroundColor: '#F1F5F8' }}
      verticalAlign='middle'
    >
      <Grid.Column style={{ maxWidth: 450 }}>
        <Segment stacked>
          <Header>Access Code Required</Header>
          {renderMessage(props)}
          {1 === props.currentStep && (
            <TwoFAStep1
              username={props.userInfo.username}
              authUser={props.authUser}
              userCellPhone={props.userInfo.cellPhone}
            />
          )}
          {2 === props.currentStep && (
            <TwoFAStep2
              username={props.userInfo.username}
              authUser={props.authUser}
            />
          )}
        </Segment>
      </Grid.Column>
      {renderLoader(props.showLoading, 'Please Wait...')}
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    error: state.main.errors,
    userInfo: state.users.currentUser,
    currentStep: state.users.currentTwoFactorAuthStep,
    showLoading: state.main.isLoading,
    sessionId: state.users.sessionId,
  };
};

export default connect(mapStateToProps, { authUser, getDealer })(TwoFactorAuth);
