import React, { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, Header } from 'semantic-ui-react';

/**
 * Dropdown of available dealers for components that need it.
 * Cannot add, remove or edit dealers in this list.
 */
const DealerDropdownList = (props) => {
  // grab the dealer list from the state object
  const { dealerList } = useSelector((state) => state.dealers);

  // create the list of dealers
  let dealerOptions = [];
  _.each(dealerList, (values) => {
    dealerOptions.push({
      key: values.dealerId,
      value: values.dealerId,
      text: values.companyName,
    });
  });

  return (
    <Fragment>
      <Dropdown
        placeholder='Select Dealer'
        className='brm-form-dropdown'
        value={props.dealerId}
        name='dealer'
        search
        selection
        selectOnBlur={true}
        options={dealerOptions}
        onChange={(e, { value }) => {
          if (value > 0) {
            props.setSelectedDealerId(value);
          }
        }}
      />
    </Fragment>
  );
};

export default DealerDropdownList;
