/**
 * Component for creating checkboxes in React Table rows.
 * This componet was part of an example provided by the
 * react-tables documentation found here:
 * https://react-table.tanstack.com/docs/examples/row-selection-and-pagination
 */
import React, { useEffect, useRef, Fragment, forwardRef } from 'react';
import { Checkbox } from 'semantic-ui-react';

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <Fragment>
      <Checkbox style={{ marginTop: '5px' }} ref={resolvedRef} {...rest} />
    </Fragment>
  );
});

export default IndeterminateCheckbox;
