import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer, createMigrate } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { PersistGate } from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage';
import reduxThunk from 'redux-thunk';
import 'semantic-ui-css/semantic.min.css';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import App from './components/App';
import reducers from './reducers';

const migrations = {
  2: (state) => {
    // migration to add new user fields
    return {
      ...state,
      currentUser: {
        ...state.currentUser,
        username: '',
        phoneNumber: '',
      },
      showUserModal: false,
      forgotEmailMsg: false,
      passwordChanged: false,
      userFormCopy: undefined,
    };
  },
  3: (state) => {
    return {
      ...state,
      forTesting: 1,
    };
  },
  4: (state) => {
    return {
      ...state,
      isAdministrator: false,
      forTesting: undefined,
    };
  },
  5: (state) => {
    return {
      ...state,
      userForm: {
        ...state.userForm,
        isAdministrator: false,
      },
    };
  },
  6: (state) => {
    return {
      ...state,
      userForm: {
        ...state.userForm,
        initials: '',
      },
      currentUser: {
        ...state.currentUser,
        initials: '',
      },
    };
  },
  7: (state) => {
    return {
      ...state,
      previousDealer: 0,
      newDealer: 0,
      addDealerCancelled: false,
    };
  },
  8: (state) => {
    return {
      ...state,
      userSaved: false,
    };
  },
  9: (state) => {
    return {
      ...state,
      pdfMultiInvoiceFileName: '',
      pdfInvoiceFileName: '',
      showMultiInvoicePrint: false,
      totalSelectedInvoices: 0,
    };
  },
  10: (state) => {
    return {
      ...state,
      txnForm: {
        ...state.txnForm,
        combinedInvoiceId: 0,
        paidFromCombinedInvoice: 0,
      },
    };
  },
  11: (state) => {
    return {
      ...state,
      currentUser: {
        ...state.currentUser,
        cellPhone: '',
      },
      userForm: {
        ...state.userForm,
        cellPhone: '',
      },
      txnForm: {
        ...state.txnForm,
        otherFees: 0.0,
      },
    };
  },
  12: (state) => {
    return {
      ...state,
      currentInvoiceListFilters: {
        startDate: '',
        endDate: '',
        status: 'all',
        hasBalance: 'all',
        dealer: 'all',
        checkNumber: '',
        ignoreDates: false,
        fuzzy: '',
      },
      txnForm: {
        ...state.txnForm,
        companyName: '',
        createdByInitials: '',
      },
      currentTwoFactorAuthStep: 0,
    };
  },
  13: (state) => {
    return {
      ...state,
      currentInvoiceListFilters: {
        ...state.currentInvoiceListFilters,
        invoiceNumber: '',
      },
    };
  },
  15: (state) => {
    return {
      ...state,
      listTotalFees: 0.0,
    };
  },
  16: (state) => {
    return {
      ...state,
      currentInvoiceListPageIndex: 0,
      currentInvoiceListPageSize: 10,
    };
  },
  17: (state) => {
    return {
      ...state,
      currentInvoiceListFilters: {
        ...state.currentInvoiceListFilters,
        dateSearchBy: 'created',
      },
    };
  },
  18: (state) => {
    return {
      ...state,
      currentUser: {
        ...state.currentUser,
        accountType: '',
        dealerId: 0,
      },
    };
  },
};

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'financialTxns',
    'notes',
    'combinedinvoices',
    'reports',
    'plates',
    'billings',
    'expenses',
    'stateFees',
  ],
  stateReconciler: autoMergeLevel2,
  version: 18,
  migrate: createMigrate(migrations, { debug: false }),
};

const persistedReducer = persistReducer(persistConfig, reducers);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(reduxThunk))
);
//const store = createStore(reducers, composeEnhancers(applyMiddleware(reduxThunk)));
const persistor = persistStore(store);

const root = document.getElementById('root');
const rootDOM = createRoot(root);

rootDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);
