import React, { useState, Fragment } from 'react';
import { Grid, Dropdown, Input, Icon, Segment } from 'semantic-ui-react';
import DatePicker from 'react-date-picker';
import InputMask from 'react-input-mask';

const BillingPaymentForm = (props) => {
  // destructure the props
  const {
    paymentDate,
    setPaymentDate,
    paymentMethod,
    setPaymentMethod,
    paymentRefNumber,
    setPaymentRefNumber,
    amountReceived,
    setCurrentAmountReceived,
    ccPaymentInfo,
    setCCPaymentInfo,
    achPaymentInfo,
    setACHPaymentInfo,
    setAlertMessage,
    setShowAlert,
  } = props;

  // list of payment types for the payment method dropdown
  const paymentTypes = [
    {
      key: 'cash',
      value: 'Cash',
      text: 'Cash',
    },
    {
      key: 'ckToBRM',
      value: 'CK To BRM',
      text: 'Check',
    },
    {
      key: 'creditCard',
      value: 'Credit Card',
      text: 'Credit Card',
    },
  ];

  return (
    <Grid stackable>
      <Grid.Column width={16} className='brm-form-label'>
        Payment Date
        <br />
        <DatePicker
          value={paymentDate}
          selected={paymentDate}
          onChange={(date) => {
            setPaymentDate(date);
          }}
          calendarIcon={<Icon name='calendar alternate outline' />}
          clearIcon={null}
        />
      </Grid.Column>
      <Grid.Column width={4} className='brm-form-label'>
        Payment Method
        <br />
        <Dropdown
          name='paymentMethod'
          defaultValue={paymentMethod}
          className='brm-form-dropdown'
          selection
          selectOnBlur={true}
          options={paymentTypes}
          onChange={(e, { value }) => setPaymentMethod(value)}
        />
      </Grid.Column>
      <Grid.Column width={4} className='brm-form-label'>
        {'CK To BRM' === paymentMethod && 'Check #'}
        {'CK To BRM' !== paymentMethod && 'Reference Number'}
        <br />
        <Input
          type='text'
          name='referenceNumber'
          defaultValue={paymentRefNumber}
          className='brm-form-input'
          onChange={(e) => setPaymentRefNumber(e.target.value)}
        />
      </Grid.Column>
      <Grid.Column width={8} textAlign='right' className='brm-form-label'>
        Amount Received
        <br />
        <Input
          type='text'
          name='amountReceived'
          value={amountReceived}
          className='brm-form-input brm-form-dollar-input'
          onChange={(e) => {
            // reject negative amounts
            const receivedAmount = parseFloat(e.target.value);
            if (receivedAmount < 0) {
              setAlertMessage('Negative amounts are not allowed');
              setShowAlert(true);
            } else {
              // set the amount as normal
              setCurrentAmountReceived(e.target.value);
            }
          }}
        />
      </Grid.Column>
      {paymentMethod === 'Credit Card' && (
        <Fragment>
          <Grid.Column width={16}>
            <strong>
              Use the Fields Below to Process the Payment through CardKnox
            </strong>
          </Grid.Column>
          <Grid.Column width={4} className='brm-form-label'>
            Name as it Appears on the Card
            <br />
            <Input
              type='text'
              name='nameOnCard'
              defaultValue={ccPaymentInfo.nameOnCard}
              className='brm-form-input'
              onChange={(e) =>
                setCCPaymentInfo({
                  ...ccPaymentInfo,
                  nameOnCard: e.target.value,
                })
              }
            />
          </Grid.Column>
          <Grid.Column width={4} className='brm-form-label'>
            Card Number
            <br />
            <Input
              type='text'
              name='ccNumber'
              defaultValue={ccPaymentInfo.ccNumber}
              className='brm-form-input'
              onChange={(e) =>
                setCCPaymentInfo({
                  ...ccPaymentInfo,
                  ccNumber: e.target.value,
                })
              }
            />
          </Grid.Column>
          <Grid.Column width={4} className='brm-form-label'>
            Expiration Date
            <br />
            <InputMask
              mask='99/99'
              onChange={(e) =>
                setCCPaymentInfo({
                  ...ccPaymentInfo,
                  expireDate: e.target.value,
                })
              }
              value={ccPaymentInfo.expireDate}
            >
              {(inputProps) => (
                <Input
                  {...inputProps}
                  type='text'
                  name='expireDate'
                  className='brm-form-input'
                />
              )}
            </InputMask>
          </Grid.Column>
          <Grid.Column width={4} className='brm-form-label'>
            CVC Number
            <br />
            <Input
              type='text'
              name='cvc'
              defaultValue={ccPaymentInfo.cvc}
              className='brm-form-input'
              onChange={(e) =>
                setCCPaymentInfo({
                  ...ccPaymentInfo,
                  cvc: e.target.value,
                })
              }
            />
          </Grid.Column>
        </Fragment>
      )}
      {paymentMethod === 'NOTUSEDATALL' && (
        <Fragment>
          <Grid.Column width={16}>
            <strong>
              Use the Fields Below to Process the Payment through CardKnox
            </strong>
          </Grid.Column>
          <Grid.Column width={4} className='brm-form-label'>
            Name on Account
            <br />
            <Input
              type='text'
              name='nameOnAccount'
              defaultValue={achPaymentInfo.nameOnAccount}
              className='brm-form-input'
              onChange={(e) =>
                setACHPaymentInfo({
                  ...achPaymentInfo,
                  nameOnAccount: e.target.value,
                })
              }
            />
          </Grid.Column>
          <Grid.Column width={4} className='brm-form-label'>
            Routing Number
            <br />
            <Input
              type='text'
              name='routingNumber'
              defaultValue={achPaymentInfo.routingNumber}
              className='brm-form-input'
              onChange={(e) =>
                setACHPaymentInfo({
                  ...achPaymentInfo,
                  routingNumber: e.target.value,
                })
              }
            />
          </Grid.Column>
          <Grid.Column width={8} className='brm-form-label'>
            Account Number
            <br />
            <Input
              type='text'
              name='accountNumber'
              defaultValue={achPaymentInfo.accountNumber}
              className='brm-form-input'
              onChange={(e) =>
                setACHPaymentInfo({
                  ...achPaymentInfo,
                  accountNumber: e.target.value,
                })
              }
            />
          </Grid.Column>
        </Fragment>
      )}
    </Grid>
  );
};

export default BillingPaymentForm;
