import React from 'react';
import { Grid, Input } from 'semantic-ui-react';

const PaymentAmountSearch = (props) => {
  return (
    <Grid>
      <Grid.Column width={8}>
        <span className='brm-filter-header'>Min./Exact Amount</span> <br />
        <Input
          type='text'
          name='paymentAmountLow'
          className='brm-form-input'
          style={{ width: '150px' }}
          value={props.paymentAmountFilter.paymentAmountLow}
          onChange={(e, { value }) =>
            props.setPaymentAmountFilter({
              ...props.paymentAmountFilter,
              paymentAmountLow: value,
            })
          }
        />
      </Grid.Column>
      <Grid.Column width={8}>
        <span className='brm-filter-header'>Max. Amount</span> <br />
        <Input
          type='text'
          name='paymentAmountHigh'
          className='brm-form-input'
          style={{ width: '150px' }}
          value={props.paymentAmountFilter.paymentAmountHigh}
          onChange={(e, { value }) =>
            props.setPaymentAmountFilter({
              ...props.paymentAmountFilter,
              paymentAmountHigh: value,
            })
          }
        />
      </Grid.Column>
    </Grid>
  );
};

export default PaymentAmountSearch;
