import {
  ADD_NEW_LIEN_HOLDER,
  FIND_LIEN_HOLDER,
  GET_LIEN_HOLDERS,
  SAVE_LIEN_HOLDER,
  SHOW_LIEN_HOLDER_FORM,
  CLEAR_INVOICE,
  LOGOUT_USER,
  LIEN_HOLDER_DELETED,
} from '../actions/types';

const INITIAL_STATE = {
  lienHolderForm: {
    lienHolderId: -1,
    code: '',
    name: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    hideFromList: '0',
  },
  lienHolderList: [],
  showLienModal: false,
};

const lienHolderState = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_LIEN_HOLDERS:
      return { ...state, lienHolderList: action.payload };
    case SHOW_LIEN_HOLDER_FORM:
      return { ...state, showLienModal: action.payload };
    case FIND_LIEN_HOLDER:
      return {
        ...state,
        lienHolderForm: {
          lienHolderId: action.payload.id,
          code: action.payload.code,
          name: action.payload.name,
          address: action.payload.address,
          city: action.payload.city,
          state: action.payload.state,
          zipCode: action.payload.zip,
          hideFromList: action.payload.hide_from_list,
        },
      };
    case ADD_NEW_LIEN_HOLDER:
    case CLEAR_INVOICE:
    case LIEN_HOLDER_DELETED:
      return {
        ...state,
        lienHolderForm: {
          lienHolderId: -1,
          code: '',
          name: '',
          address: '',
          city: '',
          state: '',
          zipCode: '',
          hideFromList: '0',
        },
      };
    case LOGOUT_USER:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default lienHolderState;
