import { combineReducers } from 'redux';
import userState from './users';
import plateState from './plates';
import dealerState from './dealers';
import lienHolderState from './lienholders';
import financialTxnState from './financialtxns';
import noteState from './notes';
import mainState from './main';
import reportState from './reports';
import combinedInvoicesState from './combinedinvoices';
import billingsState from './billings';
import expensesState from './expenses';
import stateFeesState from './statefees';

export default combineReducers({
  main: mainState,
  users: userState,
  plates: plateState,
  dealers: dealerState,
  lienholders: lienHolderState,
  financialTxns: financialTxnState,
  notes: noteState,
  reports: reportState,
  combinedinvoices: combinedInvoicesState,
  billings: billingsState,
  expenses: expensesState,
  stateFees: stateFeesState,
});
