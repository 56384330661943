import {
    START_NOTE,
    SAVE_NOTE,
    SHOW_NOTES_FORM,
    LOGOUT_USER
} from '../actions/types';

const INITIAL_STATE = {
	notesForm: {
		noteId: 0,
		note: '',
		forInvoice: false,
		parentType: '',
        parentId: 0
	},
	notesFormCopy: {
		noteId: 0,
		note: '',
		forInvoice: false,
		parentType: '',
        parentId: 0    
    },
    showNotesModal: false,
    internal: 0
};

const noteState = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case START_NOTE:
            return { 
                    ...state,
                    internal: action.payload.isInternal
                };
        case SAVE_NOTE:
            return { ...state,
                    notesForm: {
                        noteId: 0,
                        note: '',
                        forInvoice: false,
                        parentType: '',
                        parentId: 0,
                        internal: 0
                    },
                    showNotesModal: false
                };
        case SHOW_NOTES_FORM:
            return { ...state, showNotesModal: action.payload };
        case LOGOUT_USER:
            return INITIAL_STATE;
        default:
            return state;
    }
};

export default noteState;