import _ from 'lodash';
import main from '../apis/main';
import moment from 'moment';
import {
  SET_INVOICE_EXPENSES,
  SET_EXPENSE,
  SAVE_EXPENSE,
  REMOVE_EXPENSE,
  UPDATE_EXPENSE,
  SHOW_EXPENSE_FORM,
  CLEAR_EXPENSE_FORM,
  CALCULATE_EXPENSES,
} from './types';
import { proccessAPIErrors, showLoading } from './globalActions';
import { calculateTotalProfit } from './plateActions';

/**
 * Retrieves all expenses that are associated with an individual plate transaction (invoice)
 *
 * @param {int} plateTxnId the record id of the plate transaction (invoice)
 * @returns
 */
export const getPlateTransactionExpenses =
  (plateTxnId) => async (dispatch, getState) => {
    const { sessionId } = getState().users;

    const response = await main.get(
      `/api/expenses/filter/${plateTxnId}/${sessionId}`
    );

    // make sure the response is good
    if (200 === response.status) {
      // successful retrieval of the expenses, compile the results and
      // save to the state object
      let plateTxnExpenses = [];
      _.each(response.data, (values) => {
        plateTxnExpenses.push({
          id: parseInt(values.id),
          dateEntered: moment(values.date_entered).format('MM/DD/YYYY'),
          dateModified: moment(values.date_modified).format('MM/DD/YYYY'),
          addedBy: values.added_by_name,
          modifiedBy: values.modified_by_name,
          description: values.description,
          amount: values.amount,
          deleted: parseInt(values.deleted),
          plateTxnId: values.plate_txn_id,
          hidden: parseInt(values.hidden),
        });
      });

      // set the expenses associated with the invoice
      dispatch({ type: SET_INVOICE_EXPENSES, payload: plateTxnExpenses });
    } else {
      // there was a problem, so dispatch the error handler
      dispatch(
        proccessAPIErrors({
          statusCode: response.status,
          errorMsg: response.data.error,
        })
      );
    }
  };

/**
 * Populates the form state so an expense can be edited.
 *
 * @param {int} expenseId the record id of the expense to edit
 * @returns
 */
export const setExpenseToEdit = (expenseId) => async (dispatch, getState) => {
  // retrieve the list of expenses
  const { expensesList } = getState().expenses;

  // find the expense to edit from the list of expenses
  const expenseToEdit = _.find(expensesList, { id: expenseId });

  dispatch({
    type: SET_EXPENSE,
    payload: {
      id: expenseId,
      description: expenseToEdit.description,
      amount: expenseToEdit.amount,
      hidden: expenseToEdit.hidden,
    },
  });

  // open the edit form
  dispatch({ type: SHOW_EXPENSE_FORM, payload: true });
};

/**
 * Adds a brand new expense for a plate transaction.  It is possible
 * to save a new expense before a plate transaction is saved, so those
 * expenses will have a plate transaction id of zero (0)
 *
 * @param {object} values the data submitted from the expenses form or from a state fee selection
 * @returns
 */
export const addNewExpense = (values) => async (dispatch, getState) => {
  // trigger the loading screen if this is being saved from the expenses form
  if (values.notSentFromForm === undefined) {
    dispatch(showLoading(true));
  }

  // retrieve the currently active session key
  const { currentUser, sessionId } = getState().users;

  // retrieve the current plate transaction (invoice) id
  const { txnForm } = getState().plates;

  // set the values sent to the form data that will be sent to the endpoint
  let bodyFormData = new FormData();
  bodyFormData.append('session_token', sessionId);
  bodyFormData.append('user_id', currentUser.userId);
  bodyFormData.append('description', values.expenseDescription);
  bodyFormData.append('amount', values.expenseAmount);
  bodyFormData.append(
    'plate_txn_id',
    values.txnId !== undefined ? values.txnId : txnForm.txnId
  );
  bodyFormData.append('hidden', values.expenseHidden);

  try {
    const response = await main.post('/api/expenses', bodyFormData);

    dispatch({
      type: SAVE_EXPENSE,
      payload: {
        expenseId: parseInt(response.data.notice.id),
        dateEntered: moment().format('MM/DD/YYYY'),
        dateModified: moment().format('MM/DD/YYYY'),
        addedBy: currentUser.initials,
        modifiedBy: currentUser.initials,
        description: values.expenseDescription,
        amount: values.expenseAmount,
        plateTxnId: values.txnId !== undefined ? values.txnId : txnForm.txnId,
        hidden: values.expenseHidden,
      },
    });

    // no errors mean that we can close the expense form
    dispatch(showExpenseForm(false));
  } catch (e) {
    // there was a problem, so dispatch the error handler
    //console.log('Error', e.response);
    dispatch(
      proccessAPIErrors({
        statusCode: e.response.status,
        errorMsg: e.response.data.error.text,
      })
    );
  }

  // update the total expenses
  dispatch(calculateExpenses());

  // hide the loading screen, if necessary
  if (values.notSentFromForm === undefined) {
    dispatch(showLoading(false));
  }
};

/**
 * Update an individual expense
 *
 * @param {object} expenseDetails contains the values to update
 * @returns
 */
export const updateExpense = (expenseDetails) => async (dispatch, getState) => {
  // trigger the loading screen only if the form is calling this function (means the expenseDetails object was not sent)
  if (!expenseDetails.hasOwnProperty('notSentFromForm')) {
    dispatch(showLoading(true));
  }

  // retrieve the currently active session key
  const { currentUser, sessionId } = getState().users;

  // retrieve the current form values
  const { expensesForm } = getState().expenses;

  // retrieve the current plate transaction (invoice) id
  const { txnForm } = getState().plates;

  // save which value holds the expense id to update
  const expenseId =
    expensesForm.expenseId > 0
      ? expensesForm.expenseId
      : expenseDetails.expenseId;

  //console.log('Expense To Update', expenseDetails);

  // it is possible that the update could come from an unsaved invoice, if so, then
  // need to send zero (0) as the plate transaction id (invoice id)
  let plateTxnId = txnForm.txnId > 0 ? txnForm.txnId : expenseDetails.txnId;
  if (plateTxnId === undefined) {
    plateTxnId = 0;
  }

  // set the values sent to the form data that will be sent to the endpoint
  let bodyFormData = new FormData();
  bodyFormData.append('user_id', currentUser.userId);
  bodyFormData.append('description', expenseDetails.expenseDescription);
  bodyFormData.append('amount', expenseDetails.expenseAmount);
  bodyFormData.append('plate_txn_id', plateTxnId);
  bodyFormData.append('deleted', 0);
  bodyFormData.append('hidden', expenseDetails.expenseHidden);

  try {
    const response = await main.post(
      `/api/expenses/${expenseId}/${sessionId}`,
      bodyFormData
    );
    //console.log('Response', response);
    //console.log('Dispatch Action for: ', expenseDetails.expenseId);
    dispatch({
      type: UPDATE_EXPENSE,
      payload: {
        expenseId: expenseId,
        dateModified: moment().format('MM/DD/YYYY'),
        modifiedBy: currentUser.initials,
        description: expenseDetails.expenseDescription,
        amount: expenseDetails.expenseAmount,
        plateTxnId: plateTxnId,
      },
    });

    // no errors mean that we can close the expense form
    dispatch(showExpenseForm(false));
  } catch (e) {
    // there was a problem, so dispatch the error handler
    dispatch(
      proccessAPIErrors({
        statusCode: e.response.status,
        errorMsg: e.response.data.error.text,
      })
    );
  }

  // update the total expenses
  dispatch(calculateExpenses());

  // hide the loading screen, if needed
  if (!expenseDetails.hasOwnProperty('notSentFromForm')) {
    dispatch(showLoading(false));
  }
};

/**
 * Mark an expense as deleted
 *
 * @param {int} expenseId the record id of the expense to be removed
 * @returns
 */
export const removeExpense = (expenseId) => async (dispatch, getState) => {
  // trigger the loading screen
  dispatch(showLoading(true));

  // retrieve the currently active session key
  const { currentUser, sessionId } = getState().users;

  try {
    const response = await main.delete(
      `/api/expenses/${expenseId}/${currentUser.userId}/${sessionId}`
    );

    dispatch({
      type: REMOVE_EXPENSE,
      payload: expenseId,
    });
  } catch (e) {
    // there was a problem, so dispatch the error handler
    dispatch(
      proccessAPIErrors({
        statusCode: e.response.status,
        errorMsg: e.response.data.error.text,
      })
    );
  }

  // update the total expenses
  dispatch(calculateExpenses());

  // hide the loading screen
  dispatch(showLoading(false));
};

/**
 * Used for when a plate transaction (invoice) is saved.  Adds
 * the new plate transaction id to any expenses that were added.
 *
 * @param {int} plateTxnId the record id of the saved plate transaction (invoice)
 * @returns
 */
export const saveExpenses = (plateTxnId) => async (dispatch, getState) => {
  // trigger the loading screen
  dispatch(showLoading(true));

  // retrieve the currently active session key
  const { currentUser, sessionId } = getState().users;

  // retrieve all new expenses added to the invoice
  const { expensesList } = getState().expenses;
  const newExpenses = _.filter(expensesList, { plateTxnId: 0 });

  // go through each new expense and send an update to the api to
  // save the plate transaction (invoice) they belong to
  for (const expense of newExpenses) {
    // set the values sent to the form data that will be sent to the endpoint
    let bodyFormData = new FormData();
    bodyFormData.append('user_id', currentUser.userId);
    bodyFormData.append('description', expense.description);
    bodyFormData.append('amount', expense.amount);
    bodyFormData.append('deleted', 0);
    bodyFormData.append('plate_txn_id', plateTxnId);
    bodyFormData.append('hidden', expense.hidden);

    try {
      const response = await main.post(
        `/api/expenses/${expense.id}/${sessionId}`,
        bodyFormData
      );
      dispatch({
        type: UPDATE_EXPENSE,
        payload: {
          expenseId: expense.id,
          dateModified: moment().format('MM/DD/YYYY'),
          modifiedBy: currentUser.initials,
          description: expense.description,
          amount: expense.amount,
          plateTxnId: plateTxnId,
        },
      });
    } catch (e) {
      // there was a problem, so dispatch the error handler
      dispatch(
        proccessAPIErrors({
          statusCode: e.response.status,
          errorMsg: e.response.data.error.text,
        })
      );
    }
  }

  // hide the loading screen
  dispatch(showLoading(false));
};

/**
 * Triggers whether to show the expense form or not
 *
 * @param {bool} show true if showing the form, false otherwise
 * @returns redux action type SHOW_EXPENSE_FORM
 */
export const showExpenseForm = (show) => async (dispatch) => {
  dispatch({ type: SHOW_EXPENSE_FORM, payload: show });
  // clear the form if we are closing the expense form
  dispatch({ type: CLEAR_EXPENSE_FORM });
};

/**
 * Adds or updates a state fee expense for an invoice.
 *
 * @param {string} stateName the state deal name to lookup the fee for
 * @param {int} plateTxnId the plate transaction (invoice) id that the expense will attach to
 * @returns
 */
export const addUpdateStateFeeExpense =
  (stateName, plateTxnId) => async (dispatch, getState) => {
    //console.log('State', stateName);
    //console.log('Invoice Id', plateTxnId);
    // grab the state fee
    const { stateFeeList } = getState().stateFees;
    const stateFeeDetails = _.find(
      stateFeeList,
      (value) => value.stateName === stateName
    );

    // build the object to be sent for the add or update
    let expenseObj = {
      expenseId: 0,
      expenseDescription: 'State Fee - ' + stateFeeDetails.stateAbbreviation,
      expenseAmount: stateFeeDetails.amount,
      txnId: plateTxnId,
      expenseHidden: 1,
      notSentFromForm: true,
    };

    // look for an existing state fee expense in the expenses list
    const { expensesList } = getState().expenses;

    // does the state fee expense exist?
    const stateFeeExpense = _.find(expensesList, (value) =>
      value.description.includes('State Fee')
    );
    //console.log('State Fee Expense Found', stateFeeExpense);

    if (stateFeeExpense !== undefined) {
      // yes: send an update with the expense id and the new expense amount
      expenseObj.expenseId = stateFeeExpense.id;
      //console.log('Expense Object to Send', expenseObj);
      dispatch(updateExpense(expenseObj));
    } else {
      // no: create a new expense
      dispatch(addNewExpense(expenseObj));
    }
  };

/**
 * Calculates the total expenses associated with an invoice.
 *
 * @returns
 */
export const calculateExpenses = () => async (dispatch, getState) => {
  // retrieve all expenses
  const { expensesList } = getState().expenses;

  // need to check if the ucrrent user is an administrator as this will
  // determine if hidden fees should be included in the calculation
  const { isAdministrator } = getState().users;

  // go through each expense in the list and calculate the total from the amounts
  let totalExpenses = 0.0;
  _.forEach(expensesList, (value) => {
    let addExpense = true;
    if (!isAdministrator && 1 == value.hidden) {
      addExpense = false;
    }

    if (addExpense) {
      totalExpenses += parseFloat(value.amount);
    }
  });

  dispatch({ type: CALCULATE_EXPENSES, payload: totalExpenses });

  // total profit needs to be updated
  dispatch(calculateTotalProfit());
};
