/**
 * Input component that is rendered for each row in the
 * open invoice list of a billing payment.  This input
 * stores the payment amount that will be applied to
 * the invoice references in the table row.
 */
import React, { useState, useEffect } from 'react';
import { Input } from 'semantic-ui-react';
import { number } from 'yup';
import { REFRESH_CURRENT_USER } from '../../actions/types';

const LineItemPayment = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateInvoiceData,
  toggleRowSelected,
  state,
}) => {
  // we need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  // (initialValue should be the previous value of the input)
  const onBlur = () => {
    /*
    const invoiceUpdated = updateInvoiceData(index, id, value, initialValue);
    if (!invoiceUpdated) {
      // restore the original value
      setValue(initialValue);
    }
    */
    // control the checked state of the row, if there is an amount
    const isRowSelected = _.find(state.selectedRowIds, (item, key) => {
      //console.log('Current Key', key);
      if (parseInt(key) === index) {
        return true;
      }
    });

    // convert the value to a number
    const numberValue = parseFloat(value);

    // let's check to see if the user entered a 0 dollar amount, indicating
    // that the invoice will not be paid and, therefore, the checkbox
    // should be unchecked
    if (0 === numberValue && isRowSelected !== undefined) {
      // uncheck the box and return from the function
      //console.log('Uncheck the box');
      toggleRowSelected(index, false);
      return;
    }

    // if both the new value and the old value are zero, then we
    // do not want to do anything if the row was not
    // previously selected
    if (0 === numberValue && 0 === initialValue) {
      return;
    }

    // a dollar amount was entered, so let's go ahead and try to
    // update the amount to apply to the invoice selected
    //console.log('New Value', numberValue);
    //console.log('Previous Value', initialValue);
    const invoiceUpdated = updateInvoiceData(index, id, value, initialValue);
    if (!invoiceUpdated) {
      // restore the original value and return from the function because
      // we cannot use the amount that the user entered
      setValue(initialValue);
      return;
    }

    // if the amount was updated we can now see if the checkbox needs to be selected
    if (isRowSelected === undefined) {
      toggleRowSelected(index, true);
    }
  };

  // If the initialValue is changed externally, sync it up with our state
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  // we only want the payment column to be editable
  if ('payment' === id) {
    return (
      <Input
        className='brm-form-input brm-form-dollar-input'
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    );
  }
  return value;
};

export default LineItemPayment;
