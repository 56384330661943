import React, { useState } from 'react';
import { Grid, Button, Dropdown, Checkbox, Icon } from 'semantic-ui-react';
import moment from 'moment';
import DatePicker from 'react-date-picker';

const hasBalanceValues = [
  {
    key: 'all',
    value: 2,
    text: 'All',
  },
  {
    key: 'balanceDue',
    value: 1,
    text: 'Balance Due',
  },
  {
    key: 'overpaid',
    value: 0,
    text: 'Overpaid',
  },
];

const DealerFilters = (props) => {
  // filter state
  const [updatedDates, setUpdatedDates] = useState({
    startDate: moment(props.createdStartDate).format('YYYY-MM-DD'),
    endDate: moment(props.createdEndDate).format('YYYY-MM-DD'),
  });

  const [currentBalanceSelection, setCurrentBalanceSelection] = useState(
    props.hasBalance
  );

  const [useDateRange, setUseDateRange] = useState(props.useCreatedDateRange);

  return (
    <Grid stackable style={{ marginTop: '10px' }}>
      <Grid.Column width={16}>
        <h3>Filters</h3>
      </Grid.Column>
      <Grid.Column width={4}>
        <span className='brm-filter-header'>Balance Status</span>
        <br />
        <Dropdown
          name='dealerHasBalance'
          value={currentBalanceSelection}
          className='brm-form-dropdown brm-form-header-dropdown'
          selection
          selectOnBlur={true}
          options={hasBalanceValues}
          style={{ fontSize: '1em' }}
          onChange={(e, { value }) => {
            setCurrentBalanceSelection(value);
          }}
        />
      </Grid.Column>
      <Grid.Column width={4}>
        <span className='brm-filter-header'>
          Filter By Invoice Created Date Range
        </span>
        <br />
        <Checkbox
          toggle
          label={
            useDateRange
              ? 'Currently Using Date Range'
              : 'Currently Showing All'
          }
          style={{ marginTop: '5px', marginBottom: '5px' }}
          checked={useDateRange}
          onChange={(e) => setUseDateRange(!useDateRange)}
        />
      </Grid.Column>
      <Grid.Column width={4}>
        <span className='brm-filter-header'>Invoice Created Start Date</span>
        <br />
        <DatePicker
          value={new Date(moment(updatedDates.startDate))}
          onChange={(date) => {
            setUpdatedDates({
              ...updatedDates,
              startDate: moment(date).format('YYYY-MM-DD'),
            });
          }}
          calendarIcon={<Icon name='calendar alternate outline' />}
          clearIcon={null}
          disabled={useDateRange ? false : true}
        />
      </Grid.Column>
      <Grid.Column width={4}>
        <span className='brm-filter-header'>Invoice Created End Date</span>
        <br />
        <DatePicker
          value={new Date(moment(updatedDates.endDate))}
          onChange={(date) => {
            setUpdatedDates({
              ...updatedDates,
              endDate: moment(date).format('YYYY-MM-DD'),
            });
          }}
          calendarIcon={<Icon name='calendar alternate outline' />}
          clearIcon={null}
          disabled={useDateRange ? false : true}
        />
      </Grid.Column>
      <Grid.Column width={16}>
        <Button
          className='normal-button'
          onClick={() =>
            props.filterList({
              invoiceCreatedStartDate: updatedDates.startDate,
              invoiceCreatedEndDate: updatedDates.endDate,
              hasBalance: currentBalanceSelection,
              useDateRange: useDateRange,
            })
          }
        >
          Search
        </Button>
      </Grid.Column>
    </Grid>
  );
};

export default DealerFilters;
