import React from 'react';
import { Container, Message } from 'semantic-ui-react';
import PageHeader from '../PageHeader';

/**
 * Component that displays the message when a user
 * is not allowed to view a page.
 *
 * @returns
 */
const UnauthorizedMessage = () => {
  return (
    <Container>
      <PageHeader />
      <Message negative style={{ marginTop: '50px' }}>
        <Message.Header>UNAUTHORIZED</Message.Header>
        <p>You are not authorized to use this page!</p>
      </Message>
    </Container>
  );
};

export default UnauthorizedMessage;
