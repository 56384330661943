import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid, Menu, Icon } from 'semantic-ui-react';
import { Link, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { logoutUser } from '../actions';

const renderMenu = (visible, props) => {
  if (visible) {
    let menuList = [];

    // where should the home menu item go?
    if ('dealer' === props.user.accountType) {
      menuList.push(
        <Link to='/dealer-details'>
          <Menu.Item key='home' className='menu-links'>
            <Icon name='home' />
            &nbsp;&nbsp;Home
          </Menu.Item>
        </Link>
      );
    } else {
      menuList.push(
        <Link to='/dashboard'>
          <Menu.Item key='home' className='menu-links'>
            <Icon name='home' />
            &nbsp;&nbsp;Home
          </Menu.Item>
        </Link>
      );
    }

    // check if need to add user management and state expenses (administrators only)
    if (props.isAdmin) {
      // user management
      menuList.push(
        <Link to='/user-management'>
          <Menu.Item key='usermanage' className='menu-links'>
            <Icon name='users' />
            &nbsp;&nbsp;User Management
          </Menu.Item>
        </Link>
      );

      // state fees
      menuList.push(
        <Link to='/state-fees'>
          <Menu.Item key='statefees' className='menu-links'>
            <Icon name='calculator' />
            &nbsp;&nbsp;State Fees
          </Menu.Item>
        </Link>
      );
    }

    // build the rest of the menu
    if (props.user.accountType !== 'dealer') {
      menuList.push(
        <Link to='/my-profile'>
          <Menu.Item key='myprofile' className='menu-links'>
            <Icon name='user circle' />
            &nbsp;&nbsp;Edit Profile
          </Menu.Item>
        </Link>
      );

      menuList.push(
        <Link to='/invoice'>
          <Menu.Item key='addinvoice' className='menu-links'>
            <Icon name='add' />
            &nbsp;&nbsp;Add New Invoice
          </Menu.Item>
        </Link>
      );

      menuList.push(
        <Link to='/manage-dealers'>
          <Menu.Item key='managedealers' className='menu-links'>
            <Icon name='car' />
            &nbsp;&nbsp;Manage Dealers
          </Menu.Item>
        </Link>
      );

      menuList.push(
        <Link to='/billing-form'>
          <Menu.Item key='receivePayment' className='menu-links'>
            <Icon name='money' />
            &nbsp;&nbsp;Receive Payment
          </Menu.Item>
        </Link>
      );

      menuList.push(
        <Link to='/reports'>
          <Menu.Item key='reports' className='menu-links'>
            <Icon name='newspaper' />
            &nbsp;&nbsp;Reports
          </Menu.Item>
        </Link>
      );
    }

    menuList.push(
      <Menu.Item key='logout' onClick={props.logoutUser} className='menu-links'>
        <Icon name='log out' />
        &nbsp;&nbsp;Log Out
      </Menu.Item>
    );

    return (
      <Menu vertical style={{ float: 'right', textAlign: 'right' }}>
        {menuList}
      </Menu>
    );
  }
  return null;
};

const PageHeader = (props) => {
  /**
   * Responsive triggers
   */
  const isTabletOrMobile = useMediaQuery({ maxWidth: 767 });
  const [menuVisible, setMenuVisible] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (props.sessionId.length === 0) {
      // redirect to the login page
      navigate('/');
    }
  }, []);

  if (props.sessionId.length === 0) {
    // redirect to the login page
    navigate('/');
  }

  return (
    <Grid columns='equal'>
      <Grid.Row className='header-style main-header'>
        <Grid.Column floated='left'>
          {'dealer' !== props.user.accountType && (
            <Link to='/dashboard'>
              <img
                className='brm-header-logo'
                src={process.env.PUBLIC_URL + '/AS-Logo.png'}
              />
            </Link>
          )}
          {'dealer' === props.user.accountType && (
            <Link to='/dealer-details'>
              <img
                className='brm-header-logo'
                src={process.env.PUBLIC_URL + '/AS-Logo.png'}
              />
            </Link>
          )}
          {!isTabletOrMobile &&
            `${' '}Hello, ${props.user.firstName} ${props.user.lastName}`}
        </Grid.Column>
        {isTabletOrMobile && (
          <Grid.Column style={{ textAlign: 'center' }}>
            Hello, {props.user.firstName} {props.user.lastName}
          </Grid.Column>
        )}
        <Grid.Column floated='right' textAlign='right'>
          <Icon
            name='sidebar'
            style={{ cursor: 'pointer', paddingTop: '10px' }}
            onClick={() => setMenuVisible(!menuVisible)}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row
        className={
          'dealer' === props.user.accountType
            ? 'brm-dealer-nav-menu-row'
            : 'brm-nav-menu-row'
        }
        style={{ paddingTop: 0 }}
      >
        <Grid.Column floated='right'>
          {renderMenu(menuVisible, props)}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.users.currentUser,
    isAdmin: state.users.isAdministrator,
    sessionId: state.users.sessionId,
  };
};

export default connect(mapStateToProps, { logoutUser })(PageHeader);
