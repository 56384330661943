import React from 'react';
import { Message } from 'semantic-ui-react';
import { connect } from 'react-redux';

const renderWarning = (msg) => {
    if (msg.length > 0) {
        return (
            <Message warning>
                {msg}
            </Message>
        );
    }

    // default return is nothing
    return null;
}

const WarningMsg = (props) => {
    return(
        renderWarning(props.warning)
    );
};

const mapStateToProps = (state) => {
    return {
        warning: state.main.warning
    };
};

export default connect(mapStateToProps)(WarningMsg);