import React, { useState } from 'react';
import { Modal, Button } from 'semantic-ui-react';

const Confirmation = (props) => {
    const [open, setOpen] = useState(false);
    return (
        <Modal open={open} trigger={props.trigger} onOpen={() => setOpen(true)} onClose={() => setOpen(false)}>
            <Modal.Header>{props.title}</Modal.Header>
            <Modal.Content>
                {props.content}
            </Modal.Content>
            <Modal.Actions>
                <Button type="button" className="decline-button" content="No" onClick={() => setOpen(false)} />
                <Button type="button" className="confirm-button" content="Yes" onClick={() => {
                    props.confirmAction(props.confirmId);
                    setOpen(false);
                }} />
            </Modal.Actions>
        </Modal>
    )
}

export default Confirmation;