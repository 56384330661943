import React, { useState, useEffect, useMemo, Fragment } from 'react';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import {
  Container,
  Grid,
  Dropdown,
  Button,
  Header,
  Modal,
  Icon,
  Table,
  Message,
} from 'semantic-ui-react';
import { useNavigate, Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import ValidationSchema from './FormSetup/ValidationSchema';
import DealerInfo from './DealerInfo';
import RegistrationInfo from './RegistrationInfo';
import DMVInfo from './DMVInfo';
import ChargesCosts from './ChargesCosts';
import PaymentList from './Payments/PaymentList';
import NotesList from './Notes/NotesList';
import ResultsModal from '../ResultsModal';
import PaymentForm from './Payments/PaymentForm';
import DealerForm from './Dealers/DealerForm';
import LienHolderForm from './LienHolders/LienHolderForm';
import NotesForm from './Notes/NotesForm';
import LoadingComp from '../LoadingComp';
import CarMakeForm from './CarMakes/CarMakeForm';
import TotalsDisplayBox from '../Common/TotalsDisplayBox';
import packageJson from '../../../package.json';
import { invoiceStatus } from '../StaticLists';
import {
  saveInvoice,
  getAllInvoices,
  clearInvoice,
  showPrintInvoice,
  closeSavedInvoiceResult,
} from '../../actions';
import {
  PLATE_STATUS,
  DEALER_NAME,
  DEALER_PHONE,
  COURIER,
  RETURN_TO,
  REG_FIRST_NAME,
  REG_LAST_NAME,
  REG_EMAIL,
  REG_PHONE,
  REG_TXN_TYPE,
  REG_STATE_DEAL,
  REG_CAR_MAKE,
  REG_CAR_YEAR,
  REG_VIN_NUMBER,
  REG_PLATE_NUMBER,
  REG_LIEN_HOLDER,
  REG_LEASING_COMPANY,
  RECEIVED_FROM_DEALER_DATE,
  SENT_TO_DMV_DATE,
  RETURN_FROM_DMV_DATE,
  BRM_CHARGE,
  DMV_CHARGE,
  SALES_TAX,
  OTHER_FEES,
  REG_ADDRESS,
  REG_CITY,
  REG_ZIP_CODE,
  REG_COMPANY_NAME,
} from './FormSetup/FieldNames';
import ExpensesList from './Expenses/ExpensesList';
import ExpensesForm from './Expenses/ExpensesForm';
import UnauthorizedMessage from '../Common/UnauthorizedMessage';

const renderLoader = (show, message) => {
  if (show) {
    return <LoadingComp msg={message} />;
  }
  return null;
};

/**
 * Displays the dealer form on the screen if called for.
 *
 * @param {bool} show true if need to show the form, false otherwise
 * @returns
 */
const renderDealerForm = (show) => {
  if (show) {
    return (
      <Modal open size='large' closeOnEscape={false} closeOnDimmerClick={false}>
        <Modal.Content>
          <DealerForm />
        </Modal.Content>
      </Modal>
    );
  }
  return null;
};

const renderLienHolderForm = (show) => {
  if (show) {
    return (
      <Modal open size='large' closeOnEscape={false} closeOnDimmerClick={false}>
        <Modal.Content>
          <LienHolderForm />
        </Modal.Content>
      </Modal>
    );
  }
  return null;
};

/**
 * Displays the Expense form on the screen when needed.
 *
 * @param {bool} show true if show the form, false otherwise
 * @returns
 */
const renderExpenseForm = (show) => {
  if (show) {
    return (
      <Modal open size='large' closeOnEscape={false} closeOnDimmerClick={false}>
        <Modal.Content>
          <ExpensesForm />
        </Modal.Content>
      </Modal>
    );
  }
  return null;
};

/**
 * Renders the Payment Form if needed
 * @param {boolean} show - whether to show the form or not
 * @param {int} invoiceId - current invoice record id
 * @param {int} dealerId - dealer associated with the plate invoice
 * @returns - JSX for the Payment Form or null if the form should be closed
 */
const renderPaymentForm = (show, invoiceId, dealerId) => {
  if (show) {
    return (
      <Modal
        open
        className='brm-payment-modal'
        size='large'
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Content>
          <PaymentForm paymentFor='invoice' invoiceId={invoiceId} />
        </Modal.Content>
      </Modal>
    );
  }
  return null;
};

/**
 * Renders the Notes form if needed
 * @param {boolean} show - whether to show the form or not
 * @param {int} refId - current invoice record id
 * @returns - JSX for the Notes Form or null if the form should be closed
 */
const renderNotesForm = (show, refId) => {
  if (show) {
    return (
      <Modal open size='large' closeOnEscape={false} closeOnDimmerClick={false}>
        <Modal.Content>
          <NotesForm refId={refId} parentType='invoice' />
        </Modal.Content>
      </Modal>
    );
  }
  return null;
};

const renderCarMakeForm = (show) => {
  if (show) {
    return (
      <Modal open size='large' closeOnEscape={false} closeOnDimmerClick={false}>
        <Modal.Content>
          <CarMakeForm />
        </Modal.Content>
      </Modal>
    );
  }
  return null;
};

const renderPrintConfirmation = (show, invoiceNumber, showFunction) => {
  if (show) {
    return (
      <Modal open size='large' closeOnEscape={false} closeOnDimmerClick={false}>
        <Modal.Header>Print Invoice</Modal.Header>
        <Modal.Content>
          <p>The invoice has been saved and is ready to print.</p>
          <Button
            type='button'
            className='inverted-button'
            onClick={() =>
              window.open(
                packageJson.homepage +
                  '/invoices/Invoice ' +
                  invoiceNumber +
                  '.pdf'
              )
            }
          >
            <Icon name='print' />
            PRINT INVOICE
          </Button>
        </Modal.Content>
        <Modal.Actions>
          <Button
            type='button'
            className='decline-button'
            onClick={() => showFunction(false)}
          >
            CLOSE
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
  return null;
};

/**
 * Shows the confirmation pop up when closing without saving
 * @param {boolean} show
 * @param {object} values
 * @param {object} props
 */
const renderSaveConfirm = (showObj, showFunction, values = {}, props = {}) => {
  const { txnForm } = props;
  const navigate = useNavigate();

  if (showObj.show) {
    let alertMessage = '';
    if (showObj.callSource !== 'cancel') {
      // are any fields missing data?
      let missingFieldsArray = [];
      if (0 === values.courier.length) {
        missingFieldsArray.push('Courier');
      }

      if (0 === values.regFirstName.length) {
        missingFieldsArray.push('First Name');
      }

      if (0 === values.regLastName.length) {
        missingFieldsArray.push('Last Name');
      }

      if (0 === values.regEmail.length) {
        missingFieldsArray.push('Email Address');
      }

      if (0 === values.regPhone.length) {
        missingFieldsArray.push('Registrant Phone Number');
      }

      if (0 === values.regTxnType.length) {
        missingFieldsArray.push('Transaction Type');
      }

      if (0 === values.regAddress.length) {
        missingFieldsArray.push('Address');
      }

      if (0 === values.regCity.length) {
        missingFieldsArray.push('City');
      }

      if (0 === values.regZipCode.length) {
        missingFieldsArray.push('Zip Code');
      }

      if (0 === values.regStateDeal.length) {
        missingFieldsArray.push('State Deal');
      }

      if (0 === values.regCarMake.length) {
        missingFieldsArray.push('Car Make');
      }

      if (0 === values.regCarYear.length) {
        missingFieldsArray.push('Car Year');
      }

      if (0 === values.regVinNumber.length) {
        missingFieldsArray.push('Vin Number');
      }

      // check to see if this is an edit alert
      if (txnForm.txnId !== 0) {
        let dealerDate = values.receivedFromDealerDate;
        let toDMVDate = values.sendToDMVDate;
        let returnDMVDate = values.returnFromDMVDate;
        if (dealerDate !== undefined) {
          dealerDate = moment(dealerDate).format('YYYY-MM-DD hh:mm:ss');
        }
        if (values.sendToDMVDate !== undefined) {
          toDMVDate = moment(toDMVDate).format('YYYY-MM-DD hh:mm:ss');
        }
        if (values.returnFromDMVDate !== undefined) {
          returnDMVDate = moment(returnDMVDate).format('YYYY-MM-DD hh:mm:ss');
        }

        let changesArray = [];
        // this is an edit, need to construct a list of changes
        if (values.dealerName !== txnForm.dealerId) {
          changesArray.push('Dealer Name');
        }

        if (values.dealerPhone !== txnForm.dealerPhoneNumber) {
          changesArray.push('Dealer Phone Number');
        }

        if (values.courier !== txnForm.dealerCourier) {
          changesArray.push('Courier');
        }

        if (values.returnTo !== txnForm.returnedTo) {
          changesArray.push('Returned To');
        }

        if (values.regFirstName !== txnForm.firstName) {
          changesArray.push('First Name');
        }

        if (values.regLastName !== txnForm.lastName) {
          changesArray.push('Last Name');
        }

        if (values.regEmail !== txnForm.email) {
          changesArray.push('Email Address');
        }

        if (values.regPhone !== txnForm.phoneNumber) {
          changesArray.push('Registrant Phone Number');
        }

        if (values.regTxnType !== txnForm.txnType) {
          changesArray.push('Transaction Type');
        }

        if (values.regAddress !== txnForm.regAddress) {
          changesArray.push('Address');
        }

        if (values.regCity !== txnForm.regCity) {
          changesArray.push('City');
        }

        if (values.regZipCode !== txnForm.regZipCode) {
          changesArray.push('Zip Code');
        }

        if (values.regStateDeal !== txnForm.stateDeal) {
          changesArray.push('State Deal');
        }

        if (values.regCarMake !== txnForm.carMake) {
          changesArray.push('Car Make');
        }

        if (values.regCarYear !== txnForm.carYear) {
          changesArray.push('Car Year');
        }

        if (values.regVinNumber !== txnForm.vinNumber) {
          changesArray.push('Vin Number');
        }

        if (values.regPlateNumber !== txnForm.plateNumber) {
          changesArray.push('Plate Number');
        }

        if (values.regLienHolder !== txnForm.lienHolderId) {
          changesArray.push('Lien Holder');
        }

        if (values.regLeasingCompany !== txnForm.leasingCompany) {
          changesArray.push('Leasing Company');
        }

        if (dealerDate !== txnForm.receivedFromDealer) {
          changesArray.push('Received from Dealer Date');
        }

        if (toDMVDate !== txnForm.sentToDMV) {
          changesArray.push('Sent to DMV Date');
        }

        if (returnDMVDate !== txnForm.returnFromDMV) {
          changesArray.push('Return from DMV Date');
        }

        if (values.brmCharge !== txnForm.brmFees) {
          changesArray.push('BRM Charge');
        }

        if (values.dmvCharge !== txnForm.dmvFees) {
          changesArray.push('DMV Charge');
        }

        if (values.salesTax !== txnForm.salesTax) {
          changesArray.push('Sales Tax');
        }

        if (values.otherFees !== txnForm.otherFees) {
          changesArray.push('Other Fees');
        }

        if (values.plateStatus !== txnForm.status) {
          changesArray.push('Status');
        }

        // were any changes made?
        if (!_.isEmpty(changesArray)) {
          alertMessage = 'You are about to make the following changes: \n';
          alertMessage += _.join(changesArray, ',');
          if (!_.isEmpty(missingFieldsArray)) {
            alertMessage +=
              ' You are also missing the following information: \n';
            alertMessage += _.join(missingFieldsArray, ',');
          }
          alertMessage += '.\n Are you sure you want to commit these changes?';
        } else {
          alertMessage =
            'No changes detected.  Click "Yes" to confirm you did not want to make any changes ';
          if (showObj.callSource === 'print') {
            alertMessage += 'and show the Print Window.';
          } else {
            alertMessage += 'and return to the Dashboard.';
          }
        }
      } else {
        if (!_.isEmpty(missingFieldsArray)) {
          alertMessage = 'You are missing the following information: \n';
          alertMessage += _.join(missingFieldsArray, ',');
          alertMessage += '\n';
        }
        alertMessage += 'Are you sure you want to Save?';
      }
    } else {
      alertMessage = 'Are you sure you want to Cancel without Saving?';
    }

    return (
      <Modal open size='large' closeOnEscape={false} closeOnDimmerClick={false}>
        <Modal.Header>Confirm Save</Modal.Header>
        <Modal.Content>
          <Message warning>{alertMessage}</Message>
        </Modal.Content>
        <Modal.Actions>
          <Button
            type='button'
            className='confirm-button'
            content='Yes'
            onClick={() => {
              showFunction({ ...showObj, show: false });
              if (showObj.callSource === 'cancel') {
                props.clearInvoice();
                navigate(-1);
              } else {
                props.saveInvoice(
                  values,
                  showObj.callSource === 'print' ? 'print' : 'close'
                );
              }
            }}
          />
          <Button
            type='button'
            className='decline-button'
            content='No'
            onClick={() => {
              showFunction({ ...showObj, show: false });
            }}
          />
          <Button
            type='button'
            className='inverted-button'
            content='Cancel'
            onClick={() => showFunction({ ...showObj, show: false })}
          />
        </Modal.Actions>
      </Modal>
    );
  }

  return null;
};

/**
 * Shows the results of a regular invoice submission (not print)
 * @param {boolean} show
 * @param {string} msg
 */
const renderResultsModal = (show, success, error, closeFunction, dealerId) => {
  if (show) {
    // return is based on if the user wants to go ahead and enter another invoice with the same dealer
    //console.log('Dealer ID', dealerId);
    if (dealerId === -1) {
      return (
        <ResultsModal
          success={success}
          error={error}
          closeFunction={closeFunction}
          title='Save Invoice Result'
          hasLink={true}
          goToPage='/dashboard'
        />
      );
    } else {
      // reset all values, except for the dealer information
      //resetFunction();
      // stay on the invoice page to create a new one
      return (
        <ResultsModal
          success={success}
          error={error}
          closeFunction={closeFunction}
          title='Save Invoice Result'
          hasLink={false}
        />
      );
    }
  }
  return null;
};

const Invoice = (props) => {
  // prevent unauthorized users from accessing this page
  if ('dealer' === props.currentUser.accountType) {
    // show a message instead of the user list
    return <UnauthorizedMessage />;
  }
  const navigate = useNavigate();
  const [isReady, setIsReady] = useState(false);
  const [invoiceRep, setInvoiceRep] = useState(0);
  const [showConfirm, setShowConfirm] = useState({
    show: false,
    callSource: 'cancel',
  });
  //console.log('Ready', isReady);

  useEffect(() => {
    // unauthorized users should be redirected
    if (props.sessionId.length === 0) {
      // redirect to the login page
      navigate('/');
    }
    setTimeout(() => {
      setIsReady(true);
    }, 1000);
  }, []);

  /**
   * Responsive triggers
   */
  const isTabletOrMobile = useMediaQuery({ maxWidth: 767 });

  /**
   * Get the initials of the user on this invoice
   */
  if (
    props.txnForm.updatedByUserId !== props.currentUser.userId &&
    0 === invoiceRep
  ) {
    // find the rep for this invoice since not the current user
    const updatedByUser = _.find(props.userList, {
      userId: props.txnForm.updatedByUserId,
    });
    setInvoiceRep(updatedByUser.initials);
  } else if (0 === invoiceRep) {
    setInvoiceRep(props.currentUser.initials);
  }

  // initial values for the form are in the state object
  const formInitialValues = {
    [PLATE_STATUS]: props.txnForm.status,
    [DEALER_NAME]: props.txnForm.dealerId,
    [DEALER_PHONE]: props.txnForm.dealerPhoneNumber,
    [COURIER]: props.txnForm.dealerCourier,
    [RETURN_TO]: props.txnForm.returnedTo,
    [REG_FIRST_NAME]: props.txnForm.firstName,
    [REG_LAST_NAME]: props.txnForm.lastName,
    [REG_COMPANY_NAME]: props.txnForm.companyName,
    [REG_EMAIL]: props.txnForm.email,
    [REG_PHONE]: props.txnForm.phoneNumber,
    [REG_TXN_TYPE]: props.txnForm.txnType,
    [REG_ADDRESS]: props.txnForm.regAddress,
    [REG_CITY]: props.txnForm.regCity,
    [REG_STATE_DEAL]: props.txnForm.stateDeal,
    [REG_ZIP_CODE]: props.txnForm.regZipCode,
    [REG_CAR_MAKE]: props.txnForm.carMake,
    [REG_CAR_YEAR]: props.txnForm.carYear,
    [REG_VIN_NUMBER]: props.txnForm.vinNumber,
    [REG_PLATE_NUMBER]: props.txnForm.plateNumber,
    [REG_LIEN_HOLDER]: props.txnForm.lienHolderId,
    [REG_LEASING_COMPANY]: props.txnForm.leasingCompany,
    [RECEIVED_FROM_DEALER_DATE]:
      typeof props.txnForm.receivedFromDealer === 'string'
        ? new Date(props.txnForm.receivedFromDealer)
        : props.txnForm.receivedFromDealer,
    [SENT_TO_DMV_DATE]:
      typeof props.txnForm.sentToDMV === 'string'
        ? new Date(props.txnForm.sentToDMV)
        : props.txnForm.sentToDMV,
    [RETURN_FROM_DMV_DATE]:
      typeof props.txnForm.returnFromDMV === 'string'
        ? new Date(props.txnForm.returnFromDMV)
        : props.txnForm.returnFromDMV,
    [BRM_CHARGE]: props.txnForm.brmFees,
    [DMV_CHARGE]: props.txnForm.dmvFees,
    [SALES_TAX]: props.txnForm.salesTax,
    [OTHER_FEES]: props.txnForm.otherFees,
  };

  return (
    <Container className='brm-container brm-worksheet'>
      <Formik
        key={props.txnForm.txnId}
        enableReinitialize={true}
        initialValues={formInitialValues}
        validationSchema={ValidationSchema}
        onSubmit={(values) => props.saveInvoice(values, 'close')}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          values,
          errors,
          touched,
          initialValues,
          resetForm,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Grid stackable>
              <Grid.Column width={5} className='header-style header-plain-text'>
                <Link to='/dashboard'>
                  <Icon name='home' style={{ color: '#fff' }} />
                  &nbsp;&nbsp;
                </Link>
                BY: {invoiceRep}
              </Grid.Column>
              <Grid.Column width={6} className='header-style'>
                STATUS&nbsp;&nbsp;&nbsp;
                <Dropdown
                  tabIndex='1'
                  name={PLATE_STATUS}
                  value={values.plateStatus || initialValues[PLATE_STATUS]}
                  placeholder='Select Status'
                  className='brm-form-dropdown brm-form-header-dropdown'
                  selection
                  selectOnBlur={true}
                  options={invoiceStatus}
                  style={{ fontSize: '1em' }}
                  onChange={(e, { value }) => {
                    setFieldValue(PLATE_STATUS, value);
                  }}
                />
              </Grid.Column>
              <Grid.Column
                width={5}
                textAlign='right'
                className='header-style header-plain-text'
              >
                INVOICE #&nbsp;&nbsp;{props.txnForm.txnId}
              </Grid.Column>
            </Grid>
            <Grid columns={3} stackable>
              <Grid.Column className='invoice-left-column invoice-row'>
                <DealerInfo
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  initialValues={initialValues}
                  errors={errors}
                  touched={touched}
                  values={values}
                />
              </Grid.Column>
              {isTabletOrMobile && (
                <Grid.Column className='invoice-left-column invoice-row'>
                  <RegistrationInfo
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    initialValues={initialValues}
                    errors={errors}
                    touched={touched}
                    values={values}
                  />
                </Grid.Column>
              )}
              <Grid.Column className='invoice-row'>
                <DMVInfo
                  setFieldValue={setFieldValue}
                  receivedFromDealer={props.txnForm.receivedFromDealer}
                  sentToDMV={props.txnForm.sentToDMV}
                  returnFromDMV={props.txnForm.returnFromDMV}
                  values={values}
                  initialValues={initialValues}
                />
              </Grid.Column>
              <Grid.Column className='invoice-row'>
                <ChargesCosts
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  initialValues={initialValues}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
              </Grid.Column>
              {!isTabletOrMobile && (
                <Grid.Column className='invoice-left-column invoice-row'>
                  <RegistrationInfo
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    initialValues={initialValues}
                    errors={errors}
                    touched={touched}
                    values={values}
                  />
                </Grid.Column>
              )}
              <Grid.Column className='invoice-row'>
                <PaymentList
                  listHeight='275px'
                  txnList={props.paymentList}
                  invoiceId={props.txnForm.txnId}
                  invoiceValues={values}
                  saveInvoiceFunction={props.saveInvoice}
                />
                <TotalsDisplayBox
                  boxClass='brm-pay-balance-area'
                  title='BALANCE'
                  amount={parseFloat(props.txnForm.currentBalance).toFixed(2)}
                />
                <NotesList
                  noteType='invoice'
                  parentType='invoice'
                  listHeight='210px'
                  notes={props.noteList}
                  invoiceId={props.txnForm.txnId}
                  invoiceValues={values}
                  saveInvoiceFunction={props.saveInvoice}
                />
                {!props.financialTxnSaved && !isTabletOrMobile && (
                  <>
                    <Button
                      tabIndex='30'
                      type='button'
                      className='inverted-button brm-invoice-form-button'
                      onClick={() => {
                        props.clearInvoice();
                        navigate(-1);
                      }}
                    >
                      CANCEL
                    </Button>
                    <Button
                      tabIndex='32'
                      type='button'
                      className='inverted-button brm-invoice-form-button'
                      style={{ float: 'right' }}
                      onClick={() => props.saveInvoice(values, 'save-and-send')}
                    >
                      <Icon name='mail' /> SAVE &amp;{' '}
                      {props.txnForm.txnId > 0 ? 'NOTIFY' : 'SEND'}
                    </Button>
                  </>
                )}
              </Grid.Column>
              <Grid.Column className='invoice-row'>
                <ExpensesList listHeight='275px' />
                <TotalsDisplayBox
                  boxClass='brm-total-expenses-area'
                  title='EXPENSES'
                  amount={parseFloat(props.totalExpenses).toFixed(2)}
                  boxBackgroundColor='#FFF'
                  boxFontColor='#000'
                  boxFontSize='1.0em'
                />
                <NotesList
                  noteType='internal'
                  parentType='invoice'
                  listHeight='210px'
                  notes={props.internalNotes}
                  invoiceId={props.txnForm.txnId}
                  invoiceValues={values}
                  saveInvoiceFunction={props.saveInvoice}
                />
                <div class='brm-form-actions'>
                  {!props.financialTxnSaved && isTabletOrMobile && (
                    <>
                      <Button
                        tabIndex='31'
                        type='button'
                        className='inverted-button brm-invoice-form-button'
                        onClick={() => {
                          props.clearInvoice();
                          navigate(-1);
                        }}
                      >
                        CANCEL
                      </Button>
                      <Button
                        tabIndex='32'
                        type='button'
                        className='inverted-button brm-invoice-form-button'
                        onClick={() =>
                          props.saveInvoice(values, 'save-and-send')
                        }
                      >
                        <Icon name='mail' /> SAVE &amp;{' '}
                        {props.txnForm.txnId > 0 ? 'NOTIFY' : 'SEND'}
                      </Button>
                    </>
                  )}
                  <Button
                    tabIndex='33'
                    type='button'
                    className='inverted-button brm-invoice-form-button'
                    onClick={() => props.saveInvoice(values, 'print')}
                  >
                    <Icon name='print' /> SAVE &amp; PRINT
                  </Button>
                  <Button
                    tabIndex='34'
                    type='button'
                    className='inverted-button brm-invoice-form-button'
                    onClick={() => props.saveInvoice(values, 'close')}
                  >
                    <Icon name='save' /> SAVE &amp; CLOSE
                  </Button>
                  <Button
                    tabIndex='35'
                    type='button'
                    className='inverted-button brm-invoice-form-button'
                    onClick={() => props.saveInvoice(values, 'save-and-new')}
                  >
                    <Icon name='save' /> SAVE &amp; NEW
                  </Button>
                </div>
              </Grid.Column>
            </Grid>
            {/*renderSaveConfirm(showConfirm, setShowConfirm, values, props)*/}
            {renderResultsModal(
              props.invoiceSaved,
              props.success,
              props.error,
              () => {
                props.closeSavedInvoiceResult();
                //setFieldValue(PLATE_STATUS, props.txnForm.status);
                //setFieldValue(REG_FIRST_NAME, props.txnForm.firstName);
                resetForm({
                  values: {
                    ...values,
                    plateStatus: props.txnForm.status,
                    dealerName: props.txnForm.dealerId,
                    dealerPhone: props.txnForm.dealerPhoneNumber,
                    courier: props.txnForm.dealerCourier,
                    returnTo: props.txnForm.returnedTo,
                    regFirstName: props.txnForm.firstName,
                    regLastName: props.txnForm.lastName,
                    regCompanyName: props.txnForm.companyName,
                    regEmail: props.txnForm.email,
                    regPhone: props.txnForm.phoneNumber,
                    regTxnType: props.txnForm.txnType,
                    regAddress: props.txnForm.regAddress,
                    regCity: props.txnForm.regCity,
                    regStateDeal: props.txnForm.stateDeal,
                    regZipCode: props.txnForm.regZipCode,
                    regCarMake: props.txnForm.carMake,
                    regCarYear: props.txnForm.carYear,
                    regVinNumber: props.txnForm.vinNumber,
                    regPlateNumber: props.txnForm.plateNumber,
                    regLienHolder: props.txnForm.lienHolderId,
                    regLeasingCompany: props.txnForm.leasingCompany,
                    receivedFromDealerDate: props.txnForm.receivedFromDealer,
                    sendToDMVDate: props.txnForm.sentToDMV,
                    returnFromDMVDate: props.txnForm.returnFromDMV,
                    brmCharge: props.txnForm.brmFees,
                    dmvCharge: props.txnForm.dmvFees,
                    salesTax: props.txnForm.salesTax,
                    otherFees: props.txnForm.otherFees,
                  },
                });
              },
              props.txnForm.dealerId
            )}
          </Form>
        )}
      </Formik>
      {renderPaymentForm(props.showFinancialTxnModal, props.txnForm.txnId)}
      {renderExpenseForm(props.showExpenseForm)}
      {renderNotesForm(props.showNotesModal, props.txnForm.txnId)}
      {renderDealerForm(props.showDealerModal)}
      {renderLienHolderForm(props.showLienHolderModal)}
      {renderCarMakeForm(props.showCarMakeModal)}
      {renderPrintConfirmation(
        props.showPrint,
        props.txnForm.txnId,
        props.showPrintInvoice
      )}
      {renderLoader(props.showLoading, 'Saving Invoice')}
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    txnForm: state.plates.txnForm,
    paymentList: state.plates.txnList,
    noteList: state.plates.notes,
    internalNotes: state.plates.internalNotes,
    dealer: state.dealers.dealerForm,
    lienHolder: state.lienholders.lienHolderForm,
    showDealerModal: state.dealers.showModal,
    invoiceSaved: state.plates.invoiceSaved,
    showFinancialTxnModal: state.financialTxns.showFTModal,
    showLienHolderModal: state.lienholders.showLienModal,
    showNotesModal: state.notes.showNotesModal,
    showCarMakeModal: state.main.showCarMakeModal,
    showLoading: state.main.isLoading,
    userList: state.users.userList,
    currentUser: state.users.currentUser,
    showPrint: state.plates.showPrintInvoice,
    error: state.main.errors,
    success: state.main.success,
    newDealer: state.dealers.newDealer,
    prevDealer: state.dealers.previousDealer,
    addNewDealerCancelled: state.dealers.addDealerCancelled,
    financialTxnSaved: state.financialTxns.financialTxnSaved,
    isAdministrator: state.users.isAdministrator,
    showExpenseForm: state.expenses.showExpenseForm,
    totalExpenses: state.expenses.totalExpenses,
    sessionId: state.users.sessionId,
  };
};

export default connect(mapStateToProps, {
  saveInvoice,
  getAllInvoices,
  clearInvoice,
  showPrintInvoice,
  closeSavedInvoiceResult,
})(Invoice);
