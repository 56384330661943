import React, { Fragment } from 'react';
import { Grid, Header, Icon } from 'semantic-ui-react';
import DatePicker from 'react-date-picker';
import moment from 'moment';
import {
  RECEIVED_FROM_DEALER_DATE,
  SENT_TO_DMV_DATE,
  RETURN_FROM_DMV_DATE,
} from './FormSetup/FieldNames';

const DMVInfo = (props) => {
  //console.log('Date Picker Props', props);
  return (
    <Fragment>
      <Header>DMV INFORMATION</Header>
      <Grid stackable>
        <Grid.Row verticalAlign='middle' className='brm-form-row'>
          <Grid.Column width={8} className='brm-form-label'>
            Received from Dealer
          </Grid.Column>
          <Grid.Column width={8}>
            <DatePicker
              value={
                props.values[RECEIVED_FROM_DEALER_DATE] ||
                props.initialValues[RECEIVED_FROM_DEALER_DATE]
              }
              onChange={(date) => {
                //console.log(date);
                props.setFieldValue(RECEIVED_FROM_DEALER_DATE, date);
              }}
              calendarIcon={<Icon name='calendar alternate outline' />}
              clearIcon={null}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row verticalAlign='middle' className='brm-form-row'>
          <Grid.Column width={8} className='brm-form-label'>
            Sent to DMV
          </Grid.Column>
          <Grid.Column width={8}>
            <DatePicker
              value={
                props.values[SENT_TO_DMV_DATE] ||
                props.initialValues[SENT_TO_DMV_DATE]
              }
              onChange={(date) => {
                props.setFieldValue(SENT_TO_DMV_DATE, date);
              }}
              calendarIcon={<Icon name='calendar alternate outline' />}
              clearIcon={null}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row verticalAlign='middle' className='brm-form-row'>
          <Grid.Column width={8} className='brm-form-label'>
            Return from DMV
          </Grid.Column>
          <Grid.Column width={8}>
            <DatePicker
              value={
                props.values[RETURN_FROM_DMV_DATE] ||
                props.initialValues[RETURN_FROM_DMV_DATE]
              }
              onChange={(date) => {
                props.setFieldValue(RETURN_FROM_DMV_DATE, date);
              }}
              calendarIcon={<Icon name='calendar alternate outline' />}
              clearIcon={null}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Fragment>
  );
};

export default DMVInfo;
