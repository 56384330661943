export const courierList = [
    {
        key: 'dhl',
        value: 'DHL',
        text: 'DHL'
    },
    {
        key: 'fedex',
        value: 'FedEx',
        text: 'FedEx'
    },
    {
        key: 'other',
        value: 'OTHER COURIER',
        text: 'OTHER COURIER'
    },
    {
        key: 'ours',
        value: 'OURS',
        text: 'OURS'
    },
    {
        key: 'stateRoute',
        value: 'STATE ROUTE',
        text: 'STATE ROUTE'
    },
    {
        key: 'ups',
        value: 'UPS',
        text: 'UPS'
    },
    {
        key: 'usps',
        value: 'USPS',
        text: 'USPS'
    }
];