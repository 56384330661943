import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import InputMask from 'react-input-mask';
import {
  Input,
  Grid,
  Dropdown,
  Message,
  Header,
  Button,
  Icon,
  Segment,
  Radio,
  Modal,
} from 'semantic-ui-react';
import {
  TXN_TYPE,
  PAYMENT_TYPE,
  AMOUNT,
  REF_NUMBER,
  TOWARD,
  CC_NAME,
  CC_NUMBER,
  CC_EXPIRE,
  CC_CVC,
  ACH_ACCOUNT_NUMBER,
  ACH_ROUTING,
  ACH_NAME_ON_ACCOUNT,
} from './FormSetup/FieldNames';
import ValidationSchema from './FormSetup/ValidationSchema';
import {
  saveFinancialTxn,
  showFinancialTxnForm,
  processPayment,
} from '../../../actions';

/**
 * Shows the confirmation when saving
 * @param {boolean} show
 * @param {object} values
 * @param {object} props
 */
const renderSaveConfirm = (show, showFunction, values = {}, props = {}) => {
  if (show) {
    let alertMessage = '';
    if (props.financialTxnForm.txnId !== 0) {
      alertMessage =
        'You have made a change to this transaction in the amount of $';
      alertMessage +=
        parseFloat(values.amount).toFixed(2) +
        ' Are you sure you want to save this change?';
    } else {
      alertMessage =
        'You are about to save this transaction in the amount of $' +
        parseFloat(values.amount).toFixed(2);
      if ('combined-invoice' === props.paymentFor) {
        alertMessage +=
          ' Please verify the amount as this will be disbursed among all of the invoices and will lock this transaction. ';
        alertMessage += 'Do you wish to continue?';
      } else {
        alertMessage += ' Is this correct?';
      }
    }
    return (
      <Modal open size='large' closeOnEscape={false} closeOnDimmerClick={false}>
        <Modal.Header>Save Financial Transaction</Modal.Header>
        <Modal.Content>
          <Message warning>{alertMessage}</Message>
        </Modal.Content>
        <Modal.Actions>
          <Button
            type='button'
            className='confirm-button'
            content='Yes'
            onClick={() => {
              props.saveFinancialTxn(values, 0, props.paymentFor);
              showFunction(false);
            }}
          />
          <Button
            type='button'
            className='decline-button'
            content='No'
            onClick={() => {
              showFunction(false);
            }}
          />
        </Modal.Actions>
      </Modal>
    );
  }

  return null;
};

const PaymentForm = (props) => {
  const [currentPaymentEntryFields, setPaymentEntryFields] = useState('NONE');
  const [showSaveConfirm, setShowSaveConfirm] = useState(false);
  const [stayOpenOnSubmit, setStayOpenOnSubmit] = useState(false);

  //console.log('Stay Open On Submit?', stayOpenOnSubmit);

  useEffect(() => {
    if (
      currentPaymentEntryFields != 'NONE' &&
      1 !== props.financialTxnForm.paymentProcessed
    ) {
      setStayOpenOnSubmit(true);
    } else {
      setStayOpenOnSubmit(false);
    }
  }, [currentPaymentEntryFields]);

  const txnTypeList = [
    {
      key: 'payment',
      value: 'Payment',
      text: 'Payment',
    },
    {
      key: 'refund',
      value: 'Refund',
      text: 'Refund',
    },
  ];

  const payTypeList = [
    {
      key: 'cash',
      value: 'Cash',
      text: 'Cash',
    },
    {
      key: 'ckToBRM',
      value: 'CK To BRM',
      text: 'CK To BRM',
    },
    {
      key: 'ckToDMV',
      value: 'CK To DMV',
      text: 'CK To DMV',
    },
    {
      key: 'ocToBRM',
      value: 'OC To BRM',
      text: 'OC To BRM',
    },
    {
      key: 'ocToDMV',
      value: 'OC To DMV',
      text: 'OC To DMV',
    },
    {
      key: 'creditCard',
      value: 'Credit Card',
      text: 'Credit Card',
    },
    {
      key: 'brmCredit',
      value: 'BRM Credit',
      text: 'BRM Credit',
    },
    {
      key: 'brmCharge',
      value: 'BRM Charge',
      text: 'BRM Charge',
    },
    {
      key: 'brmRefund',
      value: 'BRM Refund',
      text: 'BRM Refund',
    },
  ];

  const towardList = [
    {
      key: 'txnGeneral',
      value: 'Transaction - General',
      text: 'Transaction - General',
    },
    {
      key: 'txnRegistration',
      value: 'Transaction - Registration',
      text: 'Transaction - Registration',
    },
    {
      key: 'txnFee',
      value: 'Transaction - Fee',
      text: 'Transaction - Fee',
    },
  ];

  const inRegList = [
    {
      key: 'yes',
      value: '1',
      text: 'Yes',
    },
    {
      key: 'no',
      value: '0',
      text: 'No',
    },
  ];

  // initial values for the form are in the state object
  const formInitialValues = {
    [TXN_TYPE]: props.financialTxnForm.txnType,
    [PAYMENT_TYPE]: props.financialTxnForm.paymentType,
    [AMOUNT]: props.financialTxnForm.amount,
    [REF_NUMBER]: props.financialTxnForm.referenceNumber,
    [TOWARD]: props.financialTxnForm.toward,
    [CC_NAME]: props.financialTxnForm.ccName,
    [CC_NUMBER]: props.financialTxnForm.ccNumber,
    [CC_EXPIRE]: props.financialTxnForm.ccExpire,
    [CC_CVC]: props.financialTxnForm.ccCvc,
    [ACH_ROUTING]: props.financialTxnForm.achRouting,
    [ACH_ACCOUNT_NUMBER]: props.financialTxnForm.achAccountNumber,
    [ACH_NAME_ON_ACCOUNT]: props.financialTxnForm.achNameOnAccount,
  };

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={ValidationSchema}
      enableReinitialize={true}
      onSubmit={(values) => {
        // close upon submission if none of the process payment fields have values
        //console.log('Payment Submit values', values);
        if (
          values.ccName.length === 0 &&
          values.ccNumber.length === 0 &&
          values.ccExpire.length === 0 &&
          values.ccCVC.length === 0 &&
          values.achNameOnAccount.length === 0 &&
          values.achRouting.length === 0 &&
          values.achAccountNumber.length === 0
        ) {
          props.saveFinancialTxn(values, 0, props.paymentFor, 0, false);
        } else {
          props.saveFinancialTxn(
            values,
            0,
            props.paymentFor,
            0,
            stayOpenOnSubmit
          );
        }
      }}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        values,
        errors,
        touched,
        initialValues,
      }) => {
        //console.log(values);
        return (
          <Form onSubmit={handleSubmit}>
            <Grid stackable>
              <Grid.Row className='header-style'>
                <Grid.Column>PAYMENT/REFUND</Grid.Column>
              </Grid.Row>
              <Grid.Row>
                {props.errors.length > 0 && (
                  <Message error>{props.errors}</Message>
                )}
              </Grid.Row>
              <Grid.Column width={8}>
                <Segment>
                  <Header textAlign='center'>Payment/Refund Information</Header>
                  <Grid columns={2} stackable>
                    <Grid.Column className='brm-form-row brm-form-label'>
                      Transaction Type
                    </Grid.Column>
                    <Grid.Column className='brm-form-row'>
                      <Dropdown
                        placeholder='Select Transaction Type'
                        className='brm-form-dropdown payment-form'
                        name={TXN_TYPE}
                        selectOnBlur={true}
                        selection
                        options={txnTypeList}
                        defaultValue={initialValues[TXN_TYPE]}
                        disabled={
                          1 === props.financialTxnForm.paymentProcessed
                            ? true
                            : false
                        }
                        onChange={(e, { value }) =>
                          setFieldValue(TXN_TYPE, value)
                        }
                      />
                      {errors[TXN_TYPE] && touched[TXN_TYPE] ? (
                        <Message negative size='tiny'>
                          {errors[TXN_TYPE]}
                        </Message>
                      ) : null}
                    </Grid.Column>
                    <Grid.Column className='brm-form-label brm-form-row'>
                      Payment Type
                    </Grid.Column>
                    <Grid.Column className='brm-form-row'>
                      <Dropdown
                        placeholder='Select Payment Type'
                        className='brm-form-dropdown payment-form'
                        name={PAYMENT_TYPE}
                        selection
                        defaultValue={initialValues[PAYMENT_TYPE]}
                        options={payTypeList}
                        selectOnBlur={true}
                        disabled={
                          1 === props.financialTxnForm.paymentProcessed
                            ? true
                            : false
                        }
                        onChange={(e, { value }) => {
                          setFieldValue(PAYMENT_TYPE, value);
                          // show the appropriate payment form, if applicable
                          // and if there is an invoice id
                          if (props.invoiceId !== 0) {
                            switch (value) {
                              case 'Credit Card':
                                setPaymentEntryFields('CC');
                                break;
                              case 'CK To BRM':
                                setPaymentEntryFields('ACH');
                                break;
                              default:
                                setPaymentEntryFields('NONE');
                                break;
                            }
                          }
                        }}
                      />
                      {errors[PAYMENT_TYPE] && touched[PAYMENT_TYPE] ? (
                        <Message negative size='tiny'>
                          {errors[PAYMENT_TYPE]}
                        </Message>
                      ) : null}
                    </Grid.Column>

                    <Grid.Column className='brm-form-label brm-form-row'>
                      Amount
                    </Grid.Column>
                    <Grid.Column className='brm-form-row'>
                      <Input
                        type='text'
                        name={AMOUNT}
                        className='brm-form-input payment-form'
                        defaultValue={initialValues[AMOUNT]}
                        disabled={
                          1 === props.financialTxnForm.paymentProcessed
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors[AMOUNT] && touched[AMOUNT] ? (
                        <Message negative size='tiny'>
                          {errors[AMOUNT]}
                        </Message>
                      ) : null}
                    </Grid.Column>

                    <Grid.Column className='brm-form-label brm-form-row'>
                      Check #
                    </Grid.Column>
                    <Grid.Column className='brm-form-row'>
                      <Input
                        type='text'
                        name={REF_NUMBER}
                        defaultValue={initialValues[REF_NUMBER]}
                        className='brm-form-input payment-form'
                        disabled={
                          1 === props.financialTxnForm.paymentProcessed
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid.Column>

                    <Grid.Column className='brm-form-label brm-form-row'>
                      TOWARD
                    </Grid.Column>
                    <Grid.Column className='brm-form-row'>
                      <Dropdown
                        name={TOWARD}
                        placeholder='Select One'
                        defaultValue={initialValues[TOWARD]}
                        className='brm-form-dropdown payment-form'
                        selection
                        selectOnBlur={true}
                        options={towardList}
                        disabled={
                          1 === props.financialTxnForm.paymentProcessed
                            ? true
                            : false
                        }
                        onChange={(e, { value }) =>
                          setFieldValue(TOWARD, value)
                        }
                      />
                      {errors[TOWARD] && touched[TOWARD] ? (
                        <Message negative size='tiny'>
                          {errors[TOWARD]}
                        </Message>
                      ) : null}
                    </Grid.Column>
                  </Grid>
                </Segment>
              </Grid.Column>
              <Grid.Column width={8}>
                <Segment>
                  <Header textAlign='center'>Process Payment</Header>
                  <Grid>
                    <Grid.Column width={16}>
                      {1 === props.financialTxnForm.paymentProcessed && (
                        <Message positive>
                          This Payment Has Been Successfully Processed
                        </Message>
                      )}
                      {currentPaymentEntryFields === 'NONE' &&
                        1 !== props.financialTxnForm.paymentProcessed && (
                          <Message info>
                            Select Credit Card from Payment Type to process a
                            credit card payment. Select CK to BRM from Payment
                            Type to process an ACH payment.
                          </Message>
                        )}
                      {0 === props.invoiceId && (
                        <Message warning>
                          Payments Cannot Be Processed Until the Invoice Has
                          Been Saved
                        </Message>
                      )}
                      {currentPaymentEntryFields === 'CC' &&
                        1 !== props.financialTxnForm.paymentProcessed && (
                          <Grid columns={2}>
                            <Grid.Column width={6} className='brm-form-label'>
                              Name as it Appears on the Card
                            </Grid.Column>
                            <Grid.Column width={10}>
                              <Input
                                type='text'
                                name={CC_NAME}
                                defaultValue={initialValues[CC_NAME]}
                                className='brm-form-input'
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </Grid.Column>
                            <Grid.Column width={6} className='brm-form-label'>
                              Card Number
                            </Grid.Column>
                            <Grid.Column width={10}>
                              <Input
                                type='text'
                                name={CC_NUMBER}
                                defaultValue={initialValues[CC_NUMBER]}
                                className='brm-form-input'
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </Grid.Column>
                            <Grid.Column width={6} className='brm-form-label'>
                              Expiration Date
                            </Grid.Column>
                            <Grid.Column width={10}>
                              <InputMask
                                mask='99/99'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values[CC_EXPIRE]}
                              >
                                {(inputProps) => (
                                  <Input
                                    {...inputProps}
                                    type='text'
                                    name={CC_EXPIRE}
                                    className='brm-form-input'
                                  />
                                )}
                              </InputMask>
                            </Grid.Column>
                            <Grid.Column width={6} className='brm-form-label'>
                              CVC Number
                            </Grid.Column>
                            <Grid.Column width={10}>
                              <Input
                                type='text'
                                name={CC_CVC}
                                defaultValue={initialValues[CC_CVC]}
                                className='brm-form-input'
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </Grid.Column>
                          </Grid>
                        )}
                      {currentPaymentEntryFields === 'ACH' &&
                        1 !== props.financialTxnForm.paymentProcessed && (
                          <Grid>
                            <Grid.Column width={6} className='brm-form-label'>
                              Name on Account
                            </Grid.Column>
                            <Grid.Column width={10}>
                              <Input
                                type='text'
                                name={ACH_NAME_ON_ACCOUNT}
                                defaultValue={
                                  initialValues[ACH_NAME_ON_ACCOUNT]
                                }
                                className='brm-form-input'
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </Grid.Column>
                            <Grid.Column width={6} className='brm-form-label'>
                              Routing Number
                            </Grid.Column>
                            <Grid.Column width={10}>
                              <Input
                                type='text'
                                name={ACH_ROUTING}
                                defaultValue={initialValues[ACH_ROUTING]}
                                className='brm-form-input'
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </Grid.Column>
                            <Grid.Column width={6} className='brm-form-label'>
                              Account Number
                            </Grid.Column>
                            <Grid.Column width={10}>
                              <Input
                                type='text'
                                name={ACH_ACCOUNT_NUMBER}
                                defaultValue={initialValues[ACH_ACCOUNT_NUMBER]}
                                className='brm-form-input'
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </Grid.Column>
                          </Grid>
                        )}
                    </Grid.Column>
                  </Grid>
                </Segment>
              </Grid.Column>
              <Grid.Row>
                <Grid.Column>
                  {1 !== props.financialTxnForm.paymentProcessed && (
                    <Button
                      type='button'
                      className='inverted-button'
                      onClick={() => props.showFinancialTxnForm(false)}
                    >
                      Cancel
                    </Button>
                  )}
                  {1 === props.financialTxnForm.paymentProcessed && (
                    <Button
                      type='button'
                      className='inverted-button'
                      onClick={() => props.showFinancialTxnForm(false)}
                    >
                      Close
                    </Button>
                  )}
                  {currentPaymentEntryFields === 'NONE' &&
                    1 !== props.financialTxnForm.paymentProcessed && (
                      <Button type='submit' className='inverted-button'>
                        <Icon name='save' />
                        SAVE &amp; CLOSE
                      </Button>
                    )}
                  {currentPaymentEntryFields != 'NONE' &&
                    1 !== props.financialTxnForm.paymentProcessed && (
                      <Button type='submit' className='normal-button'>
                        <Icon name='payment' />
                        SAVE &amp; PROCESS PAYMENT
                      </Button>
                    )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
            {renderSaveConfirm(
              showSaveConfirm,
              setShowSaveConfirm,
              values,
              props
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state) => {
  return {
    financialTxnForm: state.financialTxns.financialTxnForm,
    errors: state.main.errors,
  };
};

export default connect(mapStateToProps, {
  saveFinancialTxn,
  showFinancialTxnForm,
  processPayment,
})(PaymentForm);
