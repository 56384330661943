import React from 'react';
import { Table } from 'semantic-ui-react';

/**
 * Component that displays a dollar total in a rectangular box.
 * Example use is for the total balance on the invoice page.
 * @param {object} props accepted props are:
 *  - title: name of the total (ex. Balance)
 *  - amount: dollar amount to display for the total
 *  - boxBackgroundColor: the background color of the box (optional, default: #299CE2)
 *  - boxFontColor: the font color for the title and amount (optional, default: #fff)
 *  - boxFontSize: the size of the font to use (optional, default: 1.2em)
 * @returns TotalsDisplayBox component
 */
const TotalsDisplayBox = (props) => {
  const boxStyles = {
    boxBackgroundColor:
      undefined === props.boxBackgroundColor
        ? '#299CE2'
        : props.boxBackgroundColor,
    boxFontColor:
      undefined === props.boxFontColor ? '#FFF' : props.boxFontColor,
    boxFontSize: undefined === props.boxFontSize ? '1.2em' : props.boxFontSize,
  };
  return (
    <Table className={props.boxClass} style={{ marginTop: 0 }}>
      <Table.Row
        style={{
          background: boxStyles.boxBackgroundColor,
          color: boxStyles.boxFontColor,
          fontSize: boxStyles.boxFontSize,
        }}
      >
        <Table.Cell>{props.title}</Table.Cell>
        <Table.Cell textAlign='right'>${props.amount}</Table.Cell>
      </Table.Row>
    </Table>
  );
};
export default TotalsDisplayBox;
