import moment from 'moment';

import {
  GET_DEALERS,
  SAVE_DEALER,
  LOGOUT_USER,
  SHOW_DEALER_FORM,
  FIND_DEALER,
  ADD_NEW_DEALER,
  CANCEL_SAVE_DEALER,
  DEALER_DELETED,
  CONFIRM_DEALER_ACTION,
  GET_DEALER_INVOICES,
  GET_DEALER_BILLINGS,
  CLEAR_DEALER_BILLINGS,
  CLEAR_DEALER_INVOICES,
  CLEAR_DEALER_FORM,
  UPDATE_FILTERED_DEALERS,
} from '../actions/types';

const INITIAL_STATE = {
  dealerForm: {
    dealerId: -1,
    dealerName: '',
    street: '',
    city: '',
    state: '',
    zipCode: '',
    phoneNumber: '',
    phoneExtension: '',
    faxNumber: '',
    email: '',
    courier: '',
    id: '',
    includeInBilling: '1',
    contactFirstName: '',
    contactLastName: '',
    route: '',
    hideFromList: '0',
    deleteFromList: 0,
    prospect: '0',
    notes: '',
  },
  dealerList: [],
  dealerListFilters: {
    hasBalance: 2,
    invoiceCreatedStart: moment().subtract(1, 'y'),
    invoiceCreatedEnd: moment(),
    useDateRange: false,
  },
  filteredDealerList: [],
  dealersInBillingList: [],
  dealerInvoices: [],
  dealerBillings: [],
  showModal: false,
  previousDealer: 0,
  newDealer: 0,
  addDealerCancelled: false,
  dealerSaved: false,
};

const dealerState = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_DEALERS:
      return {
        ...state,
        dealerList: action.payload.dealerList,
        dealersInBillingList: action.payload.dealersInBilling,
      };
    case ADD_NEW_DEALER:
    case DEALER_DELETED:
    case CLEAR_DEALER_FORM:
      return {
        ...state,
        dealerForm: {
          dealerId: -1,
          dealerName: '',
          street: '',
          city: '',
          state: '',
          zipCode: '',
          phoneNumber: '',
          phoneExtension: '',
          faxNumber: '',
          email: '',
          courier: '',
          id: '',
          includeInBilling: '1',
          contactFirstName: '',
          contactLastName: '',
          route: '',
          hideFromList: '0',
          deleteFromList: 0,
          notes: '',
          prospect: '0',
        },
        previousDealer: action.payload,
        newDealer: 0,
        addDealerCancelled: false,
        dealerSaved: false,
      };
    case SHOW_DEALER_FORM:
      return { ...state, showModal: action.payload };
    case FIND_DEALER:
      return {
        ...state,
        dealerForm: {
          dealerId: action.payload.id,
          dealerName: action.payload.company_name,
          street: action.payload.address,
          city: action.payload.city,
          state: action.payload.state,
          zipCode: action.payload.zip,
          phoneNumber: action.payload.phone,
          phoneExtension: action.payload.phone_ext,
          faxNumber: action.payload.fax,
          email: action.payload.email,
          courier: action.payload.courier,
          id: action.payload.courier_id,
          includeInBilling: action.payload.include_in_billing_system,
          contactFirstName: action.payload.contact_first_name,
          contactLastName: action.payload.contact_last_name,
          route: action.payload.route,
          hideFromList: action.payload.hide_from_list,
          deleteFromList: action.payload.delete_list,
          notes: action.payload.notes,
          prospect: action.payload.prospect,
        },
        newDealer: 0,
        dealerSaved: false,
      };
    case SAVE_DEALER:
      return {
        ...state,
        previousDealer: 0,
        newDealer: action.payload,
        addDealerCancelled: false,
        dealerSaved: true,
      };
    case CANCEL_SAVE_DEALER:
      return {
        ...state,
        addDealerCancelled: action.payload,
      };
    case CONFIRM_DEALER_ACTION:
      return { ...state, dealerSaved: false };
    case GET_DEALER_INVOICES:
      return { ...state, dealerInvoices: action.payload };
    case GET_DEALER_BILLINGS:
      return { ...state, dealerBillings: action.payload };
    case CLEAR_DEALER_BILLINGS:
      return { ...state, dealerBillings: [] };
    case CLEAR_DEALER_INVOICES:
      return { ...state, dealerInvoices: [] };
    case UPDATE_FILTERED_DEALERS:
      return {
        ...state,
        filteredDealerList: action.payload.filteredDealerList,
        dealerListFilters: {
          hasBalance: action.payload.hasBalance,
          invoiceCreatedStart: action.payload.invoiceCreatedStartDate,
          invoiceCreatedEnd: action.payload.invoiceCreatedEndDate,
          useDateRange: action.payload.useDateRange,
        },
      };
    case LOGOUT_USER:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default dealerState;
