/** GLOBAL */
export const SET_LOADING = 'set-loading';
export const THROW_ERROR = 'throw-error';
export const CLEAR_ERROR = 'clear-error';
export const SHOW_SUCCESS = 'show-success';
export const CLEAR_SUCCESS = 'clear-success';
export const CLEAR_WARNING = 'clear-warning';
export const GET_CAR_MAKES = 'get-car-makes';
export const SHOW_LOADING = 'show-loading';
export const SESSION_EXPIRED = 'session-expired';
export const FIND_CAR_MAKE = 'find-car-make';
export const SHOW_CAR_MAKE_FORM = 'show-car-make-form';
export const SAVE_CAR_MAKE = 'save-car-make';
export const CLEAR_CAR_MAKE_FORM = 'clear-car-make-form';
export const SET_CURRENT_INVOICE_FILTERS = 'set-current-invoice-filters';
export const SET_CURRENT_INVOICE_LIST_PAGE_INDEX =
  'set-current-invoice-list-page-index';

/** USERS */
export const AUTH_USER = 'auth-user';
export const AUTH_2FA = 'auth-2fa';
export const LOGOUT_USER = 'logout-user';
export const SUBMIT_USER_INFO = 'submit-user-info';
export const GET_USERS = 'get-users';
export const FIND_USER = 'find-user';
export const CLEAR_USER_FORM = 'clear-user-form';
export const SAVE_USER = 'save-user';
export const SHOW_USER_FORM = 'show-user-form';
export const FORGOT_PASS_EMAIL_SENT = 'forgot-pass-email-sent';
export const NEW_PASSWORD = 'new-password';
export const START_PASSWORD_RESET = 'start-password-reset';
export const REFRESH_CURRENT_USER = 'refresh-current-user';
export const CLOSE_USER_SAVE_RESULT = 'close-user-save-result';
// encryption var
export const ENCRYPT_KEY =
  'cQfTjWnZr4u7x!A%D*G-KaPdSgUkXp2s5v8y/B?E(H+MbQeThWmYq3t6w9z$C&F)J@NcRfUjXn2r4u7x!A%D*G-KaPdSgVkYp3s6v8y/B?E(H+MbQeThWmZq4t7w!z$C&F)J@NcRfUjXn2r5u8x/A?D*G-KaPdSgVkYp3s6v9y$B&E)H+MbQeThWmZq4t7w!z%C*F-JaNcRfUjXn2r5u8x/A?D(G+KbPeSgVkYp3s6v9y$B&E)H@McQfTjWmZq4t7w!z%C*F-JaNdRgUkXp2r5u8x/A?D(G+KbPeShVmYq3t6v9y$B&E)H@McQfTjWnZr4u7x!z%C*F-JaNdRgUkXp2s5v8y/B?D(G+KbPeShVmYq3t6w9z$C&F)H@McQfTjWnZr4u7x!A%D*G-KaNdRgUkXp2s5v8y/B?E(H+MbQeShVmYq3t6w9z$C&F)J@NcRfUjWnZr4u7x!A%D*G-KaPdSgVkYp2s5v8y/B?E(H+MbQeThWmZq4t6w9z$C&F)J@';

/** PLATE TRANSACTIONS */
export const SUBMIT_INVOICE = 'submit-invoice';
export const CALCULATE_CHARGES = 'calculate-charges';
export const GET_INVOICE = 'get-invoice';
export const ALL_INVOICES = 'all-invoices';
export const INVOICE_SAVED = 'invoice-saved';
export const SET_INTERNAL_NOTES = 'set-internal-notes';
export const SET_INVOICE_NOTES = 'set-invoice-notes';
export const CLEAR_INVOICE = 'clear-invoice';
export const UPDATE_CURRENT_BALANCE = 'update-current-balance';
export const PRINT_INVOICE = 'print-invoice';
export const FILTER_PLATES = 'filter-plates';
export const SELECT_FOR_PRINT = 'select-for-print';
export const PRINT_MULTI_INVOICES = 'print-multi-invoices';
export const SET_INVOICE_ID = 'set-invoice-id';
export const INVOICE_REMOVED = 'invoice-removed';
export const CALCULATE_TOTAL_PROFIT = 'calculate-total-profit';

/** DEALERS */
export const GET_DEALERS = 'get-dealers';
export const FIND_DEALER = 'find-dealer';
export const SAVE_DEALER = 'save-dealer';
export const SHOW_DEALER_FORM = 'show-dealer-form';
export const ADD_NEW_DEALER = 'add-new-dealer';
export const SAVE_PREVIOUS_DEALER = 'save-previous-dealer';
export const CANCEL_SAVE_DEALER = 'cancel-save-dealer';
export const DEALER_DELETED = 'dealer-deleted';
export const CONFIRM_DEALER_ACTION = 'confirm-dealer-action';
export const GET_DEALER_INVOICES = 'get-dealer-invoices';
export const CLEAR_DEALER_INVOICES = 'clear-dealer-invoices';
export const GET_DEALER_BILLINGS = 'get-dealer-billings';
export const CLEAR_DEALER_BILLINGS = 'clear-dealer-billings';
export const CLEAR_DEALER_FORM = 'clear-dealer-form';
export const UPDATE_FILTERED_DEALERS = 'update-filtered-dealers';

/** LIENHOLDERS */
export const GET_LIEN_HOLDERS = 'get-lien-holders';
export const FIND_LIEN_HOLDER = 'find-lien-holder';
export const SAVE_LIEN_HOLDER = 'save-lien-holder';
export const SHOW_LIEN_HOLDER_FORM = 'show-lien-holder-form';
export const ADD_NEW_LIEN_HOLDER = 'add-new-lien-holder';
export const LIEN_HOLDER_DELETED = 'lien-holder-deleted';

/** FINANCIAL TRANSACTIONS */
export const SAVE_FINANCIAL_TXN = 'save-financial-txn';
export const FIND_FINANCIAL_TXN = 'find-financial-txn';
export const GET_FINANCIAL_TXN_BY_INVOICE = 'get-financial-txn-by-invoice';
export const SHOW_FINANCIAL_TXN_FORM = 'show-financial-txn-form';
export const SAVE_TEMP_FINANCIAL_TXN = 'save-temp-financial-txn';
export const ADD_NEW_FINANCIAL_TXN = 'add-new-financtial-txn';
export const SAVE_TEMP_COMBINED_INVOICE_TXNS = 'save-combined-invoice-txns';
export const AUTH_PAYMENT = 'auth-payment';
export const FINISH_PAYMENT = 'finish-payment';
export const UPDATE_PAYMENT_FORM = 'update-payment-form';
export const RESET_FINANCIAL_TXN_SAVED = 'reset-financial-txn-saved';
export const UPDATE_PAYMENT_PROCESSED = 'update-payment-processed';

/** NOTES */
export const START_NOTE = 'start-note';
export const SAVE_NOTE = 'save-note';
export const SHOW_NOTES_FORM = 'show-notes-form';
export const GET_NOTES_LIST = 'get-notes-list';
export const SAVE_TEMP_INTERNAL_NOTE = 'save-temp-internal-note';
export const SAVE_TEMP_INVOICE_NOTE = 'save-temp-invoice-note';
export const SAVE_TEMP_COMBINED_INVOICE_NOTE =
  'save-temp-combined-invoice-note';
export const SAVE_TEMP_COMBINED_INVOICE_INTERNAL_NOTE =
  'save-temp-combined-invoice-internal-note';
export const REMOVE_INVOICE_NOTE = 'remove-invoice-note';
export const REMOVE_INVOICE_INTERNAL_NOTE = 'remove-invoice-internal-note';
export const REMOVE_COMBINED_INVOICE_NOTE = 'remove-combined-invoice-note';
export const REMOVE_COMBINED_INVOICE_INTERNAL_NOTE =
  'remove-combined-invoice-internal-note';
export const NOTE_REMOVED = 'note-removed';

/** REPORTS */
export const GET_REPORTS = 'get-reports';
export const SELECT_REPORT = 'select-report';
export const UPDATE_REPORT_PARAMS = 'update-report-params';
export const SHOW_REPORT = 'show-report';
export const RESET_FILTERS = 'reset-filters';

/** COMBINED INVIOCES */
export const GET_COMBINED_INVOICES = 'get-combined-invoices';
export const FIND_COMBINED_INVOICE = 'find-combined-invoice';
export const SET_COMBINED_INVOICE_NOTES = 'set-combined-invoice-notes';
export const SET_COMBINED_INVOICE_INTERNAL_NOTES =
  'set-combined-invoice-internal-notes';
export const GET_FINANCIAL_TXN_BY_COMBINED_INVOICE =
  'get-financial-txn-by-combined-invoice';
export const GET_INVOICES_FOR_COMBINED_INVOICE =
  'get-invoices-for-combined-invoice';
export const TOGGLE_SELECTED_INVOICE = 'toggle-selected-invoice';
export const SAVE_COMBINED_INVOICE = 'save-combined-invoice';
export const COMBINED_INVOICE_SAVED = 'combined-invoice-saved';
export const UPDATE_COMBINED_INVOICE_TOTAL_BALANCE =
  'update-combined-invoice-total-balance';
export const ADD_SINGLE_INVOICE_PAYMENT_TXN_FROM_COMBINED =
  'add-single-invoice-payment-txn-from-combined';
export const NEW_COMBINED_INVOICE = 'new-combined-invoice';
export const SET_COMBINED_INVOICE_DEALER = 'set-combined-invoice-dealer';
export const PRINT_COMBINED_INVOICE = 'print-combined-invoice';

/** BILLING */
export const SUBMIT_BILLING = 'submit-billing';
export const GET_BILLING = 'get-billing';
export const GET_DEALER_OPEN_INVOICES = 'get-dealer-open-invoices';
export const BILLING_SAVED = 'billing-saved';
export const CLEAR_BILLING = 'clear-billing';
export const BILLING_REMOVED = 'billing-removed';
export const ADD_PAYMENT_TO_LINE_ITEM = 'add-payment-to-line-item';

/** EXPENSES */
export const SET_EXPENSE = 'set-expense';
export const SAVE_EXPENSE = 'save-expense';
export const SHOW_EXPENSE_FORM = 'show-expense-form';
export const SET_INVOICE_EXPENSES = 'set-invoice-expenses';
export const REMOVE_EXPENSE = 'remove-expense';
export const EXPENSE_REMOVED = 'expense-removed';
export const UPDATE_EXPENSE = 'update-expense';
export const CLEAR_EXPENSE_FORM = 'clear-expense-form';
export const CALCULATE_EXPENSES = 'calculate-expenses';

/** STATE FEES */
export const SET_STATE_FEES = 'set-state-fees';
export const SAVE_STATE_FEES = 'save-state-fees';
export const UPDATE_STATE_FEES = 'update-state-fees';
export const RESET_STATE_FEES_UPDATED = 'reset-state-fees-updated';
export const UPDATE_STATE_FEE_RESULT = 'update-state-fee-result';
