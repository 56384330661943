/**
 * State object for the Billings Form
 */
import _ from 'lodash';
// actions
import {
  BILLING_REMOVED,
  BILLING_SAVED,
  SUBMIT_BILLING,
  GET_BILLING,
  GET_DEALER_BILLINGS,
  GET_DEALER_OPEN_INVOICES,
  CLEAR_BILLING,
} from '../actions/types';

const INITIAL_STATE = {
  billingId: 0,
  dealerId: 0,
  amountReceived: 0.0,
  paymentInfo: {
    paymentDate: '',
    paymentMethod: 'Check',
    referenceNumber: '',
    paymentAmount: 0.0,
    paymentProcessed: 0,
    ccName: '',
    ccNumber: '',
    ccExpire: '',
    ccCvc: '',
    achRouting: '',
    achAccountNumber: '',
    achNameOnAccount: '',
  },
  unpaidInvoiceList: [],
  invoicesAmountApplied: [],
  amountToApply: 0.0,
  amountToCredit: 0.0,
  billingSaved: false,
  billingMemo: '',
};

const billingState = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_BILLING:
      return {
        ...state,
        billingId: action.payload.billingId,
        amountReceived: action.payload.amountReceived,
        paymentInfo: action.payload.paymentInfo,
        amountToApply: action.payload.amountToApply,
        amountToCredit: action.payload.amountToCredit,
        amountReceived: action.payload.amountReceived,
        dealerId: action.payload.dealerId,
        invoicesAmountApplied: action.payload.invoicesApplied,
        billingMemo: action.payload.memo,
      };
    case GET_DEALER_OPEN_INVOICES:
      return { ...state, unpaidInvoiceList: action.payload };
    case BILLING_SAVED:
    case BILLING_REMOVED:
      return { ...state, billingSaved: action.payload };
    case CLEAR_BILLING:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default billingState;
