import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import InputMask from 'react-input-mask';
import { AES } from 'crypto-js';
import {
  Input,
  Grid,
  Message,
  Button,
  Icon,
  Container,
} from 'semantic-ui-react';
import {
  USERNAME,
  PASSWORD,
  CONFIRM_PASSWORD,
  FIRST_NAME,
  LAST_NAME,
  EMAIL,
  PHONE_NUMBER,
  INITIALS,
  CELL_PHONE,
  ACCOUNT_TYPE,
} from './FormSetup/FieldNames';
import ValidationSchema from './FormSetup/ValidationSchema';
import LoadingComp from '../LoadingComp';
import PageHeader from '../PageHeader';
import SuccessMsg from '../SuccessMsg';
import ErrorMsg from '../ErrorMsg';
import { saveUser, getUser } from '../../actions';
import { ENCRYPT_KEY } from '../../actions/types';

const renderLoader = (show, message) => {
  if (show) {
    return <LoadingComp msg={message} />;
  }
  return null;
};

const ProfileForm = (props) => {
  const [formPopulated, setFormPopulated] = useState(false);

  // populate the form data with the current user
  useEffect(() => {
    if (!formPopulated) {
      props.getUser(props.currentUser.userId);
      setFormPopulated(true);
    }
  }, []);

  const formInitialValues = {
    [USERNAME]: props.userForm.username,
    [PASSWORD]: '',
    [CONFIRM_PASSWORD]: '',
    [FIRST_NAME]: props.userForm.firstName,
    [LAST_NAME]: props.userForm.lastName,
    [EMAIL]: props.userForm.email,
    [PHONE_NUMBER]: props.userForm.phoneNumber,
    [INITIALS]: props.userForm.initials,
    [CELL_PHONE]: props.userForm.cellPhone,
    [ACCOUNT_TYPE]: props.userForm.accountType,
  };

  return (
    <Container className='brm-container'>
      <PageHeader />
      <h1>My Profile</h1>
      <SuccessMsg />
      <ErrorMsg />
      <Formik
        initialValues={formInitialValues}
        validationSchema={ValidationSchema}
        enableReinitialize={true}
        onSubmit={(values) => props.saveUser(values, true)}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          values,
          errors,
          touched,
          initialValues,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Grid columns={2} stackable>
                <Grid.Column width={16}>
                  <Message>
                    Passwords must be at least 8 Characters long, have at least
                    1 Uppercase Letter, 1 Lowercase Letter, 1 Number and 1
                    Special Character
                  </Message>
                </Grid.Column>
                <Grid.Column className='brm-form-label brm-form-row'>
                  Username
                </Grid.Column>
                <Grid.Column className='brm-form-row'>
                  <Input
                    type='text'
                    name={USERNAME}
                    className='brm-form-input'
                    defaultValue={initialValues[USERNAME]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors[USERNAME] && touched[USERNAME] ? (
                    <Message negative size='tiny'>
                      {errors[USERNAME]}
                    </Message>
                  ) : null}
                </Grid.Column>
                <Grid.Column className='brm-form-label brm-form-row'>
                  Password
                </Grid.Column>
                <Grid.Column className='brm-form-row'>
                  <Input
                    type='password'
                    name={PASSWORD}
                    className='brm-form-input'
                    defaultValue={initialValues[PASSWORD]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors[PASSWORD] && touched[PASSWORD] ? (
                    <Message negative size='tiny'>
                      {errors[PASSWORD]}
                    </Message>
                  ) : null}
                </Grid.Column>
                <Grid.Column className='brm-form-label brm-form-row'>
                  Confirm Password
                </Grid.Column>
                <Grid.Column className='brm-form-row'>
                  <Input
                    type='password'
                    name={CONFIRM_PASSWORD}
                    className='brm-form-input'
                    defaultValue={initialValues[CONFIRM_PASSWORD]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors[CONFIRM_PASSWORD] && touched[CONFIRM_PASSWORD] ? (
                    <Message negative size='tiny'>
                      {errors[CONFIRM_PASSWORD]}
                    </Message>
                  ) : null}
                </Grid.Column>
                <Grid.Column className='brm-form-label brm-form-row'>
                  First Name
                </Grid.Column>
                <Grid.Column className='brm-form-row'>
                  <Input
                    type='text'
                    name={FIRST_NAME}
                    className='brm-form-input'
                    defaultValue={initialValues[FIRST_NAME]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors[FIRST_NAME] && touched[FIRST_NAME] ? (
                    <Message negative size='tiny'>
                      {errors[FIRST_NAME]}
                    </Message>
                  ) : null}
                </Grid.Column>
                <Grid.Column className='brm-form-label brm-form-row'>
                  Last Name
                </Grid.Column>
                <Grid.Column className='brm-form-row'>
                  <Input
                    type='text'
                    name={LAST_NAME}
                    className='brm-form-input'
                    defaultValue={initialValues[LAST_NAME]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors[LAST_NAME] && touched[LAST_NAME] ? (
                    <Message negative size='tiny'>
                      {errors[LAST_NAME]}
                    </Message>
                  ) : null}
                </Grid.Column>
                <Grid.Column className='brm-form-label brm-form-row'>
                  Initials
                </Grid.Column>
                <Grid.Column className='brm-form-row'>
                  <Input
                    type='text'
                    name={INITIALS}
                    className='brm-form-input'
                    defaultValue={initialValues[INITIALS]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors[INITIALS] && touched[INITIALS] ? (
                    <Message negative size='tiny'>
                      {errors[INITIALS]}
                    </Message>
                  ) : null}
                </Grid.Column>
                <Grid.Column className='brm-form-label brm-form-row'>
                  Email
                </Grid.Column>
                <Grid.Column className='brm-form-row'>
                  <Input
                    type='text'
                    name={EMAIL}
                    className='brm-form-input'
                    defaultValue={initialValues[EMAIL]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors[EMAIL] && touched[EMAIL] ? (
                    <Message negative size='tiny'>
                      {errors[EMAIL]}
                    </Message>
                  ) : null}
                </Grid.Column>
                <Grid.Column className='brm-form-label brm-form-row'>
                  Phone Extension
                </Grid.Column>
                <Grid.Column className='brm-form-row'>
                  <Input
                    type='text'
                    name={PHONE_NUMBER}
                    className='brm-form-input'
                    defaultValue={initialValues[PHONE_NUMBER]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid.Column>
                <Grid.Column className='brm-form-label brm-form-row'>
                  Cell Phone
                </Grid.Column>
                <Grid.Column className='brm-form-row'>
                  <InputMask
                    mask='(999) 999-9999'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values[CELL_PHONE]}
                  >
                    {(inputProps) => (
                      <Input
                        {...inputProps}
                        type='text'
                        name={CELL_PHONE}
                        className='brm-form-input'
                        defaultValue={initialValues[CELL_PHONE]}
                      />
                    )}
                  </InputMask>
                </Grid.Column>
                <Grid.Row>
                  <Grid.Column>
                    <Link to='/dashboard'>
                      <Button type='button' className='inverted-button'>
                        <Icon name='refresh' />
                        Return to Dashboard
                      </Button>
                    </Link>
                    <Button type='submit' className='inverted-button'>
                      <Icon name='save' />
                      SAVE PROFILE
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      {renderLoader(props.showLoading, 'Saving Profile...')}
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    userForm: state.users.userForm,
    currentUser: state.users.currentUser,
    showLoading: state.main.isLoading,
  };
};

export default connect(mapStateToProps, { saveUser, getUser })(ProfileForm);
