import React, { Fragment } from 'react';
import { Header } from 'semantic-ui-react';

const DealerCurrentBalanceText = (props) => {
  return (
    <Fragment>
      <Header>Current Balance</Header>
      <span style={{ fontWeight: '900', fontSize: '2.0em' }}>
        ${parseFloat(props.currentDealerBalance).toFixed(2)}
      </span>
    </Fragment>
  );
};

export default DealerCurrentBalanceText;
