import React, { useState } from 'react';
import {
  Grid,
  Button,
  Checkbox,
  Dropdown,
  Input,
  Accordion,
  Icon,
  Radio,
} from 'semantic-ui-react';
import moment from 'moment';
import DatePicker from 'react-date-picker';
import _ from 'lodash';
import PaymentAmountSearch from './PaymentAmountSearch';
import { invoiceStatus, dateFilterList, balanceSelect } from '../StaticLists';

/**
 * Updates the date ranges based on the filter selection
 * @param {string} rangeSelection
 * @param {object} stateObj
 * @param {function} stateFunction
 */
const returnUpdatedDates = (rangeSelection, stateObj, stateFunction) => {
  switch (rangeSelection) {
    case 'today':
      stateFunction({
        ...stateObj,
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      });
      break;
    case 'yesterday':
      stateFunction({
        ...stateObj,
        startDate: moment().subtract(1, 'd').format('YYYY-MM-DD'),
        endDate: moment().subtract(1, 'd').format('YYYY-MM-DD'),
      });
      break;
    case 'week':
      stateFunction({
        ...stateObj,
        startDate: moment().subtract(7, 'd').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      });
      break;
    case 'thirtyday':
      stateFunction({
        ...stateObj,
        startDate: moment().subtract(30, 'd').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      });
      break;
    case 'nintyday':
      stateFunction({
        ...stateObj,
        startDate: moment().subtract(90, 'd').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      });
      break;
    case 'year':
      stateFunction({
        ...stateObj,
        startDate: moment().subtract(1, 'y').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      });
      break;
  }
};

const renderPrintButton = (show, printInvoicesFunction, invoicesSelected) => {
  if (show) {
    return (
      <Button
        className='inverted-button'
        onClick={() => printInvoicesFunction(invoicesSelected)}
      >
        Print Selected
      </Button>
    );
  }
  return null;
};

/**
 * Handles the click events when an accordion is clicked
 * @param {object} titleProps
 * @param {int} currentIdx
 * @param {function} stateFunction
 */
const handleAccordionClicks = (titleProps, currentIdx, stateFunction) => {
  const { index } = titleProps;
  const newIdx = currentIdx === index ? -1 : index;
  //console.log('New Index', newIdx);
  stateFunction(newIdx);
};

const renderAccordionIcon = (show, active) => {
  if (show) {
    return (
      <div className='brm-accordion-icon'>
        <Icon name={active === 0 ? 'minus' : 'plus'} />
      </div>
    );
  }
  return null;
};

const PlateTxnFilters = (props) => {
  const { isTabletOrMobile } = props;

  // filter state
  const [updatedDates, setUpdatedDates] = useState({
    startDate:
      props.currentFilters.startDate === ''
        ? moment().subtract(1, 'y').format('YYYY-MM-DD')
        : moment(props.currentFilters.startDate).format('YYYY-MM-DD'),
    endDate:
      props.currentFilters.endDate === ''
        ? moment().format('YYYY-MM-DD')
        : moment(props.currentFilters.endDate).format('YYYY-MM-DD'),
  });
  const [searchByDate, setSearchByDate] = useState(
    props.currentFilters.dateSearchBy
  );
  const [selectedDateFilter, setSelectedDateFilter] = useState('year');
  const [selectedStatusFilter, setStatusFilter] = useState(
    props.currentFilters.status === '' ? 'all' : props.currentFilters.status
  );
  const [selectedBalanceFilter, setBalanceFilter] = useState(
    props.currentFilters.hasBalance === ''
      ? 'all'
      : props.currentFilters.hasBalance
  );
  const [selectedDealerFilter, setDealerFilter] = useState(
    props.currentFilters.dealer === '' ? 'all' : props.currentFilters.dealer
  );
  const [showDeletedFilter, setShowDeletedFilter] = useState('deleted');
  const [globalFilter, setGlobalFilter] = useState(
    props.currentFilters.fuzzy === '' ? '' : props.currentFilters.fuzzy
  );
  const [checkNumber, setCheckNumber] = useState(
    props.currentFilters.checkNumber === ''
      ? ''
      : props.currentFilters.checkNumber
  );
  const [invoiceNumberFilter, setInvoiceNumberFilter] = useState(
    props.currentFilters.invoiceNumber === ''
      ? ''
      : props.currentFilters.invoiceNumber
  );
  const [paymentAmountFilter, setPaymentAmountFilter] = useState({
    paymentAmountLow:
      props.currentFilters.paymentAmountLow === ''
        ? ''
        : props.currentFilters.paymentAmountLow,
    paymentAmountHigh:
      props.currentFilters.paymentAmountHigh === ''
        ? ''
        : props.currentFilters.paymentAmountHigh,
  });
  const [ignoreDates, setIgnoreDates] = useState(
    props.currentFilters.ignoreDates
  );
  const [dateFilterAccordionActive, setDateFilterAccordionActive] = useState(
    isTabletOrMobile ? -1 : 0
  );
  const [statusFilterAccordionActive, setStatusFilterAccordionActive] =
    useState(isTabletOrMobile ? -1 : 0);
  const [checkNoFilterAccordionActive, setCheckNoFilterAccordionActive] =
    useState(isTabletOrMobile ? -1 : 0);
  const [
    paymentAmountFilterAccordionActive,
    setPaymentAmountFilterAccordionActive,
  ] = useState(isTabletOrMobile ? -1 : 0);
  const [balanceFilterAccordionActive, setBalanceFilterAccordionActive] =
    useState(isTabletOrMobile ? -1 : 0);
  const [globalFilterAccordionActive, setGlobalFilterAccordionActive] =
    useState(isTabletOrMobile ? -1 : 0);
  const [dealerFilterAccordionActive, setDealerFilterAccordionActive] =
    useState(isTabletOrMobile ? -1 : 0);
  const [invoiceNumberAccordionActive, setInvoiceNumberAccordionActive] =
    useState(isTabletOrMobile ? -1 : 0);

  // filter list modifications
  let invoiceStatusFilter = [
    {
      key: 'all',
      value: 'all',
      text: 'All',
    },
  ];
  _.each(invoiceStatus, (values) => {
    invoiceStatusFilter.push(values);
  });

  let dealerFilter = [
    {
      key: 'all',
      value: 'all',
      text: 'All',
    },
  ];
  _.each(props.dealers, (values) => {
    dealerFilter.push({
      key: values.dealerId,
      value: values.dealerId,
      text: values.companyName,
    });
  });

  return (
    <Grid
      columns={4}
      style={{ marginTop: '10px' }}
      stackable
      className='brm-filters-container'
    >
      <Grid.Column className='filter-column-alt'>
        <Accordion defaultActiveIndex={dateFilterAccordionActive}>
          <Accordion.Title
            className='brm-mobile-accordion'
            index={0}
            active={dateFilterAccordionActive === 0}
            onClick={(e, titleProps) => {
              isTabletOrMobile &&
                handleAccordionClicks(
                  titleProps,
                  dateFilterAccordionActive,
                  setDateFilterAccordionActive
                );
            }}
          >
            <span className='brm-filter-header'>Filter By </span>
            <Radio
              label='Created Date'
              name='searchByDateGroup'
              value='created'
              checked={searchByDate === 'created'}
              onChange={(e, { value }) => setSearchByDate(value)}
            />{' '}
            <Radio
              label='Updated Date'
              name='searchByDateGroup'
              value='updated'
              checked={searchByDate === 'updated'}
              onChange={(e, { value }) => setSearchByDate(value)}
            />
            {renderAccordionIcon(isTabletOrMobile, dateFilterAccordionActive)}
          </Accordion.Title>
          <Checkbox
            toggle
            label='Turn On to Ignore Dates'
            style={{ marginTop: '5px', marginBottom: '5px' }}
            checked={ignoreDates}
            onChange={(e) => setIgnoreDates(!ignoreDates)}
          />
          <Accordion.Content active={dateFilterAccordionActive == 0}>
            <Dropdown
              name='rangeList'
              value={selectedDateFilter}
              className='brm-form-dropdown brm-form-header-dropdown'
              selection
              selectOnBlur={true}
              options={dateFilterList}
              style={{ fontSize: '1em' }}
              onChange={(e, { value }) => {
                setSelectedDateFilter(value);
                returnUpdatedDates(value, updatedDates, setUpdatedDates);
              }}
            />
            <Grid columns={2} style={{ marginTop: '10px' }}>
              <Grid.Column className='brm-date-filter'>
                <span className='brm-filter-header'>Start</span> <br />
                <DatePicker
                  value={new Date(moment(updatedDates.startDate))}
                  onChange={(date) => {
                    setSelectedDateFilter('custom');
                    setUpdatedDates({
                      ...updatedDates,
                      startDate: moment(date).format('YYYY-MM-DD'),
                    });
                  }}
                  calendarIcon={<Icon name='calendar alternate outline' />}
                  clearIcon={null}
                />
              </Grid.Column>
              <Grid.Column className='brm-date-filter'>
                <span className='brm-filter-header'>End</span> <br />
                <DatePicker
                  value={new Date(moment(updatedDates.endDate))}
                  onChange={(date) => {
                    setSelectedDateFilter('custom');
                    setUpdatedDates({
                      ...updatedDates,
                      endDate: moment(date).format('YYYY-MM-DD'),
                    });
                  }}
                  calendarIcon={<Icon name='calendar alternate outline' />}
                  clearIcon={null}
                />
              </Grid.Column>
            </Grid>
          </Accordion.Content>
        </Accordion>
      </Grid.Column>
      <Grid.Column>
        <Accordion defaultActiveIndex={statusFilterAccordionActive}>
          <Accordion.Title
            className='brm-mobile-accordion'
            index={0}
            active={statusFilterAccordionActive === 0}
            onClick={(e, titleProps) => {
              isTabletOrMobile &&
                handleAccordionClicks(
                  titleProps,
                  statusFilterAccordionActive,
                  setStatusFilterAccordionActive
                );
            }}
          >
            <span className='brm-filter-header'>Filter By Status</span>
            {renderAccordionIcon(isTabletOrMobile, statusFilterAccordionActive)}
          </Accordion.Title>
          <Accordion.Content active={statusFilterAccordionActive === 0}>
            <Dropdown
              name='statusList'
              className='brm-form-dropdown brm-form-header-dropdown'
              selection
              search
              selectOnBlur={true}
              value={selectedStatusFilter}
              options={invoiceStatusFilter}
              style={{ fontSize: '1em' }}
              onChange={(e, { value }) => {
                setStatusFilter(value);
              }}
            />
          </Accordion.Content>
        </Accordion>
        <Grid
          columns={1}
          style={{ marginTop: '10px' }}
          className={isTabletOrMobile ? 'filter-column-alt' : ''}
        >
          <Grid.Column>
            <Accordion defaultActiveIndex={checkNoFilterAccordionActive}>
              <Accordion.Title
                className='brm-mobile-accordion'
                index={0}
                active={checkNoFilterAccordionActive === 0}
                onClick={(e, titleProps) => {
                  isTabletOrMobile &&
                    handleAccordionClicks(
                      titleProps,
                      checkNoFilterAccordionActive,
                      setCheckNoFilterAccordionActive
                    );
                }}
              >
                <span className='brm-filter-header'>Filter By Check #</span>
                {renderAccordionIcon(
                  isTabletOrMobile,
                  checkNoFilterAccordionActive
                )}
              </Accordion.Title>
              <Accordion.Content active={checkNoFilterAccordionActive === 0}>
                <Input
                  type='text'
                  name='checkNumber'
                  className='brm-form-input'
                  value={checkNumber}
                  onChange={(e, { value }) => setCheckNumber(value)}
                />
              </Accordion.Content>
            </Accordion>
          </Grid.Column>
        </Grid>
        <Grid
          columns={1}
          style={{ marginTop: '10px' }}
          className={isTabletOrMobile ? 'filter-column-alt' : ''}
        >
          <Grid.Column>
            <Accordion defaultActiveIndex={paymentAmountFilterAccordionActive}>
              <Accordion.Title
                className='brm-mobile-accordion'
                index={0}
                active={paymentAmountFilterAccordionActive === 0}
                onClick={(e, titleProps) => {
                  isTabletOrMobile &&
                    handleAccordionClicks(
                      titleProps,
                      paymentAmountFilterAccordionActive,
                      setPaymentAmountFilterAccordionActive
                    );
                }}
              >
                <span className='brm-filter-header'>
                  Filter By Payment Amounts
                </span>
                {renderAccordionIcon(
                  isTabletOrMobile,
                  paymentAmountFilterAccordionActive
                )}
              </Accordion.Title>
              <Accordion.Content
                active={paymentAmountFilterAccordionActive === 0}
              >
                <PaymentAmountSearch
                  paymentAmountFilter={paymentAmountFilter}
                  setPaymentAmountFilter={setPaymentAmountFilter}
                />
              </Accordion.Content>
            </Accordion>
          </Grid.Column>
        </Grid>
      </Grid.Column>
      <Grid.Column className={isTabletOrMobile ? '' : 'filter-column-alt'}>
        <Accordion defaultActiveIndex={balanceFilterAccordionActive}>
          <Accordion.Title
            className='brm-mobile-accordion'
            index={0}
            active={balanceFilterAccordionActive === 0}
            onClick={(e, titleProps) => {
              isTabletOrMobile &&
                handleAccordionClicks(
                  titleProps,
                  balanceFilterAccordionActive,
                  setBalanceFilterAccordionActive
                );
            }}
          >
            <span className='brm-filter-header'>Filter if has a balance?</span>
            {renderAccordionIcon(
              isTabletOrMobile,
              balanceFilterAccordionActive
            )}
          </Accordion.Title>
          <Accordion.Content active={balanceFilterAccordionActive === 0}>
            <Dropdown
              name='hasBalanceList'
              className='brm-form-dropdown brm-form-header-dropdown'
              selection
              selectOnBlur={true}
              value={selectedBalanceFilter}
              options={balanceSelect}
              style={{ fontSize: '1em' }}
              onChange={(e, { value }) => {
                setBalanceFilter(value);
              }}
            />
          </Accordion.Content>
        </Accordion>
        <Grid
          columns={1}
          style={{ marginTop: '10px' }}
          className={isTabletOrMobile ? 'filter-column-alt' : ''}
        >
          <Grid.Column>
            <Accordion defaultActiveIndex={invoiceNumberAccordionActive}>
              <Accordion.Title
                className='brm-mobile-accordion'
                index={0}
                active={invoiceNumberAccordionActive === 0}
                onClick={(e, titleProps) => {
                  isTabletOrMobile &&
                    handleAccordionClicks(
                      titleProps,
                      invoiceNumberAccordionActive,
                      setInvoiceNumberAccordionActive
                    );
                }}
              >
                <span className='brm-filter-header'>Invoice Number</span>
                {renderAccordionIcon(
                  isTabletOrMobile,
                  invoiceNumberAccordionActive
                )}
              </Accordion.Title>
              <Accordion.Content active={invoiceNumberAccordionActive === 0}>
                <Input
                  type='text'
                  name='invoiceNumber'
                  className='brm-form-input'
                  value={invoiceNumberFilter}
                  onChange={(e, { value }) => setInvoiceNumberFilter(value)}
                />
              </Accordion.Content>
            </Accordion>
          </Grid.Column>
        </Grid>
      </Grid.Column>
      <Grid.Column>
        <Accordion defaultActiveIndex={dealerFilterAccordionActive}>
          <Accordion.Title
            className='brm-mobile-accordion'
            index={0}
            active={dealerFilterAccordionActive === 0}
            onClick={(e, titleProps) => {
              isTabletOrMobile &&
                handleAccordionClicks(
                  titleProps,
                  dealerFilterAccordionActive,
                  setDealerFilterAccordionActive
                );
            }}
          >
            <span className='brm-filter-header'>Filter By Dealer</span>
            {renderAccordionIcon(isTabletOrMobile, dealerFilterAccordionActive)}
          </Accordion.Title>
          <Accordion.Content active={dealerFilterAccordionActive === 0}>
            <Dropdown
              name='dealerList'
              className='brm-form-dropdown brm-form-header-dropdown'
              selection
              search
              selectOnBlur={true}
              value={selectedDealerFilter}
              options={dealerFilter}
              style={{ fontSize: '1em' }}
              onChange={(e, { value }) => {
                setDealerFilter(value);
              }}
            />
          </Accordion.Content>
        </Accordion>
        <Grid
          columns={1}
          style={{ marginTop: '10px' }}
          className={isTabletOrMobile ? 'filter-column-alt' : ''}
        >
          <Grid.Column>
            <Accordion defaultActiveIndex={globalFilterAccordionActive}>
              <Accordion.Title
                className='brm-mobile-accordion'
                index={0}
                active={globalFilterAccordionActive === 0}
                onClick={(e, titleProps) => {
                  isTabletOrMobile &&
                    handleAccordionClicks(
                      titleProps,
                      globalFilterAccordionActive,
                      setGlobalFilterAccordionActive
                    );
                }}
              >
                <span className='brm-filter-header'>
                  Search (Overrides Other Filters)
                </span>
                {renderAccordionIcon(
                  isTabletOrMobile,
                  globalFilterAccordionActive
                )}
              </Accordion.Title>
              <Accordion.Content active={globalFilterAccordionActive === 0}>
                <Input
                  type='text'
                  name='fuzzySearch'
                  className='brm-form-input'
                  value={globalFilter}
                  onChange={(e, { value }) => setGlobalFilter(value)}
                />
              </Accordion.Content>
            </Accordion>
          </Grid.Column>
        </Grid>
      </Grid.Column>
      <Grid.Column>
        <Button
          className='normal-button'
          onClick={() =>
            props.filterList({
              startDate: updatedDates.startDate,
              endDate: updatedDates.endDate,
              status: selectedStatusFilter,
              hasBalance: selectedBalanceFilter,
              dealer: selectedDealerFilter,
              checkNumber: checkNumber,
              ignoreDates: ignoreDates,
              fuzzy: globalFilter,
              invoiceNumber: invoiceNumberFilter,
              dateSearchBy: searchByDate,
              paymentAmountLow: paymentAmountFilter.paymentAmountLow,
              paymentAmountHigh: paymentAmountFilter.paymentAmountHigh,
            })
          }
        >
          Search
        </Button>
        &nbsp;&nbsp;
        <Button
          className='inverted-button'
          onClick={() => {
            // change to the default values
            setUpdatedDates({
              startDate: moment().subtract(7, 'd').format('YYYY-MM-DD'),
              endDate: moment().format('YYYY-MM-DD'),
            });

            setSelectedDateFilter('year');
            setStatusFilter('all');
            setBalanceFilter('all');
            setDealerFilter('all');
            setGlobalFilter('');
            setCheckNumber('');
            setInvoiceNumberFilter('');
            setIgnoreDates(false);
            setSearchByDate('created');

            // reset the results
            props.filterList({
              startDate: updatedDates.startDate,
              endDate: updatedDates.endDate,
              status: 'all',
              hasBalance: 'all',
              dealer: 'all',
              checkNumber: '',
              ignoreDates: false,
              fuzzy: '',
              invoiceNumber: '',
              dateSearchBy: 'created',
            });
          }}
        >
          Reset
        </Button>
        &nbsp;&nbsp; Total Selected: {props.totalSelected}&nbsp;&nbsp;
      </Grid.Column>
      <Grid.Column>
        {renderPrintButton(
          props.totalSelected > 0 ? true : false,
          props.printInvoices,
          props.selectedInvoices
        )}
      </Grid.Column>
    </Grid>
  );
};

export default PlateTxnFilters;
