import _ from 'lodash';
import moment from 'moment';
import main from '../apis/main';
import {
  GET_CAR_MAKES,
  ALL_INVOICES,
  SHOW_LOADING,
  LOGOUT_USER,
  FIND_CAR_MAKE,
  SAVE_CAR_MAKE,
  CLEAR_CAR_MAKE_FORM,
  SHOW_CAR_MAKE_FORM,
  CLEAR_SUCCESS,
  GET_INVOICES_FOR_COMBINED_INVOICE,
  SELECT_FOR_PRINT,
  PRINT_MULTI_INVOICES,
  THROW_ERROR,
  SET_CURRENT_INVOICE_FILTERS,
  SET_CURRENT_INVOICE_LIST_PAGE_INDEX,
  GET_DEALER_OPEN_INVOICES,
  CLEAR_ERROR,
} from './types';

export const getCarMakes = () => async (dispatch, getState) => {
  const { sessionId } = getState().users;
  const response = await main.get(`/api/car_makes/${sessionId}`);
  if (200 === response.status) {
    const responseData = response.data;

    // check for session token error
    if (responseData.error !== undefined) {
      // what type of error?
      if (responseData.error === 'Invalid session token') {
        // the session token is invalid or expired
        dispatch(sessionExpired());
        return;
      }

      // throw an error
      dispatch({ type: THROW_ERROR, payload: responseData.error.text });
      return;
    }

    let carMakeList = [
      {
        key: -1,
        value: -1,
        text: 'Select a Car Make',
      },
      {
        key: 0,
        value: 0,
        text: 'Add New',
        icon: 'plus circle',
      },
    ];
    _.each(response.data, (values) => {
      carMakeList.push({
        key: values.id,
        value: values.car_make,
        text: values.car_make,
      });
    });

    dispatch({ type: GET_CAR_MAKES, payload: carMakeList });
  } else {
    // something went wrong
    dispatch({ type: THROW_ERROR, payload: response.statusText });
  }
};

/**
 * Retrieves the invoices in the system based on parameters provided
 */
export const getAllInvoices =
  (params = {}) =>
  async (dispatch, getState) => {
    //console.log('Invoice List Params', params);
    dispatch(showLoading(true));
    const { sessionId } = getState().users;
    let bodyFormData = new FormData();
    let response = {};
    let doFuzzySearch = false;
    // what type of search?
    if (params.fuzzy !== undefined) {
      if (params.fuzzy.length > 0) {
        doFuzzySearch = true;
      }
    }

    // fuzzy search overrides all other filters
    if (doFuzzySearch) {
      // do a broad search
      bodyFormData.append('term', params.fuzzy);
      response = await main.post(
        `/api/plate_txns/search/${sessionId}`,
        bodyFormData
      );
    } else {
      // regular search
      if (params.startDate !== undefined) {
        bodyFormData.append(
          'updated_at_starting_date',
          params.startDate + ' 00:00:00'
        );

        // end date does not exist without a start date
        bodyFormData.append(
          'updated_at_ending_date',
          params.endDate + ' 23:59:59'
        );

        // what type of dates are being searched for?
        bodyFormData.append('date_search_type', params.dateSearchBy);
      }

      if (params.status !== 'all') {
        bodyFormData.append('status', params.status);
      }
      if (params.hasBalance !== 'all') {
        bodyFormData.append(
          'has_balance',
          params.hasBalance === 'yes' ? 'true' : 'false'
        );
      }
      if (params.dealer !== 'all') {
        bodyFormData.append('dealer_id', params.dealer);
      }
      if (params.checkNumber !== undefined) {
        if (params.checkNumber.length > 0) {
          bodyFormData.append('check_number', params.checkNumber);
        }
      }
      if (params.invoiceNumber !== undefined) {
        if (params.invoiceNumber.length > 0) {
          bodyFormData.append('invoice_number', params.invoiceNumber);
        }
      }
      if (params.invoiceIds !== undefined) {
        bodyFormData.append('invoice_ids', params.invoiceIds);
      }

      bodyFormData.append('ignore_dates', params.ignoreDates);

      if (params.combinedId !== undefined) {
        // this search is for combined invoices
        bodyFormData.append('combined_invoice_id', params.combinedId);
      }

      if (params.paymentAmountLow !== undefined) {
        if (params.paymentAmountLow.length > 0) {
          // make sure numeric and, if not, throw an error
          if (isNaN(params.paymentAmountLow)) {
            // throw an error
            dispatch({
              type: THROW_ERROR,
              payload: 'You must enter a numeric value for Min. Amount.',
            });
            dispatch(showLoading(false));
            return;
          }
          bodyFormData.append('payment_amount_low', params.paymentAmountLow);
        }
      }

      if (params.paymentAmountHigh !== undefined) {
        if (params.paymentAmountHigh.length > 0) {
          // make sure numeric and, if not, throw an error
          if (isNaN(params.paymentAmountHigh)) {
            // throw an error
            dispatch({
              type: THROW_ERROR,
              payload: 'You must enter a numeric value for Max. Amount.',
            });
            dispatch(showLoading(false));
            return;
          }
          bodyFormData.append('payment_amount_high', params.paymentAmountHigh);
        }
      }

      try {
        response = await main.post(
          `/api/plate_txns/${sessionId}`,
          bodyFormData
        );
      } catch (e) {
        // there was an error in the response
        console.log('Error', e.response);
        dispatch({
          type: THROW_ERROR,
          payload:
            'There was a problem retrieving the invoices.  ' +
            e.response.data.error.description,
        });
        dispatch(showLoading(false));
        return;
      }
    }

    //console.log(response);
    if (200 === response.status) {
      const responseData = response.data;

      let invoiceList = [];
      let totalOwed = 0.0;
      let totalOverpaid = 0.0;
      let allCharges = 0.0;
      let totalBRMFees = 0.0;
      let totalRegularExpenses = 0.0;
      let totalExtraExpenses = 0.0;
      let valuesToCalculateNetProfit = {
        brmFees: 0.0,
        extraExpenses: 0.0,
      };
      const dateToStartCalculatingNetProfit = moment('2022-09-15 00:00:00');

      _.each(response.data, (values) => {
        // do not include deleted status
        if (params.showDeleted !== values.status) {
          // see if the total charges are what's owed or overpaid
          let totalCharges = parseFloat(values.total_charges);
          let currentBalance = totalCharges - parseFloat(values.total_payments);
          totalRegularExpenses += totalCharges - parseFloat(values.brm_fees);
          //console.log('Regular Expenses', totalRegularExpenses);
          // console.log('Invoice Id', values.id);
          // console.log('Total Expenses', values.total_expenses);
          totalExtraExpenses += parseFloat(values.total_expenses);
          //console.log('Total Extra Expenses', totalExtraExpenses);

          // continuation depends on the balance filter
          let addInvoice = true;
          if (params.hasBalance !== 'all') {
            //console.log('Current Balance', currentBalance);
            switch (params.hasBalance) {
              case 'yes':
                if (0 >= parseFloat(currentBalance)) {
                  // if this is an edit for a billing, then we need
                  // to see if any invoices were paid
                  if (params.invoiceIds !== undefined) {
                    //console.log('Invoice Ids', params.invoiceIds);
                    const isPaidInvoice = _.find(params.invoiceIds, (item) => {
                      return item === values.id;
                    });

                    if (isPaidInvoice === undefined) {
                      // we don't want this invoice in the list
                      addInvoice = false;
                    }
                  } else {
                    addInvoice = false;
                  }
                }
                break;
              case 'no':
                if (parseFloat(currentBalance) > 0) {
                  addInvoice = false;
                }
            }
          }

          if (addInvoice) {
            let currentBRMFees = parseFloat(values.brm_fees);
            if (currentBalance > 0.0) {
              // add to the total owed
              totalOwed += currentBalance;
            } else {
              // add to the total overpaid
              totalOverpaid -= currentBalance;
            }

            // save the total charges
            allCharges += totalCharges;

            // save the total fees
            totalBRMFees += currentBRMFees;

            // check the date of the invoice and see if it can be
            // included in the total net profit calculations
            if (moment(values.updated_at) >= dateToStartCalculatingNetProfit) {
              // include the values for net profit
              valuesToCalculateNetProfit.brmFees += currentBRMFees;
              valuesToCalculateNetProfit.extraExpenses += parseFloat(
                values.total_expenses
              );
              //console.log('Calculation Values', valuesToCalculateNetProfit);
            }

            invoiceList.push({
              txnId: values.id,
              dealer: values.company_name,
              dealerId: values.dealer_id,
              dealerPhone: values.dealer_phone,
              dealerCourier: values.dealer_courier,
              returnedTo: values.returned_to,
              paidFromCombined: values.paid_from_combined_invoice,
              isFinancialInvoice: values.is_financial_invoice,
              regLastName: values.reg_last_name,
              regFirstName: values.reg_first_name,
              regFullName: values.reg_last_name + ', ' + values.reg_first_name,
              regCompanyName: values.reg_company_name,
              regEmail: values.reg_email,
              regPhone: values.reg_phone,
              regTxnType: values.reg_transaction_type,
              regAddress: values.reg_address,
              regCity: values.reg_city,
              regZipCode: values.reg_zipcode,
              regStateDeal: values.reg_state_deal,
              regCarMake: values.reg_car_make,
              regCarYear: values.reg_car_year,
              regVin: values.reg_vin,
              regPlateNumber: values.reg_plate_number,
              regLienHolder: values.reg_lien_holder_id,
              lienHolderName: values.lien_holder_name,
              leasingCompany: values.leasing_company,
              fromDealerDate: moment(new Date(values.from_dealer_date)).format(
                'MM/DD/YYYY'
              ),
              dmvSentDate: values.dmv_sent_date,
              dmvReturnDate: values.dmv_return_date,
              brmFees: values.brm_fees,
              dmvFees: values.dmv_fees,
              salesTax: values.sale_tax,
              status: values.status,
              updated: moment(new Date(values.updated_at)).format(
                'MM/DD/YYYY hh:mm a'
              ),
              created: moment(new Date(values.created_at)).format(
                'MM/DD/YYYY hh:mm a'
              ),
              totalCharges: '$' + parseFloat(values.total_charges).toFixed(2),
              currentBalance: parseFloat(currentBalance).toFixed(2),
              editId: values.id,
              printId: values.id,
              selectedForPrint: false,
              combinedInvoiceId: values.combined_invoice_id,
              selected: values.combined_invoice_id > 0 ? true : false,
              createdByInitials: values.initials,
              payment: 0.0, // this is used to have an editable column for the bulk payments
            });
          }
        }
      });

      // the state object to update is dependant on where the list should be stored
      if (params.combinedId !== undefined) {
        dispatch({
          type: GET_INVOICES_FOR_COMBINED_INVOICE,
          payload: invoiceList,
        });
      } else if (params.forBilling !== undefined) {
        dispatch({ type: GET_DEALER_OPEN_INVOICES, payload: invoiceList });
      } else {
        // calculate the overall net profit for the invoices returned
        const netProfitValues = calculateNetProfit(
          valuesToCalculateNetProfit.brmFees,
          totalRegularExpenses,
          valuesToCalculateNetProfit.extraExpenses
        );
        dispatch({
          type: ALL_INVOICES,
          payload: {
            invoiceList,
            totalOwed,
            totalOverpaid,
            allCharges,
            totalBRMFees,
            brmFeesWithExpenses: valuesToCalculateNetProfit.brmFees,
            totalExpenses: valuesToCalculateNetProfit.extraExpenses,
            totalNetProfit: netProfitValues.totalNetProfit,
            percentNetProfit: netProfitValues.netProfitPercentage,
          },
        });

        // save the search values
        dispatch({
          type: SET_CURRENT_INVOICE_FILTERS,
          payload: {
            startDate: params.startDate,
            endDate: params.endDate,
            status: params.status,
            hasBalance: params.hasBalance,
            dealer: params.dealer,
            checkNumber:
              params.checkNumber !== undefined ? params.checkNumber : '',
            ignoreDates: params.ignoreDates,
            fuzzySearchString: params.fuzzy !== undefined ? params.fuzzy : '',
            invoiceNumber:
              params.invoiceNumber !== undefined ? params.invoiceNumber : '',
            dateSearchBy:
              params.dateSearchBy !== undefined
                ? params.dateSearchBy
                : 'created',
            paymentAmountLow:
              params.paymentAmountLow !== undefined
                ? params.paymentAmountLow
                : '',
            paymentAmountHigh:
              params.paymentAmountHigh !== undefined
                ? params.paymentAmountHigh
                : '',
          },
        });
      }
    } else {
      // there was a problem, notify the user and return the error message
      dispatch(
        proccessAPIErrors({
          statusCode: response.status,
          errorMsg: response.data.error,
        })
      );
    }
    dispatch(showLoading(false));
  };

/**
 * Calculates the net profit value based on the values provided.
 *
 * @param {float} totalBRMFees total amount of BRM Fees charged to the customer
 * @param {float} totalCharges total, regular charges for an invoice (plate transaction)
 * @param {float} totalExpenses total other expenses (hidden or otherwise) for an invoice (plate transaction)
 * @returns {object} contains the totalNetProfit and netProfitPercentage values
 */
export const calculateNetProfit = (
  totalBRMFees,
  totalCharges,
  totalExpenses
) => {
  // calculate the net profit first
  //const netProfit = totalBRMFees - totalCharges - totalExpenses;
  const netProfit = totalBRMFees - totalExpenses; // total charges will not be used in the net profit calculation
  const percentNetProfit = Math.round(
    (parseFloat(netProfit) / totalBRMFees) * 100
  );

  // return the values
  return {
    totalNetProfit: netProfit,
    netProfitPercentage: percentNetProfit,
  };
};

/**
 * Triggers the loading message to either show or hide
 * @param {boolean} show
 */
export const showLoading = (show) => {
  return { type: SHOW_LOADING, payload: show };
};

/**
 * Triggers the redirect to the login page when a session expires
 */
export const sessionExpired = () => {
  return {
    type: LOGOUT_USER,
    payload: {
      warning: 'Your Session Has Expired.  Please Log Back In.',
      success: '',
    },
  };
};

/**
 * Retrieve a specific car make
 */
export const getCarMake = (makeName) => async (dispatch, getState) => {
  const { sessionId } = getState().users;
  const { carMakeList } = getState().main;
  // find the make id
  const makeId = _.find(carMakeList, { value: makeName });

  const response = await main.get(`/api/car_makes/${makeId.key}/${sessionId}`);
  if (200 === response.status) {
    // check for session token error
    if (response.data.error !== undefined) {
      // what type of error?
      if (response.data.error === 'Invalid session token') {
        // the session token is invalid or expired
        dispatch(sessionExpired());
        return;
      }

      // throw an error
      dispatch({ type: THROW_ERROR, payload: responseData.error.text });
      return;
    }

    const responseData = response.data[0];

    dispatch({ type: FIND_CAR_MAKE, payload: responseData });
  } else {
    // something went wrong
    dispatch({ type: THROW_ERROR, payload: response.statusText });
  }
};

/**
 * Save a car make
 */
export const saveCarMake = (values) => async (dispatch, getState) => {
  const { sessionId, currentUser } = getState().users;
  const { carMakeForm } = getState().main;
  let response = {};

  // build the submission object
  let bodyFormData = new FormData();
  bodyFormData.append('car_make', values.carMake);
  bodyFormData.append('created_by', currentUser.userId);
  bodyFormData.append('updated_by', currentUser.userId);
  // new user or an update?
  if (0 === carMakeForm.makeId) {
    // New User
    bodyFormData.append('session_token', sessionId);
    response = await main.post('/api/car_makes', bodyFormData);
  } else {
    // update existing
    response = await main.post(
      `/api/car_makes/${carMakeForm.makeId}/${sessionId}`,
      bodyFormData
    );
  }
  if (200 === response.status || 201 === response.status) {
    const responseData = response.data;

    // check for session token error
    if (responseData.error !== undefined) {
      // what type of error?
      if (responseData.error === 'Invalid session token') {
        // the session token is invalid or expired
        dispatch(sessionExpired());
        return;
      }

      // throw an error
      dispatch({ type: THROW_ERROR, payload: responseData.error.text });
      return;
    }

    if (responseData.notice.text !== undefined) {
      // successful insertion
      // update the car make list
      dispatch({ type: SAVE_CAR_MAKE });
      dispatch(getCarMakes());
      dispatch(showCarMakesForm(false));
    } else {
      // problem saving
      dispatch({
        type: THROW_ERROR,
        payload: 'There was a problem saving the Car Make.  Please try again.',
      });
    }
  } else {
    // something went wrong
    dispatch({ type: THROW_ERROR, payload: response.statusText });
  }
};

/**
 * Show or hide the car make form
 */
export const showCarMakesForm = (show) => {
  return { type: SHOW_CAR_MAKE_FORM, payload: show };
};

/**
 * Start adding a new car make
 */
export const addNewCarMake = () => {
  return { type: CLEAR_CAR_MAKE_FORM };
};

/**
 * Clears out the success message
 */
export const clearSuccess = () => {
  return { type: CLEAR_SUCCESS };
};

/**
 * Clears out the error message
 */
export const clearError = () => {
  return { type: CLEAR_ERROR };
};

/**
 * Selects and invoice for printing
 * @param {int} txnId
 * @param {boolean} selected
 */
export const toggleInvoiceForPrint =
  (txnId, selected) => async (dispatch, getState) => {
    const { totalSelectedInvoices } = getState().main;
    let selectedInvoices = totalSelectedInvoices;
    if (!selected) {
      --selectedInvoices;
    } else {
      selectedInvoices++;
    }
    dispatch({
      type: SELECT_FOR_PRINT,
      payload: { txnId, selected, selectedInvoices },
    });
  };

/**
 * Calls the endpoint to retrieve multiple PDF invoices for printing
 */
export const getMultipleInvoicePDF =
  (invoicesToPrint) => async (dispatch, getState) => {
    dispatch(showLoading(true));
    const { sessionId } = getState().users;
    const { invoiceList } = getState().main;

    let selectedInvoices = [];

    // get the dealer information, lien holder name and user ref
    //const selectedInvoices = _.filter(invoiceList, { selectedForPrint: true });
    _.forEach(invoicesToPrint, (value, key) => {
      selectedInvoices.push(invoiceList[key]);
    });
    //console.log('Selected', selectedInvoices);

    // build the request object
    let requestObj = [];

    _.each(selectedInvoices, (value) => {
      requestObj.push(value.txnId);
    });

    const response = await main.post(
      `/api/pdf_plate_txn_multiple/${sessionId}`,
      requestObj
    );

    if (200 === response.status) {
      // check for session token error
      if (response.data.error !== undefined) {
        // what type of error?
        if (response.data.error === 'Invalid session token') {
          // the session token is invalid or expired
          dispatch(sessionExpired());
          dispatch(showLoading(false));
          return;
        }

        // throw an error
        dispatch(showLoading(false));
        dispatch({ type: THROW_ERROR, payload: response.data.error.text });
        return;
      }

      // check that the pdf was generated
      if (response.data.success) {
        dispatch({
          type: PRINT_MULTI_INVOICES,
          payload: { show: true, fileName: response.data.invoiceFile },
        });
      } else {
        dispatch({
          type: THROW_ERROR,
          payload: 'There was a problem printing the invoice.',
        });
      }
    } else {
      // there was an error from the endpoint
      dispatch({ type: THROW_ERROR, payload: response.statusText });
    }
    dispatch(showLoading(false));
  };

/**
 * Closes the Milti Invoice Print Window
 */
export const closeMultiInvoicePrint = () => {
  return { type: PRINT_MULTI_INVOICES, payload: { show: false, fileName: '' } };
};

/**
 * Saves the current page index and list size
 * @param {int} currentIndex - current page index of the invoice list on the dashboard
 * @param {int} currentListSize - number of records that are shown for each page in the list
 * @returns
 */
export const setCurrentInvoiceListPageIndex = (
  currentIndex,
  currentListSize
) => {
  return {
    type: SET_CURRENT_INVOICE_LIST_PAGE_INDEX,
    payload: { pageIndex: currentIndex, listSize: currentListSize },
  };
};

/**
 * Performs the necessary actions when an error is returned
 * from an API call.
 * The parameters sent are in an object and this function destructures the
 * object.
 *
 * @param {object} errorObj - contains all of the values to process from the error response of an api call
 * {
 *    {int} statusCode - error response code from the api
 *    {string} erorMsg - the message contained in the api response
 * }
 */
export const proccessAPIErrors = (errorObj) => async (dispatch) => {
  // destructure the error object sent
  const { statusCode, errorMsg } = errorObj;

  /** STEP 1 - Trigger the Error and Set the Error Message shown to the user */
  dispatch({ type: THROW_ERROR, payload: errorMsg });

  /** STEP 2 - Hide the loading screen */
  dispatch(showLoading(false));

  /** STEP 3 - Log out the user if the session is expired by checking for Status Code 403 - Forbidden */
  if (403 === statusCode) {
    dispatch(sessionExpired());
  }
};
