import _ from 'lodash';
import main from '../apis/main';
import moment from 'moment';
import {
  sessionExpired,
  showLoading,
  proccessAPIErrors,
} from './globalActions';
import {
  GET_DEALERS,
  SAVE_DEALER,
  THROW_ERROR,
  SHOW_DEALER_FORM,
  FIND_DEALER,
  ADD_NEW_DEALER,
  CANCEL_SAVE_DEALER,
  SET_COMBINED_INVOICE_DEALER,
  DEALER_DELETED,
  CONFIRM_DEALER_ACTION,
  SHOW_SUCCESS,
  GET_DEALER_INVOICES,
  CLEAR_DEALER_INVOICES,
  GET_DEALER_BILLINGS,
  CLEAR_DEALER_BILLINGS,
  CLEAR_DEALER_FORM,
  UPDATE_FILTERED_DEALERS,
} from './types';

/**
 * Retreives all of the dealers from the database
 */
export const getDealerList = () => async (dispatch, getState) => {
  const { sessionId } = getState().users;
  const response = await main.get(`/api/dealers/filter/${sessionId}`);
  if (200 === response.status) {
    const responseData = response.data;

    // check for session token error
    if (responseData.error !== undefined) {
      // what type of error?
      if (responseData.error === 'Invalid session token') {
        // the session token is invalid or expired
        dispatch(sessionExpired());
        return;
      }

      // throw an error
      dispatch({ type: THROW_ERROR, payload: responseData.error.text });
      return;
    }

    // main list of dealers
    let dealerList = [];

    // list of dealers that use combined invoices
    let dealersInBilling = [];

    _.each(response.data, (values) => {
      // go ahead and build the phone number
      let dealerPhoneNumber = values.phone;
      if (values.phone_ext !== undefined) {
        dealerPhoneNumber +=
          values.phone_ext.length > 0 ? ' Ext.' + values.phone_ext : '';
      }
      dealerList.push({
        dealerId: values.id,
        companyName: values.company_name,
        address:
          values.address +
          ' ' +
          values.city +
          ', ' +
          values.state +
          ' ' +
          values.zip,
        phone: dealerPhoneNumber,
        fax: values.fax,
        email: values.email,
        courier: values.courier,
        contactName: values.contact_first_name + ' ' + values.contact_last_name,
        notes: values.notes,
        currentBalance: parseFloat(values.currentBalance).toFixed(2),
        totalCredits: parseFloat(values.totalCredits).toFixed(2),
      });

      // does the dealer use combined invoices?
      if ('1' === values.include_in_billing_system) {
        // add them to the list the combined invoices use
        dealersInBilling.push({
          key: values.id,
          value: values.id,
          text: values.company_name,
        });
      }
    });

    dispatch({ type: GET_DEALERS, payload: { dealerList, dealersInBilling } });
  } else {
    // something went wrong
    dispatch({ type: THROW_ERROR, payload: response.statusText });
  }
};

/**
 * Retrieves the filtered dealer list based on the parameters provided by the user
 *
 * @param {object} params - the following parameters are accepted:
 *  - hasBalance: 0 = money owed by BRM, 1 = dealer owes money (has a balance), 2 = all balances
 *  - invoiceCreatedStartDate: beginning creation date to search through the plate invoices for dealers
 *  - invoiceCreatedEndDate: ending creation date to search through the plate invoices for dealers
 */
export const getFilteredDealerList = (params) => async (dispatch, getState) => {
  // show the loading screen
  dispatch(showLoading(true));

  const { sessionId } = getState().users;

  // destructure the params sent
  const {
    hasBalance,
    invoiceCreatedStartDate,
    invoiceCreatedEndDate,
    useDateRange,
  } = params;

  // if the date range is not being used, then use the defaults
  const useStartDate = useDateRange
    ? moment(invoiceCreatedStartDate).format('YYYY-MM-DD')
    : moment('2000-01-01').format('YYYY-MM-DD');
  const useEndDate = useDateRange
    ? moment(invoiceCreatedEndDate).format('YYYY-MM-DD')
    : moment().format('YYYY-MM-DD');

  const response = await main.get(
    `/api/dealers/filter/${sessionId}/${useStartDate}/${useEndDate}/${hasBalance}`
  );
  if (200 === response.status) {
    const responseData = response.data;

    // check for session token error
    if (responseData.error !== undefined) {
      // what type of error?
      if (responseData.error === 'Invalid session token') {
        // the session token is invalid or expired
        dispatch(sessionExpired());
        return;
      }

      // throw an error
      dispatch({ type: THROW_ERROR, payload: responseData.error.text });
      return;
    }

    // main list of dealers
    let filteredDealerList = [];

    _.each(response.data, (values) => {
      filteredDealerList.push({
        dealerId: values.id,
        companyName: values.company_name,
        address:
          values.address +
          ' ' +
          values.city +
          ', ' +
          values.state +
          ' ' +
          values.zip,
        phone:
          values.phone +
          (values.phone_ext.length > 0 ? ' Ext.' + values.phone_ext : ''),
        fax: values.fax,
        email: values.email,
        courier: values.courier,
        contactName: values.contact_first_name + ' ' + values.contact_last_name,
        notes: values.notes,
        currentBalance: parseFloat(values.currentBalance).toFixed(2),
        totalCredits: parseFloat(values.totalCredits).toFixed(2),
      });
    });

    dispatch({
      type: UPDATE_FILTERED_DEALERS,
      payload: {
        filteredDealerList,
        hasBalance,
        invoiceCreatedStartDate,
        invoiceCreatedEndDate,
        useDateRange,
      },
    });
  } else {
    // something went wrong
    dispatch({ type: THROW_ERROR, payload: response.statusText });
  }

  // hide the loading screen
  dispatch(showLoading(false));
};

export const saveDealer = (values) => async (dispatch, getState) => {
  // show the loading screen
  dispatch(showLoading(true));

  const { sessionId } = getState().users;
  const { dealerForm } = getState().dealers;
  let response = {};

  // build the submission object
  let bodyFormData = new FormData();
  bodyFormData.append('company_name', values.companyName);
  bodyFormData.append('address', values.address);
  bodyFormData.append('city', values.city);
  bodyFormData.append('state', values.stateAbbr);
  bodyFormData.append('zip', values.zipCode);
  bodyFormData.append('phone', values.phoneNumber);
  bodyFormData.append('phone_ext', values.phoneExt);
  bodyFormData.append('fax', values.faxNumber);
  bodyFormData.append('email', values.emailAddress);
  bodyFormData.append('courier', values.courier);
  bodyFormData.append('courier_id', values.id);
  bodyFormData.append('include_in_billing_system', values.inBilling);
  bodyFormData.append('contact_first_name', values.contactFirstName);
  bodyFormData.append('contact_last_name', values.contactLastName);
  bodyFormData.append('route', values.route);
  bodyFormData.append('hide_from_list', 0);
  bodyFormData.append('delete_list', 0);
  bodyFormData.append('notes', values.notes);
  bodyFormData.append('prospect', values.prospect);
  // new dealer or an update?
  if (0 >= dealerForm.dealerId) {
    // New Dealer
    bodyFormData.append('session_token', sessionId);
    response = await main.post('/api/dealers', bodyFormData);
  } else {
    // update existing
    response = await main.post(
      `/api/dealers/${dealerForm.dealerId}/${sessionId}`,
      bodyFormData
    );
  }
  if (200 === response.status || 201 === response.status) {
    const responseData = response.data;

    // check for session token error
    if (responseData.error !== undefined) {
      // what type of error?
      if (responseData.error === 'Invalid session token') {
        // the session token is invalid or expired
        dispatch(sessionExpired());
        return;
      }

      // throw an error
      dispatch({ type: THROW_ERROR, payload: responseData.error.text });
      return;
    }

    if (responseData.notice.text !== undefined) {
      // successful insertion
      let useDealerId = dealerForm.dealerId;
      if (responseData.notice.id !== undefined) {
        useDealerId = responseData.notice.id;
      }
      dispatch(getDealer(useDealerId));

      // update the dealer list
      await dispatch(getDealerList());
      if (0 >= dealerForm.dealerId) {
        // only for new dealers entered
        dispatch({ type: SAVE_DEALER, payload: useDealerId });
      } else {
        dispatch({ type: SAVE_DEALER, payload: 0 });
      }
      dispatch(cancelAddDealer(false));
      dispatch(showDealerForm(false));
      dispatch({
        type: SHOW_SUCCESS,
        payload: 'The Dealer was successfully saved.',
      });
    } else {
      // bad credentials
      dispatch({
        type: THROW_ERROR,
        payload: 'There was a problem saving the Dealer.  Please try again.',
      });
    }
  } else {
    // something went wrong
    dispatch({ type: THROW_ERROR, payload: response.statusText });
  }

  // hide the loading screen
  dispatch(showLoading(false));
};

export const showDealerForm = (show) => {
  return { type: SHOW_DEALER_FORM, payload: show };
};

export const addNewDealer = (prevDealerId) => {
  return { type: ADD_NEW_DEALER, payload: prevDealerId };
};

export const getDealer =
  (dealerId, forCombined = false) =>
  async (dispatch, getState) => {
    if (dealerId > 0) {
      const { sessionId } = getState().users;
      const response = await main.get(`/api/dealers/${dealerId}/${sessionId}`);
      if (200 === response.status) {
        // check for session token error
        if (response.data.error !== undefined) {
          // what type of error?
          if (response.data.error === 'Invalid session token') {
            // the session token is invalid or expired
            dispatch(sessionExpired());
            return;
          }

          // throw an error
          dispatch({ type: THROW_ERROR, payload: responseData.error.text });
          return;
        }

        const responseData = response.data[0];

        if (forCombined) {
          // the dealer information is for a combined invoice
          dispatch({
            type: SET_COMBINED_INVOICE_DEALER,
            payload: responseData,
          });
        } else {
          dispatch({ type: FIND_DEALER, payload: responseData });
        }
      } else {
        // something went wrong
        dispatch({ type: THROW_ERROR, payload: response.statusText });
      }
    }
  };

/**
 * Triggers when the user cancels adding a new dealer
 */
export const cancelAddDealer = (cancelled) => {
  return { type: CANCEL_SAVE_DEALER, payload: cancelled };
};

/**
 * Used to close the confirmation window when an action is performed on a dealer
 *
 * @return action type
 */
export const confirmDealerSaved = () => {
  return { type: CONFIRM_DEALER_ACTION };
};

export const removeDealer = (dealerId) => async (dispatch, getState) => {
  // grab the current session id to authenticate with the API
  const { sessionId } = getState().users;

  // call the endpoint to mark the dealer deleted (this will not remove the dealer from the dealers table)
  const response = await main.delete(`/api/dealers/${dealerId}/${sessionId}`);

  if (200 === response.status) {
    // check for session token error
    if (response.data.error !== undefined) {
      // what type of error?
      if (response.data.error === 'Invalid session token') {
        // the session token is invalid or expired
        dispatch(sessionExpired());
        return;
      }

      // throw an error
      dispatch({ type: THROW_ERROR, payload: responseData.error.text });
      return;
    }

    // got this far, check that the deletion succeeded
    if (response.data.success) {
      // refresh the dealer list and clear out the dealer form
      dispatch(getDealerList());
      dispatch({ type: DEALER_DELETED, payload: 2 });
    } else {
      dispatch({
        type: THROW_ERROR,
        payload: 'There was a problem removing the dealer.',
      });
    }
  } else {
    // there was an error from the endpoint
    dispatch({ type: THROW_ERROR, payload: response.statusText });
  }
};

/**
 * Grabs all invoices associated with the dealer sent
 *
 * @param {int} dealerId - record ID of the dealer we want to see invoices for
 */
export const getInvoicesByDealer = (dealerId) => async (dispatch, getState) => {
  // need the active session token
  const { sessionId } = getState().users;

  // call the endpoint to grab all of the invoices associated with a dealer
  let bodyFormData = new FormData();
  bodyFormData.append('dealer_id', dealerId);
  bodyFormData.append('ignore_dates', 'true');
  const response = await main.post(
    `/api/plate_txns/${sessionId}`,
    bodyFormData
  );

  // did we get a successful response?
  if (200 === response.status) {
    // format the list for proper display
    let invoiceList = [];
    _.each(response.data, (values) => {
      let currentBalance =
        parseFloat(values.total_charges) - parseFloat(values.total_payments);
      invoiceList.push({
        txnId: values.id,
        dealer: values.company_name,
        regFullName: values.reg_last_name + ', ' + values.reg_first_name,
        regCompanyName: values.reg_company_name,
        regVin: values.reg_vin,
        regPlateNumber: values.reg_plate_number,
        fromDealerDate: moment(values.from_dealer_date).format('MM/DD/YYYY'),
        status: values.status,
        updated: moment(values.updated_at).format('MM/DD/YYYY hh:mm a'),
        totalCharges: '$' + values.total_charges,
        currentBalance: parseFloat(currentBalance).toFixed(2),
        editId: values.id,
        createdByInitials: values.initials,
      });
    });
    // set the return to the invoice list
    dispatch({ type: GET_DEALER_INVOICES, payload: invoiceList });
  } else {
    // there was a problem, notify the user and return the error message
    dispatch(
      proccessAPIErrors({
        statusCode: response.status,
        errorMsg: response.data.error,
      })
    );
  }
};

export const getBillingsByDealer = (dealerId) => async (dispatch, getState) => {
  // need the active session token
  const { sessionId } = getState().users;

  // call the endpoint to grab all of the billing payments associated with a dealer
  const response = await main.get(
    `/api/financial_txns/search/dealer/${dealerId}/1/${sessionId}`
  );

  // did we get a successful response?
  if (200 === response.status) {
    // format the list for proper display
    let billingList = [];
    _.each(response.data, (values) => {
      billingList.push({
        billingId: values.id,
        editId: values.id,
        updated: moment(values.updated_at).format('MM/DD/YYYY hh:mm a'),
        paymentType: values.payment_type,
        amount: values.amount,
        referenceNumber: values.ref_number,
        notApplied: values.billing_amount_not_applied,
        processedThroughGateway:
          0 === parseInt(values.payment_processed) ? 'No' : 'Yes',
      });
    });
    // set the return to the invoice list
    dispatch({ type: GET_DEALER_BILLINGS, payload: billingList });
  } else {
    // there was a problem, notify the user and return the error message
    dispatch(
      proccessAPIErrors({
        statusCode: response.status,
        errorMsg: response.data.error,
      })
    );
  }
};

/**
 * Clears out the dealer specific invoice list
 *
 * @returns
 */
export const clearDealerInvoices = () => {
  return { type: CLEAR_DEALER_INVOICES };
};

/**
 * Clears out the dealer billings list
 *
 * @returns
 */
export const clearDealerBillings = () => {
  return { type: CLEAR_DEALER_BILLINGS };
};

/**
 * Resets all values in the dealer form state
 *
 * @returns
 */
export const clearDealerForm = () => {
  return { type: CLEAR_DEALER_FORM };
};
