import React, { useState, useMemo, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Container,
  Table,
  Icon,
  Dropdown,
  Menu,
  Input,
  Message,
  Label,
} from 'semantic-ui-react';
import _ from 'lodash';
import { useTable, useFilters, usePagination, useSortBy } from 'react-table';
import { resultPageSizeOpts } from '../StaticLists';
import { updateStateFee } from '../../actions';

/**
 * Component that lists all state fees with
 * editable text boxes if the fees need to be changed.
 *
 * @param {object} props
 * @returns
 */
const StateList = (props) => {
  const [showInputMessage, setShowInputMessage] = useState(0);
  // map the columns for the table to be rendered
  const columns = useMemo(
    () => [
      { Header: 'State', accessor: 'stateName' },
      {
        Header: 'Fee Amount',
        accessor: 'amount',
        Cell: (row) => {
          return (
            <Fragment>
              <Input
                style={{ width: '150px' }}
                className='brm-form-input brm-form-dollar-input'
                value={row.row.original.amount}
                onChange={(e) => {
                  // make sure the user is only typing in digits, otherwise don't
                  // change the input value
                  if (/^\d*\.?\d*$/.test(e.target.value)) {
                    props.updateStateFee(row.row.original.id, e.target.value);
                  }
                }}
              />
            </Fragment>
          );
        },
      },
      {
        Header: 'Update Result',
        accessor: 'updateResult',
        width: 200,
        Cell: (row) => {
          switch (row.row.original.updateResult) {
            case 'Updated':
              return (
                <Message size='tiny' positive>
                  <Message.Header>Updated</Message.Header>
                </Message>
              );
            case 'Failed':
              return (
                <Message size='tiny' negative>
                  <Message.Header>Failed</Message.Header>
                  <p>{row.row.original.updateErrorMessage}</p>
                </Message>
              );
            default:
              return (
                <Message size='tiny'>
                  <Message.Header>
                    {row.row.original.updateResult}
                  </Message.Header>
                </Message>
              );
          }
        },
      },
      { Header: 'Last Updated', accessor: 'dateChanged' },
      { Header: 'Last Updated By', accessor: 'changedByName' },
    ],
    []
  );

  const data = props.stateFeeList;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
      autoResetPage: false,
      autoResetSortBy: false,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  return (
    <Container>
      <Table celled {...getTableProps()}>
        <Table.Header>
          {headerGroups.map((headerGroup) => (
            <Table.Row {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Table.HeaderCell
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? [<Icon name='sort down' />]
                        : [<Icon name='sort up' />]
                      : column.disableSortBy
                      ? ''
                      : [<Icon name='sort' />]}
                  </span>
                </Table.HeaderCell>
              ))}
            </Table.Row>
          ))}
        </Table.Header>
        <Table.Body {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <Table.Row {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <Table.Cell {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </Table.Cell>
                  );
                })}
              </Table.Row>
            );
          })}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan='10'>
              <Dropdown
                value={pageSize}
                options={resultPageSizeOpts}
                selection
                selectOnBlur={true}
                className='brm-form-dropdown brm-form-header-dropdown'
                onChange={(e, { value }) => setPageSize(Number(value))}
              />
              <Menu floated='right' pagination>
                <Menu.Item
                  as='a'
                  icon
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                >
                  <Icon name='angle double left' />
                </Menu.Item>
                <Menu.Item
                  as='a'
                  icon
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  <Icon name='angle left' />
                </Menu.Item>
                <Menu.Item as='a'>
                  Page: {pageIndex + 1} of {pageOptions.length}
                </Menu.Item>
                <Menu.Item
                  as='a'
                  icon
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  <Icon name='angle right' />
                </Menu.Item>
                <Menu.Item
                  as='a'
                  icon
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  <Icon name='angle double right' />
                </Menu.Item>
              </Menu>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    stateFeeList: state.stateFees.stateFeeList,
  };
};

export default connect(mapStateToProps, { updateStateFee })(StateList);
