import * as yup from 'yup';

import {
  USERNAME,
  PASSWORD,
  CONFIRM_PASSWORD,
  FIRST_NAME,
  LAST_NAME,
  EMAIL,
  INITIALS,
  ACCOUNT_TYPE,
  DEALER_ID,
  USER_ID,
} from './FieldNames';

export default yup.object().shape({
  [USERNAME]: yup.string().required('Username is required'),
  [PASSWORD]: yup
    .string()
    .test('password', 'Password is required', function (value) {
      console.log(this.parent.userId);
      const userIdValue = this.parent.userId || 0;

      if (userIdValue === 0) {
        return value && value.length > 0;
      }

      return true;
    })
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'Password Must Contain at Least 8 Characters, One Uppercase, One Lowercase, One Number, and One Special Character'
    ),
  [CONFIRM_PASSWORD]: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
  [FIRST_NAME]: yup.string().required('First Name is required'),
  [LAST_NAME]: yup.string().required('Last Name is required'),
  [EMAIL]: yup.string().email('Email address is invalid'),
  [INITIALS]: yup.string().required('You must enter the initials of the user.'),
  [ACCOUNT_TYPE]: yup.string().required('You must select an account type'),
  [DEALER_ID]: yup.string().when(ACCOUNT_TYPE, {
    is: (value) => 'dealer' === value,
    then: () =>
      yup
        .string()
        .required('You must select the dealership the user is associated with'),
  }),
});
