import React, { useRef, useMemo, useState, useEffect } from 'react';
import { Button, Grid, Container, Icon, Message } from 'semantic-ui-react';
import _ from 'lodash';
import {
  useTable,
  useFilters,
  usePagination,
  useSortBy,
  useRowSelect,
} from 'react-table';
import DealerDropdown from './DealerDropdown';
import AmountReceivedText from './AmountReceivedText';
import DealerCurrentBalanceText from './DealerCurrentBalanceText';
import CurrentDealerCreditText from './CurrentDealerCreditText';
import BillingPaymentForm from './BillingPaymentForm';
import OpenInvoiceList from './OpenInvoiceList';
import BillingHeader from './BillingHeader';
import IndeterminateCheckbox from '../IndeterminateCheckbox';
import LineItemPayment from './LineItemPayment';
import BillingTotals from './BillingTotals';
import LoadingComp from '../LoadingComp';
import ResultsModal from '../ResultsModal';
import ErrorModal from '../ErrorModal';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

// redux actions needed for this component
import {
  getDealerOutstandingInvoices,
  clearBillingForm,
  distributeBillingPayment,
  clearBillingError,
} from '../../actions';

/**
 * Sets the color of a dollar amount given in the invoice list
 * @param {object} row
 */
const renderAmountColor = (row) => {
  const dollarAmount = parseFloat(row.value).toFixed(2);
  if (dollarAmount <= 0.0) {
    return <span style={{ color: '#22B573' }}>${row.value}</span>;
  }
  return <span style={{ color: '#F14724' }}>${row.value}</span>;
};

// renders the loading component
const renderLoader = (show, message) => {
  if (show) {
    return <LoadingComp msg={message} />;
  }
  return null;
};

/**
 * Shows the results of a regular invoice submission (not print)
 * @param {boolean} show
 * @param {string} msg
 */
const renderResultsModal = (
  show,
  success,
  error,
  successCloseFunction,
  errorCloseFunction
) => {
  if (show) {
    // modal used is dependant on if there is an error
    if (error.length > 0) {
      // use the Error Modal
      return (
        <ErrorModal
          title='An Error Has Occured'
          error={error}
          closeFunction={errorCloseFunction}
        />
      );
    }

    // show successful result
    return (
      <ResultsModal
        success={success}
        error={error}
        closeFunction={successCloseFunction}
        title='Save Payment Result'
        hasLink={false}
      />
    );
  }
  return null;
};

const BillingForm = (props) => {
  /** Component Level State Variables */
  const [currentAmountReceived, setCurrentAmountReceived] = useState(0.0);
  const [paymentAmountToApply, setPaymentAmountToApply] = useState(0.0);
  const [paymentAmountToCredit, setPaymentAmountToCredit] = useState(0.0);
  const [currentDealerBalance, setCurrentDealerBalance] = useState(0.0);
  const [existingCredit, setExistingCredit] = useState(0.0);
  const [showAlertMessage, setShowAlertMessage] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [selectedDealer, setSelectedDealer] = useState(-1);
  const [paymentDate, setPaymentDate] = useState(new Date());
  const [paymentMethod, setPaymentMethod] = useState('CK To BRM');
  const [paymentRefNumber, setPaymentRefNumber] = useState('');
  const [initialSelectedInvoices, setInitialSelectedInvoices] = useState({});
  const [achPaymentInfo, setACHPaymentInfo] = useState({
    nameOnAccount: '',
    routingNumber: '',
    accountNumber: '',
  });
  const [ccPaymentInfo, setCCPaymentInfo] = useState({
    ccNumber: '',
    nameOnCard: '',
    expireDate: '',
    cvc: '',
  });
  const [showLocalSubmissionError, setShowLocalSubmissionError] =
    useState(false);

  /** The following component state variables are necessary for tracking
   * payments applied to line item invoices.
   */
  const [data, setData] = useState([]);
  const [originalData] = useState(data);
  const [tableReset, setTableReset] = useState(false);

  /** FORM REFS SECTION */
  // setup references to the different inputs
  const memoInputRef = useRef(props.billingData.billingMemo);

  /** END FORM REFS */

  // create a reference to the navigation object of react router
  // so we can redirect to the dashboard when finished
  // THIS WILL NEED TO BE ADJUSTED WHEN THE DEALER PAGE IS BUILT
  const navigate = useNavigate();

  /**
   * Handles adjusting the amount to apply and amount to credit for the payment
   *
   * @param {float} newValue - payment value entered by the user
   * @param {float} oldValue - previous payment value
   */
  const updateAmounts = (newValue, oldValue) => {
    // get the difference between the old value and the new value
    // and apply the difference to the amount to apply and the amount to credit
    const valueDifference = parseFloat(newValue) - parseFloat(oldValue);
    //console.log('Amount Updating Difference', valueDifference);
    const newAmountToApply = paymentAmountToApply + valueDifference;
    const newAmountToCredit = paymentAmountToCredit - valueDifference;
    setPaymentAmountToApply(newAmountToApply);
    setPaymentAmountToCredit(newAmountToCredit);
  };

  /** TABLE SETUP SECTION */

  // define the columns for the invoice table
  const columns = useMemo(
    () => [
      { Header: 'Invoice #', accessor: 'txnId' },
      { Header: 'Created By', accessor: 'createdByInitials' },
      { Header: 'From Dealer Date', accessor: 'fromDealerDate' },
      { Header: 'Dealer', accessor: 'dealer' },
      { Header: 'Reg. Name', accessor: 'regFullName' },
      { Header: 'Reg. Company', accessor: 'regCompanyName' },
      { Header: 'Status', accessor: 'status' },
      { Header: 'Plate', accessor: 'regPlateNumber' },
      { Header: 'VIN #', accessor: 'regVin' },
      { Header: 'Total Charges', accessor: 'totalCharges' },
      {
        Header: 'Current Balance',
        accessor: 'currentBalance',
        Cell: (row) => renderAmountColor(row),
      },
      { Header: 'Payment', accessor: 'payment', disableSortBy: true },
    ],
    []
  );

  /**
   * Verifies that there is enough money in the amount to apply to an
   * invoice to cover it.  If there is not enough, then only a partial amount
   * can be applied if the amount of credit left is greater than zero.  If the
   * credit amount left is zero, then the user must be notified.
   *
   * @param {float} toApply - amount requested to apply to an invoice
   * @param {float} maxAmount - the maximum amount that can be applied to an invoice (remaining balance)
   * @param {float} diffFromOldValue - the total difference between the amount requested to apply and the previous value that was applied
   * @param {float} oldValue - the previous value entered before the amount was changed by the user
   * @returns
   */
  const checkAppliedPayment = (
    toApply,
    maxAmount,
    diffFromOldValue,
    oldValue
  ) => {
    //console.log('To Apply Sent', toApply);
    //console.log('Max Amount Sent', maxAmount);
    //console.log('Credit Amount Left', paymentAmountToCredit);

    // negative amounts are automatically rejected
    if (toApply < 0) {
      setAlertMessage('Negative amounts are not allowed');
      setShowAlertMessage(true);
      return false;
    }

    // first we have to check to see if the amount to apply is greater than the maximum
    // amount left to pay for an invoice
    if (toApply > parseFloat(maxAmount)) {
      // this is an automatic failure because we will not allow
      // the user to enter more than is owed on an invoice
      setAlertMessage('You cannot apply more than what is owed on an invoice');
      setShowAlertMessage(true);
      return false;
    }

    // We need to make sure there is enough credit to cover the payment that was entereed
    if (
      diffFromOldValue > paymentAmountToCredit &&
      0 === paymentAmountToCredit
    ) {
      // there's nothing left to apply, so we cannot update this payment line
      setAlertMessage('There is not enough credit to apply to the invoice');
      setShowAlertMessage(true);
      return false;
    } else if (
      diffFromOldValue > paymentAmountToCredit &&
      paymentAmountToCredit > 0
    ) {
      // we can only apply a partial payment to the invoice, so the value to apply
      // to the invoice is the credit amount plus the old value
      toApply = oldValue + paymentAmountToCredit;
    }

    return toApply;
  };

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateInvoiceData = (rowIndex, columnId, value, oldValue) => {
    setShowAlertMessage(false);
    // We need to make sure there is enough credit to cover the payment that was entereed
    // This may be a double check, but we need to check here in case the user enters a value in the
    // payment box.
    //console.log('New Value', value);
    //console.log('Old Value', oldValue);
    //console.log('Current Balance', data[rowIndex].currentBalance);
    const valueDifference = value - oldValue;
    //console.log('New Value Difference from Old Value: ', valueDifference);
    let toApply = value;
    if (valueDifference > 0) {
      toApply = checkAppliedPayment(
        value,
        data[rowIndex].currentBalance,
        valueDifference,
        oldValue
      );
    }
    //console.log('Amount to Apply', toApply);
    if (toApply === false) {
      // there's nothing left to apply, so we cannot update this payment line and
      // we must restore the previous value of the textbox
      setData((old) =>
        old.map((row, index) => {
          if (index === rowIndex) {
            return {
              ...old[rowIndex],
              [columnId]: oldValue,
            };
          }
          return row;
        })
      );
      return false;
    }

    //console.log('Adjusting Amounts');

    // adjust the amount to apply and amount to credit
    updateAmounts(parseFloat(toApply), oldValue);

    setData((old) =>
      old.map((row, index) => {
        //console.log('Checking Row', row);
        //console.log('Match?', index === rowIndex);
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: parseFloat(toApply),
          };
        }
        return row;
      })
    );
    //console.log('Line Item Payment Index', rowIndex);
    //console.log('Line Item Payment Value', value);
    return true;
  };

  // set the editable cell renderer as the default Cell renderer
  const defaultColumn = {
    Cell: LineItemPayment,
  };

  // add another effect to monitor when a dealer is selected and
  // the data needs to change
  useEffect(() => {
    setTableReset(true);

    // we need to create a reference to the invoice data saved in
    // state in order to modify the payment amounts if this
    // is an edit
    let invoicesInBilling = props.invoiceList;

    if (props.billingData.invoicesAmountApplied.length > 0) {
      // this is an edit, so set the invoices that have already been paid
      // grab the list of plate ids to find
      const invoiceIds = _.map(
        props.billingData.invoicesAmountApplied,
        (values) => {
          return values.plate_id;
        }
      );
      //console.log('Selected Plate Ids', invoiceIds);
      let selectedInvoices = {};

      // we loop through the invoices to match up what invoices
      // need to be checked and what amounts need to be set
      // in the amount fields
      _.each(props.invoiceList, (value, key) => {
        _.each(invoiceIds, (invoiceValue, invoiceKey) => {
          if (invoiceValue === value.txnId) {
            selectedInvoices = { ...selectedInvoices, [key]: true };
            invoicesInBilling = _.map(invoicesInBilling, (row, index) => {
              //console.log('Invoice Row', row);
              //console.log('Check Key', index + ' = ' + key);
              if (index === key) {
                return {
                  ...row,
                  payment: parseFloat(
                    props.billingData.invoicesAmountApplied[invoiceKey].amount
                  ).toFixed(2),
                };
              }
              return row;
            });
          }
        });
      });
      //console.log('Selected Plate Indicies', selectedInvoices);

      // set the selected invoices
      setInitialSelectedInvoices(selectedInvoices);
    }
    setData(invoicesInBilling);
  }, [props.invoiceList]);

  // this will monitor changes in the invoice data state
  // if it is found that the table reset flag is set to true
  // then we know that a new dealer has been selected and we
  // need to change the table reset flag back to false so
  // the checkbox and textbox states will change properly
  useEffect(() => {
    if (tableReset) {
      setTableReset(false);
    }
  }, [data]);

  // monitor the changes in the amount received field to update the
  // amount to apply and the amount to credit
  useEffect(() => {
    // a change in the state value has been detected, update the credit amount accordingly
    const newCreditAmount =
      parseFloat(currentAmountReceived) -
      parseFloat(paymentAmountToApply) +
      parseFloat(existingCredit);
    setPaymentAmountToCredit(newCreditAmount);
  }, [currentAmountReceived]);

  // monitor when the dealer has been changed and set the
  // amount to credit to the dealer's credit (Only for new invoices)
  useEffect(() => {
    //if (props.billingData.billingId === 0) {
    // does the dealer selected have an outstanding credit that
    // needs to be used?
    const selectedDealerInfo = _.find(props.dealers, {
      dealerId: selectedDealer,
    });

    if (selectedDealerInfo !== undefined) {
      // set any credit amount they have
      setExistingCredit(parseFloat(selectedDealerInfo.totalCredits));
      setPaymentAmountToCredit(parseFloat(selectedDealerInfo.totalCredits));
      setCurrentDealerBalance(parseFloat(selectedDealerInfo.currentBalance));
    }
    //}
  }, [selectedDealer]);

  // We need to see if the current dealer id is set when the component
  // loads for the first time.  This will indicate that the billing
  // is being called from the dealer list.
  useEffect(() => {
    let currentDealerCredit = {};
    if (props.currentDealerId > 0) {
      // trigger the update of the local dealer id
      //console.log('Current Dealer', props.currentDealerId);
      setSelectedDealer(props.currentDealerId);
      currentDealerCredit = _.find(props.dealers, {
        dealerId: props.currentDealerId,
      });
    }

    // if there is a billing id present then we need
    // to retrieve the billing data for the edit
    if (props.billingData.billingId > 0) {
      // this is an edit, we need to set the local state values
      setCurrentAmountReceived(parseFloat(props.billingData.amountReceived));
      setPaymentAmountToApply(parseFloat(props.billingData.amountToApply));

      const totalCreditAmount = parseFloat(props.billingData.amountToCredit);

      setPaymentAmountToCredit(parseFloat(props.billingData.amountToCredit));
      setExistingCredit(
        parseFloat(currentDealerCredit.totalCredits) - totalCreditAmount
      );
      setPaymentDate(new Date(props.billingData.paymentInfo.paymentDate));
      setPaymentMethod(props.billingData.paymentInfo.paymentMethod);
      setPaymentRefNumber(props.billingData.paymentInfo.referenceNumber);
      props.getDealerOutstandingInvoices(
        props.billingData.dealerId,
        props.billingData.invoicesAmountApplied
      );
    } else {
      // set the existing dealer credit amount if neccessary
      if (props.currentDealerId > 0) {
        const formattedDealerCreditAmount = parseFloat(
          currentDealerCredit.totalCredits
        );
        setExistingCredit(formattedDealerCreditAmount);
        setPaymentAmountToCredit(formattedDealerCreditAmount);
      }
    }
  }, []);

  // We need to set the initially selected invoices when performing an
  // edit.  To do this we will monitor when the outsanding invoice list has
  // completed populating.
  useEffect(() => {}, [props.billingData.invoicesAmountApplied]);

  /** REACT TABLE SETUP */
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        selectedRowIds: initialSelectedInvoices,
      },
      // we're using the stateReducer function to help monitor when the checked
      // state of a row changes so we can properly modify the payment values
      stateReducer: (newState, action, prevState) => {
        //console.log('Action Object', action);
        // we are specifically targeting when a checkbox is toggled on a row
        if (action.type === 'toggleRowSelected') {
          //console.log('Row Checked', action.id);
          //console.log('Previous State', prevState);
          // find out if the row that triggered the checkbox toggle was
          // previously in the checked state
          const selectedRowKey = _.findKey(
            prevState.selectedRowIds,
            (o, key) => {
              //console.log('Key:' + key + ' Id: ' + action.id);
              return parseInt(key) === parseInt(action.id);
            }
          );
          //console.log('Row Previously Selected?', selectedRowKey);
          //console.log('Data', data);

          // The selected row check will be undefined if the row was not
          // previously checked, indicating that the user has selected
          // this row.  However, this can also be triggered by entering an
          // amount in the textbox when the checked state of the row checkbox
          // is unchecked.  In that case we leave the amounts alone and accept
          // the amount that the user entered in the box.
          if (selectedRowKey === undefined && 0 === data[action.id].payment) {
            // try to update the amount in the text box
            const applyResult = updateInvoiceData(
              parseInt(action.id),
              'payment',
              parseFloat(data[action.id].currentBalance).toFixed(2),
              0.0
            );

            //console.log('To Apply Result', applyResult);

            if (applyResult === false) {
              // this invoice cannot be paid, return the previous state of the checkbox
              return prevState;
            }
          } else if (selectedRowKey !== undefined) {
            //console.log('Uncheck Box and Update Amounts');
            // the previous state of the checkbox was checked so zero out the payment textbox
            updateInvoiceData(
              parseInt(action.id),
              'payment',
              parseFloat(0.0),
              data[action.id].payment
            );
          }
        }

        return newState;
      },
      // use the skipPageReset option to disable page resetting temporarily
      autoResetPage: tableReset,
      autoResetSelectedRows: tableReset,
      autoResetSortBy: tableReset,
      // updateInvoiceData isn't part of the API, but
      // anything we put into these options will
      // automatically be available on the instance.
      // That way we can call this function from our
      // cell renderer!
      updateInvoiceData,
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // make a column for selection
        {
          id: 'selection',
          disableSortBy: true,
          // Header: ({ getToggleAllPageRowsSelectedProps }) => (
          //   <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
          // ),
          // The cell can use the individual row's getToggleRowSelectedProps method to
          // render a checkbox
          Cell: ({ row }) => (
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
          ),
        },
        ...columns,
      ]);
    }
  );

  /** END TABLE SETUP SECTION */

  /**
   * Form submit handler to send the billing information to the server.
   */
  const submitBilling = () => {
    //console.log('Billing Submitted');
    //console.log('Memo Ref', memoInputRef.current.value);

    // Make sure there is not a negative credit amount before continuing
    if (paymentAmountToCredit < 0) {
      // STOP, this payment cannot be submitted
      setShowLocalSubmissionError(true);
      return;
    }

    // build the submission object
    const billingSubmissionObj = {
      billingId: props.billingData.billingId,
      txnType: 'Payment',
      toward: 'Transaction - General',
      paymentType: paymentMethod,
      amountReceived: currentAmountReceived,
      amountToCredit: paymentAmountToCredit,
      existingCredit:
        existingCredit > 0 ? existingCredit + paymentAmountToCredit : 0,
      referenceNumber: paymentRefNumber,
      dealer: selectedDealer,
      memo: memoInputRef.current.value,
      invoicesToPay: _.filter(data, (value) => {
        return value.payment > 0;
      }),
      paymentProcessObj: {
        amount: currentAmountReceived,
        ccNumber: ccPaymentInfo.ccNumber,
        ccName: ccPaymentInfo.nameOnCard,
        ccExpire: ccPaymentInfo.expireDate,
        ccCVC: ccPaymentInfo.cvc,
        achAccountNumber: achPaymentInfo.accountNumber,
        achRouting: achPaymentInfo.routingNumber,
        achNameOnAccount: achPaymentInfo.nameOnAccount,
      },
    };

    //console.log('Submission Object', billingSubmissionObj);
    props.distributeBillingPayment(billingSubmissionObj);
  };

  return (
    <Container>
      <BillingHeader
        userInitials={props.currentUser.initials}
        billingId={props.billingData.billingId}
      />
      <Grid stackable>
        {parseInt(props.billingData.paymentInfo.paymentProcessed) > 0 && (
          <Grid.Column width={16}>
            <Message warning style={{ textAlign: 'center' }}>
              <Message.Header>
                This payment cannot be edited because payment has already been
                processed.
              </Message.Header>
            </Message>
          </Grid.Column>
        )}
        <Grid.Column width={4}>
          <DealerDropdown
            dealerList={props.dealers}
            dealerId={selectedDealer}
            getDealerInvoices={props.getDealerOutstandingInvoices}
            setDealer={setSelectedDealer}
            billingId={props.billingData.billingId}
          />
        </Grid.Column>
        <Grid.Column width={4}>
          <DealerCurrentBalanceText
            currentDealerBalance={currentDealerBalance}
          />
        </Grid.Column>
        <Grid.Column width={4}>
          <CurrentDealerCreditText dealerCredit={existingCredit} />
        </Grid.Column>
        <Grid.Column width={4} textAlign='right'>
          <AmountReceivedText amountReceived={currentAmountReceived} />
        </Grid.Column>
      </Grid>
      <BillingPaymentForm
        paymentDate={paymentDate}
        setPaymentDate={setPaymentDate}
        paymentMethod={paymentMethod}
        setPaymentMethod={setPaymentMethod}
        paymentRefNumber={paymentRefNumber}
        setPaymentRefNumber={setPaymentRefNumber}
        amountReceived={currentAmountReceived}
        setCurrentAmountReceived={setCurrentAmountReceived}
        ccPaymentInfo={ccPaymentInfo}
        setCCPaymentInfo={setCCPaymentInfo}
        achPaymentInfo={achPaymentInfo}
        setACHPaymentInfo={setACHPaymentInfo}
        setAlertMessage={setAlertMessage}
        setShowAlert={setShowAlertMessage}
      />
      {showAlertMessage && (
        <ResultsModal
          success={''}
          error={alertMessage}
          closeFunction={setShowAlertMessage}
          title='Warning'
          hasLink={false}
        />
      )}
      <Grid stackable>
        <Grid.Column width={8} className='brm-form-label'>
          Memo
          <br />
          <textarea style={{ width: '100%' }} ref={memoInputRef}>
            {props.billingData.billingMemo}
          </textarea>
        </Grid.Column>
        <Grid.Column width={8}>
          <BillingTotals
            amountToApply={paymentAmountToApply}
            amountToCredit={paymentAmountToCredit}
          />
        </Grid.Column>
      </Grid>
      <OpenInvoiceList
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        prepareRow={prepareRow}
        page={page}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        pageOptions={pageOptions}
        pageCount={pageCount}
        gotoPage={gotoPage}
        nextPage={nextPage}
        previousPage={previousPage}
        setPageSize={setPageSize}
        pageIndex={pageIndex}
        pageSize={pageSize}
        selectedRowIds={selectedRowIds}
        data={data}
      />
      <BillingTotals
        amountToApply={paymentAmountToApply}
        amountToCredit={paymentAmountToCredit}
      />
      <Grid stackable>
        <Grid.Column width={4}>
          <Button
            className='inverted-button'
            onClick={() => {
              // clear out the billing form state and redirect to the dashboard
              props.clearBillingForm();
              navigate(-1);
            }}
          >
            Cancel
          </Button>
        </Grid.Column>
        <Grid.Column width={12} textAlign='right'>
          {0 === parseInt(props.billingData.paymentInfo.paymentProcessed) && (
            <Button
              type='button'
              className='inverted-button'
              onClick={submitBilling}
            >
              <Icon name='save' />
              Save &amp; Close
            </Button>
          )}
        </Grid.Column>
      </Grid>
      {renderResultsModal(
        props.billingData.billingSaved,
        props.success,
        props.error,
        () => {
          props.clearBillingForm();
          navigate(-1);
        },
        props.clearBillingError
      )}
      {renderLoader(props.showLoading, 'Saving Payment')}
      {showLocalSubmissionError && (
        <ErrorModal
          title='Negative Credit Amount'
          error='You have a negative credit to apply amount.  You cannot submit this payment until corrected.  Please adjust payments to the invoices to apply payment to until the credit amount is zero or greater.'
          closeFunction={() => setShowLocalSubmissionError(false)}
        />
      )}
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    dealers: state.dealers.dealerList,
    currentDealerId: state.dealers.dealerForm.dealerId,
    invoiceList: state.billings.unpaidInvoiceList,
    currentUser: state.users.currentUser,
    showLoading: state.main.isLoading,
    error: state.main.errors,
    success: state.main.success,
    billingData: state.billings,
  };
};

export default connect(mapStateToProps, {
  getDealerOutstandingInvoices,
  clearBillingForm,
  distributeBillingPayment,
  clearBillingError,
})(BillingForm);
