import {
  LOGOUT_USER,
  CALCULATE_CHARGES,
  SUBMIT_INVOICE,
  INVOICE_SAVED,
  GET_FINANCIAL_TXN_BY_INVOICE,
  SET_INVOICE_NOTES,
  SET_INTERNAL_NOTES,
  GET_INVOICE,
  CLEAR_INVOICE,
  SAVE_TEMP_FINANCIAL_TXN,
  SAVE_TEMP_INTERNAL_NOTE,
  SAVE_TEMP_INVOICE_NOTE,
  UPDATE_CURRENT_BALANCE,
  PRINT_INVOICE,
  REMOVE_INVOICE_NOTE,
  REMOVE_INVOICE_INTERNAL_NOTE,
  SET_INVOICE_ID,
  INVOICE_REMOVED,
  CALCULATE_TOTAL_PROFIT,
} from '../actions/types';

const INITIAL_STATE = {
  txnForm: {
    txnId: 0,
    dealerId: -1,
    updatedByUserId: 0,
    dealerPhoneNumber: '',
    dealerCourier: '',
    returnedTo: 'dealer',
    firstName: '',
    lastName: '',
    companyName: '',
    email: '',
    phoneNumber: '',
    txnType: '',
    regAddress: '',
    regCity: '',
    regZipCode: '',
    stateDeal: '',
    carMake: '',
    carYear: '',
    vinNumber: '',
    plateNumber: '',
    lienHolderId: -1,
    leasingCompany: '',
    receivedFromDealer: new Date(),
    sentToDMV: null,
    returnFromDMV: null,
    brmFees: 0.0,
    dmvFees: 0.0,
    otherFees: 0.0,
    salesTax: 0.0,
    chargeTotal: 0.0,
    currentBalance: 0.0,
    status: 'Received From Dealer',
    combinedInvoiceId: 0,
    paidFromCombinedInvoice: 0,
    createdByInitials: '',
  },
  invoiceSaved: false,
  invoiceRemoved: false,
  showPrintInvoice: false,
  pdfInvoiceFileName: '',
  txnList: [],
  notes: [],
  internalNotes: [],
  totalNetProfit: 0.0,
  percentageProfit: 0,
  currentChargeCostValues: {
    brmFees: 0.0,
    dmvFees: 0.0,
    otherFees: 0.0,
    salesTax: 0.0,
  },
};

const plateState = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGOUT_USER:
      return INITIAL_STATE;
    case GET_INVOICE:
      return {
        ...state,
        txnForm: {
          txnId: action.payload.id,
          dealerId: action.payload.dealer_id,
          updatedByUserId: action.payload.updated_by,
          dealerPhoneNumber: action.payload.dealer_phone,
          dealerCourier: action.payload.dealer_courier,
          returnedTo: action.payload.returned_to,
          firstName: action.payload.reg_first_name,
          lastName: action.payload.reg_last_name,
          companyName: action.payload.reg_company_name,
          email: action.payload.reg_email,
          phoneNumber: action.payload.reg_phone,
          txnType: action.payload.reg_transaction_type,
          regAddress: action.payload.reg_address,
          regCity: action.payload.reg_city,
          regZipCode: action.payload.reg_zipcode,
          stateDeal: action.payload.reg_state_deal,
          carMake: action.payload.reg_car_make,
          carYear: action.payload.reg_car_year,
          vinNumber: action.payload.reg_vin,
          plateNumber: action.payload.reg_plate_number,
          lienHolderId: action.payload.reg_lien_holder_id,
          leasingCompany: action.payload.leasing_company,
          receivedFromDealer: action.payload.from_dealer_date,
          sentToDMV: action.payload.dmv_sent_date,
          returnFromDMV: action.payload.dmv_return_date,
          brmFees: action.payload.brm_fees,
          dmvFees: action.payload.dmv_fees,
          otherFees: action.payload.other_fees,
          salesTax: action.payload.sale_tax,
          chargeTotal:
            parseFloat(action.payload.brm_fees) +
            parseFloat(action.payload.dmv_fees) +
            parseFloat(action.payload.sale_tax) +
            parseFloat(action.payload.other_fees),
          status: action.payload.status,
          currentBalance: 0.0,
          combinedInvoiceId: action.payload.combined_invoice_id,
          paidFromCombinedInvoice: action.payload.paid_from_combined_invoice,
          createdByInitials: action.payload.initials,
        },
        currentChargeCostValues: {
          brmFees: parseFloat(action.payload.brm_fees),
          dmvFees: parseFloat(action.payload.dmv_fees),
          salesTax: parseFloat(action.payload.sale_tax),
          otherFees: parseFloat(action.payload.other_fees),
        },
      };
    case SUBMIT_INVOICE:
      return {
        ...state,
        txnForm: {
          txnId: 0,
          dealerId: action.payload.dealerId,
          updatedByUserId: action.payload.currentUserId,
          dealerPhoneNumber: action.payload.dealerPhone,
          dealerCourier: action.payload.dealerCourier,
          returnedTo: 'dealer',
          firstName: '',
          lastName: '',
          companyName: '',
          email: '',
          phoneNumber: '',
          txnType: '',
          regAddress: '',
          regCity: '',
          regZipCode: '',
          stateDeal: '',
          carMake: '',
          carYear: '',
          vinNumber: '',
          plateNumber: '',
          lienHolderId: -1,
          leasingCompany: '',
          receivedFromDealer: new Date(),
          sentToDMV: null,
          returnFromDMV: null,
          brmFees: 0.0,
          dmvFees: 0.0,
          otherFees: 0.0,
          salesTax: 0.0,
          chargeTotal: 0.0,
          status: 'Received From Dealer',
          combinedInvoiceId: 0,
          paidFromCombinedInvoice: 0,
          createdByInitials: '',
        },
        txnList: [],
        notes: [],
        internalNotes: [],
        invoiceSaved: true,
        invoiceRemoved: false,
        showPrintInvoice: false,
        totalNetProfit: 0.0,
        percentageProfit: 0,
        currentChargeCostValues: {
          brmFees: 0.0,
          dmvFees: 0.0,
          otherFees: 0.0,
          salesTax: 0.0,
        },
      };
    case CLEAR_INVOICE:
      return {
        ...state,
        txnForm: {
          txnId: 0,
          dealerId: -1,
          updatedByUserId: action.payload,
          dealerPhoneNumber: '',
          dealerCourier: '',
          returnedTo: 'dealer',
          firstName: '',
          lastName: '',
          companyName: '',
          email: '',
          phoneNumber: '',
          txnType: '',
          regAddress: '',
          regCity: '',
          regZipCode: '',
          stateDeal: '',
          carMake: '',
          carYear: '',
          vinNumber: '',
          plateNumber: '',
          lienHolderId: -1,
          leasingCompany: '',
          receivedFromDealer: new Date(),
          sentToDMV: null,
          returnFromDMV: null,
          brmFees: 0.0,
          dmvFees: 0.0,
          otherFees: 0.0,
          salesTax: 0.0,
          chargeTotal: 0.0,
          currentBalance: 0.0,
          status: 'Received From Dealer',
          combinedInvoiceId: 0,
          paidFromCombinedInvoice: 0,
          createdByInitials: '',
        },
        txnList: [],
        notes: [],
        internalNotes: [],
        invoiceSaved: false,
        invoiceRemoved: false,
        showPrintInvoice: false,
        totalNetProfit: 0.0,
        percentageProfit: 0,
        currentChargeCostValues: {
          brmFees: 0.0,
          dmvFees: 0.0,
          otherFees: 0.0,
          salesTax: 0.0,
        },
      };
    case CALCULATE_CHARGES:
      return {
        ...state,
        txnForm: {
          ...state.txnForm,
          chargeTotal: action.payload.totalCharges,
        },
        currentChargeCostValues: {
          brmFees: action.payload.currentFees.brmFees,
          dmvFees: action.payload.currentFees.dmvFees,
          otherFees: action.payload.currentFees.otherFees,
          salesTax: action.payload.currentFees.salesTax,
        },
      };
    case UPDATE_CURRENT_BALANCE:
      return {
        ...state,
        txnForm: { ...state.txnForm, currentBalance: action.payload },
      };
    case INVOICE_SAVED:
      return { ...state, invoiceSaved: false };
    case GET_FINANCIAL_TXN_BY_INVOICE:
      return { ...state, txnList: action.payload };
    case SET_INTERNAL_NOTES:
      return { ...state, internalNotes: action.payload };
    case SET_INVOICE_NOTES:
      return { ...state, notes: action.payload };
    case SAVE_TEMP_FINANCIAL_TXN:
      return { ...state, txnList: [...state.txnList, action.payload] };
    case SAVE_TEMP_INVOICE_NOTE:
      return { ...state, notes: [...state.notes, action.payload] };
    case SAVE_TEMP_INTERNAL_NOTE:
      return {
        ...state,
        internalNotes: [...state.internalNotes, action.payload],
      };
    case REMOVE_INVOICE_NOTE:
      return {
        ...state,
        notes: state.notes.filter((value) => value.id !== action.payload),
      };
    case REMOVE_INVOICE_INTERNAL_NOTE:
      return {
        ...state,
        internalNotes: state.internalNotes.filter(
          (value) => value.id !== action.payload
        ),
      };
    case PRINT_INVOICE:
      return {
        ...state,
        showPrintInvoice: action.payload.show,
        pdfInvoiceFileName: action.payload.fileName,
      };
    case SET_INVOICE_ID:
      return { ...state, txnForm: { ...state.txnForm, txnId: action.payload } };
    case INVOICE_REMOVED:
      return { ...state, invoiceRemoved: action.payload };
    case CALCULATE_TOTAL_PROFIT:
      return {
        ...state,
        totalNetProfit: action.payload.netProfit,
        percentageProfit: action.payload.percentageProfit,
      };
    default:
      return state;
  }
};

export default plateState;
