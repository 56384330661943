import _ from 'lodash';
import {
  SET_STATE_FEES,
  SAVE_STATE_FEES,
  RESET_STATE_FEES_UPDATED,
  UPDATE_STATE_FEES,
  UPDATE_STATE_FEE_RESULT,
} from '../actions/types';

const INITIAL_STATE = {
  stateFeeList: [],
  feesSaved: false,
  feesSavedMessage: '',
};

const stateFeeState = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_STATE_FEES:
      return { ...state, stateFeeList: action.payload };
    case UPDATE_STATE_FEES:
      return {
        ...state,
        stateFeeList: _.map(state.stateFeeList, (value) =>
          value.id === action.payload.id
            ? { ...value, amount: action.payload.amount, updated: true }
            : value
        ),
      };
    case UPDATE_STATE_FEE_RESULT:
      return {
        ...state,
        stateFeeList: _.map(state.stateFeeList, (value) =>
          value.id === action.payload.feeId
            ? {
                ...value,
                updateResult: action.payload.result,
                updateErrorMessage: action.payload.error,
                dateChanged: action.payload.dateUpdated,
              }
            : value
        ),
      };
    case SAVE_STATE_FEES:
      return {
        ...state,
        feesSaved: action.payload.saveCompleted,
        feesSavedMessage: action.payload.message,
      };
    case RESET_STATE_FEES_UPDATED:
      return { ...state, feesSaved: false, feesSavedMessage: '' };
    default:
      return state;
  }
};

export default stateFeeState;
