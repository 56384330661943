import React from 'react';
import { connect } from 'react-redux';
import { Container, Button, Header, Icon, Label } from 'semantic-ui-react';
import PageHeader from '../PageHeader';
import StateList from './StateList';
import LoadingComp from '../LoadingComp';
import ResultsModal from '../ResultsModal';
import ErrorModal from '../ErrorModal';
import {
  getAllStateFees,
  saveStateFees,
  clearStateFeesSaved,
} from '../../actions';

/**
 * Component to show all states with their related fees.
 *
 * @param {object} props
 * @returns
 */
const ManageStateFees = (props) => {
  // renders the loading component
  const renderLoader = (show, message) => {
    if (show) {
      return <LoadingComp msg={message} />;
    }
    return null;
  };

  /**
   * Shows the results of a regular invoice submission (not print)
   * @param {boolean} show
   * @param {string} msg
   */
  const renderResultsModal = (
    show,
    success,
    error,
    successCloseFunction,
    errorCloseFunction
  ) => {
    if (show) {
      // modal used is dependant on if there is an error
      if (error.length > 0) {
        // use the Error Modal
        return (
          <ErrorModal
            title='An Error Has Occured'
            error={error}
            closeFunction={errorCloseFunction}
          />
        );
      }

      // show successful result
      return (
        <ResultsModal
          success={success}
          error={error}
          closeFunction={successCloseFunction}
          title='Save State Fees Result'
          hasLink={false}
        />
      );
    }
    return null;
  };

  // prevent unauthorized users from accessing this page
  if (!props.isAdministrator) {
    // show a message instead of the user list
    return <UnauthorizedMessage />;
  }
  return (
    <Container className='brm-container'>
      <PageHeader />
      <Header>State Fees</Header>
      <Button
        type='button'
        className='inverted-button'
        onClick={() => props.saveStateFees()}
      >
        <Icon name='save' />
        &nbsp;&nbsp;Save Changes
      </Button>
      <br />
      <br />
      <StateList />
      <br />
      <Button
        type='button'
        className='inverted-button'
        onClick={() => props.saveStateFees()}
      >
        <Icon name='save' />
        &nbsp;&nbsp;Save Changes
      </Button>
      {renderLoader(props.showLoading, 'Saving Fees')}
      {renderResultsModal(
        props.feesSaved,
        props.success,
        props.error,
        () => {
          props.clearStateFeesSaved();
        },
        props.clearStateFeesSaved
      )}
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    showLoading: state.main.isLoading,
    isAdministrator: state.users.isAdministrator,
    feesSaved: state.stateFees.feesSaved,
    savedMessage: state.stateFees.feesSavedMessage,
    error: state.main.errors,
    success: state.main.success,
  };
};

export default connect(mapStateToProps, {
  getAllStateFees,
  saveStateFees,
  clearStateFeesSaved,
})(ManageStateFees);
