import React, { Fragment } from 'react';
import { Grid } from 'semantic-ui-react';

/**
 * Shows the total charges and costs.  If the user
 * is an administrator it shows the net profit for
 * a plate invoice.
 *
 * @param {object} props
 * @returns
 */
const TotalsLine = (props) => {
  return (
    <Fragment>
      <Grid.Column
        width={!props.isTabletOrMobile ? 4 : 16}
        verticalAlign='middle'
      >
        {props.isAdministrator && (
          <strong>
            TOTAL:&nbsp;&nbsp;${parseFloat(props.chargeTotal).toFixed(2)}
          </strong>
        )}
        {!props.isAdministrator && <strong>TOTAL</strong>}
        {props.isTabletOrMobile && !props.isAdministrator && (
          <div style={{ float: 'right' }}>
            <strong>${parseFloat(props.chargeTotal).toFixed(2)}</strong>
          </div>
        )}
        {props.isTabletOrMobile && props.isAdministrator && (
          <div style={{ float: 'right' }}>
            <strong>
              NET PROFIT:&nbsp;&nbsp;${parseFloat(props.netProfit).toFixed(2)}
              &nbsp; ({props.netProfitPercent}%)
            </strong>
          </div>
        )}
      </Grid.Column>
      {!props.isTabletOrMobile && !props.isAdministrator && (
        <Grid.Column width={12} textAlign='right' verticalAlign='middle'>
          <strong>${parseFloat(props.chargeTotal).toFixed(2)}</strong>
        </Grid.Column>
      )}
      {!props.isTabletOrMobile && props.isAdministrator && (
        <Grid.Column width={12} textAlign='right' verticalAlign='middle'>
          <strong>
            NET PROFIT:&nbsp;&nbsp;${parseFloat(props.netProfit).toFixed(2)}
            &nbsp;({props.netProfitPercent}%)
          </strong>
        </Grid.Column>
      )}
    </Fragment>
  );
};

export default TotalsLine;
