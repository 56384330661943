import React from 'react';
import { HashRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Login from './Login';
import Dashboard from './Dashboard/Dashboard';
import Invoice from './PlateInvoicing/Invoice';
import ManageUsers from './UserManagement/ManageUsers';
import NewPassword from './ForgotPassword/NewPassword';
import ReportViewer from './Reports/ReportViewer';
import ProfileForm from './MyProfile/ProfileForm';
import TwoFactorAuth from './TwoFactorAuth/TwoFactorAuth';
import BillingForm from './Billing/BillingForm';
import ManageDealers from './Dealers/ManageDealers';
import DealerDetails from './Dealers/DealerDetails';
import ManageStateFees from './StateFees/ManageStateFees';
import ReadOnlyInvoice from './Dealers/Invoice/ReadOnlyInvoice';

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/invoice' element={<Invoice />} />
        <Route path='/user-management' element={<ManageUsers />} />
        <Route path='/manage-dealers' element={<ManageDealers />} />
        <Route path='/state-fees' element={<ManageStateFees />} />
        <Route path='/dealer-details' element={<DealerDetails />} />
        <Route path='/my-profile' element={<ProfileForm />} />
        <Route path='/billing-form' element={<BillingForm />} />
        <Route path='/reports' element={<ReportViewer />} />
        <Route path='/reset-pass/:linkHash/' element={<NewPassword />} />
        <Route path='/two-factor-authenticate' element={<TwoFactorAuth />} />
        <Route path='/dealer-invoice' element={<ReadOnlyInvoice />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
