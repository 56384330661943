import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import InputMask from 'react-input-mask';
import {
  Header,
  Input,
  Radio,
  Dropdown,
  Grid,
  Message,
  Icon,
} from 'semantic-ui-react';
import {
  DEALER_NAME,
  DEALER_PHONE,
  COURIER,
  RETURN_TO,
} from './FormSetup/FieldNames';
import { courierList } from './Couriers';
import Confirmation from '../Confirmation';
import {
  showDealerForm,
  getDealer,
  addNewDealer,
  removeDealer,
} from '../../actions';

const DealerInfo = (props) => {
  // state to monitor if a dealer was removed
  const [deleteDealer, setDeleteDealer] = useState(false);

  // construct the list from the state object
  let dealerOptions = [
    {
      key: -1,
      value: -1,
      text: 'Select a Dealer',
    },
    {
      key: 0,
      value: 0,
      text: 'Add New',
      icon: 'plus circle',
    },
  ];

  _.each(props.dealerList, (values) => {
    dealerOptions.push({
      key: values.dealerId,
      value: values.dealerId,
      text: values.companyName,
    });
  });

  // use the useEffect hook to monitor the changes to the deleteDealer flag
  useEffect(() => {
    // we only care if the removeDealer state was changed to true
    // if deleteDealer is true, then we need to clear out the dealer dropdown
    // selection and the dealer phone and courier
    if (deleteDealer) {
      // execute the remove dealer action
      props.removeDealer(props.values[DEALER_NAME]);

      // clear out the field values
      props.setFieldValue(DEALER_NAME, '-1');
      props.setFieldValue(DEALER_PHONE, '');
      props.setFieldValue(COURIER, '');

      // set remove dealer local state to false
      setDeleteDealer(false);
    }
  }, [deleteDealer]);

  const renderDealerEditIcon = () => {
    if (props.values[DEALER_NAME] > 0) {
      return (
        <Fragment>
          <Icon name='pencil' onClick={() => props.showDealerForm(true)} />
          {props.isAdministrator && (
            <Confirmation
              title='Confirm Deletion'
              content={`You are about to delete ${props.dealerForm.dealerName}.  Are you sure you want to do this?`}
              confirmId={true}
              confirmAction={setDeleteDealer}
              trigger={<Icon style={{ cursor: 'pointer' }} name='trash' />}
            />
          )}
        </Fragment>
      );
    }
    return null;
  };

  // track changes to the Return To Radio Buttons
  //console.log('Dealer Values', props.values);
  const returnToChecked =
    props.values[RETURN_TO].length > 0
      ? props.values[RETURN_TO]
      : props.initialValues[RETURN_TO];
  //console.log('Dealer Initial Value', props.initialValues[DEALER_NAME]);
  //console.log('Dealer Current Value', props.values[DEALER_NAME]);
  return (
    <Fragment>
      <Header>DEALER INFORMATION</Header>
      <Grid stackable>
        <Grid.Row className='brm-form-row'>
          <Grid.Column
            width={4}
            verticalAlign='middle'
            className='brm-form-label'
          >
            Name
          </Grid.Column>
          <Grid.Column width={12}>
            <Dropdown
              tabIndex='2'
              placeholder='Select Dealer'
              className='brm-form-dropdown'
              style={{ width: '250px' }}
              value={
                props.values[DEALER_NAME] || props.initialValues[DEALER_NAME]
              }
              name={DEALER_NAME}
              search
              selection
              selectOnBlur={true}
              options={dealerOptions}
              onChange={async (e, { value }) => {
                //console.log('Dealer Selected', value);
                props.values[DEALER_PHONE] = '';
                if (0 === value) {
                  // open the dealer form
                  props.addNewDealer(props.dealerForm.dealerId);
                  props.showDealerForm(true);
                } else if (value !== -1) {
                  await props.getDealer(value);
                  const thisDealer = _.find(props.dealerList, {
                    dealerId: value,
                  });
                  //console.log('Dealer Selected', thisDealer);
                  props.setFieldValue(DEALER_PHONE, thisDealer.phone);
                  props.setFieldValue(COURIER, thisDealer.courier);
                  props.setFieldValue(DEALER_NAME, value);
                }
              }}
            />
            {'  '}
            {renderDealerEditIcon()}
            {props.errors[DEALER_NAME] && props.touched[DEALER_NAME] ? (
              <Message negative size='tiny'>
                {props.errors[DEALER_NAME]}
              </Message>
            ) : null}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className='brm-form-row'>
          <Grid.Column
            width={4}
            verticalAlign='middle'
            className='brm-form-label'
          >
            Phone
          </Grid.Column>
          <Grid.Column width={12}>
            <InputMask
              mask='(999) 999-9999'
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values[DEALER_PHONE]}
            >
              {(inputProps) => (
                <Input
                  {...inputProps}
                  tabIndex='3'
                  type='text'
                  name={DEALER_PHONE}
                  className='brm-form-input'
                />
              )}
            </InputMask>
            {props.errors[DEALER_PHONE] && props.touched[DEALER_PHONE] ? (
              <Message negative size='tiny'>
                {props.errors[DEALER_PHONE]}
              </Message>
            ) : null}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className='brm-form-row'>
          <Grid.Column
            width={4}
            verticalAlign='middle'
            className='brm-form-label'
          >
            Courier
          </Grid.Column>
          <Grid.Column width={12}>
            <Dropdown
              tabIndex='4'
              placeholder='Select Courier'
              value={props.values[COURIER]}
              name={COURIER}
              className='brm-form-dropdown'
              selection
              selectOnBlur={true}
              options={courierList}
              onChange={(e, { value }) => {
                props.setFieldValue(COURIER, value);
              }}
            />
            {props.errors[COURIER] && props.touched[COURIER] ? (
              <Message negative size='tiny'>
                {props.errors[COURIER]}
              </Message>
            ) : null}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className='brm-form-row'>
          <Grid.Column
            width={4}
            verticalAlign='middle'
            className='brm-form-label'
          >
            Return to:
          </Grid.Column>
          <Grid.Column width={12}>
            <Radio
              tabIndex='5'
              name={RETURN_TO}
              label='Dealer'
              value='dealer'
              checked={returnToChecked === 'dealer'}
              onChange={(e, { value }) => props.setFieldValue(RETURN_TO, value)}
              className='brm-radio'
            />
            {'   '}
            <Radio
              tabIndex='6'
              name={RETURN_TO}
              label='Registrant'
              value='registrant'
              checked={returnToChecked === 'registrant'}
              onChange={(e, { value }) => props.setFieldValue(RETURN_TO, value)}
              className='brm-radio'
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    dealerList: state.dealers.dealerList,
    dealerForm: state.dealers.dealerForm,
    isAdministrator: state.users.isAdministrator,
  };
};

export default connect(mapStateToProps, {
  showDealerForm,
  getDealer,
  addNewDealer,
  removeDealer,
})(DealerInfo);
