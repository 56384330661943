import axios from 'axios';

/**
 * This file contains the api source for the app.
 *
 * DEV: https://on-the-boards.com/brm/dev/devapi/public
 * PRODUCTION: https://barrycrm.com/api/invoice_system/public
 * LOCAL: http://localhost/invoice_system_api/invoice_system/public
 */

export default axios.create({
  baseURL: 'https://barrycrm.com/api/invoice_system/public',
  //baseURL: 'https://on-the-boards.com/brm/dev/devapi/public',
});
