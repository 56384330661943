import React, { useState } from 'react';
import { Dropdown } from 'semantic-ui-react';

const DropdownFilter = (props) => {
  const [currentValue, setCurrentValue] = useState();

  return (
    <Dropdown
      value={currentValue}
      className='brm-form-dropdown brm-form-header-dropdown'
      search
      selection
      selectOnBlur={true}
      options={props.opts}
      style={{ fontSize: '1em' }}
      onChange={(e, { value }) => {
        setCurrentValue(value);
        props.changeFunction(value, props.tableColumn, props.index);
      }}
    />
  );
};

export default DropdownFilter;
