import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import {
  Container,
  Grid,
  Dropdown,
  Header,
  Button,
  Message,
} from 'semantic-ui-react';
import PageHeader from '../PageHeader';
import DateFilter from './DateFilter';
import DropdownFilter from './DropdownFilter';
import TextFilter from './TextFilter';
import ReportResults from './ReportResults';
import LoadingComp from '../LoadingComp';
import {
  getReports,
  getReportInfo,
  updateReportParams,
  runReport,
  resetFilters,
} from '../../actions';

// renders the loading prompt when searching for results
const renderLoader = (show, message) => {
  if (show) {
    return <LoadingComp msg={message} />;
  }
  return null;
};

// build the list of reports to select
const renderReportList = (props) => {
  const { reports, currentReport } = props;
  let reportDropItems = [];
  _.each(reports, (values) => {
    reportDropItems.push({
      key: values.reportId,
      value: values.reportId,
      text: values.reportName,
    });
  });
  return (
    <Dropdown
      name='allReports'
      value={currentReport.reportId}
      className='brm-form-dropdown brm-form-header-dropdown'
      selection
      selectOnBlur={true}
      options={reportDropItems}
      style={{ fontSize: '1em' }}
      onChange={(e, { value }) => {
        props.getReportInfo(value);
      }}
    />
  );
};

// construct the filters for the selected report
const renderReportFilters = (props) => {
  const { currentReport } = props;
  let filtersJSX = [];
  // loop through each definition to see what type of input is used
  _.each(currentReport.reportFields, (fields) => {
    // field options are datepicker, dropdown and text
    switch (fields.fieldType) {
      case 'datepicker':
        filtersJSX.push(
          buildDatePicker(
            fields.params,
            fields.tableColumn,
            props.updateReportParams
          )
        );
        break;
      case 'dropdown':
        filtersJSX.push(
          buildDropdown(
            fields.params,
            fields.tableColumn,
            fields.listSource,
            props
          )
        );
        break;
      case 'text':
        filtersJSX.push(
          <Grid.Column>
            <strong>{fields.params[0].label}</strong>
            <br />
            <TextFilter
              tableColumn={fields.tableColumn}
              index={0}
              changeFunction={props.updateReportParams}
            />
          </Grid.Column>
        );
        break;
    }
  });

  // add the button to run the report
  filtersJSX.push(
    <Grid.Row>
      <Grid.Column>
        <Button className='normal-button' onClick={() => props.runReport()}>
          Run Report
        </Button>
      </Grid.Column>
    </Grid.Row>
  );

  return filtersJSX;
};

// function to build a date picker filter
const buildDatePicker = (params, tableColumn, changeFunction) => {
  //console.log('Date Params', params);
  // filter can have more than one date picker
  let datesJSX = [];
  let currentIndex = 0;
  _.each(params, (value, index) => {
    datesJSX.push(
      <Grid.Column className='brm-date-filter'>
        <strong>{value.label}</strong>
        <br />
        <DateFilter
          tableColumn={tableColumn}
          index={index}
          changeFunction={changeFunction}
        />
      </Grid.Column>
    );
    currentIndex++;
  });
  return datesJSX;
};

// function to build a dropdown filter
const buildDropdown = (params, tableColumn, listSource, props) => {
  // grab the list to be used for the dropdown
  let filterList = [];
  // { key, value, text }
  switch (listSource) {
    case 'users':
      _.each(props.userList, (info) => {
        filterList.push({
          key: info.userId,
          value: info.userId,
          text: info.firstName + ' ' + info.lastName,
        });
      });
      break;
    case 'dealers':
      _.each(props.dealerList, (info) => {
        if (info.key > 0) {
          filterList.push({
            key: info.key,
            value: info.value,
            text: info.text,
          });
        }
      });
      break;
    case 'lienholders':
      _.each(props.lienHoldersList, (info) => {
        if (info.key > 0) {
          filterList.push({
            key: info.key,
            value: info.value,
            text: info.text,
          });
        }
      });
      break;
  }
  return (
    <Grid.Column className='brm-date-filter'>
      <strong>{params[0].label}</strong>
      <br />
      <DropdownFilter
        opts={filterList}
        tableColumn={tableColumn}
        index={0}
        changeFunction={props.updateReportParams}
      />
    </Grid.Column>
  );
};

/**
 * Renders the results table
 * @param {boolean} show
 * @param {object} props
 */
const renderReportResults = (show, props) => {
  if (show) {
    return (
      <ReportResults
        results={props.currentReport.results}
        tableHeaders={props.currentReport.tableHeaders}
      />
    );
  }
  return <Message warning>No Results</Message>;
};

const ReportViewer = (props) => {
  const [reportsUpdated, setReportsUpdated] = useState(false);
  const [showResults, setShowResults] = useState(false);

  // build the report list on load
  useEffect(() => {
    if (!reportsUpdated) {
      props.getReports();
      setReportsUpdated(true);
    }
  });

  // check to see if the report results should be shown
  if (props.currentReport.results.length > 0 && !showResults) {
    setShowResults(true);
  } else if (0 === props.currentReport.results.length && showResults) {
    setShowResults(false);
  }

  //console.log('Show Results', showResults);

  return (
    <Container className='brm-container'>
      <PageHeader />
      <Header>Reports</Header>
      <h3>Select a Report</h3>
      {renderReportList(props)}
      <h3>Use the Provided Filters and Click the "Run Report" Button</h3>
      <Grid
        columns={4}
        style={{ marginTop: '10px' }}
        stackable
        className='brm-filters-container'
      >
        {renderReportFilters(props)}
      </Grid>
      {renderReportResults(showResults, props)}
      {renderLoader(props.showLoading, 'Please Wait...')}
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    reports: state.reports.reportList,
    currentReport: state.reports.currentReport,
    dealerList: state.dealers.dealerList,
    userList: state.users.userList,
    lienHoldersList: state.lienholders.lienHolderList,
    showLoading: state.main.isLoading,
  };
};

export default connect(mapStateToProps, {
  getReports,
  getReportInfo,
  updateReportParams,
  runReport,
  resetFilters,
})(ReportViewer);
