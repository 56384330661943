import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Input, Grid, Message, Icon, Button } from 'semantic-ui-react';
import { saveCarMake, showCarMakesForm } from '../../../actions';

const CarMakeForm = (props) => {
    // validation schema
    const validationSchema = yup.object({
        carMake: yup.string().required('You must enter the car make.')
    });

    // initial values for the form are in the state object
    const formInitialValues = {
        makeName: props.makeForm.makeName
    };

    return(
        <Formik
                initialValues={formInitialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => props.saveCarMake(values)}
            >
                {
                    ({handleChange, handleBlur, handleSubmit, initialValues, setFieldValue, values, errors, touched}) => (
                        <Form onSubmit={handleSubmit}>
                            <Grid>
                                <Grid.Row className="header-style">
                                    <Grid.Column>
                                        Car Make
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row className="brm-form-row">
                                    <Grid.Column>
                                        <Input 
                                            name="carMake"
                                            onChange={handleChange} 
                                            onBlur={handleBlur}
                                            className="brm-form-input"
                                            defaultValue={initialValues.makeName}
                                        />
                                        {errors.makeName && touched.makeName ? (
                                            <Message negative size="tiny">{errors.makeName}</Message>
                                        ) : null}
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Button type="button" className="inverted-button" onClick={() => props.showCarMakesForm(false)}>Cancel</Button>
                                        <Button type="submit" className="inverted-button"><Icon name="save" />{' '}SAVE &amp; CLOSE</Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Form>
                    )
                }
        </Formik>
    );
};

const mapStateToProps = (state) => {
    return {
        makeForm: state.main.carMakeForm
    };
}

export default connect(mapStateToProps, { saveCarMake, showCarMakesForm })(CarMakeForm);