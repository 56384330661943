import React from 'react';
import { Input } from 'semantic-ui-react';

const DefaultColumnFilter = ({
  column: { filterValue, preFilteredRows, setFilter },
}) => {
  const count = preFilteredRows.length;

  return (
    <Input
      value={filterValue || ''}
      className='brm-form-input'
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
    />
  );
};

export default DefaultColumnFilter;
