import {
    GET_REPORTS,
    SELECT_REPORT,
    SHOW_REPORT,
    LOGOUT_USER,
    UPDATE_REPORT_PARAMS,
    RESET_FILTERS
} from '../actions/types';

const INITIAL_STATE = {
    reportList:[],
    currentReport: {
        reportId: 0,
        reportFields: [],
        params: [],
        tableHeaders: {},
        results: []
    },
    resetFilters: []
};

const reportState = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_REPORTS:
            return { ...state, reportList: action.payload };
        case SELECT_REPORT:
            return {
                ...state,
                currentReport: {
                    reportId: action.payload.id,
                    reportFields: action.payload.fields,
                    params: action.payload.params,
                    tableHeaders: action.payload.tableHeaders,
                    results: []
                },
                resetFilters: action.payload.params
            };
        case UPDATE_REPORT_PARAMS:
            return {
                ...state,
                currentReport: {
                    ...state.currentReport,
                    params: state.currentReport.params.map((param) => {
                        return param.tableColumn === action.payload.field
                            ? {...param, 
                                values: param.values.map((item, index) => {
                                return index === action.payload.valueIndex
                                ? action.payload.value
                                : item
                            })}
							: param
                    })
                }
            };
        case SHOW_REPORT:
            return { 
                ...state, 
                currentReport: {
                    ...state.currentReport,
                    results: action.payload
                } 
            };
        case RESET_FILTERS:
            return {
                ...state,
                currentReport: {
                    ...state.currentReport,
                    params: state.resetFilters
                }
            }
        case LOGOUT_USER:
            return INITIAL_STATE;
        default:
            return state;
    }
}

export default reportState;