import React from 'react';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import {
  Input,
  Grid,
  Message,
  Icon,
  Button,
  TextArea,
  Radio,
} from 'semantic-ui-react';
import {
  EXPENSE_AMOUNT,
  EXPENSE_DESCRIPTION,
  EXPENSE_HIDDEN,
  EXPENSE_ID,
} from './FormSetup/FieldNames';
import ValidationSchema from './FormSetup/ValidationSchema';
import {
  addNewExpense,
  updateExpense,
  showExpenseForm,
} from '../../../actions';

const ExpensesForm = (props) => {
  // set the inital values for the form, mainly for edits
  const formInitialValues = {
    [EXPENSE_AMOUNT]: props.expenseValues.amount,
    [EXPENSE_DESCRIPTION]: props.expenseValues.description,
    [EXPENSE_ID]: props.expenseValues.expenseId,
    [EXPENSE_HIDDEN]: props.expenseValues.hidden,
  };

  return (
    <Formik
      initialValues={formInitialValues}
      enableReiniitalize={true}
      ValidationSchema={ValidationSchema}
      onSubmit={(values) => {
        0 === props.expenseValues.expenseId
          ? props.addNewExpense(values)
          : props.updateExpense(values);
      }}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        values,
        errors,
        touched,
        initialValues,
      }) => {
        // determine which radio button is selected for the hidden expense value (only for administrators)
        const hiddenChecked =
          values[EXPENSE_HIDDEN] !== undefined
            ? values[EXPENSE_HIDDEN]
            : initialValues[EXPENSE_HIDDEN];

        return (
          <Form onSubmit={handleSubmit}>
            <Grid>
              <Grid.Row className='header-style'>
                <Grid.Column width={16}>Expense</Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  {props.error && (
                    <Message negative>
                      <Message.Header>Problem Saving Expense</Message.Header>
                      <p>{props.error}</p>
                    </Message>
                  )}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className='brm-form-row'>
                <Grid.Column width={4}>Amount</Grid.Column>
                <Grid.Column width={12}>
                  <Input
                    type='text'
                    name={EXPENSE_AMOUNT}
                    className='brm-form-input'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    defaultValue={initialValues[EXPENSE_AMOUNT]}
                  />
                  {errors[EXPENSE_AMOUNT] && touched[EXPENSE_AMOUNT] ? (
                    <Message negative size='tiny'>
                      {errors[EXPENSE_AMOUNT]}
                    </Message>
                  ) : null}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className='brm-form-row'>
                <Grid.Column width={4}>Description</Grid.Column>
                <Grid.Column width={12}>
                  <TextArea
                    placeholder='Add Description Here...'
                    name={EXPENSE_DESCRIPTION}
                    rows={10}
                    style={{ width: '100%' }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    defaultValue={initialValues[EXPENSE_DESCRIPTION]}
                  />
                  {errors[EXPENSE_DESCRIPTION] &&
                  touched[EXPENSE_DESCRIPTION] ? (
                    <Message negative size='tiny'>
                      {errors[EXPENSE_DESCRIPTION]}
                    </Message>
                  ) : null}
                </Grid.Column>
              </Grid.Row>
              {props.isAdministrator && (
                <Grid.Row>
                  <Grid.Column>
                    Hidden Fee&nbsp;&nbsp;
                    <Radio
                      name={EXPENSE_HIDDEN}
                      className='brm-radio'
                      label='Yes'
                      value={1}
                      checked={1 === hiddenChecked}
                      onChange={(e, { value }) =>
                        setFieldValue(EXPENSE_HIDDEN, value)
                      }
                    />
                    &nbsp;&nbsp;
                    <Radio
                      name={EXPENSE_HIDDEN}
                      className='brm-radio'
                      label='No'
                      value={0}
                      checked={0 === hiddenChecked}
                      onChange={(e, { value }) =>
                        setFieldValue(EXPENSE_HIDDEN, value)
                      }
                    />
                  </Grid.Column>
                </Grid.Row>
              )}
              <Grid.Row>
                <Grid.Column textAlign='right' width={16}>
                  <Button
                    type='button'
                    className='inverted-button'
                    onClick={() => {
                      props.showExpenseForm(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button type='submit' className='inverted-button'>
                    <Icon name='save' /> Save &amp; Close
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state) => {
  return {
    isAdministrator: state.users.isAdministrator,
    expenseValues: state.expenses.expensesForm,
    error: state.main.errors,
  };
};

export default connect(mapStateToProps, {
  addNewExpense,
  updateExpense,
  showExpenseForm,
})(ExpensesForm);
