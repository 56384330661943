import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Container,
  Grid,
  Button,
  Header,
  Modal,
  Message,
} from 'semantic-ui-react';
import PageHeader from '../PageHeader';
import UserList from './UserList';
import UserForm from './UserForm';
import LoadingComp from '../LoadingComp';
import ResultsModal from '../ResultsModal';
import UnauthorizedMessage from '../Common/UnauthorizedMessage';
import {
  getAllUsers,
  getUser,
  showUserForm,
  removeUser,
  closeSavedUserResult,
} from '../../actions';

const renderUserForm = (show) => {
  if (show) {
    return (
      <Modal open size='large' closeOnEscape={false} closeOnDimmerClick={false}>
        <Modal.Content>
          <UserForm />
        </Modal.Content>
      </Modal>
    );
  }
  return null;
};

const renderLoader = (props) => {
  if (props.showLoading) {
    // message dependant on what action the page is executing
    let message = 'Retrieving User Information...';
    if (props.showUserModal) {
      message = 'Saving User...';
    }
    return <LoadingComp msg={message} />;
  }
  return null;
};

const renderResults = (show, success, error, closeFunction) => {
  if (show) {
    return (
      <ResultsModal
        success={success}
        error={error}
        closeFunction={closeFunction}
        title='Save User Result'
        hasLink={false}
        goToPage=''
      />
    );
  }
  return null;
};

/**
 * Component that lists all users in the system with the ability
 * to change their information or add new users to the system.
 *
 * @param {object} props
 * @returns
 */
const ManageUsers = (props) => {
  // prevent unauthorized users from accessing this page
  if (!props.isAdministrator) {
    // show a message instead of the user list
    return <UnauthorizedMessage />;
  }
  return (
    <Container className='brm-container'>
      <PageHeader />
      <Header>User Management</Header>
      <Button
        type='button'
        className='normal-button'
        onClick={() => props.showUserForm(true, true)}
      >
        + Add New User
      </Button>
      <Grid columns='equal'>
        <Grid.Row>
          <Grid.Column>
            <UserList
              users={props.userList}
              showForm={props.showUserForm}
              getUser={props.getUser}
              removeUser={props.removeUser}
              currentUser={props.user}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {renderUserForm(props.showUserModal)}
      {renderResults(
        props.userSaved,
        props.success,
        props.error,
        props.closeSavedUserResult
      )}
      {renderLoader(props)}
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.users.currentUser,
    userList: state.users.userList,
    showUserModal: state.users.showUserModal,
    showLoading: state.main.isLoading,
    userSaved: state.users.userSaved,
    error: state.main.errors,
    success: state.main.success,
    isAdministrator: state.users.isAdministrator,
  };
};

export default connect(mapStateToProps, {
  getAllUsers,
  getUser,
  showUserForm,
  removeUser,
  closeSavedUserResult,
})(ManageUsers);
