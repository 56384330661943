import React, { useMemo } from 'react';
import {
  Container,
  Table,
  Menu,
  Icon,
  Dropdown,
  Button,
  Modal,
  Grid,
} from 'semantic-ui-react';
import _ from 'lodash';
import {
  useTable,
  useFilters,
  usePagination,
  useSortBy,
  useGlobalFilter,
} from 'react-table';
import Confirmation from '../Confirmation';
import { resultPageSizeOpts } from '../StaticLists';
import { matchSorter } from 'match-sorter';
import DefaultColumnFilter from '../TableFilters/DefaultColumnFilter';
import GlobalFilter from '../TableFilters/GlobalFilter';
import DealerForm from '../PlateInvoicing/Dealers/DealerForm';
import ReactExport from 'react-data-export';
import { useNavigate } from 'react-router-dom';

/**
 * Shows the dealer form when adding a new dealer or editing
 *
 * @param {boolean} show
 * @returns
 */
const renderDealerForm = (show) => {
  if (show) {
    return (
      <Modal open size='large' closeOnEscape={false} closeOnDimmerClick={false}>
        <Modal.Content>
          <DealerForm />
        </Modal.Content>
      </Modal>
    );
  }
  return null;
};

/**
 * Renders the pencil icon for editing and the
 * trash can icon for removing for each row
 *
 * @param {object} row
 * @param {object} props
 * @returns
 */
const renderEdit = (row, props, navigate) => {
  const dealerId = row.row.original.dealerId;
  let actionButtons = [
    <Icon
      style={{ cursor: 'pointer' }}
      name='pencil'
      onClick={async () => {
        await props.getDealer(dealerId);
        props.showDealerForm(true);
      }}
    />,
    <span>&nbsp;&nbsp;&nbsp;</span>,
    <Icon
      style={{ cursor: 'pointer' }}
      name='eye'
      onClick={async () => {
        // grab the dealers information and then
        // direct to the dealer details page
        await props.getDealer(dealerId);
        navigate('/dealer-details');
      }}
    />,
    <span>&nbsp;&nbsp;&nbsp;</span>,
    <Icon
      style={{ cursor: 'pointer' }}
      name='money'
      className='list-icon'
      onClick={async () => {
        // grab the outstanding invoices for the dealer
        await props.getDealer(dealerId);
        props.getDealerOutstandingInvoices(dealerId, []);
        navigate('/billing-form');
      }}
    />,
    <span>&nbsp;&nbsp;&nbsp;</span>,
  ];

  actionButtons.push(
    <Confirmation
      title='Confirm Deletion'
      content={`You are about to delete this dealer.  Are you sure you want to do this?`}
      confirmId={dealerId}
      confirmAction={() => props.removeDealer(dealerId)}
      trigger={<Icon style={{ cursor: 'pointer' }} name='trash' />}
    />
  );

  return actionButtons;
};

// function for doing fuzzy searches using the match sorter module
function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

function DealerList(props) {
  // need to use the useNavigate hook to navigate to
  // the dealer details page
  const navigate = useNavigate();

  // defines the filter type for the filters we will use
  const filterTypes = useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  // define the default column behavior
  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const columns = useMemo(
    () => [
      { Header: 'Company Name', accessor: 'companyName', maxWidth: '150px' },
      { Header: 'Address', accessor: 'address', maxWidth: '150px' },
      { Header: 'Contact', accessor: 'contactName', maxWidth: '150px' },
      { Header: 'Phone', accessor: 'phone' },
      { Header: 'Fax', accessor: 'fax' },
      { Header: 'Email', accessor: 'email', maxWidth: '150px' },
      { Header: 'Courier', accessor: 'courier' },
      {
        Header: 'Current Balance',
        accessor: 'currentBalance',
      },
      { Header: 'Credits', accessor: 'totalCredits' },
      {
        Header: 'Actions',
        accessor: 'key',
        disableSortBy: true,
        disableFilters: true,
        minWidth: '100px',
        Cell: (row) => renderEdit(row, props, navigate),
      },
    ],
    []
  );

  const data = props.dealers;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    visibleColumns,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  // setup the excel data export values
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  return (
    <Container>
      {/** EXPORT TO EXCEL */}
      <ExcelFile
        element={
          <Button className='normal-button'>
            <Icon name='file excel' />
            Export Dealers to Excel
          </Button>
        }
      >
        <ExcelSheet data={data} name='Dealers'>
          <ExcelColumn label='Company Name' value='companyName' />
          <ExcelColumn label='Address' value='address' />
          <ExcelColumn label='Contact' value='contactName' />
          <ExcelColumn label='Phone' value='phone' />
          <ExcelColumn label='Fax' value='fax' />
          <ExcelColumn label='Email' value='email' />
          <ExcelColumn label='Courier' value='courier' />
          <ExcelColumn label='Current Balance' value='currentBalance' />
          <ExcelColumn label='Credits' value='totalCredits' />
          <ExcelColumn label='Notes' value='notes' />
        </ExcelSheet>
      </ExcelFile>
      {/** END EXPORT TO EXCEL */}
      <Table celled {...getTableProps()}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              colSpan={visibleColumns.length}
              style={{
                textAlign: 'left',
              }}
            >
              <Grid stackable>
                <Grid.Column width={6}>
                  <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                  />
                </Grid.Column>
                <Grid.Column width={10} textAlign='right'>
                  <strong>Actions Key:</strong>&nbsp;&nbsp;&nbsp;&nbsp;
                  <Icon name='pencil' /> - Edit&nbsp;&nbsp;&nbsp;&nbsp;
                  <Icon name='eye' /> - View Details&nbsp;&nbsp;&nbsp;&nbsp;
                  <Icon className='list-icon' name='money' /> - Receive
                  Payment&nbsp;&nbsp;&nbsp;&nbsp;
                  <Icon name='trash' /> - Remove
                </Grid.Column>
              </Grid>
            </Table.HeaderCell>
          </Table.Row>
          {headerGroups.map((headerGroup) => (
            <Table.Row {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Table.HeaderCell
                  {...column.getHeaderProps(column.getSortByToggleProps(), {
                    style: { maxWidth: column.maxWidth },
                  })}
                >
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? [<Icon name='sort down' />]
                        : [<Icon name='sort up' />]
                      : column.disableSortBy
                      ? ''
                      : [<Icon name='sort' />]}
                  </span>
                </Table.HeaderCell>
              ))}
            </Table.Row>
          ))}
        </Table.Header>
        <Table.Body {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <Table.Row {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <Table.Cell
                      {...cell.getCellProps({
                        style: {
                          maxWidth: cell.column.maxWidth,
                          minWidth: cell.column.minWidth,
                          overflowWrap: 'break-word',
                        },
                      })}
                    >
                      {cell.render('Cell')}
                    </Table.Cell>
                  );
                })}
              </Table.Row>
            );
          })}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan='10'>
              <Dropdown
                value={pageSize}
                options={resultPageSizeOpts}
                selection
                selectOnBlur={true}
                className='brm-form-dropdown brm-form-header-dropdown'
                onChange={(e, { value }) => setPageSize(Number(value))}
              />
              <Menu floated='right' pagination>
                <Menu.Item
                  as='a'
                  icon
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                >
                  <Icon name='angle double left' />
                </Menu.Item>
                <Menu.Item
                  as='a'
                  icon
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  <Icon name='angle left' />
                </Menu.Item>
                <Menu.Item as='a'>
                  Page: {pageIndex + 1} of {pageOptions.length}
                </Menu.Item>
                <Menu.Item
                  as='a'
                  icon
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  <Icon name='angle right' />
                </Menu.Item>
                <Menu.Item
                  as='a'
                  icon
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  <Icon name='angle double right' />
                </Menu.Item>
              </Menu>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
      {renderDealerForm(props.showDealerModal)}
    </Container>
  );
}

export default DealerList;
