import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Form, Radio, Button, Message } from 'semantic-ui-react';
import { Formik } from 'formik';

const TwoFAStep1 = (props) => {
  return (
    <Fragment>
      <Formik
        initialValues={{
          username: props.username,
          sendCodeBy: props.userCellPhone.length > 0 ? 'SMS' : 'EMAIL',
          twoFactorCode: 0,
        }}
        onSubmit={(values) => props.authUser(values)}
      >
        {({ handleSubmit, setFieldValue, values, initialValues }) => {
          const sendCodeByChecked =
            values.sendCodeBy.length > 0
              ? values.sendCodeBy
              : initialValues.sendCodeBy;
          if (!props.passwordSet) {
            return (
              <Form onSubmit={handleSubmit}>
                <Form.Field>
                  <label>
                    Please Choose How You Would Like Your Access Code Sent
                  </label>
                  {props.userCellPhone.length > 0 && (
                    <Radio
                      name='sendCodeBy'
                      label='Text Message'
                      value='SMS'
                      checked={sendCodeByChecked === 'SMS'}
                      onChange={(e, { value }) =>
                        setFieldValue('sendCodeBy', value)
                      }
                    />
                  )}
                  {0 === props.userCellPhone.length && (
                    <Message negative>
                      <p>
                        You must have a cell phone number to receive a code by
                        text message.
                      </p>
                    </Message>
                  )}
                  <br />
                  <Radio
                    name='sendCodeBy'
                    label='Email Address'
                    value='EMAIL'
                    checked={sendCodeByChecked === 'EMAIL'}
                    onChange={(e, { value }) =>
                      setFieldValue('sendCodeBy', value)
                    }
                  />
                </Form.Field>
                <Button type='submit' className='normal-button'>
                  Next
                </Button>
              </Form>
            );
          }
          return null;
        }}
      </Formik>
    </Fragment>
  );
};

export default TwoFAStep1;
