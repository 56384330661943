import _ from 'lodash';
import moment from 'moment';
import { AES, enc, pad } from 'crypto-js';
import main from '../apis/main';
import {
  getFinancialTxnByInvoice,
  saveTempFinancialTxn,
  updateBalance,
} from './plateActions';
import {
  saveTempCIFinancialTxn,
  getFinancialTxnsByCombinedInvoice,
  updateCIBalance,
  distributePaymentToInvoices,
} from './combinedInvoicesActions';
import { showLoading } from './globalActions';
import { saveNote } from './noteActions';
import {
  SAVE_FINANCIAL_TXN,
  FIND_FINANCIAL_TXN,
  SHOW_FINANCIAL_TXN_FORM,
  ADD_NEW_FINANCIAL_TXN,
  CLEAR_ERROR,
  UPDATE_PAYMENT_PROCESSED,
} from './types';
import { proccessAPIErrors } from '.';

export const saveFinancialTxn =
  (values, newId = 0, idType = 'invoice', parentId = 0, keepFormOpen = false) =>
  async (dispatch, getState) => {
    //console.log('Submit Values', values);
    //console.log('Keep Form Open?', keepFormOpen);
    dispatch(showLoading(true));
    const { currentUser, sessionId } = getState().users;
    const { financialTxnForm } = getState().financialTxns;
    const { combinedInvoiceForm } = getState().combinedinvoices;
    const { txnForm } = getState().plates;
    //console.log('Txn Form Data', financialTxnForm);

    // need to check if the dealer id was sent to the function or
    // if it needs to be extracted from the invoice or combined invoice form
    let dealerId = values.dealerId;

    // the id number used is dependant on the id type
    let theId = newId;
    switch (idType) {
      case 'invoice':
        theId = newId > 0 ? newId : txnForm.txnId;
        if (dealerId === undefined) {
          dealerId = txnForm.dealerId;
        }
        break;
      case 'combined-invoice':
        theId = newId > 0 ? newId : combinedInvoiceForm.combinedInvoiceId;
        if (dealerId === undefined) {
          dealerId = combinedInvoiceForm.dealerId;
        }
        break;
    }

    // audit object
    let auditObj = {};
    let auditNote = '';

    // check to see if this is for an unsaved invoice
    if (0 === theId) {
      // this is new, must temporarily save the information
      const saveObj = {
        txn_type: values.txnType,
        created_by: currentUser.userId,
        updated_by: currentUser.userId,
        payment_type: values.paymentType,
        amount: parseFloat(values.amount).toFixed(2),
        ref_number: values.refNumber,
        toward: values.toward,
        in_reg: 1,
        deleted: 0,
        plate_id: 0,
        combined_invoice_id: 0,
        created_at: moment().format('YYYY-MM-DD hh:mm:ss'),
        payment_processed: financialTxnForm.paymentProcessed,
      };

      // update and balance dependant on the id type
      switch (idType) {
        case 'invoice':
          dispatch(saveTempFinancialTxn(saveObj));
          dispatch({
            type: SAVE_FINANCIAL_TXN,
            payload: {
              paymentSaved: true,
              keepFormOpen: false,
              financialTxnId: 0,
              txnId: 0,
              txnType: '',
              paymentType: '',
              amount: 0,
              toward: '',
            },
          });
          dispatch(updateBalance());
          break;
        case 'combined-invoice':
          dispatch(saveTempCIFinancialTxn(saveObj));
          dispatch({
            type: SAVE_FINANCIAL_TXN,
            payload: {
              paymentSaved: true,
              keepFormOpen: false,
              financialTxnId: 0,
              txnId: 0,
              txnType: '',
              paymentType: '',
              amount: 0,
              toward: '',
            },
          });
          dispatch(updateCIBalance());
          break;
      }

      // do not execute anything else
      dispatch(showLoading(false));
      return;
    }

    let response = {};

    // build the submission object
    let bodyFormData = new FormData();
    bodyFormData.append('txn_type', values.txnType);
    bodyFormData.append('created_by', currentUser.userId);
    bodyFormData.append('updated_by', currentUser.userId);
    bodyFormData.append('payment_type', values.paymentType);
    bodyFormData.append('amount', parseFloat(values.amount).toFixed(2));
    bodyFormData.append('ref_number', values.refNumber);
    bodyFormData.append('toward', values.toward);
    bodyFormData.append('in_reg', 1);
    bodyFormData.append('deleted', 0);
    bodyFormData.append('plate_id', 'invoice' === idType ? theId : 0);
    bodyFormData.append('dealer_id', dealerId);
    bodyFormData.append(
      'combined_invoice_id',
      'combined-invoice' === idType ? theId : 0
    );
    bodyFormData.append('parent_financial_txn_id', parentId);
    bodyFormData.append('cc_last_4', '');
    bodyFormData.append(
      'cc_expire',
      values.ccExpire === undefined ? '' : values.ccExpire
    );
    bodyFormData.append('cc_card_type', '');
    bodyFormData.append('ach_last_4', '');
    bodyFormData.append('payment_processed', financialTxnForm.paymentProcessed);
    // new financial transaction or an update?
    if (0 === financialTxnForm.financialTxnId) {
      // New transaction
      bodyFormData.append('session_token', sessionId);
      response = await main.post('/api/financial_txns', bodyFormData);
    } else {
      /**
       * Add audit information for any differences between what was submitted and the
       * current state object.  Also designate a special audit note if anything was
       * changed.
       */
      if (values.paymentType !== financialTxnForm.paymentType) {
        auditObj = {
          ...auditObj,
          paymentType: {
            oldValue: financialTxnForm.paymentType,
            newValue: values.paymentType,
          },
        };
        auditNote +=
          'Payment Type Changed From ' +
          financialTxnForm.paymentType +
          ' To $' +
          values.paymentType +
          '\n';
      }

      if (
        parseFloat(values.amount).toFixed(2) !==
        parseFloat(financialTxnForm.amount).toFixed(2)
      ) {
        auditObj = {
          ...auditObj,
          amount: {
            oldValue: financialTxnForm.amount,
            newValue: values.amount,
          },
        };
        auditNote +=
          'Payment Amount Changed From $' +
          financialTxnForm.amount +
          ' To $' +
          values.amount +
          '\n';
      }

      if (values.refNumber !== financialTxnForm.referenceNumber) {
        auditObj = {
          ...auditObj,
          refNumber: {
            oldValue: financialTxnForm.referenceNumber,
            newValue: values.refNumber,
          },
        };
        auditNote +=
          'Referance # Changed From ' +
          financialTxnForm.referenceNumber +
          ' To ' +
          values.refNumber +
          '\n';
      }

      if (values.toward !== financialTxnForm.toward) {
        auditObj = {
          ...auditObj,
          toward: {
            oldValue: financialTxnForm.toward,
            newValue: values.toward,
          },
        };
        auditNote +=
          'Toward Changed From ' +
          financialTxnForm.toward +
          ' To $' +
          values.toward +
          '\n';
      }

      // update existing
      response = await main.post(
        `/api/financial_txns/${financialTxnForm.financialTxnId}/${sessionId}`,
        bodyFormData
      );
    }

    // did we get a good response?
    if (response.status !== 200 && response.status !== 201) {
      // there was a problem, notify the user and return the error message
      dispatch(
        proccessAPIErrors({
          statusCode: response.status,
          errorMsg: response.data.error,
        })
      );
      return;
    }

    const responseData = response.data;

    // send the audit object, if necessary
    if (!_.isEmpty(auditObj)) {
      let auditFormData = new FormData();
      auditFormData.append('session_token', sessionId);
      auditFormData.append('txn_id', financialTxnForm.financialTxnId);
      auditFormData.append('txn_type', 'financial-txn');
      auditFormData.append('created_by', currentUser.userId);
      auditFormData.append('txn_changes', JSON.stringify(auditObj));
      const auditResponse = await main.post('api/txn_edit_log', auditFormData);
    }

    // save any charge changes as an audit note
    if (auditNote.length > 0) {
      const notePrepend =
        'Changes Made To Txn ID: ' + financialTxnForm.financialTxnId + '\n';
      let submitObj = {
        note: notePrepend + auditNote,
        internal: 1,
      };
      dispatch(saveNote(submitObj, theId));
    }

    // do we need to process a payment?
    //console.log('Before Process Payment', values);
    if (
      values.ccNumber !== undefined ||
      values.achAccountNumber !== undefined
    ) {
      if (values.ccNumber.length > 0 || values.achAccountNumber.length > 0) {
        await dispatch(
          processPayment(
            values,
            financialTxnForm.financialTxnId === 0
              ? responseData.notice.id
              : financialTxnForm.financialTxnId
          )
        );
      }
    }

    // update the transaction list, based on the id type
    switch (idType) {
      case 'invoice':
        if (0 === parentId) {
          // This is from a single plate transaction
          await dispatch(getFinancialTxnByInvoice(theId));
          dispatch(updateBalance());
        }
        break;
      case 'combined-invoice':
        await dispatch(getFinancialTxnsByCombinedInvoice(theId));
        dispatch(updateCIBalance());
        dispatch(distributePaymentToInvoices(values, responseData.notice.id));
        break;
    }

    //dispatch({ type: SHOW_FINANCIAL_TXN_FORM, payload: keepFormOpen });
    dispatch({
      type: SAVE_FINANCIAL_TXN,
      payload: {
        paymentSaved: true,
        keepFormOpen,
        financialTxnId:
          financialTxnForm.financialTxnId === 0
            ? responseData.notice.id
            : financialTxnForm.financialTxnId,
        txnId: theId,
        txnType: values.txnType,
        paymentType: values.paymentType,
        amount: values.amount,
        toward: values.toward,
      },
    });

    // remove the loading state
    dispatch(showLoading(false));
  };

export const showFinancialTxnForm = (show) => {
  return { type: SHOW_FINANCIAL_TXN_FORM, payload: show };
};

export const getFinancialTxn = (txnId) => async (dispatch, getState) => {
  const { sessionId } = getState().users;
  const response = await main.get(`/api/financial_txns/${txnId}/${sessionId}`);

  // did we get a good response?
  if (response.status !== 200) {
    // there was a problem, notify the user and return the error message
    dispatch(
      proccessAPIErrors({
        statusCode: response.status,
        errorMsg: response.data.error,
      })
    );
    return;
  }

  const responseData = response.data[0];
  dispatch({ type: FIND_FINANCIAL_TXN, payload: responseData });
  dispatch(showFinancialTxnForm(true));
};

/**
 * Clear the payment form for entering new payments
 */
export const addNewFinancialTxn = () => {
  return { type: ADD_NEW_FINANCIAL_TXN, payload: { paymentSaved: false } };
};

/**
 * Mark a Financial Transaction as removed
 */
export const removeFinancialTxn = (txnId) => async (dispatch, getState) => {
  //console.log('Remove', txnId);
  dispatch(showLoading(true));
  const { currentUser, sessionId } = getState().users;
  const { txnForm } = getState().plates;
  const { combinedInvoiceForm } = getState().combinedinvoices;

  let response = {};

  // build the submission object
  let bodyFormData = new FormData();
  bodyFormData.append('updated_by', currentUser.userId);

  response = await main.post(
    `/api/financial_txns_mark_deleted/${txnId}/${sessionId}`,
    bodyFormData
  );

  // did we get a good response?
  if (response.status !== 201) {
    // there was a problem, notify the user and return the error message
    dispatch(
      proccessAPIErrors({
        statusCode: response.status,
        errorMsg: response.data.error,
      })
    );
    return;
  }

  // transaction was marked for removal, refresh the list
  // update the transaction list, based on the id type
  if (txnForm.txnId > 0) {
    // This is from a single plate transaction
    await dispatch(getFinancialTxnByInvoice(txnForm.txnId));
    dispatch(updateBalance());
  } else {
    // combined invoice
    await dispatch(
      getFinancialTxnsByCombinedInvoice(combinedInvoiceForm.combinedInvoiceId)
    );
    dispatch(updateCIBalance());
  }

  dispatch(showLoading(false));
};

export const processPayment =
  (values, financialTxnId) => async (dispatch, getState) => {
    // grab the current session
    const { sessionId } = getState().users;
    const { financialTxnForm } = getState().financialTxns;

    //dispatch(showLoading(true));

    // encrypt the credit card number or ach account number
    const encKey = enc.Hex.parse('655368566b5970337336763979244226');
    const iv = enc.Hex.parse('29482b4d6251655468576d5a71347437');
    let encCC = '';
    let encACH = '';
    if (values.ccNumber.length > 0) {
      // processing a credit card
      encCC = AES.encrypt(values.ccNumber, encKey, {
        iv: iv,
        padding: pad.ZeroPadding,
      }).toString();
    } else {
      // processing ACH
      encACH = AES.encrypt(values.achAccountNumber, encKey, {
        iv: iv,
        padding: pad.ZeroPadding,
      }).toString();
    }

    // process a payment
    const payObj = {
      session_token: sessionId,
      payment_type: values.ccNumber.length > 0 ? 'CC' : 'ACH',
      cc_name: values.ccName,
      cc_number: encCC,
      cc_expire: values.ccExpire.replace('/', ''),
      cc_cvv: values.ccCVC,
      ach_number: encACH,
      ach_routing: values.achRouting,
      ach_name_on_account: values.achNameOnAccount,
      amount: values.amount,
      id: financialTxnId,
    };
    //console.log('Payment Obj', payObj);
    const payResult = await main.post('/api/process_payment', payObj);
    //console.log('Payment Res', payResult);

    //dispatch(showLoading(false));

    // did we get a good response?
    if (payResult.status !== 201) {
      // there was a problem, notify the user and return the error message
      dispatch(
        proccessAPIErrors({
          statusCode: payResult.status,
          errorMsg: payResult.data.error,
        })
      );
      // preserve the payment information so the user can try again
      dispatch({
        type: UPDATE_PAYMENT_PROCESSED,
        payload: {
          processed: 0,
          paymentRef: '',
          ccName: values.ccName,
          ccNumber: values.ccNumber,
          ccExpire: values.ccExpire,
          ccCvc: values.ccCVC,
          achAccountNumber: values.achAccountNumber,
          achRouting: values.achRouting,
          achNameOnAccount: values.achNameOnAccount,
        },
      });
      return;
    }

    // let the user know the payment was successful
    dispatch({
      type: UPDATE_PAYMENT_PROCESSED,
      payload: {
        processed: 1,
        paymentRef: payResult.data.notice.payment_ref,
        ccName: '',
        ccNumber: '',
        ccExpire: '',
        ccCvc: '',
        achNumber: '',
        achRouting: '',
        achNameOnAccount: '',
      },
    });
    dispatch({ type: CLEAR_ERROR });
  };
