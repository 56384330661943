import React, { Fragment } from 'react';
import { Header } from 'semantic-ui-react';

const CurrentDealerCreditText = (props) => {
  return (
    <Fragment>
      <Header>Dealer Credit Amount</Header>
      <span style={{ fontWeight: '900', fontSize: '2.0em' }}>
        ${parseFloat(props.dealerCredit).toFixed(2)}
      </span>
    </Fragment>
  );
};

export default CurrentDealerCreditText;
