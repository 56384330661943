import React from 'react';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import InputMask from 'react-input-mask';
import {
  Input,
  Grid,
  Dropdown,
  Message,
  Radio,
  Header,
  TextArea,
  Button,
  Icon,
} from 'semantic-ui-react';
import {
  PROSPECT,
  COMPANY_NAME,
  ADDRESS,
  CITY,
  STATE_ABBR,
  ZIP_CODE,
  PHONE_NUMBER,
  PHONE_EXT,
  FAX_NUMBER,
  EMAIL_ADDRESS,
  COURIER,
  ID,
  CONTACT_FIRST_NAME,
  CONTACT_LAST_NAME,
  ROUTE,
  NOTES,
  IN_BILLING,
} from './FormSetup/FieldNames';
import ValidationSchema from './FormSetup/ValidationSchema';
import { courierList } from '../Couriers';
import { saveDealer, showDealerForm, cancelAddDealer } from '../../../actions';

const DealerForm = (props) => {
  // route list
  const routeList = [
    {
      key: 'none',
      value: 'None',
      text: 'None',
    },
    {
      key: 'route1',
      value: 'Route 1',
      text: 'Route 1',
    },
    {
      key: 'route2',
      value: 'Route 2',
      text: 'Route 2',
    },
  ];

  // initial values for the form are in the state object
  const formInitialValues = {
    [PROSPECT]: props.dealerForm.prospect,
    [COMPANY_NAME]: props.dealerForm.dealerName,
    [ADDRESS]: props.dealerForm.street,
    [CITY]: props.dealerForm.city,
    [STATE_ABBR]: props.dealerForm.state,
    [ZIP_CODE]: props.dealerForm.zipCode,
    [PHONE_NUMBER]: props.dealerForm.phoneNumber,
    [PHONE_EXT]: props.dealerForm.phoneExtension,
    [FAX_NUMBER]: props.dealerForm.faxNumber,
    [EMAIL_ADDRESS]: props.dealerForm.email,
    [COURIER]: props.dealerForm.courier,
    [ID]: props.dealerForm.id,
    [CONTACT_FIRST_NAME]: props.dealerForm.contactFirstName,
    [CONTACT_LAST_NAME]: props.dealerForm.contactLastName,
    [ROUTE]: props.dealerForm.route,
    [NOTES]: props.dealerForm.notes,
    [IN_BILLING]: props.dealerForm.includeInBilling,
  };

  return (
    <Formik
      initialValues={formInitialValues}
      enableReinitialize={true}
      validationSchema={ValidationSchema}
      onSubmit={(values) => props.saveDealer(values)}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        values,
        errors,
        touched,
        initialValues,
      }) => {
        //console.log('Dealer Values', values);
        const prospectChecked =
          values[PROSPECT].length > 0
            ? values[PROSPECT]
            : initialValues[PROSPECT];
        const inBillingChecked =
          values[IN_BILLING].length > 0
            ? values[IN_BILLING]
            : initialValues[IN_BILLING];

        return (
          <Form onSubmit={handleSubmit}>
            <Grid columns={2} stackable>
              <Grid.Row className='header-style'>
                <Grid.Column>DEALER INFORMATION</Grid.Column>
                <Grid.Column>
                  Prospect?&nbsp;&nbsp;
                  <Radio
                    name={PROSPECT}
                    className='brm-radio-in-header'
                    label='Yes'
                    value='1'
                    checked={prospectChecked === '1'}
                    onChange={(e, { value }) => setFieldValue(PROSPECT, value)}
                  />
                  &nbsp;&nbsp;
                  <Radio
                    name={PROSPECT}
                    className='brm-radio-in-header'
                    label='No'
                    value='0'
                    checked={prospectChecked === '0'}
                    onChange={(e, { value }) => setFieldValue(PROSPECT, value)}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Grid columns={2} stackable>
                    <Grid.Column width={16}>
                      <Header>Edit/Add Dealer Information</Header>
                    </Grid.Column>
                    <Grid.Column
                      width={4}
                      verticalAlign='middle'
                      className='brm-form-label brm-form-row'
                    >
                      Company Name
                    </Grid.Column>
                    <Grid.Column width={12} className='brm-form-row'>
                      <Input
                        type='text'
                        name={COMPANY_NAME}
                        className='brm-form-input'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={initialValues[COMPANY_NAME]}
                      />
                      {errors[COMPANY_NAME] && touched[COMPANY_NAME] ? (
                        <Message negative size='tiny'>
                          {errors[COMPANY_NAME]}
                        </Message>
                      ) : null}
                    </Grid.Column>
                    <Grid.Column
                      width={4}
                      verticalAlign='middle'
                      className='brm-form-label brm-form-row'
                    >
                      Address
                    </Grid.Column>
                    <Grid.Column width={12} className='brm-form-row'>
                      <Input
                        type='text'
                        name={ADDRESS}
                        className='brm-form-input'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={initialValues[ADDRESS]}
                      />
                      {errors[ADDRESS] && touched[ADDRESS] ? (
                        <Message negative size='tiny'>
                          {errors[ADDRESS]}
                        </Message>
                      ) : null}
                    </Grid.Column>
                    <Grid.Column
                      width={4}
                      verticalAlign='middle'
                      className='brm-form-label brm-form-row'
                    >
                      City, State, Zip
                    </Grid.Column>
                    <Grid.Column width={12} className='brm-form-row'>
                      <Input
                        type='text'
                        name={CITY}
                        className='brm-form-input'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={{ width: 150 }}
                        defaultValue={initialValues[CITY]}
                      />
                      {errors[CITY] && touched[CITY] ? (
                        <Message negative size='tiny'>
                          {errors[CITY]}
                        </Message>
                      ) : null}{' '}
                      <Input
                        type='text'
                        name={STATE_ABBR}
                        className='brm-form-input'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={{ width: 50 }}
                        defaultValue={initialValues[STATE_ABBR]}
                      />
                      {errors[STATE_ABBR] && touched[STATE_ABBR] ? (
                        <Message negative size='tiny'>
                          {errors[STATE_ABBR]}
                        </Message>
                      ) : null}{' '}
                      <Input
                        type='text'
                        name={ZIP_CODE}
                        className='brm-form-input'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={{ width: 75 }}
                        defaultValue={initialValues[ZIP_CODE]}
                      />
                      {errors[ZIP_CODE] && touched[ZIP_CODE] ? (
                        <Message negative size='tiny'>
                          {errors[ZIP_CODE]}
                        </Message>
                      ) : null}
                    </Grid.Column>
                    <Grid.Column
                      width={4}
                      verticalAlign='middle'
                      className='brm-form-label brm-form-row'
                    >
                      Phone
                    </Grid.Column>
                    <Grid.Column width={12} className='brm-form-row'>
                      <InputMask
                        mask='(999) 999-9999'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values[PHONE_NUMBER]}
                      >
                        {(inputProps) => (
                          <Input
                            {...inputProps}
                            type='text'
                            name={PHONE_NUMBER}
                            className='brm-form-input'
                            style={{ width: 150 }}
                            defaultValue={initialValues[PHONE_NUMBER]}
                          />
                        )}
                      </InputMask>
                      {errors[PHONE_NUMBER] && touched[PHONE_NUMBER] ? (
                        <Message negative size='tiny'>
                          {errors[PHONE_NUMBER]}
                        </Message>
                      ) : null}{' '}
                      <span className='brm-form-label'>Ext</span> {'  '}
                      <Input
                        type='text'
                        name={PHONE_EXT}
                        className='brm-form-input'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={{ width: 50 }}
                        defaultValue={initialValues[PHONE_EXT]}
                      />
                    </Grid.Column>
                    <Grid.Column
                      width={4}
                      verticalAlign='middle'
                      className='brm-form-label brm-form-row'
                    >
                      Fax
                    </Grid.Column>
                    <Grid.Column width={12} className='brm-form-row'>
                      <InputMask
                        mask='(999) 999-9999'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values[FAX_NUMBER]}
                      >
                        {(inputProps) => (
                          <Input
                            {...inputProps}
                            type='text'
                            name={FAX_NUMBER}
                            className='brm-form-input'
                            defaultValue={initialValues[FAX_NUMBER]}
                          />
                        )}
                      </InputMask>
                    </Grid.Column>
                    <Grid.Column
                      width={4}
                      verticalAlign='middle'
                      className='brm-form-label brm-form-row'
                    >
                      Email
                    </Grid.Column>
                    <Grid.Column width={12} className='brm-form-row'>
                      <Input
                        type='text'
                        name={EMAIL_ADDRESS}
                        className='brm-form-input'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={initialValues[EMAIL_ADDRESS]}
                      />
                      {errors[EMAIL_ADDRESS] && touched[EMAIL_ADDRESS] ? (
                        <Message negative size='tiny'>
                          {errors[EMAIL_ADDRESS]}
                        </Message>
                      ) : null}
                    </Grid.Column>
                    <Grid.Column
                      width={4}
                      verticalAlign='middle'
                      className='brm-form-label brm-form-row'
                    >
                      Courier
                    </Grid.Column>
                    <Grid.Column width={12} className='brm-form-row'>
                      <Dropdown
                        placeholder='Select Courier'
                        className='brm-form-dropdown'
                        name={COURIER}
                        selection
                        options={courierList}
                        selectOnBlur={true}
                        defaultValue={initialValues[COURIER]}
                        onChange={(e, { value }) =>
                          setFieldValue(COURIER, value)
                        }
                      />
                      {errors.courier && touched.COURIER ? (
                        <Message negative size='tiny'>
                          {errors[COURIER]}
                        </Message>
                      ) : null}
                    </Grid.Column>
                    <Grid.Column
                      width={4}
                      verticalAlign='middle'
                      className='brm-form-label brm-form-row'
                    >
                      ID
                    </Grid.Column>
                    <Grid.Column width={12} className='brm-form-row'>
                      <Input
                        type='text'
                        name={ID}
                        className='brm-form-input'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={initialValues[ID]}
                      />
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
                <Grid.Column>
                  <Grid columns={2} stackable>
                    <Grid.Column width={16}>
                      <Header>Contact</Header>
                    </Grid.Column>
                    <Grid.Column
                      width={4}
                      verticalAlign='middle'
                      className='brm-form-label brm-form-row'
                    >
                      First Name
                    </Grid.Column>
                    <Grid.Column width={12} className='brm-form-row'>
                      <Input
                        type='text'
                        name={CONTACT_FIRST_NAME}
                        className='brm-form-input'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={initialValues[CONTACT_FIRST_NAME]}
                      />
                      {errors[CONTACT_FIRST_NAME] &&
                      touched.CONTACT_FIRST_NAME ? (
                        <Message negative size='tiny'>
                          {errors[CONTACT_FIRST_NAME]}
                        </Message>
                      ) : null}
                    </Grid.Column>
                    <Grid.Column
                      width={4}
                      verticalAlign='middle'
                      className='brm-form-label brm-form-row'
                    >
                      Last Name
                    </Grid.Column>
                    <Grid.Column width={12} className='brm-form-row'>
                      <Input
                        type='text'
                        name={CONTACT_LAST_NAME}
                        className='brm-form-input'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={initialValues[CONTACT_LAST_NAME]}
                      />
                      {errors[CONTACT_LAST_NAME] &&
                      touched[CONTACT_LAST_NAME] ? (
                        <Message negative size='tiny'>
                          {errors[CONTACT_LAST_NAME]}
                        </Message>
                      ) : null}
                    </Grid.Column>
                    <Grid.Column
                      width={4}
                      verticalAlign='middle'
                      className='brm-form-label brm-form-row'
                    >
                      Route
                    </Grid.Column>
                    <Grid.Column width={12} className='brm-form-row'>
                      <Dropdown
                        placeholder='Select Route'
                        className='brm-form-dropdown'
                        name={ROUTE}
                        selection
                        selectOnBlur={true}
                        options={routeList}
                        defaultValue={initialValues[ROUTE]}
                        onChange={(e, { value }) => setFieldValue(ROUTE, value)}
                      />
                    </Grid.Column>
                    <Grid.Column
                      width={16}
                      className='brm-form-label brm-form-row'
                    >
                      Notes
                      <br />
                      <TextArea
                        name={NOTES}
                        style={{ width: '100%' }}
                        rows={7}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={initialValues[NOTES]}
                      />
                    </Grid.Column>
                    <Grid.Column
                      width={6}
                      verticalAlign='middle'
                      className='brm-form-label brm-form-row'
                    >
                      Include in Billing System?
                    </Grid.Column>
                    <Grid.Column width={10} className='brm-form-row'>
                      <Radio
                        name={IN_BILLING}
                        className='brm-radio'
                        label='Yes'
                        value='1'
                        checked={inBillingChecked === '1'}
                        onChange={(e, { value }) =>
                          setFieldValue(IN_BILLING, value)
                        }
                      />
                      {'   '}
                      <Radio
                        name={IN_BILLING}
                        className='brm-radio'
                        label='No'
                        value='0'
                        checked={inBillingChecked === '0'}
                        onChange={(e, { value }) =>
                          setFieldValue(IN_BILLING, value)
                        }
                      />
                    </Grid.Column>
                    <Grid.Column textAlign='right' width={16}>
                      <Button
                        type='button'
                        className='inverted-button'
                        onClick={() => {
                          props.cancelAddDealer(true);
                          props.showDealerForm(false);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button type='submit' className='inverted-button'>
                        <Icon name='save' /> Save &amp; Close
                      </Button>
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state) => {
  return {
    dealerForm: state.dealers.dealerForm,
  };
};

export default connect(mapStateToProps, {
  saveDealer,
  showDealerForm,
  cancelAddDealer,
})(DealerForm);
