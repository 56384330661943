import React, { Fragment, useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Segment, Button, Grid, Icon, Header } from 'semantic-ui-react';
import { connect } from 'react-redux';
import Confirmation from '../../Confirmation';
import {
  showExpenseForm,
  setExpenseToEdit,
  removeExpense,
} from '../../../actions';

const ExpensesList = (props) => {
  const [allExpenses, setAllExpenses] = useState([]);

  /**
   * Renders the edit and delete icon buttons for an expense line
   *
   * @param {object} values
   * @returns {array} actionButtons JSX for the button icons
   */
  const renderActionIcons = (values) => {
    let actionButtons = [];
    if (!values.description.includes('State Fee')) {
      actionButtons.push(
        <Icon onClick={() => props.setExpenseToEdit(values.id)} name='pencil' />
      );
    }

    // only show delete function to administrators and if the expense is not a state expense
    if (props.isAdministrator && !values.description.includes('State Fee')) {
      // get the financial transaction information
      actionButtons.push(
        <Confirmation
          title='Confirm Deletion'
          content={`You are about to delete an expense.  Are you sure you want to do this?`}
          confirmId={values.id}
          confirmAction={() => props.removeExpense(values.id)}
          trigger={<Icon style={{ cursor: 'pointer' }} name='trash' />}
        />
      );
    }

    return actionButtons;
  };

  // build the expenses list to display every time the list updates
  useEffect(() => {
    let expensesList = [];
    _.each(props.expensesList, (values) => {
      // do not add hidden expenses unless the user is an administrator
      let addExpense = true;
      if (!props.isAdministrator && values.hidden) {
        addExpense = false;
      }
      if (addExpense) {
        expensesList.push(
          <Segment padded>
            <Grid columns={2}>
              <Grid.Row className='invoice-boxed-list-item brm-small-font'>
                <Grid.Column width={8}>{values.modifiedBy}</Grid.Column>
                <Grid.Column width={8}>
                  {moment(values.dateModified).format('MM/DD/YYYY')}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className='invoice-boxed-list-item brm-larger-font'>
                <Grid.Column width={10}>{values.description}</Grid.Column>
                <Grid.Column width={6} textAlign='right'>
                  <strong>
                    ${values.amount}&nbsp;&nbsp;&nbsp;
                    {renderActionIcons(values)}
                  </strong>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        );
      }
    });
    // update the local state that contains the list
    setAllExpenses(expensesList);
  }, [props.expensesList]);

  return (
    <Fragment>
      <Grid>
        <Grid.Column width={12}>
          <Header>EXPENSES</Header>
        </Grid.Column>
        <Grid.Column width={4} style={{ textAlign: 'right' }}>
          <Button
            tabIndex='28'
            type='button'
            size='mini'
            circular
            positive
            icon='plus'
            className='brm-add-button-icon'
            onClick={() => props.showExpenseForm(true)}
          />{' '}
          <span
            style={{ cursor: 'pointer' }}
            className='brm-form-label'
            onClick={() => props.showExpenseForm(true)}
          >
            ADD
          </span>
        </Grid.Column>
      </Grid>
      <Segment.Group
        verticalAlign='middle'
        style={{
          height: props.listHeight,
          overflowY: 'scroll',
          marginBottom: 0,
        }}
      >
        {allExpenses}
      </Segment.Group>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    isAdministrator: state.users.isAdministrator,
    expensesList: state.expenses.expensesList,
  };
};

export default connect(mapStateToProps, {
  showExpenseForm,
  setExpenseToEdit,
  removeExpense,
})(ExpensesList);
