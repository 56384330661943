import React, { useState, useEffect } from 'react';
import {
  Grid,
  Header,
  Button,
  Input,
  Form,
  Segment,
  Message,
} from 'semantic-ui-react';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import ErrorMsg from './ErrorMsg';
import SuccessMsg from './SuccessMsg';
import WarningMsg from './WarningMsg';
import LoadingComp from './LoadingComp';
import { authUser, forgotPassSendMsg, getDealer } from '../actions';
import packageJson from '../../package.json';

const renderLoader = (show, message) => {
  if (show) {
    return <LoadingComp msg={message} />;
  }
  return null;
};

const renderForgotPassMsg = (msg) => {
  if (msg.length > 0) {
    return <Message>{msg}</Message>;
  }
  return null;
};

const renderForgotPassForm = (show, email, updateEmail, props) => {
  if (show) {
    return (
      <Segment stacked>
        <Form>
          <Form.Field>
            <label>Enter Your Account Email Address</label>
            <Input
              type='text'
              name='email'
              fluid
              icon='mail'
              iconPosition='left'
              onChange={(e, { value }) => updateEmail(value)}
            />
          </Form.Field>
          <Button
            type='button'
            className='normal-button'
            onClick={() => props.forgotPassSendMsg(email)}
          >
            Submit
          </Button>
        </Form>
      </Segment>
    );
  }
  return null;
};

const Login = (props) => {
  const navigate = useNavigate();

  const [showForgotForm, setShowForgotForm] = useState(false);
  const [forgotEmail, updateForgotEmail] = useState('');

  useEffect(() => {
    async function redirectToPage() {
      console.log('Login Props', props);
      if (props.sessionId.length > 0) {
        if ('dealer' === props.currentUser.accountType) {
          await props.getDealer(props.currentUser.dealerId);
          navigate('/dealer-details');
        } else {
          navigate('/dashboard');
        }
      } else if (1 === props.needTwoFactorAuth) {
        navigate('/two-factor-authenticate');
      }
    }
    redirectToPage();
  }, [props.sessionId, props.needTwoFactorAuth]);

  return (
    <Grid
      textAlign='center'
      style={{ height: '100vh', backgroundColor: '#d7dbde' }}
      verticalAlign='middle'
    >
      <Grid.Column style={{ maxWidth: 450 }}>
        <SuccessMsg />
        <ErrorMsg />
        <WarningMsg />
        {renderForgotPassMsg(props.forgotPassMsg)}
        <img
          src={process.env.PUBLIC_URL + '/AS-Logo.png'}
          style={{ width: '50%' }}
        />
        <Header as='h2' textAlign='center'>
          Invoicing System Version {packageJson.version}
        </Header>
        <Segment stacked>
          <Formik
            initialValues={{
              username: '',
              password: '',
              sendCodeBy: 'NONE',
              twoFactorCode: 0,
            }}
            onSubmit={(values) => props.authUser(values)}
          >
            {({ handleChange, handleBlur, handleSubmit, values }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Field>
                  <label>User Name</label>
                  <Input
                    type='text'
                    name='username'
                    fluid
                    icon='user'
                    iconPosition='left'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.username}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Password</label>
                  <Input
                    type='password'
                    name='password'
                    fluid
                    icon='lock'
                    iconPosition='left'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                </Form.Field>
                <Button type='submit' className='normal-button'>
                  Log In
                </Button>
              </Form>
            )}
          </Formik>
        </Segment>
        <Message>
          Forgot Password?{' '}
          <a href='#' onClick={() => setShowForgotForm(!showForgotForm)}>
            Click Here
          </a>
        </Message>
        {renderForgotPassForm(
          showForgotForm,
          forgotEmail,
          updateForgotEmail,
          props
        )}
      </Grid.Column>
      {renderLoader(props.showLoading, 'Please Wait...')}
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    sessionId: state.users.sessionId,
    showLoading: state.main.isLoading,
    forgotPassMsg: state.users.forgotEmailMsg,
    needTwoFactorAuth: state.users.currentTwoFactorAuthStep,
    currentUser: state.users.currentUser,
  };
};

export default connect(mapStateToProps, {
  authUser,
  forgotPassSendMsg,
  getDealer,
})(Login);
