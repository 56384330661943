import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Container, Grid, Modal, Button, Icon } from 'semantic-ui-react';
import packageJson from '../../../package.json';
import PlateTxnList from './PlateTxnList';
import LoadingComp from '../LoadingComp';
import PageHeader from '../PageHeader';
import ErrorModal from '../ErrorModal';
import UnauthorizedMessage from '../Common/UnauthorizedMessage';
import {
  getDealerList,
  getCarMakes,
  getLienHolders,
  getAllInvoices,
  getInvoice,
  removeInvoice,
  getAllUsers,
  clearInvoice,
  clearSuccess,
  toggleInvoiceForPrint,
  closeMultiInvoicePrint,
  getMultipleInvoicePDF,
  setCurrentInvoiceListPageIndex,
  clearDealerForm,
  clearError,
  clearInvoiceRemoved,
  getAllStateFees,
} from '../../actions';

const renderLoader = (show, message) => {
  if (show) {
    return <LoadingComp msg={message} />;
  }
  return null;
};

/**
 * Shows the Error Modal when an error occurs.  In this
 * particular component it's mainly used for search errors.
 *
 * @param {string} message
 * @param {function} errorCloseFunction
 */
const renderError = (message, errorCloseFunction) => {
  if (message.length > 0) {
    // an error was thrown on this page
    return (
      <ErrorModal
        title='An Error Has Occured'
        error={message}
        closeFunction={errorCloseFunction}
      />
    );
  }
};

const renderPrintConfirmation = (show, fileName, showFunction) => {
  if (show) {
    return (
      <Modal open size='large' closeOnEscape={false} closeOnDimmerClick={false}>
        <Modal.Header>Print Invoice</Modal.Header>
        <Modal.Content>
          <p>The invoice has been saved and is ready to print.</p>
          <Button
            type='button'
            className='inverted-button'
            onClick={() =>
              window.open(packageJson.homepage + '/invoices/' + fileName)
            }
          >
            <Icon name='print' />
            PRINT INVOICES
          </Button>
        </Modal.Content>
        <Modal.Actions>
          <Button
            type='button'
            className='decline-button'
            onClick={() => showFunction(false)}
          >
            CLOSE
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
  return null;
};

const Dashboard = (props) => {
  // prevent unauthorized users from accessing this page
  if ('dealer' === props.user.accountType) {
    // show a message instead of the user list
    return <UnauthorizedMessage />;
  }
  /** Initializations */
  const [dealersUpdated, setDealersUpdated] = useState(false);
  const [carMakesUpdated, setCarMakesUpdated] = useState(false);
  const [lienHoldersUpdated, setLienHoldersUpdated] = useState(false);
  const [invoicesUpdated, setInvoicesUpdated] = useState(false);
  const [usersUpdated, setUsersUpdated] = useState(false);
  const [invoiceCleared, setInvoiceCleared] = useState(false);
  const [successMsgCleared, setSuccessMsgCleared] = useState(false);
  const [stateFeesList, setStateFeesList] = useState(false);

  // fires when the component loads on the page
  useEffect(() => {
    if (!dealersUpdated) {
      props.getDealerList();
      setDealersUpdated(true);
    }

    if (!carMakesUpdated) {
      props.getCarMakes();
      setCarMakesUpdated(true);
    }

    if (!lienHoldersUpdated) {
      props.getLienHolders();
      setLienHoldersUpdated(true);
    }

    if (!invoicesUpdated) {
      props.getAllInvoices({
        startDate:
          props.currentFilters.startDate === ''
            ? moment().subtract(1, 'y').format('YYYY-MM-DD')
            : moment(props.currentFilters.startDate).format('YYYY-MM-DD'),
        endDate:
          props.currentFilters.endDate === ''
            ? moment().format('YYYY-MM-DD')
            : moment(props.currentFilters.endDate).format('YYYY-MM-DD'),
        hasBalance:
          props.currentFilters.hasBalance === ''
            ? 'all'
            : props.currentFilters.hasBalance,
        status:
          props.currentFilters.status === ''
            ? 'all'
            : props.currentFilters.status,
        dealer:
          props.currentFilters.dealer === ''
            ? 'all'
            : props.currentFilters.dealer,
        checkNumber:
          props.currentFilters.checkNumber === ''
            ? ''
            : props.currentFilters.checkNumber,
        invoiceNumber:
          props.currentFilters.invoiceNumber === ''
            ? ''
            : props.currentFilters.invoiceNumber,
        fuzzy:
          props.currentFilters.fuzzy === '' ? '' : props.currentFilters.fuzzy,
        ignoreDates: props.currentFilters.ignoreDates,
        paymentAmountLow:
          props.currentFilters.paymentAmountLow === ''
            ? ''
            : props.currentFilters.paymentAmountLow,
        paymentAmountHigh:
          props.currentFilters.paymentAmountHigh === ''
            ? ''
            : props.currentFilters.paymentAmountHigh,
        showDeleted:
          'deleted' /** This filters off of the plate transaction status.  Status of 'deleted' means don't show deleted ones. */,
      });
      setInvoicesUpdated(true);
    }

    if (!usersUpdated) {
      props.getAllUsers();
      setUsersUpdated(true);
    }

    if (!invoiceCleared) {
      props.clearInvoice();
      setInvoiceCleared(true);
    }

    if (!successMsgCleared) {
      props.clearSuccess();
      setSuccessMsgCleared(true);
    }

    if (!stateFeesList) {
      props.getAllStateFees();
      setStateFeesList(true);
    }

    // clear out the dealer form every time this component is loaded
    props.clearDealerForm();
  }, []);

  return (
    <Container className='brm-container'>
      <PageHeader />
      <Grid columns='equal'>
        <Grid.Row>
          <Grid.Column>
            <PlateTxnList
              invoices={props.invoiceList}
              getInvoice={props.getInvoice}
              deleteInvoice={props.removeInvoice}
              dealers={props.dealerList}
              filterList={props.getAllInvoices}
              charges={props.totalCharges}
              owed={props.totalOwed}
              overpaid={props.totalOverpaid}
              isAdmin={props.isAdministrator}
              printSelector={props.toggleInvoiceForPrint}
              printInvoices={props.getMultipleInvoicePDF}
              totalSelected={props.totalSelected}
              currentFilters={props.currentFilters}
              fees={props.totalFees}
              currentListPageIndex={props.currentPageIndex}
              currentListPageSize={props.currentListSize}
              setListPageIndexAndSize={props.setCurrentInvoiceListPageIndex}
              isAdministrator={props.isAdministrator}
              totalNetProfit={props.totalNetProfit}
              totalExtraExpenses={props.totalExpenses}
              totalNetProfitPercent={props.totalNetProfitPercent}
              totalBRMFeesWithExpenses={props.totalBRMFeesWithExpenses}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <div style={{ width: '100%', textAlign: 'center' }}>
            <strong>BRM Invoice System Version {packageJson.version}</strong>
          </div>
        </Grid.Row>
      </Grid>
      {renderLoader(props.showLoading, 'Searching Invoices...')}
      {renderPrintConfirmation(
        props.showMultiInvoicePrint,
        props.invoiceFileName,
        props.closeMultiInvoicePrint
      )}
      {props.error.length > 0 && renderError(props.error, props.clearError)}
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.users.currentUser,
    isAdministrator: state.users.isAdministrator,
    dealerList: state.dealers.dealerList,
    carMakeList: state.main.carMakeList,
    lienHolderList: state.lienholders.lienHolderList,
    invoiceList: state.main.invoiceList,
    showLoading: state.main.isLoading,
    totalCharges: state.main.listTotalCharges,
    totalOwed: state.main.listTotalOwed,
    totalOverpaid: state.main.listTotalOverpaid,
    invoiceFileName: state.main.pdfMultiInvoiceFileName,
    showMultiInvoicePrint: state.main.showMultiInvoicePrint,
    totalSelected: state.main.totalSelectedInvoices,
    currentFilters: state.main.currentInvoiceListFilters,
    totalFees: state.main.listTotalFees,
    currentPageIndex: state.main.currentInvoiceListPageIndex,
    currentListSize: state.main.currentInvoiceListPageSize,
    error: state.main.errors,
    invoiceRemoved: state.plates.invoiceRemoved,
    totalExpenses: state.main.listTotalExpenses,
    totalNetProfit: state.main.listTotalNetProfit,
    totalNetProfitPercent: state.main.listPercentageNetProfit,
    totalBRMFeesWithExpenses: state.main.listBRMFeesWithExpenses,
  };
};

export default connect(mapStateToProps, {
  getDealerList,
  getCarMakes,
  getLienHolders,
  getAllInvoices,
  getInvoice,
  removeInvoice,
  getAllUsers,
  clearInvoice,
  clearSuccess,
  toggleInvoiceForPrint,
  closeMultiInvoicePrint,
  getMultipleInvoicePDF,
  setCurrentInvoiceListPageIndex,
  clearDealerForm,
  clearError,
  clearInvoiceRemoved,
  getAllStateFees,
})(Dashboard);
