import React from 'react';
import { Grid } from 'semantic-ui-react';

function ReadOnlyInvoiceCharges(props) {
  const { brmFees, dmvFees, otherFees, salesTax } = props.txnForm;
  return (
    <>
      {/*Row*/}
      <Grid.Column width={16} className='read-only-invoice-section-header'>
        CHARGES & FEES
      </Grid.Column>
      {/*End Row*/}
      {/*Row*/}
      <Grid.Column width={2}>Service Fee</Grid.Column>
      <Grid.Column width={2}>{brmFees}</Grid.Column>
      <Grid.Column width={2}>DMV Fee</Grid.Column>
      <Grid.Column width={2}>{dmvFees}</Grid.Column>
      <Grid.Column width={2}>Other Fees</Grid.Column>
      <Grid.Column width={2}>{otherFees}</Grid.Column>
      <Grid.Column width={2}>Sales Tax</Grid.Column>
      <Grid.Column width={2}>{salesTax}</Grid.Column>
      {/*End Row*/}
    </>
  );
}

export default ReadOnlyInvoiceCharges;
