import React from 'react';
import { Grid, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const BillingHeader = (props) => {
  return (
    <Grid columns={2}>
      <Grid.Column className='header-style header-plain-text'>
        <Link to='/dashboard'>
          <Icon name='home' style={{ color: '#fff' }} />
          &nbsp;&nbsp;
        </Link>
        BILLING WORKSHEET BY {props.userInitials}
      </Grid.Column>
      <Grid.Column className='header-style header-plain-text' textAlign='right'>
        BILLING # {props.billingId}
      </Grid.Column>
    </Grid>
  );
};

export default BillingHeader;
